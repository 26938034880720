import { initializeApp } from "firebase/app";
import { getMessaging, getToken } from "firebase/messaging";
import { getAuth } from "firebase/auth";
import { config, vapidKey } from "../../../config/firebase";

const app = initializeApp(config);

export const messaging = getMessaging(app);
export const auth = getAuth(app);

export const createToken = async () => {
  return new Promise(async (resolve, reject) => {
    try {
      const perm = await Notification.requestPermission();
      if (perm === "granted") {
        let token = await getToken(messaging, { vapidKey });
        resolve(token);
      }
    } catch (error) {
      reject();
    }
  });
};

export default app;
