import React from "react";
import { telephoneFormatter } from "../../utils/common";

const TenantsPersonalInfo1 = ({ application }) => {
  const { firstName, lastName, phoneMobile, email, photo } = application;

  return (
    <div className="card">
      <div className="card-body person-info-card">
        <div className="img">
          <img
            src={photo.url || "assets/img/avatar.jpg"}
            alt="Profile"
            className="rounded-circle"
          />
        </div>
        <div className="con">
          <div className="contact--name">
            {firstName} {lastName}
          </div>
          <div className="contact--email">{email}</div>
          <div className="contact--phone">
            {telephoneFormatter(phoneMobile)}
          </div>
        </div>
        <div className="tbl responsive-table">
          <table className="table table-hover">
            <thead>
              <tr>
                <th>Dependants</th>
                <th>Pets</th>
                <th>Move-In</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td data-target="Dependants">Need add to query</td>
                <td data-target="Pets">Need add to query</td>
                <td data-target="Move-In">Need add to query</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className="but">
          <div className="btn-group" role="group">
            <button
              id="btnGroupDrop1"
              type="button"
              className="btn btn-primary dropdown-toggle"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              Contact
            </button>
            <div
              className="dropdown-menu dropdown-menu-right"
              aria-labelledby="btnGroupDrop1"
            >
              <a className="dropdown-item" to="#!">
                {" "}
                {/*eslint-disable-line*/}
                Email
              </a>
              <a className="dropdown-item" to="#!">
                {" "}
                {/*eslint-disable-line*/}
                Message
              </a>
            </div>
          </div>
          <div className="btn-group" role="group">
            <button
              id="btnGroupDrop2"
              type="button"
              className="btn btn-secondary dropdown-toggle"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              Actions
            </button>
            <div
              className="dropdown-menu dropdown-menu-right"
              aria-labelledby="btnGroupDrop2"
            >
              <a className="dropdown-item" to="#!">
                {" "}
                {/*eslint-disable-line*/}
                Accept Application
              </a>
              <a className="dropdown-item" to="#!">
                {" "}
                {/*eslint-disable-line*/}
                Decline Application
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
    
  );
};

export default TenantsPersonalInfo1;
