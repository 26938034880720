/* eslint-disable */
import React from 'react';

const ThirdPartyApiListItem = React.memo(
  ({
    id,
    number,
    photos,
    bathrooms,
    bedrooms,
    sqft,
    children,
  }) => {
    return (
      <div className="card-row">
        <div className="block img-box d-inline-flex">
          <img
            src={
              Array.isArray(photos) && photos.length !== 0
                ? photos[0]
                : 'assets/img/404-boxes-background.jpg'
            }
            alt="404-boxes-background"
          />
        </div>
        <div className="block">
          <div className="card-titile">Unit number:{number}</div>
        </div>
        <div className="block card-aminites">
          <span>
            {bedrooms} Bed
          </span>{' '}
          <span className="dot" />
          <span>
            {bathrooms} Bath
          </span>
          <span className="dot" />
          <span>{`${sqft} sq.ft`}</span>
        </div>
        <div className="block">{children}</div>
      </div>
    );
  }
);

export default ThirdPartyApiListItem;
