import mixpanel from "mixpanel-browser";
import React, { Component, useState, useEffect } from "react";
import { toast } from "react-toastify";
import {
  Card,
  Confirm,
  Container,
  Grid,
  Icon,
  Button,
  Menu,
  Segment,
} from "semantic-ui-react";
import { PageLoader } from "../../components/Loader/PageLoader";
import { getClient } from "../../init-apollo-googleFn";
import { getCookie } from "../../store/auth/authUtility";
import { googleEventsGql } from "../../store/calendar/calendar";
import { pmApplicationsGql } from "../../store/person/applications";
import { TourLocation, updateTour } from "../../store/person/leads";
import { maintenanceGql } from "../../store/person/maintenance";
import { eventsGql } from "../../store/person/person";
import {
  APPLICATION_MANAGER,
  CALENDAR_SERVICE_URL,
  LEADS_TOUR,
  PERSON_URL,
} from "../../utils/constants";
import "./Schedule.scss";
import CreateEventModal from "./CreateEventModal";
import Tour from "reactour";
import { scheduleSteps } from "../../utils/tourSteps";
import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import withDragAndDrop from "react-big-calendar/lib/addons/dragAndDrop";
import "react-big-calendar/lib/addons/dragAndDrop/styles.css";
import "react-big-calendar/lib/css/react-big-calendar.css";
const localizer = momentLocalizer(moment);
const personClient = getClient(PERSON_URL);

function Schedule(props) {
  const [isOpen, toggleModal] = useState(false);

  const [eventList, setEventList] = useState([]);
  useEffect(() => {
    fetchCalendar();
  }, [props?.selectedPrimary?.node?.id]);

// Destructure the `selectedPrimary` prop and extract its `node.id` property into `locationId`
const fetchCalendar = async () => {
  const { selectedPrimary: { node: { id: locationId } } } = props;

  try {
    // Use the `personClient` to query for the `personCalendar` data
    const { data: { personCalendar: { edges = [] } = {} } = {} } = await personClient.query({
      // Use the `eventsGql` query and pass in `locationId` as a variable
      query: eventsGql,
      variables: { locationId },
    });
    
    // Map over the `edges` array and extract relevant data into a new array of objects
    const eventList = edges.map(({ node: { startDate, endDate, eventName, id } }) => ({
      id,
      title: eventName,
      // Use `moment` to add one day to each date and format it as a string, then convert to `Date` object
      start: new Date(moment(startDate).add(1, 'day').format('YYYY-MM-DD HH:mm:ss')),
      end: new Date(moment(endDate).add(1, 'day').format('YYYY-MM-DD HH:mm:ss')),
    }));
    
    // Update the `eventList` state with the new array of objects
    setEventList(eventList);
  } catch (error) {
    // Log any errors to the console
    console.error(error);
  }
};

  return (
    <div className="App">
      <div className="">
        <div className="top-flex">
          <div className="page-title">
            <h3>Schedule</h3>
          </div>
          <div className="page-button">
            <Button onClick={() => toggleModal(true)}>Add</Button>
          </div>
        </div>
      </div>

      <CreateEventModal
        getCal={fetchCalendar}
        locationId={props?.selectedPrimary?.node?.id}
        personId={props?.user?.ndbId}
        toggleModal={toggleModal}
        isOpen={isOpen}
      />
      <Calendar
        localizer={localizer}
        defaultDate={new Date()}
        defaultView="month"
        events={eventList}
        style={{ height: "70vh" }}
      />
    </div>
  );
}

export default Schedule;

