import React, { useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import RegularLogView from "./RegularView";
import SingleLogView from "./SingleLogView";

import { formatDateTo12Hours } from "../../../../utils/common";
import { MediaContentType } from "../../../../store/redux/chat";

const ChatLogs = ({ ref }) => {
  const { person, chat } = useSelector((store) => store);
  const { chatRoomId } = useParams();

  const chatLogsFromStore = chat.chatLogs;
  let chatLogs = [];
  if (chatRoomId !== undefined && chatLogsFromStore[chatRoomId]) {
    chatLogs = chatLogsFromStore[chatRoomId];
  }

  useEffect(() => {
    const a = document.getElementById("chat-logs");
    a.scrollTop = a.scrollHeight;
  }, []);

  useEffect(() => {
    const a = document.getElementById("chat-logs");
    // Focus to the last message if the scroll is more than 70% of scrollHeight
    const height = a.scrollHeight - a.offsetHeight;
    if (height * 0.7 < a.scrollTop) {
      a.scrollTop = a.scrollHeight;
    }
  }, [chatLogs]);

  return (
    <div className="chat-logs " id="chat-logs" ref={ref}>
      {chatLogs&&chatLogs.map((item, key) => {
        const time = formatDateTo12Hours(new Date(item.timeStamp));
        const isSender = item.senderId === person.ndbId ? "Y" : "N";
        const has_documents = item.media?.filter(
          ({ type }) =>
            ![MediaContentType.jpeg, MediaContentType.png].includes(type)
        );

        const props = { isSender, time, ...item };

        if (
          item.media?.length === 1 &&
          [(MediaContentType.jpeg, MediaContentType.png)].includes(
            item.media[0].content_type
          )
        ) {
          return <SingleLogView key={key} {...props} />;
        }

        return <RegularLogView key={key} {...props} />;
      })}
    </div>
  );
};

export default ChatLogs;
