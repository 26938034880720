import gql from "graphql-tag";

export const tenantsByLocationGql = gql`
  query Tenants($primaryLocationId: String, $filter: TenantFilters) {
    tenants(primaryLocationId: $primaryLocationId, filter: $filter) {
      edges {
        node {
          tenants {
            firstName
            lastName
            phoneMobile
            phoneHome
            email
          }
          ndbId
          paid
          property {
            place {
              price
            }
            location {
              unitNum
            }
          }
        }
      }
    }
  }
`;

export const billsGql = gql`
  query Bills($role: String, $leaseId: String) {
    bills(role: $role, leaseId: $leaseId) {
      transactions
      amount
      paid
      fees
      paidFull
      type
      created
      ndbId
    }
  }
`;

export const personPaymentActionGql = gql`
  mutation personPaymentAction($input: PersonPaymentActionInput!) {
    personPaymentAction(input: $input) {
      response
    }
  }
`;
