import React, { Component } from "react";
import { withApollo } from "react-apollo";
import mixpanel from "mixpanel-browser";
import { SlJournals } from "../../store/person/accounting";
import { getClient } from "../../init-apollo-googleFn";
import get from "lodash/get";
import { Loader } from "../../components/Loader/Loader";
import { GeneralAccountingTable } from "../../components/Tables/GeneralAccountingTable";
import { toast } from "react-toastify";
import { ACCOUNTING } from "../../utils/constants";
import "react-toastify/dist/ReactToastify.css";
import {
  Button,
} from "semantic-ui-react";
import JournalModal from "../../components/Modals/JournalModal";
import moment from "moment";
import { jsPDF } from "jspdf";
import "jspdf-autotable";

const ledgerClient = getClient(ACCOUNTING);

class Journal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      primary: this.props.selectedPrimary
        ? this.props.selectedPrimary.node
        : "",
      loading: false,
      tableData: [],
      offset: 0,
      view: false,
      ledgerCache: [],
    };
  }

  componentDidMount() {
    this.getLedger();
    mixpanel.track("Manager Page Load", {
      sub: "Ledger",
    });
  }
  componentWillReceiveProps(prevProps) {
    const nodeData = prevProps.selectedPrimary
      ? prevProps.selectedPrimary.node
      : "";
    if (nodeData !== this.state.primary) {
      this.setState({ primary: nodeData }, () => {
        this.setState({
          selectedMonthEvents: {
            ...this.state.selectedMonthEvents,
            montEvents: [],
          },
        });
        this.getLedger();
      });
    }
  }
  stateDataToPDF = () => {
    const doc = new jsPDF();
    const header = [
      "Number",
      "Ledger",
      "Type",
      "Location",
      "Ref",
      "Post",
      "Transaction",
      "Created",
      "Update",
      "Amount",
    ];

    // Add table header
    doc.setFontSize(15);
    doc.setTextColor(40);
    doc.text("Journal", 14, 15);
    console.log(this.state.tableData);
    // Convert data to array of arrays
    const data = this.state.tableData.map((item) => [
      item.number,
      item.sourceLedger,
      item.entryType,
      item.Location,
      item.reference,
      item.uiPostedDate,
      item.uiTransactionDate,
      item.uiCreatedAt,
      item.uiUpdatedAt,
      item.uiAmount,
    ]);

    // Add table
    doc.setFontSize(11);
    doc.setTextColor(0);
    doc.autoTable({
      head: [header],
      body: data,
      theme: "grid",
      margin: { top: 20 },
      styles: { overflow: "linebreak", fontSize: 8 },
      minCellWidth: 30, // Set a minimum width for all columns
      headStyles: {
        fillColor: [214, 202, 232], // Set the background color to light purple
        textColor: 0, // Set the text color to black
      },
    });

    // Output PDF
    doc.save("Journal.pdf");
  };

  success = () =>
    toast.success("Success!", {
      position: "top-center",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    }
    );

  getLedger = (data) => {
    try {
      this.setState({ loading: true });
      ledgerClient
        .query({
          query: SlJournals,
          variables: {
            location: this.props.selectedPrimary.node.id,
            status: 'draft',
            offset: 0,
            limit: 9999999
          },
        })
        .then((res) => {
          if (res.data) {
            const dataToPass = get(res, "data.slJournals.data", null).map((obj, i) => {
              const { amount, postedDate, transactionDate, createdAt, updatedAt } = obj;
              const uiPostedDate = postedDate ? moment(postedDate).format("MM/DD/YYYY") : "";
              const uiTransactionDate = transactionDate ? moment(transactionDate).format("MM/DD/YYYY") : "";
              const uiCreatedAt = createdAt ? moment(createdAt).format("MM/DD/YYYY") : "";
              const uiUpdatedAt = updatedAt ? moment(updatedAt).format("MM/DD/YYYY") : "";
              const uiAmount = `$ ${Number.parseFloat(amount).toLocaleString('en')}`;
              const actions = <JournalModal
                updateLedger={this.updateLedger}
                propertyId={this.props.selectedPrimary.node.id}
                getLedger={this.getLedger}
                props={obj}
                id={obj._id}
                primaryLocations={this.props.primaryLocations}
                user={this.props.user}
                success={this.success}
                edit={true}
              />
              return (({ number, sourceLedger, entryType, Location, reference }) => ({ number, sourceLedger, entryType, Location, reference, uiPostedDate, uiTransactionDate, uiCreatedAt, uiUpdatedAt, uiAmount, actions }))(obj)
            });
            this.setState({
              tableData: dataToPass,
              ledgerCache: get(res, "data.slJournals.data", null),
              totalItems: get(
                res,
                "data.slJournals.totalItems"
              ),
            });
          }
          this.setState({ loading: false });
        })
        .catch((error) => {
          console.log(error);
          this.setState({ loading: false });
        });
    } catch (e) {
      this.setState({ loading: false });
    }
  };

  dataPush = (response) => {
    const node = response.data.createSlLedgerAccount.slLedgerAccount;
    this.setState((prevState) => ({
      tableData: [...prevState.tableData, node],
    }));
  };

  updateLedger = (update, ledgerId) => {
    const { tableData } = this.state;
    const array = [];
    if (update === "Delete") {
      this.setState({
        tableData: tableData.filter((ledger) => ledger._id !== ledgerId),
      });
    } else {
      tableData.forEach((ledger) => {
        if (
          ledger._id === update.data.updateSlLedgerAccount.slLedgerAccount._id
        ) {
          ledger = update.data.updateSlLedgerAccount.slLedgerAccount;
          array.push(ledger);
        } else {
          array.push(ledger);
        }
      });
      this.setState({ tableData: array });
    }
  };
  hideAdd = (e) => {
    console.log(e);
  };

  handleOffset = (x) => {
    this.setState({ offset: x });
  }

  render() {
    const { loading } = this.state;
    const mainHeader = ["Number", "Ledger", "Type", "Location", "Reference", "Posted", "Transaction", "Created", "Updated", "Amount", "Actions"];
    const fail = () =>
      toast.error("No agent with this ID exists in our system", {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    const success = () =>
      toast.success("Success!", {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });

    // !: Error handling.
    // TODO: Style.
    return (
      <>
        <main className="main-content" role="main">
          <div className="row mb-4">
            <div className="col-md-12">
              <div className="page-header">
                <div className="d-flex align-items-center">
                  <div>
                    <div className="page-header-title">Draft Journal</div>
                  </div>
                </div>
                <div className="d-flex align-items-end">
                  <JournalModal
                    dataPush={this.dataPush}
                    fail={fail}
                    user={this.props.user}
                    success={success}
                    propertyName={
                      this.props.selectedPrimary.node &&
                      this.props.selectedPrimary.node.name
                    }
                    primaryLocations={this.props.primaryLocations}
                    getLedger={this.getLedger}
                    propertyId={
                      this.props.selectedPrimary.node &&
                      this.props.selectedPrimary.node.id
                    }
                  />
                  <Button
                    onClick={() => this.stateDataToPDF()}
                    compact
                    className="ml-4 noPrint noPrint-button"
                    style={{
                      backgroundImage:
                        "linear-gradient(110deg, #3b1c5a, #374db1 162%)",
                    }}
                  >
                    Print
                  </Button>
                </div>
              </div>
            </div>
          </div>
          {loading ? (
            <Loader text inTable />
          ) : (
            <>
              <GeneralAccountingTable
                dataPush={this.dataPush}
                getLedger={this.getLedger}
                hideAdd={this.hideAdd}
                mainCellData={this.state.tableData}
                mainHeader={mainHeader}
                primaryLocations={this.props.primaryLocations}
                propertyData={
                  this.props.selectedPrimary && this.props.selectedPrimary
                }
                propertyId={
                  this.props.selectedPrimary.node &&
                  this.props.selectedPrimary.node.id
                }
                totalItems={this.state.totalItems && this.state.totalItems}
                updateLedger={this.updateLedger}
                user={this.props.user}
              />
            </>
          )}
        </main>
      </>
    );
  }
}

export default withApollo(Journal);
