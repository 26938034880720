import React, { useEffect, useState, useReducer } from "react";
import { Button, Header, Image, Modal, Icon, Grid, List,Confirm } from 'semantic-ui-react'
import {
    UpdateCharges
} from "../../../../store/person/accounting";
import { ACCOUNTING, LEASE_MANAGER } from "../../../../utils/constants";
import { getClient } from '../../../../init-apollo-googleFn';
import { toastFailMsg, toastSuccessMsg } from "../../../../utils/common";


const chargeClient = getClient(LEASE_MANAGER);

function EditAmount(chargeCode) {
  const [open, setOpen] = React.useState(false)
  const [openConfirm, setOpenConfirm] = React.useState(false)
  const [newAmount, setNewAmount] = React.useState(chargeCode.amount)


   const ActionCharge = (e) => {
    try {
      chargeClient
        .mutate({
          mutation: UpdateCharges,
          variables: {
            input: {
                lease: {
                    leaseId:chargeCode.tenant.id,
                    chargeCode:[
                      {
                        chargeCodeId:chargeCode.id,
                        amount:newAmount,
                        isDelete: false
                      }
                    ]

                }
            },
          },
        })
        .then((response) => {
        chargeCode.fetchLeaseCharges()
        setOpenConfirm(false)
        toastSuccessMsg("Amount changed")
          setOpen(false);
        }) .catch((error) => {
            toastFailMsg("Something went wrong")
            setOpenConfirm(false);
          });
    } catch (e) {
      toastFailMsg("Something went wrong")
      setOpenConfirm(false);
    }
  }
  return (
      <>
    <Modal
        className="semanticModal"
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}
      size="mini"
      open={open}
      trigger={<Icon  name="pencil"/>}
    >
      <Modal.Header>Edit Amount</Modal.Header>
      <Modal.Content >
        <Modal.Description>
          <Grid columns="2" divided>    
            <Grid.Column>
            <List bulleted>
                <List.Item>{chargeCode.chargeCode}</List.Item>
                <List.Item>$ {chargeCode.amount}</List.Item>
                <List.Item>{chargeCode.date}</List.Item>
            </List>
            </Grid.Column>
            <Grid.Column>
                <label style={{display:'block'}} for="subject">New Value</label>
                <input onChange={(e)=> setNewAmount(e.target.value)} style={{display:'block'}} id="subject" type="text" value={newAmount} name="subject"/>
            </Grid.Column>
          </Grid>
        </Modal.Description>
      </Modal.Content>
      <Modal.Actions>
      <Button content='Close' onClick={() => setOpen(false)} secondary floated="left"/>
        <Button
          content="Confirm"
          labelPosition='right'
          icon='checkmark'
          onClick={() => setOpenConfirm(true)}
          positive
        />
      </Modal.Actions>
    </Modal>
        <Confirm
            className="semanticModal"
            size="mini"
            content='Are you sure you wish to change this amount?'
          open={openConfirm}
          onCancel={() => setOpenConfirm(false)}
          onConfirm={() => ActionCharge()}
        />
        </>
  )
}

export default EditAmount