import React, { useState } from "react";
import { Button, Modal, Header, Form, Dropdown, Grid, Icon, Divider } from "semantic-ui-react";
import get from "lodash/get";
import { getClient } from "../../init-apollo-googleFn";

import { LedgerAccounting } from "../../store/person/accounting";
import Datetime from "react-datetime";
import moment from "moment";
import "./moveLeadModal.scss";
import {
  DeleteSlJournal,
  PostSlJournal,
  EditSlJournal,
  LocationJournal,
  VendorsAccounting,
  ManagerInvoice,
  CreateSlJournal
} from "../../store/person/accounting";
import { ACCOUNTING, LEASE_MANAGER } from "../../utils/constants";
import { toastFailMsg, toastSuccessMsg } from "../../utils/common";
const chargeClient = getClient(ACCOUNTING);
const managerClient = getClient(LEASE_MANAGER);

export default function JournalModal(props) {
  const [open, setOpen] = useState(false);
  const [entryType, entryTypeFunc] = useState(false);
  const [reference, referenceFunc] = useState(false);
  const [transactionDate, transactionDateFunc] = useState(false);
  const [postedDate, postedDateFunc] = useState(false);
  const [location, locationFunc] = useState(false);
  const [locationName, locationNameFunc] = useState(false);
  const [customerName, customerNameFunc] = useState(false);
  const [vendorName, vendorNameFunc] = useState(false);
  const [locationLedger, locationLedgerFunc] = useState(false);
  const [icLocation, icLocationFunc] = useState(false);
  const [customer, customerFunc] = useState(false);
  const [customerArray, customerArrayFunc] = useState([]);
  const [vendor, vendorFunc] = useState(false);
  const [vendorArray, vendorArrayFunc] = useState([]);
  const [editValuesArray, editValuesArrayFunc] = useState([]);
  const [document, documentFunc] = useState([]);
  const [notes, notesFunc] = useState('');
  const [ledgerArray, ledgerArrayFunc] = useState([]);
  const [ledgerChosenArray, ledgerChosenArrayFunc] = useState([
    {
      "description": null,
      "debit": 0,
      "credit": 0,
      "LedgerAccountId": null,
    },
    {
      "description": null,
      "debit": 0,
      "credit": 0,
      "LedgerAccountId": null,
    },
  ]);
  const [loading, setLoader] = useState(false);

  const postJournal = () => {
    chargeClient
      .mutate({
        mutation: PostSlJournal,
        variables: {
          input: {
            journalId: props.props._id,
            location: location,
          }
        }
      })
      .then((res) => {
        if (props.edit && res.data.postSlJournal.response == '400') {
          toastFailMsg(res.data.postSlJournal.slJournal.error)
        }
        else {
          toastSuccessMsg("Draft Journal Posted")
          props.getLedger()
          setOpen(false)
        }
      })
      .catch((error) => {

      });
  }

  const deleteJournal = () => {
    chargeClient
      .mutate({
        mutation: DeleteSlJournal,
        variables: {
          input: {
            journalId: props.props._id,
            location: location,
          }
        }
      })
      .then((res) => {
        if (props.edit && res.data.deleteSlJournal.response == '400') {
          toastFailMsg(res.data.deleteSlJournal.slJournal)
        }
        else {
          toastSuccessMsg("Draft Journal Deleted")
          props.getLedger()
          setOpen(false)
        }
      })
      .catch((error) => {

      });
  }

  const createJournal = (e) => {
    ledgerChosenArray.forEach(function (element) {
      element.transactionDate = moment(transactionDate).format("YYYY-MM-DD");
      element.postedDate = moment(postedDate).format("YYYY-MM-DD");
      if (customer) {
        element.AgentId = customer;
      }
      if (vendor) {
        element.VendorId = vendor;
      }
    });
    chargeClient
      .mutate({
        mutation: props.edit ? EditSlJournal : CreateSlJournal,
        variables: props.edit ? {
          journalId: props.props._id,
          input: {
            status: "Draft",
            entryType: entryType,
            sourceLedger: locationLedger,
            currency: "USD",
            notes: notes,
            reference: reference,
            location: location,
            Transactions: ledgerChosenArray
          }
        } : {
          input: {
            status: "Draft",
            entryType: entryType,
            sourceLedger: locationLedger,
            currency: "USD",
            notes: notes,
            reference: reference,
            location: location,
            Transactions: ledgerChosenArray
          },
        }

      })
      .then((res) => {
        if (props.edit && res.data.updateSlJournal.response == '400') {
          toastFailMsg(res.data.updateSlJournal.slJournal)
        }
        else if (!props.edit && res.data.createSlJournal.response == "400") {
          toastFailMsg(res.data.createSlJournal.slJournal)
        }
        else {
          toastSuccessMsg("Draft Journal Created")
          props.getLedger()
          setOpen(false)
        }
      })
      .catch((error) => {

      });
  };

  const entryArray = [
    {
      key: 'Standard',
      text: 'Standard',
      value: 'Standard',
    },
    {
      key: 'Reversing',
      text: 'Reversing',
      value: 'Reversing',
    },
  ]
  const ledgerPrimaryArray = [
    {
      key: 'Financial',
      text: 'Financial',
      value: 'Financial',
    },
    {
      key: 'AR',
      text: 'AR',
      value: 'AR',
    },
    {
      key: 'AP',
      text: 'AP',
      value: 'AP',
    },
  ]



  const handleCustomerChange = (event, { value, text }) => {
    if (value) {
      customerFunc(value)
      customerNameFunc(event.target.textContent)
    }
  }
  const handleVendorChange = (event, { value, text }) => {
    if (value) {
      vendorFunc(value)
      vendorNameFunc(event.target.textContent)
    }
  }
  const handleLedgerChange = (value, index) => {
    if (value) {
      ledgerChosenArrayFunc(Object.values({ ...ledgerChosenArray, [index]: { ...ledgerChosenArray[index], LedgerAccountId: value } }))
    }
  }


  const runLedgerQuery = () => {
    chargeClient
      .query({
        query: LedgerAccounting,
        variables: {
          location: props.propertyId,
          offset: 0,
          limit: 1000,
        },
      })
      .then((res) => {
        ledgerArrayFunc(res.data.slLocationLedgerAccounts.data)
      })
      .catch((error) => {

      });
  }
  const runVendorQuery = () => {
    chargeClient
      .query({
        query: VendorsAccounting,
        variables: {
          location: props.propertyId,
        },
      })
      .then((res) => {
        vendorArrayFunc(get(res, "data.slLocationVendors.data", []),)
      })
      .catch((error) => {

      });
  }

  const runCustomerQuery = () => {
    managerClient
      .query({
        query: ManagerInvoice,
        variables: {
          locationId: props.propertyId,
        },
      })
      .then((res) => {
        customerArrayFunc(res.data.invoiceCustomers);
      })
      .catch((error) => {

      });
  }

  const runEditJournalQuery = () => {

    chargeClient
      .query({
        query: LocationJournal,
        variables: {
          location: props.propertyId,
          journal: props.id.toString()
        },
      })
      .then((res) => {
        const editValues = res.data.slLocationAccountJournal
        var names = editValues.Transactions.map(function (item) {
          return item.LedgerAccount.name;
        });
        editValuesArrayFunc(names)
        entryTypeFunc(editValues.entryType)
        referenceFunc(editValues.reference)
        transactionDateFunc(props.props.transactionDate)
        postedDateFunc(props.props.postedDate)
        locationFunc(props.propertyId)
        locationNameFunc(props.props.Location)
        locationLedgerFunc(editValues.sourceLedger)
        icLocationFunc(false)
        customerFunc(editValues.Transactions[0].AgentId)
        customerNameFunc(editValues.Customer && editValues.Customer.first_name + " " + editValues.Customer.last_name)
        customerArrayFunc([])
        vendorFunc(editValues.VendorId)
        vendorNameFunc(editValues.Vendor && editValues.Vendor.name)
        vendorArrayFunc([])
        documentFunc([])
        notesFunc(editValues.notes)
        ledgerArrayFunc([])
        const LedgerObject = editValues.Transactions.map((item) =>
        ({
          "description": item.description,
          "debit": item.debit,
          "credit": item.credit,
          "LedgerAccountId": item.LedgerAccountId,
        }));
        ledgerChosenArrayFunc(LedgerObject);
      })
      .catch((error) => {

      });
  }

  const AddRow = () => {
    const newObj = {
      "description": null,
      "debit": 0,
      "credit": 0,
      "LedgerAccountId": null,
      "AgentId": 0,
      "VendorId": 0,
    }
    ledgerChosenArrayFunc([...ledgerChosenArray, newObj])
    ValidationCheck()
  }

  const RemoveRow = () => {
    if (ledgerChosenArray.length > 2) {
      const Arr = ledgerChosenArray.slice(0, ledgerChosenArray.length - 1);
      ledgerChosenArrayFunc(Arr)
    }
    else return
  }

  const ValidationCheck = () => {
    if (!entryType ||
      !reference ||
      !transactionDate ||
      !postedDate ||
      !ledgerChosenArray[0].LedgerAccountId ||
      !ledgerChosenArray[0].debit ||
      !ledgerChosenArray[0].credit ||
      !ledgerChosenArray[1].LedgerAccountId ||
      !ledgerChosenArray[1].debit ||
      !ledgerChosenArray[1].credit ||
      ledgerChosenArray.filter(item => item.LedgerAccountId == null).length > 0 ||
      ledgerChosenArray.filter(item => item.debit == '').length > 0 ||
      ledgerChosenArray.filter(item => item.credit == '').length > 0 ||
      ledgerChosenArray.reduce((partialSum, a) => partialSum + +a.debit, 0) - ledgerChosenArray.reduce((partialSum, a) => partialSum + +a.credit, 0) !== 0
    ) {
      return true
    }
    else return false
  }
  const ClearState = () => {
    if (props.edit) {
      runEditJournalQuery()

    }
    else {
      entryTypeFunc(false)
      referenceFunc(false)
      transactionDateFunc(false)
      postedDateFunc(false)
      locationFunc(false)
      locationLedgerFunc(false)
      locationNameFunc(false)
      icLocationFunc(false)
      customerFunc(false)
      customerArrayFunc([])
      vendorFunc(false)
      vendorArrayFunc([])
      documentFunc([])
      notesFunc('')
      ledgerArrayFunc([])
      if (props) {
        locationNameFunc(props.propertyName)
        locationFunc(props.propertyId)
      }
      runCustomerQuery()
      runVendorQuery()
      runLedgerQuery()
      ledgerChosenArrayFunc([
        {
          "description": null,
          "debit": 0,
          "credit": 0,
          "transactionDate": transactionDate,
          "postedDate": postedDate,
          "LedgerAccountId": null,
          "AgentId": 0,
          "VendorId": 0,
        },
        {
          "description": null,
          "debit": 0,
          "credit": 0,
          "transactionDate": transactionDate,
          "postedDate": postedDate,
          "LedgerAccountId": null,
          "AgentId": 0,
          "VendorId": 0,
        },
      ]);
    }
  }


  return (
    <Modal
      className="semanticModal modal-radius journal-modal overflow-y-visible"
      onOpen={() => setOpen(true)}
      open={open}

      trigger={
        <Button
          icon={props.edit}
          onClick={ClearState}
          compact
          className="ml-3 button-hover-universal"
          id="getLedger"
          size="tiny"
        >
          {props.edit ? <Icon name='edit' /> : <span>Create</span>}
        </Button>
      }
    >
      <Modal.Header
        textAlign="left"
        className="modal-header-bg position-sticky "
      >
        <Header
          className="modal-heading-custom position-sticky"
          textAlign="left"
        >
          {props.props ? "Update Journal" : "Create Journal"}
        </Header>
      </Modal.Header>
      <Modal.Content image>
        <Modal.Description className="journal-entry-modal">
          <Form color="blue">
            {/* First Row */}
            <Form.Group widths="equal">
              <Form.Field required>
                <label >
                  Entry Type
                </label>
                <Dropdown
                  options={entryArray}
                  placeholder={entryType}
                  onChange={(e) => entryTypeFunc(e.target.textContent)}
                  selection
                />
              </Form.Field>
              <Form.Field required>
                <label>Reference</label>
                <input
                  value={reference || ""}
                  onChange={(e) => referenceFunc(e.target.value)}
                />
              </Form.Field>
              <Form.Field required>
                <label>Transaction Date</label>
                <Datetime
                  value={transactionDate && moment(transactionDate).format("MM/DD/YYYY")}
                  timeFormat={false}
                  onChange={transactionDateFunc}
                />
              </Form.Field>
              <Form.Field required>
                <label>Posted Date</label>
                <Datetime
                  value={postedDate && moment(postedDate).format("MM/DD/YYYY")}
                  timeFormat={false}
                  onChange={postedDateFunc}
                />
              </Form.Field>
            </Form.Group>
            {/* Second Row */}
            <Form.Group widths="equal">
              <Form.Field required>
                <label >
                  Location
                </label>
                <input
                  value={locationName}
                  disabled
                />
              </Form.Field>
              <Form.Field required>
                <label>Source Ledger</label>
                <Dropdown
                  onChange={(e) => locationLedgerFunc(e.target.textContent)}
                  selection
                  placeholder={locationLedger}
                  options={ledgerPrimaryArray}
                />
              </Form.Field>
              <Form.Field >
                <label>Renter Code</label>
                <Dropdown
                  onChange={handleCustomerChange}
                  selection
                  placeholder={customerName}
                  options={customerArray && customerArray.map((val) => ({
                    key: val.agentId,
                    text: val.name,
                    value: val.agentId,
                  }))}
                />
              </Form.Field>
              <Form.Field>
                <label>Vendor</label>
                <Dropdown
                  onChange={handleVendorChange}
                  selection
                  placeholder={vendorName}
                  options={vendorArray && vendorArray.map((val) => ({
                    key: val._id,
                    text: val.name,
                    value: val._id,
                  }))}
                />
              </Form.Field>
            </Form.Group>
            <Divider />
            {/* Third Row Ledger */}
            <div className="ledger-modal-scroll">
              {
                ledgerChosenArray.map((ledger, index) => {
                  return (
                    <Form.Group widths="equal">
                      <Form.Field required>
                        <label >
                          GL Code
                        </label>
                        <Dropdown
                          onChange={(e, { value }) => handleLedgerChange(value, index)}
                          selection
                          placeholder={editValuesArray && editValuesArray[index] || ""}
                          options={ledgerArray.map((val) => ({
                            key: val._id,
                            text: val.name + "-" + val.number,
                            value: val._id,
                          }))}
                        />
                      </Form.Field>
                      <Form.Field >
                        <label>Description</label>
                        <input
                          value={ledgerChosenArray[index].description || ""}
                          onChange={(e) => ledgerChosenArrayFunc(Object.values({ ...ledgerChosenArray, [index]: { ...ledgerChosenArray[index], description: e.target.value } }))}
                        />
                      </Form.Field>
                      <Form.Field required>
                        <label>Debit</label>
                        <input
                          onKeyPress={(event) => {
                            if (!/^[0-9 ()+-]+$/.test(event.key)) {
                              event.preventDefault();
                            }
                          }}
                          value={ledgerChosenArray[index].debit || ""}
                          onChange={(e) => ledgerChosenArrayFunc(Object.values({ ...ledgerChosenArray, [index]: { ...ledgerChosenArray[index], debit: e.target.value } }))}
                        />
                      </Form.Field>
                      <Form.Field required>
                        <label>Credit</label>
                        <input
                          onKeyPress={(event) => {
                            if (!/^[0-9 ()+-]+$/.test(event.key)) {
                              event.preventDefault();
                            }
                          }}
                          value={ledgerChosenArray[index].credit || ""}
                          onChange={(e) => ledgerChosenArrayFunc(Object.values({ ...ledgerChosenArray, [index]: { ...ledgerChosenArray[index], credit: e.target.value } }))}
                        />
                      </Form.Field>
                    </Form.Group>
                  )
                })
              }
            </div>
            <Divider />
            <Grid columns={4} >
              <Grid.Column>Total</Grid.Column>
              <Grid.Column></Grid.Column>
              <Grid.Column textAlign="right">${ledgerChosenArray.reduce((partialSum, a) => partialSum + +a.debit, 0)}</Grid.Column>
              <Grid.Column textAlign="right">${ledgerChosenArray.reduce((partialSum, a) => partialSum + +a.credit, 0)}</Grid.Column>
            </Grid>
            <br></br>
            <Button compact onClick={() => AddRow()}>Add Row</Button><Button compact onClick={() => RemoveRow()}>Delete Row</Button>
            <Form.Group widths="equal">
              <Form.Field>
                <label>Notes</label>
                <textarea
                  style={{ height: "50px" }}
                  value={notes}
                  onChange={(e) => notesFunc(e.target.value)}
                />
              </Form.Field>
            </Form.Group>
          </Form>
        </Modal.Description>
      </Modal.Content>
      <Modal.Actions>
        <Button
          className="modal-close-button"
          content="Close"
          onClick={() => setOpen(false)}
          positive
        />
        {loading ? (
          <Button loading>Loading</Button>
        ) : (
          <>
            {/* eslint-disable */}
            {
              props.edit &&
              <>
                <Button
                  disabled
                  className="modal-save-button"
                  content={"Post"}
                  onClick={postJournal}
                  positive
                />
                <Button
                  disabled={ValidationCheck()}
                  className="modal-save-button"
                  content={"Delete"}
                  onClick={deleteJournal}
                  positive
                />
              </>
            }
            <Button
              disabled={ValidationCheck()}
              className="modal-save-button"
              content={props.edit ? "Update" : "Create"}
              onClick={createJournal}
              positive
            />
            {/* eslint-enable */}
          </>
        )}
      </Modal.Actions>
    </Modal>
  );
}