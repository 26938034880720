import React, { useState, useEffect } from "react";
import {
  Button,
  Header,
  Form,
  Checkbox,
  Dropdown,
  Modal,
  Select,
  Input,
  Breadcrumb,
} from "semantic-ui-react";
import { get } from "lodash";
import { unitType, unitsOnly } from "../../store/person/properties";
import {
  APPLICATION_MANAGER,
  LEASE_MANAGER,
  BLUEMOON_API,
  LEASE_TIME_FORMAT,
  UNITAPI,
  LEASE_FREQUENCY,
  UNIT_TYPE_API,
  ACCOUNTING,
  CHARGE_CODE,
  LOCATIONAPI,
} from "../../utils";
import {
  ChargeAccounting,
  DefaultChargeCodes,
} from "../../store/person/accounting";
import moment from "moment";
import Datetime from "react-datetime";
import { getClient } from "../../init-apollo-googleFn";
import {
  getLeaseClaim,
  unitAvailabilityCheck,
  proRate,
  createLease,
  locationPolicies,
  lgavailable,
} from "../../store/person/leases";
import {
  toastFailMsg,
  toastSuccessMsg,
  disablePastDt,
} from "../../utils/common";
import { InviteRenter } from "../../store/person/applications";

const leaseManager = getClient(LEASE_MANAGER);
const bluemoonApi = getClient(BLUEMOON_API);
const unitManager = getClient(UNITAPI);
const unitTypeManager = getClient(UNIT_TYPE_API);
const chargeClient = getClient(ACCOUNTING);
const chargeCodeClient = getClient(CHARGE_CODE);
const applicationQuery = getClient(APPLICATION_MANAGER);
const locationClient = getClient(LOCATIONAPI);

function InviteResident(props) {
  const [open, setOpen] = React.useState(false);
  const [startDate, setStartDate] = React.useState(false);
  const [endDate, setEndDate] = React.useState(false);
  const [moveDate, setMoveDate] = React.useState(false);
  const [unitTypeData, setUnitTypeData] = React.useState(false);
  const [unitTypeText, setUnitTypeText] = React.useState(false);
  const [unitsData, setUnitsData] = React.useState(false);
  const [unitNumberText, setNumberText] = React.useState(false);
  const [frequency, setFrequency] = React.useState(false);
  // const [frequency, setFrequency] = React.useState(false);
  // const [open, setOpen] = React.useState(false);
  // const [open, setOpen] = React.useState(false);
  // const [open, setOpen] = React.useState(false);
  // const [open, setOpen] = React.useState(false);
  const [chargeCodes, setCharges] = React.useState([]);
  const [chargeOptions, setChargeOptions] = React.useState([]);
  const [unitTypesData, setUnitTypesData] = React.useState([]);
  const [inviteArray, inviteArrayFunc] = useState([
    {
      email: null,
    },
  ]);

  const getLedger = () => {
    // getUnitTypeData();
    chargeClient
      .query({
        query: ChargeAccounting,
        variables: {
          location: props.location.id,
        },
      })
      .then((res) => {
        if (res.data) {
          setCharges(get(res, "data.chargeCodes.edges", null));
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
 const fetchUnitNumber = () => {
    unitManager
      .query({
        query: unitsOnly,
        variables: {
          locationId: props.location.id,
          // unitTypesId: !id ? value.id : id.id,
        },
      })
      .then((res) => {
        
      })
      .catch((error) => {
        console.log(error);
      });
  };
  // const getDefaultChargeCodes = () => {
  //   chargeCodeClient
  //     .query({
  //       query: DefaultChargeCodes,
  //       variables: {
  //         locationId: props.location,
  //       },
  //     })
  //     .then((res) => {
  //       if (res.data) {
  //         const data =
  //           res.data.defaultChargeCodes.edges &&
  //           res.data.defaultChargeCodes.edges[0];
  //         const {
  //           unitChargeCode,
  //           petChargeCode,
  //           vehicleChargeCode,
  //           depositChargeCode,
  //         } = data.node;
  //         if (unitChargeCode) {
  //           this.setState({
  //             unitChargeText: unitChargeCode.name,
  //             unitChargeCode: unitChargeCode.id,
  //             unitChargeAmount: application.unitNumber
  //               ? application.unitNumber.price
  //               : unitChargeCode.amountDefault,
  //             unitQuantity: unitChargeCode.quantity,
  //           });
  //         }
  //         if (petChargeCode) {
  //           this.setState({
  //             petChargeText: petChargeCode.name,
  //             petChargeCode: petChargeCode.id,
  //             petChargeAmount: petChargeCode.amountDefault,
  //             petQuantity: petChargeCode.quantity,
  //           });
  //         }
  //         if (vehicleChargeCode) {
  //           this.setState({
  //             vehicleChargeText: vehicleChargeCode.name,
  //             vehicleChargeCode: vehicleChargeCode.id,
  //             vehicleChargeAmount: vehicleChargeCode.amountDefault,
  //             vehicleQuantity: vehicleChargeCode.quantity,
  //           });
  //         }
  //         if (depositChargeCode) {
  //           this.setState({
  //             depositChargeText: depositChargeCode.name,
  //             depositChargeCode: depositChargeCode.id,
  //             depositChargeAmount: depositChargeCode.amountDefault,
  //             depositQuantity: depositChargeCode.quantity,
  //           });
  //         }
  //       }
  //     })
  //     .catch((error) => {
  //       console.log(error);
  //     });
  // };

  const prepareChargeOptions = () => {
    const chargeOptions =
    chargeCodes &&
    chargeCodes.map((charge) => ({
        key: charge.node.id,
        text: charge.node.name,
        value: charge.node,
      }));
      setChargeOptions(chargeOptions)
  };
  useEffect(() => {
    prepareChargeOptions();
  }, [chargeCodes]);
  // useEffect(() => {
  //   getDefaultChargeCodes();
  // }, [unitTypesData]);
  useEffect(() => {
    getLedger();
  }, []);
  useEffect(() => {
    console.log(moment(startDate).format('LL'), moment(endDate).format('LL'), moment(moveDate).format('LL'));
    fetchUnitNumber()
  }, [startDate, endDate, moveDate]);

 

  const AddRow = () => {
    const newObj = {
      email: null,
    };
    inviteArrayFunc([...inviteArray, newObj]);
  };
  const RemoveRow = () => {
    if (inviteArray.length > 1) {
      const Arr = inviteArray.slice(0, inviteArray.length - 1);
      inviteArrayFunc(Arr);
    } else return;
  };

  const validationTest = () => {
    if (
      inviteArray.every((e) =>
        /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(e.email)
      )
    ) {
      return false;
    } else {
      return true;
    }
  };

  return (
    <Modal
      className="semanticModal"
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}
      open={open}
      size="large"
      trigger={
        <Button
          onClick={() =>
            inviteArrayFunc([
              {
                email: null,
              },
            ])
          }
          className="btn-gradient btn-med residents-fourth-step"
        >
          Invite Resident
        </Button>
      }
    >
      <Modal.Header>Invite Resident</Modal.Header>
      <Modal.Content>
        <Modal.Description>
          <Form className="unit-info-form mt-2">
            <Form.Group widths="equal">
              <div className="move-date required field">
                <label className="move-date-label">Start Date</label>
                <Datetime
                  timeFormat={false}
                  minDate={moment().toDate()}
                  closeOnSelect
                  inputProps={{ readOnly: true, placeholder: "MM/DD/YYYY" }}
                  defaultValue={moment(startDate)}
                  onChange={(e) => setStartDate(e)}
                />
              </div>
              <div className="move-date required field">
                <label className="move-date-label">End Date</label>
                <Datetime
                  timeFormat={false}
                  isValidDate={disablePastDt}
                  minDate={moment().toDate()}
                  closeOnSelect
                  inputProps={{ readOnly: true, placeholder: "MM/DD/YYYY" }}
                  defaultValue={moment(endDate)}
                  onChange={(e) => setEndDate(e)}
                />
              </div>
              <div className="move-date required field">
                <label className="move-date-label">Move Date</label>
                <Datetime
                  timeFormat={false}
                  minDate={moment().toDate()}
                  closeOnSelect
                  inputProps={{ readOnly: true, placeholder: "MM/DD/YYYY" }}
                  defaultValue={moment(moveDate)}
                  onChange={(e) => setMoveDate(e)}
                />
              </div>
              {/* <Form.Select
                  label="Bill Day"
                  placeholder="Bill Day"
                  selectOnBlur={false}
                  search
                  disabled
                  selection
                  text={this.state.frequency == "Monthly" ? "1" : "1 & 15"}
                  value={this.state.frequency == "Monthly" ? "1" : "1 & 15"}
                  className="required"
                /> */}
            </Form.Group>
            <Form.Group widths="equal" className="unit-info-form-first-row">
              {/* <Form.Select
                label="Floor Plan"
                placeholder="Floor Plan"
                selectOnBlur={false}
                search
                options={unitTypeData && unitTypeData}
                selection
                text={unitTypeText}
                value={unitTypeText}
                // onChange={this.fetchUnitNumber}
                className="required"
              /> */}
              <Form.Select
                label="Unit Number"
                placeholder="Unit Number"
                selection
                selectOnBlur={false}
                search
                options={unitsData}
                text={unitNumberText}
                value={unitNumberText}
                // onChange={this.selectUnit}
                className="required"
              />
              <Form.Select
                label="Frequency"
                search
                selection
                options={LEASE_FREQUENCY}
                text={frequency}
                selectOnBlur={false}
                // onChange={this.selectFreq}
                className="required"
              />
            </Form.Group>
            <Form.Group widths="equal" className="unit-info-form-first-row">
              <Form.Select
                label="Unit Charge Code"
                selection
                selectOnBlur={false}
                search
                options={chargeCodes}
                // text={
                //   unitChargeText
                //     ? `${unitChargeText} - $${unitChargeAmount}`
                //     : ""
                // }
                // onChange={this.selectUnitChargeCode}
                className="required"
              />
              <Form.Input
                label="Unit Amount Per Period"
                search
                required
                type="number"
                // value={unitChargeAmount}
                selectOnBlur={false}
                // onChange={(e) =>
                //   this.onChangeChargeAmount(e, "unitChargeAmount")
                // }
                className="height-38"
              />
              {/* {proRateEnabled && (
                <Form.Input
                  label="Unit Pro Rata"
                  required
                  type="number"
                  value={unitProRata}
                  selectOnBlur={false}
                  onChange={(e) =>
                    this.setState({ unitProRata: e.target.value })
                  }
                  className="height-38"
                />
              )} */}
              <Form.Input
                label="Unit Quantity"
                search
                disabled
                required
                type="number"
                // defaultValue={unitQuantity}
                selectOnBlur={false}
                // onChange={(e) =>
                //   this.setState({ unitQuantity: e.target.value })
                // }
                className="height-38"
              />
            </Form.Group>
            <Form.Group widths="equal" className="unit-info-form-first-row">
              {/* eslint-disable */}
              <Form.Select
                label="Pet Charge Code"
                selection
                search
                required
                // options={chargeOptions}
                // text={
                //   petChargeText
                //     ? `${petChargeText} - ` + `$${petChargeAmount}`
                //     : ""
                // }
                selectOnBlur={false}
                // onChange={this.selectPetChargeCode}
              />
              {/* eslint-enable */}
              <Form.Input
                label="Pet Amount Per Period"
                search
                required
                type="number"
                // value={petChargeAmount}
                selectOnBlur={false}
                // onChange={(e) =>
                //   this.onChangeChargeAmount(e, "petChargeAmount")
                // }
                className="height-38"
              />
              {/* {proRateEnabled && (
                <Form.Input
                  label="Pet Pro Rata"
                  required
                  type="number"
                  // value={petProRata}
                  selectOnBlur={false}
                  onChange={(e) =>
                    this.setState({ petProRata: e.target.value })
                  }
                  className="height-38"
                />
              )} */}
              <Form.Input
                label="Pet Quantity"
                search
                required
                type="number"
                // defaultValue={petQuantity}
                selectOnBlur={false}
                // onChange={(e) => this.setState({ petQuantity: e.target.value })}
                className="height-38"
              />
            </Form.Group>
            <Form.Group widths="equal" className="unit-info-form-first-row">
              <Form.Select
                label="Vehicle Charge Code"
                selection
                search
                required
                // options={chargeOptions}
                // text={
                //   vehicleChargeText
                //     ? `${vehicleChargeText} - $${vehicleChargeAmount}`
                //     : ""
                // }
                selectOnBlur={false}
                // onChange={this.selectVehicleChargeCode}
              />
              <Form.Input
                label="Vehicle Amount Per Period"
                search
                required
                type="number"
                // value={vehicleChargeAmount}
                selectOnBlur={false}
                // onChange={(e) =>
                //   this.onChangeChargeAmount(e, "vehicleChargeAmount")
                // }
                className="height-38"
              />
              {/* {proRateEnabled && (
                <Form.Input
                  label="Vehicle Pro Rata"
                  required
                  type="number"
                  value={vehicleProRata}
                  selectOnBlur={false}
                  onChange={(e) =>
                    this.setState({ vehicleProRata: e.target.value })
                  }
                  className="height-38"
                />
              )} */}
              <Form.Input
                label="Vehicle Quantity"
                search
                required
                type="number"
                // defaultValue={vehicleQuantity}
                selectOnBlur={false}
                // onChange={(e) =>
                //   this.setState({ vehicleQuantity: e.target.value })
                // }
                className="height-38"
              />
            </Form.Group>
            <Form.Group widths="equal" className="unit-info-form-first-row">
              <Form.Select
                label="Fixed Deposit"
                selection
                search
                required
                // options={chargeOptions}
                // text={depositChargeText ? `${depositChargeText}` : " "}
                selectOnBlur={false}
                // onChange={this.selectDepositChargeCode}
              />
              <Form.Input
                label="Deposit Amount"
                search
                required
                type="number"
                // defaultValue={Number(0)}
                // value={fixedDepositAmount}
                selectOnBlur={false}
                // onChange={(e) =>
                //   this.setState({ fixedDepositAmount: e.target.value })
                // }
                className="height-38"
              />
              <Form.Input
                label="Non Refundable Amount"
                search
                required
                type="number"
                // defaultValue={Number(0)}
                // value={refundableAmount}
                selectOnBlur={false}
                // onChange={(e) =>
                //   this.setState({ refundableAmount: e.target.value })
                // }
                className="height-38"
              />
            </Form.Group>
            {/* {extraCharges.map((charge, index) => (
              <Form.Group
                widths="equal"
                className="unit-info-form-first-row"
                key={charge.id}
              >
                <Form.Select
                  label={`Extra Charge ${index + 1} Code`}
                  selection
                  search
                  options={chargeOptions}
                  text={
                    `Extra Charge ${index + 1} - $${charge.amountDefault}` || ""
                  }
                  selectOnBlur={false}
                  onChange={(...args) =>
                    this.onChangeExtraCharge(...args, index)
                  }
                />
                <Form.Input
                  label={`Extra Charge ${index + 1} Amount`}
                  search
                  type="number"
                  defaultValue={charge.amountDefault}
                  selectOnBlur={false}
                  onChange={(e) =>
                    this.onChangeExtraChargeAmount(
                      e.target.value,
                      "amountDefault",
                      index
                    )
                  }
                  className="height-38"
                />
                {proRateEnabled && (
                  <Form.Input
                    label="Extra Charge Pro Rata"
                    required
                    type="number"
                    value={charge.proRata}
                    selectOnBlur={false}
                    onChange={(e) =>
                      this.onChangeExtraChargeAmount(
                        e.target.value,
                        "proRata",
                        index
                      )
                    }
                    className="height-38"
                  />
                )}
                <Form.Input
                  label={`Extra Charge ${index + 1} Quantity`}
                  search
                  type="number"
                  defaultValue={charge.quantity}
                  selectOnBlur={false}
                  onChange={(e) =>
                    this.onChangeExtraChargeAmount(
                      e.target.value,
                      "quantity",
                      index
                    )
                  }
                  className="height-38"
                />
              </Form.Group>
            ))} */}
            {/* {isBluemoon && signProcess === "esign" && (
                <Form.Group>
                  <Form.Field style={{ width: "100%" }}>
                    <label>Custom Policy</label>
                    <Dropdown
                      placeholder="Custom Policy"
                      search
                      multiple
                      selection
                      defaultValue={customDataOptions}
                      options={customOptions}
                      onChange={this.setCustomData}
                    />
                  </Form.Field>
                  <Form.Field style={{ width: "100%" }}>
                    <label>Standard Policy</label>
                    <Dropdown
                      placeholder="Standard Policy"
                      search
                      multiple
                      selection
                      defaultValue={standardDataOptions}
                      options={standardOptions}
                      onChange={this.setStandardData}
                    />
                  </Form.Field>
                </Form.Group>
              )} */}
            {/* {isDocuSign && signProcess === "esign" && (
                <Form.Group>
                  <Form.Field>
                    <label>Policy Documents</label>
                    <Dropdown
                      placeholder="Location Policy"
                      search
                      multiple
                      selection
                      defaultValue={policyDataOptions}
                      options={docusignOptions}
                      onChange={this.setDocuData}
                    />
                  </Form.Field>
                </Form.Group>
              )} */}
            {/* <Form.Group>
                {lgavail && (
                  <Checkbox
                    label="Allow Lease Guarantee"
                    checked={leaseGuarantee}
                    onChange={() =>
                      this.updateCheckboxOption(!leaseGuarantee, "LG")
                    }
                  />
                )}
                <Checkbox
                  label="Include First and Last Month"
                  checked={firstLastInvoice}
                  onChange={() =>
                    this.updateCheckboxOption(!firstLastInvoice, "FLM")
                  }
                />
                <Checkbox
                  label="Enable Pro Rate"
                  checked={proRateEnabled}
                  onChange={() =>
                    this.updateCheckboxOption(!proRateEnabled, "PR")
                  }
                />
              </Form.Group> */}
          </Form>
        </Modal.Description>
      </Modal.Content>
      <Modal.Actions>
        <Button
          disabled={inviteArray.length == 1}
          color="black"
          onClick={() => RemoveRow()}
        >
          Remove
        </Button>
        <Button
          disabled={inviteArray.length == 5}
          color="black"
          onClick={() => AddRow()}
        >
          Add
        </Button>
        <Button
          disabled={validationTest()}
          content="Send All"
          labelPosition="right"
          icon="checkmark"
          // onClick={() => sendInvite()}
          positive
        />
      </Modal.Actions>
    </Modal>
  );
}

export default InviteResident;
