import React, { Component } from "react";
import { withApollo } from "react-apollo";
import mixpanel from "mixpanel-browser";
import get from "lodash/get";
import { qpDatatables, qpTooltipPopover } from "../../utils/misc";
import "./Properties.scss";
import { PageLoader } from "../../components/Loader/PageLoader";
import { Container, Dropdown, Grid } from "semantic-ui-react";
const friendOptions = [
  {
    key: "Jenny Hess",
    text: "Jenny Hess",
    value: "Jenny Hess",
    image: { avatar: true, src: "/images/avatar/small/jenny.jpg" },
  },
  {
    key: "Elliot Fu",
    text: "Elliot Fu",
    value: "Elliot Fu",
    image: { avatar: true, src: "/images/avatar/small/elliot.jpg" },
  },
  {
    key: "Stevie Feliciano",
    text: "Stevie Feliciano",
    value: "Stevie Feliciano",
    image: { avatar: true, src: "/images/avatar/small/stevie.jpg" },
  },
  {
    key: "Christian",
    text: "Christian",
    value: "Christian",
    image: { avatar: true, src: "/images/avatar/small/christian.jpg" },
  },
  {
    key: "Matt",
    text: "Matt",
    value: "Matt",
    image: { avatar: true, src: "/images/avatar/small/matt.jpg" },
  },
  {
    key: "Justen Kitsune",
    text: "Justen Kitsune",
    value: "Justen Kitsune",
    image: { avatar: true, src: "/images/avatar/small/justen.jpg" },
  },
];

class PropertiesComponent extends Component {
  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
    this.state = {
      primaryLocations: [],
      loading: false,
      primary: this.props.selectedPrimary
        ? this.props.selectedPrimary.node
        : "",
      allApps: [],
      isFirstRender: true,
    };
  }

  componentDidMount() {
    qpDatatables();
    qpTooltipPopover();
    mixpanel.track("Manager Page Load", {
      sub: "Properties Component",
    });
  }
  componentWillReceiveProps(prevProps) {
    const nodeData = prevProps.selectedPrimary
      ? prevProps.selectedPrimary.node
      : "";
    if (nodeData !== this.state.primary) {
      this.setState({ primary: nodeData }, () => {
      });
    }
  }
  setLocation() {
    this.setState({ primary: this.props.selectedPrimary }, () => {});
  }

  componentWillUnmount() {
    this.setState({
      isFirstRender: true,
    });
  }

  setTitle = (data) => {
    if (data) {
      const prime = get(data, "data.primaryLocations.edges", null);
      this.setState({
        primaryLocations: prime,
        allApps: prime,
        isFirstRender: false,
      });
    }
  };

  handleChange(e) {
    let currentList = [];
    let newList = [];
    // If the search bar isn't empty
    if (e.target.value !== "") {
      // Assign the original list to currentList
      currentList = this.state.allApps;
      newList = currentList.filter((item) => {
        const lc = item.node.property.location.name.toLowerCase();
        const filter = e.target.value.toLowerCase();
        return lc.includes(filter);
      });
    } else {
      newList = this.state.allApps;
    }
    this.setState({
      primaryLocations: newList,
    });
  }

  redirectToLeases(id) {
    this.props.history.push(`/leases/${id}`);
  }

  redirectToProperty() {
    this.props.history.push({
      pathname:
        "/manageLocation/location=" + this.props.selectedPrimary.node.customId,
    });
  }

  render() {
    return (
      <>
        {!this.props.selectedPrimary.node ? (
          <PageLoader />
        ) : (
          <>
            <Container className="property-container">
              <div className="header-section">
                <div className="left-container">
                  <div className="head">Properies</div>
                  <p>Here's the list of your added properties</p>
                </div>
                <div className="right-container">
                  <ul>
                    {/* eslint-disable */}
                    <li>
                      <a className="active">
                        <img
                          src="assets/img/card-menu-icon.svg"
                          alt="menu icon"
                        />
                      </a>
                    </li>
                    <li>
                      <a>
                        <img
                          src="assets/img/list-menu-icon.svg"
                          alt="menu icon"
                        />
                      </a>
                    </li>
                    {/* eslint-enable */}
                  </ul>
                  <Dropdown
                    placeholder="Select Friend"
                    fluid
                    selection
                    options={friendOptions}
                  />
                  <button class="btn next-btn">Add Property</button>
                </div>
              </div>

              <div className="card-inner-container list-view">
                <div className="card-row">
                  <div className="block img-box d-inline-flex">
                    <img
                      src="assets/img/404-boxes-background.jpg"
                      alt="404-boxes-background"
                    />
                  </div>
                  <div className="block">
                    <div className="card-titile">Cascade Meadow Apartments</div>
                    <div className="address">
                      11311 19th Ave SE, everett, Washington DC, 98208, US
                    </div>
                  </div>
                  <div className="block card-aminites">
                    <span>1+ Beds</span> <span className="dot"></span>
                    <span className="">1+ Baths</span>
                    <span className="dot"></span>
                    <span>1017 sq.ft</span>
                  </div>

                  <div className="block">
                    <div className="black-strip">
                      <span>$1.4k-$1.8k </span>
                      <span className="bar"></span>
                      <span>96 units</span>
                    </div>
                    <div className="custom-dropdown">
                      {/* eslint-disable */}
                      <a className="more-btn">
                        <img
                          src="assets/img/black-icons-more.svg"
                          alt="white-icons-more"
                        />
                      </a>
                      <div className="dropdown-list">
                        <div className="list-item">
                          <a>
                            <img
                              src="assets/img/icons-edit.svg"
                              alt="icons-edit"
                            />{" "}
                            Edit Property
                          </a>
                        </div>
                        <div className="list-item red-text">
                          <a>
                            <img
                              src="assets/img/icons-red-delete.svg"
                              alt="icons-red-delete"
                            />{" "}
                            Delete Property
                          </a>
                        </div>
                        {/* eslint-enable */}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="card-row">
                  <div className="block img-box d-inline-flex">
                    <img
                      src="assets/img/404-boxes-background.jpg"
                      alt="404-boxes-background"
                    />
                  </div>
                  <div className="block">
                    <div className="card-titile">Cascade Meadow Apartments</div>
                    <div className="address">
                      11311 19th Ave SE, everett, Washington DC, 98208, US
                    </div>
                  </div>
                  <div className="block card-aminites">
                    <span>1+ Beds</span> <span className="dot"></span>
                    <span className="">1+ Baths</span>
                    <span className="dot"></span>
                    <span>1017 sq.ft</span>
                  </div>

                  <div className="block">
                    <div className="black-strip">
                      <span>$1.4k-$1.8k </span>
                      <span className="bar"></span>
                      <span>96 units</span>
                    </div>
                    <div className="custom-dropdown">
                      {/* eslint-disable */}
                      <a className="more-btn">
                        <img
                          src="assets/img/black-icons-more.svg"
                          alt="white-icons-more"
                        />
                      </a>
                      <div className="dropdown-list">
                        <div className="list-item">
                          <a>
                            <img
                              src="assets/img/icons-edit.svg"
                              alt="icons-edit"
                            />{" "}
                            Edit Property
                          </a>
                        </div>
                        <div className="list-item red-text">
                          <a>
                            <img
                              src="assets/img/icons-red-delete.svg"
                              alt="icons-red-delete"
                            />{" "}
                            Delete Property
                          </a>
                        </div>
                        {/* eslint-enable */}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="card-row">
                  <div className="block img-box d-inline-flex">
                    <img
                      src="assets/img/404-boxes-background.jpg"
                      alt="404-boxes-background"
                    />
                  </div>
                  <div className="block">
                    <div className="card-titile">Cascade Meadow Apartments</div>
                    <div className="address">
                      11311 19th Ave SE, everett, Washington DC, 98208, US
                    </div>
                  </div>
                  <div className="block card-aminites">
                    <span>1+ Beds</span> <span className="dot"></span>
                    <span className="">1+ Baths</span>
                    <span className="dot"></span>
                    <span>1017 sq.ft</span>
                  </div>

                  <div className="block">
                    <div className="black-strip">
                      <span>$1.4k-$1.8k </span>
                      <span className="bar"></span>
                      <span>96 units</span>
                    </div>
                    <div className="custom-dropdown">
                      {/* eslint-disable */}
                      <a className="more-btn">
                        <img
                          src="assets/img/black-icons-more.svg"
                          alt="white-icons-more"
                        />
                      </a>
                      <div className="dropdown-list">
                        <div className="list-item">
                          <a>
                            <img
                              src="assets/img/icons-edit.svg"
                              alt="icons-edit"
                            />{" "}
                            Edit Property
                          </a>
                        </div>
                        <div className="list-item red-text">
                          <a>
                            <img
                              src="assets/img/icons-red-delete.svg"
                              alt="icons-red-delete"
                            />{" "}
                            Delete Property
                          </a>
                        </div>
                        {/* eslint-enable */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Container>
            <Container className="add-property-container">
              <div className="header">
                {/* eslint-disable */}
                <a className="back-btn">
                  <img
                    src="assets/img/left-arrow-small.svg"
                    alt="arrow-down-small"
                  />
                </a>
                {/* eslint-enable */}
                Add Property
              </div>
              <div className="menu-container">
                <ul className="list-container">
                  {/* eslint-disable */}
                  <li className="active">
                    <a>
                      <span>1</span> Property Information
                    </a>
                  </li>
                  <li>
                    <a>
                      <span>2</span> Units and Sizes
                    </a>
                  </li>
                  <li>
                    <a>
                      <span>3</span> Payments and Policies
                    </a>
                  </li>
                  <li>
                    <a>
                      <span>4</span> Company Information
                    </a>
                  </li>
                  <li>
                    <a>
                      <span>5</span> Review Listing
                    </a>
                  </li>
                  {/* eslint-enable */}
                </ul>
                <div className="loader-block">
                  <span>Step 2: Units and sizes looks great!</span> Saving the
                  information and next step is coming up.
                </div>
              </div>

              <div className="upload-photo-container">
                <input type="file" />
                <div className="inner-section">
                  <img
                    src="assets/img/left-arrow-small.svg"
                    alt="arrow-down-small"
                  />
                  <div className="normal-text">
                    Upload photos of your property
                  </div>
                  <div className="hint">
                    You can upload images in .jpg, .png format.
                  </div>
                </div>
              </div>
              <div className="preview-box">
                <div className="img-box">
                  <img
                    src="/assets/img/frontend-feature-cards-1.png"
                    alt="frontend-feature-cards-1"
                  />
                  <div className="overlay-box"></div>
                </div>
                <div className="img-box">
                  <img
                    src="/assets/img/frontend-feature-cards-1.png"
                    alt="frontend-feature-cards-1"
                  />
                  <div className="overlay-box"></div>
                </div>
                <div className="img-box">
                  <img
                    src="/assets/img/frontend-feature-cards-1.png"
                    alt="frontend-feature-cards-1"
                  />
                  <div className="overlay-box"></div>
                </div>
              </div>
              <div className="form-section">
                <form>
                  <Grid>
                    <Grid.Column mobile={16} tablet={8} computer={8}>
                      <div class="form-group">
                        <div class="has-float-label">
                          <input
                            class="form-control"
                            type="text"
                            placeholder="Email address"
                            autocomplete="nope"
                          />
                          <label>Property Name</label>
                        </div>
                      </div>
                    </Grid.Column>
                    <Grid.Column mobile={16} tablet={8} computer={8}>
                      <div class="has-float-label select-float-label">
                        <Dropdown fluid selection options={friendOptions} />
                        <label>Property Type</label>
                      </div>
                    </Grid.Column>
                    <Grid.Column mobile={16} tablet={8} computer={8}>
                      <div class="form-group">
                        <div class="has-float-label">
                          <input
                            class="form-control"
                            type="text"
                            placeholder="Email address"
                            autocomplete="nope"
                          />
                          <label>Address</label>
                        </div>
                      </div>
                    </Grid.Column>
                    <Grid.Column mobile={16} tablet={8} computer={8}>
                      <div class="has-float-label select-float-label">
                        <Dropdown fluid selection options={friendOptions} />
                        <label>Property Type</label>
                      </div>
                    </Grid.Column>
                    <Grid.Column mobile={16} tablet={16} computer={16}>
                      <div class="form-group">
                        <div class="has-float-label textarea-label">
                          <textarea
                            class="form-control"
                            type="text"
                            placeholder="Email address"
                            autocomplete="nope"
                          ></textarea>
                          <label>Address</label>
                        </div>
                      </div>
                    </Grid.Column>
                    <Grid.Column mobile={16} tablet={16} computer={16}>
                      <div class="white-block">
                        <div className="head">Services</div>
                        <div className="radio-container">
                          <label class="custom-checkbox">
                            Dog walking
                            <input type="checkbox" />
                            <span class="checkmark"></span>
                          </label>
                          <label class="custom-checkbox">
                            Grocery delivery
                            <input type="checkbox" />
                            <span class="checkmark"></span>
                          </label>
                        </div>
                      </div>
                    </Grid.Column>
                    <Grid.Column mobile={16} tablet={16} computer={16}>
                      <div class="white-block">
                        <div className="head">Pets Permitted</div>
                        <div className="radio-container">
                          <label class="custom-radio">
                            Yes
                            <input type="radio" name="pets" />
                            <span class="checkmark"></span>
                          </label>
                          <label class="custom-radio">
                            No
                            <input type="radio" name="pets" />
                            <span class="checkmark"></span>
                          </label>
                        </div>
                      </div>
                    </Grid.Column>
                    <Grid.Column mobile={16} tablet={16} computer={16}>
                      <div class="grey-block">
                        <div className="head">Total number of pets</div>
                        <p>
                          How many pets do you want to allow in this property?
                        </p>
                        <form>
                          <div className="form-row">
                            <div className="input-section">
                              <div class="select-box">
                                <Dropdown
                                  fluid
                                  selection
                                  options={friendOptions}
                                />
                              </div>
                              <div class="select-box">
                                <Dropdown
                                  fluid
                                  selection
                                  options={friendOptions}
                                />
                              </div>
                              <div class="select-box">
                                <div class="input-box">
                                  <input
                                    class="form-control"
                                    type="text"
                                    autocomplete="nope"
                                  />
                                  <span className="hint">lbs</span>
                                </div>
                              </div>
                            </div>
                            {/* eslint-disable */}
                            <a className="delete-btn">
                              <img
                                src="assets/img/black-icons-delete.svg"
                                alt="black-icons-delete"
                              />
                            </a>
                            {/* eslint-enable */}
                          </div>
                          <div className="form-row">
                            <div className="input-section">
                              <div class="select-box">
                                <Dropdown
                                  fluid
                                  selection
                                  options={friendOptions}
                                />
                              </div>
                              <div class="select-box">
                                <Dropdown
                                  fluid
                                  selection
                                  options={friendOptions}
                                />
                              </div>
                              <div class="select-box">
                                <div class="input-box">
                                  <input
                                    class="form-control"
                                    type="text"
                                    autocomplete="nope"
                                  />
                                  <span className="hint">lbs</span>
                                </div>
                              </div>
                            </div>
                            {/* eslint-disable */}
                            <a className="delete-btn">
                              <img
                                src="assets/img/black-icons-delete.svg"
                                alt="black-icons-delete"
                              />
                            </a>
                            {/* eslint-enable */}
                          </div>
                          <div className="form-row">
                            <div className="input-section">
                              <div class="select-box">
                                <Dropdown
                                  fluid
                                  selection
                                  options={friendOptions}
                                />
                              </div>
                              <div class="select-box">
                                <Dropdown
                                  fluid
                                  selection
                                  options={friendOptions}
                                />
                              </div>
                              <div class="select-box">
                                <div class="input-box">
                                  <input
                                    class="form-control"
                                    type="text"
                                    autocomplete="nope"
                                  />
                                  <span className="hint">lbs</span>
                                </div>
                              </div>
                            </div>
                            {/* eslint-disable */}
                            <a className="delete-btn">
                              <img
                                src="assets/img/icons-plus.svg"
                                alt="icons-plus"
                              />
                            </a>
                            {/* eslint-enable */}
                          </div>
                        </form>
                      </div>
                    </Grid.Column>
                    <Grid.Column mobile={16} tablet={16} computer={16}>
                      <div class="white-block">
                        <div className="head">
                          Service Animal Documentation Required{" "}
                        </div>
                        <div className="radio-container">
                          <label class="custom-radio">
                            Yes
                            <input type="radio" name="animal-doc" />
                            <span class="checkmark"></span>
                          </label>
                          <label class="custom-radio">
                            No
                            <input type="radio" name="animal-doc" />
                            <span class="checkmark"></span>
                          </label>
                        </div>
                      </div>
                    </Grid.Column>
                    <Grid.Column mobile={16} tablet={16} computer={16}>
                      <div class="white-block amenites-box">
                        <div className="head">Amenities</div>
                        <div className="radio-container">
                          <label class="custom-checkbox">
                            WiFi
                            <input type="checkbox" />
                            <span class="checkmark"></span>
                          </label>
                          <label class="custom-checkbox">
                            Heater
                            <input type="checkbox" />
                            <span class="checkmark"></span>
                          </label>
                          <label class="custom-checkbox">
                            Free parking
                            <input type="checkbox" />
                            <span class="checkmark"></span>
                          </label>
                          <label class="custom-checkbox">
                            Kitchen
                            <input type="checkbox" />
                            <span class="checkmark"></span>
                          </label>
                          <label class="custom-checkbox">
                            Cable TV
                            <input type="checkbox" />
                            <span class="checkmark"></span>
                          </label>
                          <label class="custom-checkbox">
                            Air conditioning
                            <input type="checkbox" />
                            <span class="checkmark"></span>
                          </label>
                          <label class="custom-checkbox">
                            Hangers
                            <input type="checkbox" />
                            <span class="checkmark"></span>
                          </label>
                          <label class="custom-checkbox">
                            Iron
                            <input type="checkbox" />
                            <span class="checkmark"></span>
                          </label>
                          <label class="custom-checkbox">
                            Dryer
                            <input type="checkbox" />
                            <span class="checkmark"></span>
                          </label>
                          <label class="custom-checkbox">
                            Washing machine
                            <input type="checkbox" />
                            <span class="checkmark"></span>
                          </label>
                          <label class="custom-checkbox">
                            Games console
                            <input type="checkbox" />
                            <span class="checkmark"></span>
                          </label>
                          <label class="custom-checkbox">
                            High chair
                            <input type="checkbox" />
                            <span class="checkmark"></span>
                          </label>
                          <label class="custom-checkbox">
                            Smoke alarm
                            <input type="checkbox" />
                            <span class="checkmark"></span>
                          </label>
                          <label class="custom-checkbox">
                            Fire extinguisher
                            <input type="checkbox" />
                            <span class="checkmark"></span>
                          </label>
                          <label class="custom-checkbox">
                            Kettle
                            <input type="checkbox" />
                            <span class="checkmark"></span>
                          </label>
                        </div>
                      </div>
                    </Grid.Column>
                    <Grid.Column mobile={16} tablet={16} computer={16}>
                      <div class="btn-block">
                        <div>
                          <button type="button" className="btn cancel-btn">
                            Cancel
                          </button>
                        </div>
                        <div>
                          <button type="button" className="btn save-btn">
                            Save as a draft
                          </button>
                          <button type="button" className="btn next-btn">
                            Proceed to step 2
                          </button>
                        </div>
                      </div>
                    </Grid.Column>
                  </Grid>
                </form>
              </div>
            </Container>
          </>
        )}
      </>
    );
  }
}

export default withApollo(PropertiesComponent);
