import React, { Component } from "react";
import { withApollo } from "react-apollo";
import mixpanel from "mixpanel-browser";
import { getClient } from "../../init-apollo-googleFn";
import get from "lodash/get";
import Datetime from "react-datetime";
import {
  LedgerAccounting,
  ReconcileData,
  ReconcileSumData,
  SumLocationAccount,
} from "../../store/person/accounting";
import { getEmitter } from "../../utils";
import moment from "moment";
import { toastFailMsg } from "../../utils/common";
import "./Accounting.scss";

import { Dropdown, Button, Header, Segment, Grid } from "semantic-ui-react";
import { Table } from "../../components/Tables/ReconcileData";
import { ACCOUNTING } from "../../utils/constants";
import "react-toastify/dist/ReactToastify.css";
import { Loader } from "../../components/Loader/Loader";
import { jsPDF } from "jspdf";
import "jspdf-autotable";

const ledgerClient = getClient(ACCOUNTING);

const DEFAULT_LIMIT = 99999999;

class Reconcile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      primary: this.props.selectedPrimary
        ? this.props.selectedPrimary.node
        : "",
      loading: false,
      initialFetch: false,
      startDate: moment().startOf("month").format("YYYY-MM-DD"),
      endDate: moment().endOf("month").format("YYYY-MM-DD"),
      selectedLedger: null,
      ledgerId: "",
      sumLocation: 0,
      sumVariance: "",
      sumStatement: 0,
      transactions: [],
      totalItems: 0,
      paginate: {
        start: 0,
        end: 30,
      },
    };
  }

  componentDidMount() {
    const value = this.props.match.params.id;
    this.getLedger(value);

    mixpanel.track("Manager Page Load", {
      sub: "Reconcile",
    });
  }

  newRecon = (data) => {
    this.ReconcileAccount(data);
  };
  stateDataToPDF = () => {
    const doc = new jsPDF();
    const header = ["Journal", "Status", "Unit", "Name", "Account", "Subtype", "PX", "Post", "Ref", "Desc", "Debit", "Credit"];

    // Add table header
    doc.setFontSize(15);
    doc.setTextColor(40);
    doc.text("Reconcile", 14, 15);
    console.log(this.state.transactions);
    // Convert data to array of arrays
    const data = this.state.transactions.map((item) => [
      item?.Journal?.number,
      item?.Journal?.status,
      item.Unit,
      item.Agent_name,
      item.LedgerAccount_number,
      item.LedgerAccount_subtype,
      moment(item.transactionDate).format("DD/MM/YYYY"),
      moment(item.postedDate).format("DD/MM/YYYY"),
      item?.Journal?.reference,
      item.description,
      `$${item.debit}`,
      `$${item.credit}`,
    ]);

    // Add table
    doc.setFontSize(11);
    doc.setTextColor(0);
    doc.autoTable({
      head: [header],
      body: data,
      theme: "grid",
      margin: { top: 20 },
      styles: { overflow: "linebreak", fontSize: 8 },
      minCellWidth: 30, // Set a minimum width for all columns
      headStyles: {
        fillColor: [214, 202, 232], // Set the background color to light purple
        textColor: 0, // Set the text color to black
      },
    });

    // Output PDF
    doc.save("table.pdf");
  };
  componentWillReceiveProps(prevProps) {
    const nodeData = prevProps.selectedPrimary
      ? prevProps.selectedPrimary.node
      : "";
    if (nodeData !== this.state.primary) {
      this.setState({ primary: nodeData }, () => {
        if (this.state.transactions.length) {
          this.setState({
            transactions: [],
            paginate: {
              start: 0,
              end: DEFAULT_LIMIT,
            },
          });
        }
        this.setState({
          selectedMonthEvents: {
            ...this.state.selectedMonthEvents,
            montEvents: [],
          },
        });
        this.getLedger();
      });
    }
  }

  getLedger = (data) => {
    try {
      this.setState({ loading: true });
      ledgerClient
        .query({
          query: LedgerAccounting,
          variables: {
            location: this.props.selectedPrimary.node.id,
            offset: 0,
            limit: 99999999,
          },
        })
        .then((res) => {
          if (res.data) {
            const Arr = get(res, "data.slLocationLedgerAccounts.data", null);
            const getName = Arr.filter((item) => item._id == data);
            this.setState({
              ledgerCache: get(res, "data.slLocationLedgerAccounts.data", null),
              selectedLedger: getName && getName[0] && getName[0].name,
              ledgerId: data
                ? data
                : get(res, "data.slLocationLedgerAccounts.data[0]._id", null),
            });
            if (window.document.getElementById("getLedger")) {
              window.document.getElementById("getLedger").click();
            }
          }
          this.setState({ loading: false });
        })
        .catch((error) => {
          console.log(error);
          this.setState({ loading: false });
        });
    } catch (e) {
      this.setState({ loading: false });
    }
  };

  changeLedger = (val) => {
    this.setState({ ledgerId: val.value });
  };

  gatherSumLocation = (start, end, id, location) => {
    try {
      this.setState({ loading: true });
      ledgerClient
        .query({
          query: SumLocationAccount,
          variables: {
            location: location,
            ledger: id,
            start: start,
            end: end,
          },
        })
        .then((res) => {
          this.setState({
            sumLocation: res.data.slSumLocationAccount
              ? res.data.slSumLocationAccount
              : 0,
          });
        })
        .catch((error) => {
          console.log(error);
          this.setState({ loading: false });
        });
    } catch (e) {
      this.setState({ loading: false });
    }
  };

  ReconcileAccountRoute = (data) => {
    const { startDate, endDate, ledgerId, paginate, transactions, totalItems } =
      this.state;
    const { id: locationId } = this.props.selectedPrimary.node;
    ledgerClient
      .query({
        query: ReconcileData,
        variables: {
          start: startDate || "",
          end: endDate || "",
          ledger: String(ledgerId) || "",
          location: locationId,
          offset: paginate.start,
          limit: paginate.end,
        },
      })
      .then((res) => {
        if (res.data) {
          this.gatherSumLocation(
            startDate,
            endDate,
            String(ledgerId),
            locationId
          );
          const value = get(res, "data.slLocationTransactions.data", []);
          const total = get(res, "data.slLocationTransactions.totalItems", 0);
          let updatedPagination = paginate;
          if (value.length === DEFAULT_LIMIT && total > DEFAULT_LIMIT)
            updatedPagination = {
              start: paginate.end,
              end: paginate.end + DEFAULT_LIMIT,
            };

          this.setState({
            transactions:
              paginate.start === 0 ? value : [...transactions, ...value],
            totalItems: total,
            paginate: updatedPagination,
          });
          this.setState({ loading: false });
        }
        this.setState({ loading: false });
      })
      .catch((error) => {
        this.setState({ loading: false });
        console.log(error);
        toastFailMsg(error);
      });
  };

  ReconcileAccount = async (refetch) => {
    this.setState({ loading: true });
    if (refetch) {
      await this.setState({
        totalItems: 1,
        transactions: [],
        paginate: {
          start: 0,
          end: DEFAULT_LIMIT,
        },
        initialFetch: true,
      });
      if (this.state.startDate || this.state.endDate || this.state.ledgerId) {
        this.SumData();
      }
      this.setState({ loading: false });
    }
    const { id: locationId } = this.props.selectedPrimary.node;
    const { startDate, endDate, ledgerId, paginate, transactions, totalItems } =
      this.state;
    if (transactions.length <= totalItems)
      ledgerClient
        .query({
          query: ReconcileData,
          variables: {
            start: startDate || "",
            end: endDate || "",
            ledger: String(ledgerId) || "",
            location: locationId,
            offset: paginate.start,
            limit: paginate.end,
          },
        })
        .then((res) => {
          if (res.data) {
            this.gatherSumLocation(
              startDate,
              endDate,
              String(ledgerId),
              locationId
            );
            const value = get(res, "data.slLocationTransactions.data", []);
            const total = get(res, "data.slLocationTransactions.totalItems", 0);
            this.setState({
              transactions: value,
              totalItems: total,
            });
            this.setState({ loading: false });
          }
          this.setState({ loading: false });
        })
        .catch((error) => {
          this.setState({ loading: false });
          console.log(error);
          toastFailMsg(error);
        });
  };

  SumData = () => {
    ledgerClient
      .query({
        query: ReconcileSumData,
        variables: {
          start: this.state.startDate ? this.state.startDate : "",
          end: this.state.endDate ? this.state.endDate : "",
          ledger: this.state.ledgerId ? this.state.ledgerId.toString() : "",
          location: this.props.selectedPrimary.node.id,
        },
      })
      .then((res) => {
        if (res.data) {
          this.setState({ sumData: res.data.slSumLocationAccount });
        }
      })
      .catch((error) => {
        console.log(error);
        toastFailMsg(error);
      });
  };

  handleStartClick = (date) => {
    this.setState({
      startDate: moment(date._d).format("YYYY-MM-DD"),
    });
  };
  handleEndClick = (date) => {
    this.setState({
      endDate: moment(date._d).format("YYYY-MM-DD"),
    });
  };

  handlePaginate = (x) => {
    this.setState({ paginate: { start: x, end: this.state.paginate.end } });
  };

  render() {
    const { loading, ledgerCache } = this.state;
    const financials =
      ledgerCache &&
      ledgerCache.map((val) => ({
        key: val._id,
        value: val._id,
        text: val.name,
      }));

    const { transactions, totalItems, sumData } = this.state;
    return (
      <>
        <main className="main-content" role="main">
          <div className="tenants-container">
            {ledgerCache && (
              <>
                <div className="row mb-4">
                  <div className="col-md-12">
                    <div className="page-header">
                      <div className="d-flex align-items-left">
                        <div>
                          <div className="page-header-title">Reconcile</div>
                        </div>
                      </div>
                      <div className="d-flex align-items-end">
                        <div className="ml-3">
                          <Header textAlign="center">Start Date</Header>
                          <Datetime
                            inputProps={{ placeholder: "Select Start Date" }}
                            defaultValue={moment().startOf("month")}
                            timeFormat={false}
                            onChange={this.handleStartClick}
                          />
                        </div>
                        <div className="ml-3">
                          <Header textAlign="center">End Date</Header>
                          <Datetime
                            inputProps={{ placeholder: "Select End Date" }}
                            defaultValue={moment().endOf("month")}
                            timeFormat={false}
                            onChange={this.handleEndClick}
                          />
                        </div>
                        <div className="rdt ml-3">
                          <Header textAlign="center">Ledger</Header>
                          <Dropdown
                            style={{ zIndex: "11" }}
                            onChange={(e, { value }) =>
                              this.changeLedger({ value })
                            }
                            selection
                            search
                            placeholder={
                              this.state.selectedLedger
                                ? this.state.selectedLedger
                                : financials[0].text
                            }
                            options={financials}
                          />
                        </div>
                      </div>
                      <div className="d-flex align-items-end">
                        <Button
                          className="ml-3 button-hover-universal"
                          compact
                          id="getLedger"
                          onClick={() => this.ReconcileAccount()}
                        >
                          <span>Update</span>
                        </Button>
                        <Button
                          className="ml-4 noPrint noPrint-button"
                          compact
                          style={{
                            backgroundImage:
                              "linear-gradient(110deg, #3b1c5a, #374db1 162%)",
                          }}
                          onClick={() => this.stateDataToPDF()}
                        >
                          Print
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
                <Segment textAlign="center">
                  <Grid columns={3} stackable textAlign="center">
                    <Grid.Row verticalAlign="middle">
                      <Grid.Column>
                        <Header>
                          Balance
                          <Header.Subheader>
                            ${this.state.sumLocation.toLocaleString("en")}
                          </Header.Subheader>
                        </Header>
                      </Grid.Column>

                      <Grid.Column>
                        <Header>
                          Statement
                          <Header.Subheader>
                            <input
                              onKeyPress={(event) => {
                                if (!/^[0-9 ()+-]+$/.test(event.key)) {
                                  event.preventDefault();
                                }
                              }}
                              onChange={(e) =>
                                this.setState({ sumStatement: e.target.value })
                              }
                            />
                          </Header.Subheader>
                        </Header>
                      </Grid.Column>
                      <Grid.Column>
                        <Header>
                          Variance
                          <Header.Subheader>
                            $
                            {!isNaN(this.state.sumStatement) &&
                              (
                                +this.state.sumLocation -
                                +this.state.sumStatement
                              ).toLocaleString("en")}
                          </Header.Subheader>
                        </Header>
                      </Grid.Column>
                    </Grid.Row>
                  </Grid>
                </Segment>
              </>
            )}
            {loading ? (
              <Loader text inTable />
            ) : (
              <>
                {typeof sumData == "number" && (
                  <Header>
                    Net Balance:{" "}
                    {sumData && sumData >= 0
                      ? `$${sumData
                          .toString()
                          .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`
                      : `$(${Math.abs(sumData)
                          .toString()
                          .replace(/\B(?=(\d{3})+(?!\d))/g, ",")})`}
                  </Header>
                )}
                <Table
                  getLedger={this.getLedger}
                  handlePaginate={this.handlePaginate}
                  propertyData={
                    this.props.selectedPrimary && this.props.selectedPrimary
                  }
                  paginate={this.state.paginate}
                  propertyId={
                    this.props.selectedPrimary.node &&
                    this.props.selectedPrimary.node.id
                  }
                  ReconcileAccount={() => this.ReconcileAccount(false)}
                  totalItems={totalItems && totalItems}
                  transCache={transactions}
                />
              </>
            )}
          </div>
        </main>
      </>
    );
  }
}

export default withApollo(Reconcile);
