import React, { Component } from "react";
import "./FlashMessage.scss";
import { parseGraphQLErrors } from "../../utils/common";

class FlashMessage extends Component {
  constructor(props) {
    super(props);
    this.myRef = React.createRef();
  }

  componentDidMount() {
    this.hideMessage();
  }

  //??
  handleClickCrossBtn = () => {};

  setMessage() {
    const { message } = this.props;
    if (message) {
      document.getElementById("flashmessage").style.visibility = "visible";
      this.hideMessageTimer();
      if (message.indexOf("phone") > -1) {
        return "Please enter a valid phone number.";
      } else if (message.indexOf("email") > -1) {
        return "Please enter a valid email.";
      } else {
        return parseGraphQLErrors(message);
      }
    }
  }

  hideMessage() {
    if (document.getElementById("flashmessage")) {
      document.getElementById("flashmessage").style.visibility = "hidden";
    }
  }

  handleClick = () => {
    this.props.closeMessage();
    this.hideMessage();
  };

  hideMessageTimer = () => {
    setTimeout(() => {
      this.props.closeMessage();
      this.hideMessage();
    }, 5000);
  };

  render() {
    const { isError } = this.props;
    const classes = isError ? "alert-danger" : "alert-success";
    return (
      <div
        ref={this.myRef}
        id="flashmessage"
        className={`alert ${classes}`}
        role="alert"
      >
        {this.setMessage()}
        <button
          type="button"
          className="close"
          onClick={() => this.handleClick()}
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
    );
  }
}

export default FlashMessage;
