import React from "react";
import { MediaContentType } from "../../../store/redux/chat";

import "./Media.scss";

const FileInfoWrapper = (props) => {
  return (
    <div className="file-wrapper">
      <div className="file-icon">{props.children}</div>
      <div className="file-info">
        <div className="name">{props.name}</div>
        <div className="size">{props.size * 0.001} kB</div>
      </div>
    </div>
  );
};

const Media = (media) => {
  if (
    [MediaContentType.jpeg, MediaContentType.png].includes(media.content_type)
  ) {
    return <img src={media.uri} className="image" alt="img" />;
  }

  if (
    [
      MediaContentType.csv,
      MediaContentType.xls,
      MediaContentType.xlsx,
    ].includes(media.content_type)
  ) {
    return (
      <FileInfoWrapper {...media}>
        <img src="/assets/img/attachment-xls.svg" alt="img" />
      </FileInfoWrapper>
    );
  }

  if (
    [MediaContentType.doc, MediaContentType.docx].includes(media.content_type)
  ) {
    return (
      <FileInfoWrapper {...media}>
        <img src="/assets/img/attachment-doc.svg" alt="img" />
      </FileInfoWrapper>
    );
  }

  if ([MediaContentType.pdf].includes(media.content_type)) {
    return (
      <FileInfoWrapper {...media}>
        <img src="/assets/img/attachment-pdf.svg" alt="img" />
      </FileInfoWrapper>
    );
  }

  return null;
};

export default Media;
