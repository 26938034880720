//TopNav.js
import React, { Component, useState, useContext, useEffect } from "react";
import {
  Menu,
  Icon,
  Input,
  Header,
  Dropdown,
  Modal,
  List,
  Grid,
  Button,
  Feed,
  Divider,
  Popup,
} from "semantic-ui-react";
import { withRouter, Link, Redirect } from "react-router-dom";
import { compose } from "recompose";
import { withApollo } from "react-apollo";
import { getEmitter, LOCATIONAPI } from "../../utils";
import CountUp from "react-countup";
import { useDispatch } from "react-redux";
import DocModal from "../../components/Modals/DocModal";
import { Loader } from "../../components/Loader/Loader";
import "./TopNav.scss";
import PropertyChangeWarning from "../../components/Modals/propertyChangeWarningModal";
import { getClient, logoutAction } from "../../store/auth";
import { NotificationContext } from "../NotificationContext";
import moment from "moment";
import { getPointsBalance } from "../../store/person/properties";
import { RESET_CHAT } from "../../store/redux/chat";
import { RESET_PERSON } from "../../store/redux/person";
import * as WepayUtils from "../../utils/wepay";

const eventEmit = getEmitter();

function TopNav(props) {
  const { unreadAlerts, useUpdateEffect } = useContext(NotificationContext);
  const [points, setPoints] = useState(0);
  const NewPrimaryLocations = getClient(LOCATIONAPI);
  const [redirect, setRedirect] = useState(false);
  const dispatch = useDispatch();
  // useEffect(() => {

  //   eventEmit.on(NOTIFICATION, (data) => updateNotif(data));
  //  messaging &&
  //  messaging.onMessage(function(payload) {
  //     const eventEmit = getEmitter()
  //     eventEmit.emit(NOTIFICATION, payload)
  //   });
  // },[]);

  const getMyPoints = () => {
    try {
      NewPrimaryLocations.query({
        query: getPointsBalance,
        variables: {
          locationId: props.selectedPrimary.node.id,
        },
      }).then((res) => {
        const data = res.data && res.data.location;
        if (data && data.edges && data.edges[0]) {
          if (data.edges[0].node) {
            setPoints(data.edges[0].node.pointsBalance);
          }
        }
      });
    } catch (e) { }
  };

  /* eslint-disable */
  useEffect(() => {
    eventEmit.on("PurchasePoints", () => getMyPoints());
    props.getUserRole();
    getMyPoints();
  }, [props]);
  /* eslint-enable */

  const handleLogout = () => {
    logoutAction(props.client).then(() => {
      dispatch({ type: RESET_CHAT });
      dispatch({ type: RESET_PERSON });
      props.history.push("/");
      window.location.reload();
    });
  };
  const setOpenDocModal = () => {
    window.$("#DocModal-1").modal("toggle");
  }
  // const [openDocModal, setOpenDocModal] = React.useState(false)
  return (
    <>
      {useUpdateEffect()}
      <Menu
        fixed="top"
        borderless
        className={props.leftNavCollapsed ? "topnav expanded" : "topnav"}
      >
        <div className="fixed-menu-top">
          <img
            src="/assets/img/logo-top-mobile.svg"
            className="logo-small"
            alt="Leasera"
          />
          <TopNavSearch />
          <Menu.Menu position="right">
            <div className="top-nav-item-point">
              <div className="top-nav-item">
                <span className="points-icon"></span>
                <Link to="/loyalty">
                  <CountUp
                    className="points"
                    delay={0}
                    duration={10}
                    // start={currentPoints}
                    end={points}
                  />
                  <span className="top-nav-text">Points</span>
                </Link>
              </div>
            </div>
            <PropertySelector {...props} />
            {
              props.selectedPrimary &&props.selectedPrimary.length !== 0 &&
              <>
                <WepayVerifiedNav selectedPrimary={props.selectedPrimary} />&nbsp;&nbsp;&nbsp;&nbsp;</>
            }

            {/* <DocModal setOpenDocModal={setOpenDocModal} selectedPrimary={props.selectedPrimary} /> */}
            <TopNavNotif
              icon="alarm"
              getNotif={props.rightSidebarToggle}
              notifCount={unreadAlerts}
              {...props}
            />
            <TopNavUserMenu {...props} handleLogout={handleLogout} />

            <TopNavBurger
              icon="bars"
              onClick={props.leftNavToggle}
              {...props}
            />
          </Menu.Menu>
        </div>
        <div className="fixed-menu-bottom d-md-none">
          <div className="fixed-menu-left">
            <TopNavSearch />
          </div>
          <div className="fixed-menu-right">
            <PropertySelector {...props} />
          </div>
        </div>
      </Menu>
      {/* Mobile Menu */}
      <div className="mobile-menu-modal">
        <div className="mobile-menu-list">
          <div className="mobile-menu-list-item active">
            <a href="#">
              <img src="assets/img/mobile/icon-explore.svg" />
              <span>Explore</span>
            </a>
          </div>
          <div className="mobile-menu-list-item">
            <a href="#">
              <img src="assets/img/mobile/icon-leasera.svg" />
              <span>Dashboard</span>
            </a>
          </div>
          <div className="mobile-menu-list-item">
            <a href="#">
              <img src="assets/img/mobile/icon-schedule.svg" />
              <span>Leads</span>
            </a>
          </div>
          <div className="mobile-menu-list-item">
            <a href="#">
              <img src="assets/img/mobile/icon-maintenance.svg" />
              <span>Applications</span>
            </a>
          </div>
          <div className="mobile-menu-list-item">
            <a href="#">
              <img src="assets/img/mobile/icon-points.svg" />
              <span>Leases</span>
            </a>
          </div>
          <div className="mobile-menu-list-item">
            <a href="#">
              <img src="assets/img/mobile/icon-maintenance.svg" />
              <span>Maintenance</span>
            </a>
          </div>
          <div className="mobile-menu-list-item">
            <a href="#">
              <img src="assets/img/mobile/icon-profile.svg" />
              <span>Residents</span>
            </a>
          </div>
          <div className="mobile-menu-list-item">
            <a href="#">
              <img src="assets/img/mobile/icon-badges.svg" />
              <span>File Manager</span>
            </a>
          </div>
          <div className="mobile-menu-list-item">
            <a href="#">
              <img src="assets/img/mobile/icon-points.svg" />
              <span>Loyalty</span>
            </a>
          </div>
          <div className="mobile-menu-list-item">
            <a href="#">
              <img src="assets/img/mobile/icon-schedule.svg" />
              <span>Schedule</span>
            </a>
          </div>
          <div className="mobile-menu-list-item">
            <a href="#">
              <img src="assets/img/mobile/icon-leasera.svg" />
              <span>Properties</span>
            </a>
          </div>
          <div className="mobile-menu-list-item">
            <a href="#">
              <img src="assets/img/mobile/icon-maintenance.svg" />
              <span>Settings</span>
            </a>
          </div>
          <div className="mobile-menu-list-item">
            <a href="#">
              <img src="assets/img/mobile/icon-payments.svg" />
              <span>Accounting</span>
            </a>
          </div>
        </div>
        <div className="mobile-menu-button">
          <button className="btn w-100">Cancel</button>
        </div>
      </div>
      {/* Mobile Menu */}
    </>
  );
}

export default compose(withRouter, withApollo)(TopNav);

function TopNavSearch() {
  return (
    <span className="leasera-search">
      <Menu.Item className="search-inline">
        {/* <Input placeholder='Search...' /> */}
      </Menu.Item>
      <TopDrawer
        trigger={<Icon icon="search" className="search phone-only nav-icon" />}
        className="phone-only"
      >
        <span className="search-dropdown">
          <Input placeholder="Search..." />
        </span>
      </TopDrawer>
    </span>
  );
}

function TopNavBurger(props) {
  const { unreadAlerts, allAlerts, getAlerts, notifMutation } =
    useContext(NotificationContext);
  const [showAllAlerts, setShowAllAlerts] = useState(false);

  const idArray = Object.values(unreadAlerts).map((k) => {
    return k.Id;
  });

  const handleDropdown = (e) => {
    e.stopPropagation();
    setShowAllAlerts(!showAllAlerts);
  };

  return (
    <Dropdown
      item
      floating
      direction="left"
      closeOnChange={false}
      onClose={() => setShowAllAlerts(false)}
      trigger={
        <div>
          <div className="top-nav-item">
            <Icon name="bars" className="large-icon" />
          </div>
        </div>
      }
      className="user-menu d-md-none"
    >
      <Dropdown.Menu className="notification-wrap">
        <div className="dropdown-top-menu-user-info">
          <p className="mb-0">Notifications</p>
          <button className="toggle-alerts" onClick={(e) => handleDropdown(e)}>
            {!showAllAlerts ? "VIEW ALL" : "VIEW UNREAD"}
          </button>
        </div>
        <Feed
          className=""
          style={{ overflowY: "auto", maxHeight: "350px", topMargin: "0px" }}
        >
          {!showAllAlerts ? (
            unreadAlerts.length > 0 ? (
              unreadAlerts.map((data) => (
                <>
                  <div className="detail-txt">
                    <span className="icon-block">
                      <i class="bell icon"></i>
                    </span>
                    <Feed.Event
                      summary={data.message}
                      meta={moment(
                        data.created ? data.created : data.created_at
                      )
                        .local()
                        .format("lll")}
                    />
                  </div>
                  <Divider />
                </>
              ))
            ) : (
              <>
                <div className="detail-txt">
                  <span className="icon-block">
                    <i class="bell icon"></i>
                  </span>
                  <Feed.Event>No new Notifications</Feed.Event>
                </div>
                <Divider />
              </>
            )
          ) : (
            allAlerts.map((data) => (
              <>
                <div className="detail-txt">
                  <span className="icon-block">
                    <i class="bell icon"></i>
                  </span>
                  <Feed.Event
                    summary={data.message}
                    meta={moment(data.created ? data.created : data.created_at)
                      .local()
                      .format("lll")}
                  />
                </div>
                <Divider />
              </>
            ))
          )}
        </Feed>
      </Dropdown.Menu>
    </Dropdown>
  );
}

function TopNavNotif(props) {
  const { unreadAlerts, allAlerts, notifMutation } =
    useContext(NotificationContext);
  const [showAllAlerts, setShowAllAlerts] = useState(false);

  const openNotificationPage = () => {
    props.history.push({
      pathname: "/notifications",
      state: {
        data: allAlerts,
      },
    });
  };

  return (
    <div
      className={`notification-btn ${unreadAlerts.length !== 0 && "alert"}`}
      onClick={openNotificationPage}
    >
      {/* <img src="../../assets/img/icons-notification.svg" alt="icon" /> */}
      <svg
        width="21"
        height="21"
        viewBox="0 0 21 21"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g fill="none" fill-rule="evenodd" opacity=".7">
          <path d="M2.52 2.52h15.96v15.96H2.52z" />
          <path
            d="m15.82 14.934.266.354a.332.332 0 0 1-.266.532H5.18a.332.332 0 0 1-.266-.532l.266-.354V9.17a5.32 5.32 0 1 1 10.64 0v5.764zm-6.982 1.551h3.325a1.662 1.662 0 1 1-3.325 0z"
            fill="#5E6267"
            fill-rule="nonzero"
          />
        </g>
      </svg>
    </div>
  );
}

function TopNavUserMenu(props) {
  return (
    <Dropdown
      item
      floating
      direction="left"
      trigger={
        <span>
          <Icon name="user circle" className="large-icon" />
          <span className="name">
            {props.user.firstName} {props.user.lastName}
          </span>
        </span>
      }
      className="user-menu"
    >
      <Dropdown.Menu>
        <Dropdown.Item text='Manage Profile' icon='user' as="a" href="/#/profile" />
        <Dropdown.Item
          text="Logout"
          icon="log out"
          onClick={props.handleLogout}
        />
      </Dropdown.Menu>
    </Dropdown>
  );
}

function PropertySelector(props) {
  return (
    <Menu.Item className="property-selector">
      <PropertyDropdown {...props} className="phone-hide" />
      <TopDrawer
        trigger={<i class="fa fa-angle-down" aria-hidden="true"></i>}
        className="phone-only"
      >
        <PropertyDropdown {...props} className="property-selector-mobile " />
      </TopDrawer>
    </Menu.Item>
  );
}

class PropertyDropdown extends Component {
  constructor(props) {
    super(props);
    this.state = {
      primaryList: {},
      loading: true,
      open: false,
      selectedProperty: "",
      type: "",
    };
  }

  componentDidMount() {
    this.setPropertyList();
  }

  componentDidUpdate(prevProps) {
    if (this.props.primaryLocations !== prevProps.primaryLocations) {
      this.setPropertyList();
    }
  }

  setPropertyList = () => {
    if (this.props.primaryLocations) {
      this.setState({
        primaryList: this.props.primaryLocations.map((location, index) => ({
          key: "location.node" + index + 1,
          text: location.node.customId,
          value: index,
        })),
        loading: false,
      });
    }
  };

  selectProperty = (e) => {
    eventEmit.emit("topDrawerClose"); // Now that we selected a property let's close the top drawer
    this.props.setLocation(e); // call back to the parent to set the location used for all pages (todo change to Event Emitter)
  };

  changeProperty = async (e) => {
    if (
      window.location.href.includes("applicationForm") ||
      window.location.href.includes("viewApplication")
    ) {
      this.setState({
        open: true,
        selectedProperty: e.target.textContent,
        type: "application",
      });
    } else if (window.location.href.includes("viewLead")) {
      this.setState({
        open: true,
        selectedProperty: e.target.textContent,
        type: "lead",
      });
    } else {
      this.setState({ open: false });
      this.selectProperty(e.target.textContent);
    }
  };

  closeApplication = () => {
    this.setState({ open: false });
    this.selectProperty(this.state.selectedProperty);
  };

  closeModal = () => {
    this.setState({ open: false });
  };

  render() {
    const className = "property-dropdown " + this.props.className; // merge the className coming from the parent

    return (
      <>
        {this.state.loading && !this.props.selectedPrimary && (
          <Loader active size="tiny" />
        )}
        {!this.state.loading && (
          <Dropdown
            className={className}
            floating
            options={this.state.primaryList || []}
            text={
              this.props.selectedPrimary
                ? this.props.selectedPrimary.node.customId
                : ""
            }
            search
            onChange={(e) => this.changeProperty(e)}
          />
        )}

        {this.state.open && (
          <PropertyChangeWarning
            changeProperty={this.closeApplication}
            value={this.state.selectedProperty}
            closeModal={this.closeModal}
            type={this.state.type}
          />
        )}
      </>
    );
  }
}

class TopDrawer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      open: false,
    };
  }

  componentDidMount() {
    eventEmit.on("topDrawerClose", this.closeDrawer); // single event to manage closing all top drawers for the top nav.
  }

  componentWillUnmount() {
    eventEmit.removeEventListener("topDrawerClose", this.closeDrawer); // we no longer need the listener, remove it
  }

  toggleOpen = (e, val) => {
    eventEmit.emit("topDrawerClose"); //if any other top drawers are open close them
    if (e) e.preventDefault();

    if (!val) {
      this.setState({ open: !this.state.open });
    } else {
      if (val !== this.state.open) this.setState({ open: val });
    }
  };

  // method used by event emitter for closing this top drawer instance
  closeDrawer = () => {
    this.setState({ open: false });
  };

  render() {
    const wrapperClassName = this.props.className
      ? "top-drawer " + this.props.className
      : "top-drawer"; // merge the className coming from the parent

    return (
      <span className={wrapperClassName}>
        <Menu.Item className="td-trigger" onClick={(e) => this.toggleOpen(e)}>
          {this.props.trigger}
        </Menu.Item>
        <div className={this.state.open ? "td-drawer td-visible" : "td-drawer"}>
          {this.props.children}
          <br />
          <div className="td-close">
            <a href="/" onClick={(e) => this.toggleOpen(e, false)}>
              Close
            </a>
          </div>
        </div>
        <div
          className={this.state.open ? "td-backdrop td-visible" : "td-backdrop"}
        />
      </span>
    );
  }
}

const WepayVerifiedNav = ({ selectedPrimary }) => {
  const [legalEntityVerification, setLegalEntityVerification] = React.useState();

  const locationId = selectedPrimary?.node?.id;

  const checkWepayAccountVerification = async () => {
    if (locationId === undefined) return;
    try {
      const legalEntityVerification =
        await WepayUtils.getLegalEntityVerification(locationId);
      setLegalEntityVerification(legalEntityVerification);
    } catch (error) {
      console.log("error", error);
    }
  };

  React.useEffect(() => {
    checkWepayAccountVerification();
  }, [locationId]);




  return (
    <DocModal legalEntityVerification={legalEntityVerification} selectedPrimary={selectedPrimary} />

  );
};
