import gql from "graphql-tag";
export const ViewAllTasks = gql`
  query task {
    task {
      edges {
        node {
          id
          locationId
          description
          priority
          complete
          completed
          created
        }
      }
    }
  }
`;

export const CreateTask = gql`
  mutation createTask($input: CreateTaskInput!) {
    createTask(task: $input) {
      response
    }
  }
`;

export const UpdateTask = gql`
  mutation updateTask($input: UpdateTaskInput!) {
    updateTask(task: $input) {
      response
    }
  }
`;

export const DeleteTask = gql`
  mutation deleteTask($input: DeleteTaskInput!) {
    deleteTask(task: $input) {
      response
    }
  }
`;
