import React from "react";
import LogBody from "./LogBody";
import MessageTypeIcon from "./MessageTypeIcon";
import Media from "../Media";

const SingleLogView = ({ body, media, time, isSender, messageLabel }) => {
  const timestamp = (
    <div className="time-type-wrapper">
      <MessageTypeIcon type={messageLabel} />
      <div className="time">
        <span>{time}</span>
      </div>
    </div>
  );
  return (
    <LogBody
      {...{ isSender, messageLabel, type: "single", media_type: "none" }}
    >
      <div className="body">
        <span>{body}</span>
        <div className="media">
          {media.map((data, key) => (
            <Media key={key} {...data} />
          ))}
        </div>
      </div>
      {timestamp}
    </LogBody>
  );
};

export default SingleLogView;
