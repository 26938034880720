import React, { useState, useEffect } from "react";
import { withApollo } from "react-apollo";
import mixpanel from "mixpanel-browser";
import get from "lodash/get";
import { qpDatatables, qpTooltipPopover } from "../../utils/misc";
import "./Settings.scss";
import { PageLoader } from "../../components/Loader/PageLoader";
import TourCreation from "../../components/Modals/TourCreationModal";
import BucketExpansion from "../../components/Modals/BucketCreationModal";
import { Header, Card, Segment, Icon } from "semantic-ui-react";
import { Link, NavLink, withRouter, matchPath } from "react-router-dom";

function Settings(props) {
  const [primaryLocations, setPrimaryLocations] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isTourOpen, setIsTourOpen] = useState(false);
  const [primary, setPrimary] = useState(
    props.selectedPrimary ? props.selectedPrimary.node : ""
  );
  const [allApps, setAllApps] = useState([]);
  const [isFirstRender, setIsFirstRender] = useState(true);

  useEffect(() => {
    qpDatatables();
    qpTooltipPopover();
    mixpanel.track("Manager Page Load", {
      sub: "Settings",
    });
  }, []);

  useEffect(() => {
    const nodeData = props.selectedPrimary
      ? props.selectedPrimary.node
      : "";
    if (nodeData !== primary) {
      setPrimary(nodeData);
    }
  }, [props.selectedPrimary]);

  useEffect(() => {
    return () => {
      setIsFirstRender(true);
    };
  }, []);


  return (
    <>
      {!props.selectedPrimary.node ? (
        <PageLoader text />
      ) : (
        <>
          <Header className="settings-first-step">
            Property Settings for {primary.name}{" "}
            <Icon
              size="small"
              circular
              name="lightbulb"
              onClick={() => setIsTourOpen(true)}
            />
          </Header>
          <Link to="/settings/tourManagement">
            <div className="settings-outerCard">
              <div className="settings-cardText">
                <span className="settings-cardTitle">Tour Management</span>
                <br></br>
                <span className="settings-cardDescription">
                  View/manage your tour types
                </span>
                <img
                  className="settings-cardArrow"
                  src="/assets/img/right-arrow.svg"
                  alt="arrow"
                ></img>
              </div>
            </div>
          </Link>
          {/* <TourCreation primary={primary} /> */}
          {/* <BucketExpansion primary={primary} /> */}
          {/* </Card.Group> */}
        </>
      )}
    </>
  );
}

export default withApollo(Settings);