import React, { useEffect, useState } from "react";
import { getClient } from "../../../init-apollo-googleFn";
import { withApollo } from "react-apollo";
import mixpanel from "mixpanel-browser";
import get from "lodash/get";
import TourCreation from "../../../components/Modals/TourCreationModal";
import { qpDatatables, qpTooltipPopover } from "../../../utils/misc";
import "./TourManagement.scss";
import { PageLoader } from "../../../components/Loader/PageLoader";
import { Dropdown, Form, Radio } from "semantic-ui-react";
import { LEADS_TOUR, LEADS_TOUR_TYPE } from "../../../utils/constants";
import NoTourTypes from "./NoTourTypes";

import {
  createTour,
  TourTypes,
  createTourType,
  TourLocation,
  deleteTourType,
} from "../../../store/person/leads";
import moment from "moment";
import { toastSuccessMsg, toastFailMsg } from "../../../utils/common";
import { Link, NavLink, withRouter, matchPath } from "react-router-dom";

const tourAPI = getClient(LEADS_TOUR);
const tourTypeAPI = getClient(LEADS_TOUR_TYPE);

function TourManagement(props) {
  const [open, setOpen] = React.useState(false);
  const [tourCode, setCode] = React.useState(false);
  const [tourName, setName] = React.useState(false);
  const [tourDeletion, setDeletion] = React.useState(false);
  const [tourDuration, setDuration] = React.useState(10);
  const [tourLink, setLink] = React.useState(false);
  const [tourType, setType] = React.useState("in-person");
  const [tourFacing, setFacing] = React.useState(false);
  const [loading, setLoading] = useState(true);

  const [tourTypes, setTourTypes] = React.useState([]);

  useEffect(() => {
    getTours();
  }, [props.selectedPrimary]);
  useEffect(() => {
    console.log(tourType);
  }, [tourType]);

  const getTours = () => {
    try {
      tourTypeAPI
        .query({
          query: TourTypes,
          variables: {
            location: props.selectedPrimary.node.id,
          },
        })
        .then((res) => {
          const type = get(res, "data.tourTypes.edges", null);
          setTourTypes(type);
          setLoading(false)
        })
        .catch((error) => {
          setLoading(false)
        });
    } catch (e) {
      setLoading(false)
    }
  };

  const handleInviteSubmit = (localDateTime) => {
    const value = moment().format("DD/MM/YYYY");
    const input = {
      tourType: {
        location: props.selectedPrimary.node.id,
        code: tourCode,
        duration: tourDuration,
        name: tourName,
        tourType: tourType,
        selfBooking: tourFacing,
      },
    };
    if (tourLink) {
      input.tourType.connectionInfo = tourLink;
    }
    tourTypeAPI
      .mutate({
        mutation: createTourType,
        variables: {
          input: input,
        },
      })
      .then((response) => {
        getTours();
      })
      .catch((error) => {
        // handle error
      });
  };
  const deleteTourTypeFunction = () => {
    try {
      tourTypeAPI
        .mutate({
          mutation: deleteTourType,
          variables: {
            input: {
              tourType: {
                id: tourDeletion,
              },
            },
          },
        })
        .then((response) => {
          getTours();
          toastSuccessMsg("Tour type has been deleted");
          closeModal()
        })
        .catch((r) => {
          toastFailMsg("We encountered an issue");
          closeModal()
        });
    } catch (e) {
      alert(e);
      console.log(e);
    }
  };

  const tourTypesDropdown = [
    {
      key: 1,
      text: "All Tours",
      value: "All Tours",
    },
    {
      key: 2,
      text: "Person",
      value: "In-Person",
    },
    {
      key: 3,
      text: "Virtual",
      value: "Virtual",
    },
  ];
  const closeModal = () => {
    const modal = document.getElementById("DeleteTourTypesModal");
    const backdrop = document.querySelector(".modal-backdrop");
    modal.style.display = "none";
    backdrop.parentNode.removeChild(backdrop);
    modal.classList.remove("show");
    modal.removeAttribute("aria-modal");
    modal.setAttribute("aria-hidden", true);
  };
  return (
    <>
      <div className="tour-topSpace">
        <div className="tour-topLeft">
          <Link to="/settings">
            <div className="tour-backButton">
              <img
                className="tour-backArrow"
                src="/assets/img/right-arrow.svg"
                alt="arrow"
              ></img>
            </div>
          </Link>
          <div className="tour-title">
            Tour Settings for{" "}
            {props.selectedPrimary && props.selectedPrimary.node.name}{" "}
          </div>
        </div>
        <div className="tour-topRight">
          <Dropdown
            defaultValue={tourTypesDropdown[0].value}
            search
            selection
            className="tours-typeDropdown"
            options={tourTypesDropdown}
          />
          <button
            onClick={() => {
              const modal = document.getElementById("NewTourTypesModal");
              const modalInstance = new window.bootstrap.Modal(modal);
              modalInstance.show();
            }}
            className="tour-addTourButton"
          >
            Add Tour
          </button>
        </div>
      </div>
      {loading ? (
        <PageLoader/>
      ) : tourTypes.length === 0 ? (
        <NoTourTypes />
      ) : (
        <>
          <br></br>
          <div className="tour-rows">
            {tourTypes.map((item) => {
              const type = item.node;
              return (
                <div className="tour-outerCard">
                  <div className="tour-cardTextLeft">
                    <span className="tour-cardTitle">
                      {type.name} &#x2022; {type.code}{" "}
                      {type.connectionInfo && (
                        <span>&#x2022; {type.connectionInfo}</span>
                      )}
                    </span>
                    <span className="tour-cardDescription">
                      <span className="tour-date">
                        <img
                          className="tour-cardImg"
                          src="/assets/img/Vectorcalendar.svg"
                          alt="arrow"
                        ></img>{" "}
                        {moment(type.updated).format("LLL")}
                      </span>
                      <span className="tour-length">
                        <img
                          className="tour-cardImg"
                          src="/assets/img/Vectorclock.svg"
                          alt="arrow"
                        ></img>
                        {"  "}
                        {type.duration}
                      </span>
                      <span className="tour-type">
                        <img
                          className="tour-cardImg"
                          src="/assets/img/VectorType.svg"
                          alt="arrow"
                        ></img>{" "}
                        {type.selfBooking ? "Self Booking" : "Internal Booking"}
                      </span>
                    </span>
                  </div>
                  <div className="tour-cardTextRight">
                    <div className="tour-tag">
                      <span
                        className="tour-tagText"
                        style={{
                          color: type.connectionInfo ? "#11AE7D" : "#3b1c5a",
                        }}
                      >
                        {type.connectionInfo ? "Virtual" : "In-Person"}
                      </span>
                    </div>
                    <div className="tour-dots">
                      <img
                        className="tour-cardImg"
                        src="/assets/img/Shapetrash.svg"
                        onClick={() => {
                          const modal = document.getElementById(
                            "DeleteTourTypesModal"
                          );
                          const modalInstance = new window.bootstrap.Modal(
                            modal
                          );
                          setDeletion(type.id);
                          modalInstance.show();
                        }}
                      ></img>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </>
      )}
      {/* Delete Tour Modal */}
      <div
        class="modal fade DeleteTourTypesModal"
        id="DeleteTourTypesModal"
        tabindex="-1"
        role="dialog"
        aria-labelledby="forgotPasswordModal"
        aria-hidden="true"
        // style={{ display: "block" }}
      >
        <div class="modal-dialog-centered-tour" role="document">
          <div class="modal-content ">
            <div class="modal-header">
              <h5 class="modal-title" id="forgotPasswordModalLabel">
                Delete Tour
              </h5>
              <p class="modal-sutitle">
                Are you sure you want to delete this tour type?
              </p>
              <button
                type="submit"
                class="btn modal-large-btn-cancel"
                onClick={() => closeModal()}
              >
                Cancel
              </button>
              <button
                type="submit"
                class="btn modal-large-btn-yes"
                onClick={() => deleteTourTypeFunction()}
              >
                Yes, Delete Tour
              </button>
            </div>
          </div>
        </div>
      </div>

      {/* Add Tour Modal */}
      <div
        className="modal fade tour-addTypeModal"
        id="NewTourTypesModal"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="NewTourTypesModal"
        aria-hidden="true"
      >
        <div className="modal-tourContent">
          <div className="modal-tourHeader">
            <div className="modal-tourTitle">Add Tour Type</div>
            <button
              type="button"
              className="close"
              aria-label="Close"
              onClick={() => {
                // Get the modal and backdrop elements
                const modal = document.getElementById("NewTourTypesModal");
                const backdrop = document.querySelector(".modal-backdrop");

                // Hide the modal and remove the backdrop
                modal.style.display = "none";
                backdrop.parentNode.removeChild(backdrop);

                // Reset the modal's state to allow it to be opened again
                modal.classList.remove("show");
                modal.removeAttribute("aria-modal");
                modal.setAttribute("aria-hidden", true);
              }}
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <Form>
              <div className="modal-tourInputs">
                <div className="form-group">
                  <div className="has-float-label">
                    <select
                      className="form-control"
                      style={{ marginLeft: "0px" }}
                      onChange={(e) => setType(e.target.value)}
                    >
                      <option value="in-person">In-Person</option>
                      <option value="virtual">Virtual</option>
                    </select>
                    {/* <input
                          className="form-control"
                          id="amount"
                          name="amount"
                          placeholder="Amount"
                          autoComplete="off"
                          type="number"
                          onChange={(event) => {
                            setCode(event.target.value);
                          }}
                        /> */}
                    <label htmlFor="amount">Type</label>
                  </div>
                </div>
              </div>
              <div className="modal-tourInputs">
                <div className="form-group">
                  <div className="has-float-label">
                    <input
                      className="form-control"
                      id="amount"
                      name="amount"
                      placeholder="Amount"
                      autoComplete="off"
                      onChange={(event) => {
                        setCode(event.target.value);
                      }}
                    />
                    <label htmlFor="amount">Code</label>
                  </div>
                </div>
              </div>
              <div className="modal-tourInputs">
                <div className="form-group">
                  <div className="has-float-label">
                    <input
                      className="form-control"
                      id="amount"
                      name="amount"
                      placeholder="Amount"
                      autoComplete="off"
                      onChange={(event) => {
                        setName(event.target.value);
                      }}
                    />
                    <label htmlFor="amount">Tour Name</label>
                  </div>
                </div>
              </div>
              {/* <div className="modal-tourInputs">
                    <div className="form-group">
                      <div className="has-float-label">
                        <input
                          className="form-control"
                          id="amount"
                          name="amount"
                          placeholder="Amount"
                          autoComplete="off"
                          type="number"
                          onChange={(event) => {
                            setCode(event.target.value);
                          }}
                        />
                        <label htmlFor="amount">Date & Time</label>
                      </div>
                    </div>
                  </div> */}
              <div className="modal-tourInputs">
                <div className="form-group">
                  <div className="has-float-label">
                    <select
                      className="form-control"
                      style={{ marginLeft: "0px" }}
                      onChange={(e) => setDuration(e.target.value)}
                    >
                      <option value="10">10 Minutes</option>
                      <option value="20">20 Minutes</option>
                      <option value="30">30 Minutes</option>
                      <option value="40">40 Minutes</option>
                      <option value="50">50 Minutes</option>
                      <option value="60">60 Minutes</option>
                    </select>
                    {/* <input
                          className="form-control"
                          id="amount"
                          name="amount"
                          placeholder="Amount"
                          autoComplete="off"
                          type="number"
                          onChange={(event) => {
                            setCode(event.target.value);
                          }}
                        /> */}
                    <label htmlFor="amount">Duration</label>
                  </div>
                </div>
              </div>
              {tourType == "virtual" && (
                <div className="modal-tourInputs">
                  <div className="form-group">
                    <div className="has-float-label">
                      <input
                        className="form-control"
                        id="amount"
                        name="amount"
                        placeholder="Amount"
                        autoComplete="off"
                        onChange={(event) => {
                          setLink(event.target.value);
                        }}
                      />
                      <label htmlFor="amount">Meeting Link</label>
                    </div>
                  </div>
                </div>
              )}
              <div className="modal-tourInputs">
                <div className="form-group">
                  <div className="has-float-label">
                    <div className="form-control radio-input">
                      <Radio slider onChange={(evt, data) => setFacing(data)} />
                    </div>
                    <label className="self-Booking" htmlFor="amount">
                      Self Booking
                    </label>
                  </div>
                </div>
              </div>
              <div className="modal-tourButton">
                <button
                  onClick={() => handleInviteSubmit()}
                  disabled={!tourCode || !tourName}
                >
                  <span>Add Tour</span>
                </button>
              </div>
            </Form>
          </div>
        </div>
      </div>
    </>
  );
}

export default TourManagement;
