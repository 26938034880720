export const ChatRoomLabelType = {
  PropertyManager: "Property Manager",
  ServiceProfessional: "Service Professional",
  Renter: "Renter",
  Homeowner: "Homeowner",
};

export const MediaContentType = {
  jpeg: "image/jpeg",
  png: "image/png",
  pdf: "application/pdf",
  doc: "application/msword",
  docx: "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
  xls: "application/vnd.ms-excel",
  xlsx: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  csv: "text/csv",
};

const initialState = {
  roomList: [],
  contactList: [],
  chatLogs: {},
  chatRoomDetails: {},
};

export const SET_CHAT_ROOM_LIST = "SET_CHAT_ROOM_LIST";
export const SET_CHAT_CONTACT_LIST = "SET_CHAT_CONTACT_LIST";
export const SET_CHAT_LOG = "SET_CHAT_LOG";
export const ADD_CHAT_ROOM = "ADD_CHAT_ROOM";
export const SET_CHAT_ROOM_DETAIL = "SET_CHAT_ROOM_DETAIL";
export const RESET_CHAT = "RESET_CHAT";
export const SET_LAST_MESSAGE = "SET_LAST_MESSAGE";

const reducer = (state = initialState, { payload, type }) => {
  if (type === RESET_CHAT) {
    return initialState;
  }

  if (type === SET_CHAT_ROOM_LIST) {
    return {
      ...state,
      roomList: payload,
    };
  }

  if (type === ADD_CHAT_ROOM) {
    const isExists = state.roomList.filter(
      (room) => room?.chatRoomId?.toString() === payload?.chatRoomId?.toString()
    );
    if (isExists.length === 0) {
      return {
        ...state,
        roomList: [...state.roomList, payload],
      };
    }
  }

  if (type === SET_CHAT_LOG) {
    return {
      ...state,
      chatLogs: {
        ...state.chatLogs,
        ...payload,
      },
    };
  }

  if (type === SET_CHAT_CONTACT_LIST) {
    return {
      ...state,
      contactList: payload,
    };
  }
  if (type === SET_CHAT_ROOM_DETAIL) {
    return {
      ...state,
      chatRoomDetails: {
        ...state.chatRoomDetails,
        [payload.chatRoomId]: payload.detail,
      },
    };
  }

  if (type === SET_LAST_MESSAGE) {
    const newRoomList = state.roomList.map((item) => {
      if (item.chatRoomId.toString() === payload.chatRoomId.toString()) {
        item.previewMessage = payload.preview;
      }
      return item;
    });

    return {
      ...state,
      roomList: newRoomList,
    };
  }
  return state;
};

export default reducer;
