import gql from "graphql-tag";

export const LedgerAccounting = gql`
  query slLocationLedgerAccounts(
    $location: String!
    $limit: Int!
    $offset: Int!
  ) {
    slLocationLedgerAccounts(
      location: $location
      limit: $limit
      offset: $offset
    ) {
      totalItems
      data
    }
  }
`;

export const TrialBalance = gql`
  query trialBalance(
    $slLocationId: Int!,
    $dateRanges: [GenericScalar]!,
    $status: String!,
    $consolidated: Boolean!,
    $limit: Int!,
    $offset: Int!
  ) {
    trialBalance(
      slLocationId: $slLocationId,
      dateRanges: $dateRanges,
      status: $status,
      consolidated: $consolidated,
      limit: $limit,
      offset: $offset
    )
  }
`;




export const BalanceAccounting = gql`
  query balanceSheet(
    $locationId: String!
  ) {
    balanceSheet(
      locationId: $locationId
    ) {
      edges {
        node {
          name
          requestedDate
          number
          type
          subType
          balance
        }
      }
    }
  }
`;
export const ProfitLossAccounting = gql`
  query profitLoss(
    $locationId: String!
  ) {
    profitLoss(
      locationId: $locationId
    ) {
      edges {
        node {
          name
          requestedDate
          number
          type
          subType
          balance
          locationId
          id
          slLedgerId
        }
      }
    }
  }
`;


export const LedgerAccountingRoll = gql`
  query slLocationLedgerAccounts(
    $location: String!
    $limit: Int!
    $offset: Int!
  ) {
    slLocationLedgerAccounts(
      location: $location
      limit: $limit
      offset: $offset
    ) {
      totalItems
      data
    }
  }
`;

export const GeneralLedgerRoll = gql`
  query generalLedger(
    $slLocationId: Int!
    $startDate: String!
    $endDate: String!
    $status: String!
    $consolidated: Boolean!
  ) {
    generalLedger(
      slLocationId: $slLocationId
      startDate: $startDate
      endDate: $endDate
      status: $status
      consolidated: $consolidated
    )
  }
`;

export const AccountingMetrics = gql`
  query slMetrics($location: String!) {
    slMetrics(location: $location) {
      edges {
        node {
          id
          locationId
          invoicesPaid30
          invoicesUnpaid30
          invoicesTotal30
          invoicesPaid365
          invoicesUnpaid365
          invoicesTotal365
          expensesTotal30
          expensesTotal365
          assetsTotal365
          assetsTotal30
          incomeTotal30
          incomeTotal365
          netIncomeTotal30
          netIncomeTotal365
          createdAt
          updatedAt
          incomeTotalGraph
          expensesTotalGraph
          invoicesTotalGraph
        }
      }
    }
  }
`;
export const ConcessionsAmountRenter = gql`
  query concessionsAmountRenter($renterId: String!) {
    concessionsAmountRenter(renterId: $renterId) {
      edges {
        node {
          id
        locationId
        leaseId
        renterPersonId
        concessionsAmount
        description
        }
      }
    }
  }
`;

export const LeaseMetaData = gql`
  query leaseMetaData($leaseId: String!) {
    leaseMetaData(leaseId: $leaseId) {
      edges {
          node {
              leaseId
              moveInChecklist
          }
      }
  }
  }
`;

export const SingleLedgerAccounting = gql`
  query slLocationAccountTransactions(
    $location: String!
    $LedgerAccountId: Int!
    $startDate: Date!
    $endDate: Date!
  ) {
    slLocationAccountTransactions(
      location: $location
      LedgerAccountId: $LedgerAccountId
      startDate: $startDate
      endDate: $endDate
    ) {
      totalItems
      startingBalance
      data
    }
  }
`;

export const TransAccounting = gql`
  query slLocationTransactions(
    $location: String!
    $limit: Int!
    $offset: Int!
  ) {
    slLocationTransactions(
      location: $location
      limit: $limit
      offset: $offset
    ) {
      totalItems
      startingBalance
      data
    }
  }
`;

export const ChargeAccounting = gql`
  query chargeCodes($location: String!) {
    chargeCodes(location: $location) {
      edges {
        node {
          id
          name
          quantity
          desc
          extLedgerId
          locationId
          extTaxcodeId
          amountDefault
          descInternal
          code
          type
        }
      }
    }
  }
`;

export const DefaultChargeCodes = gql`
  query defaultChargeCodes($locationId: String!) {
    defaultChargeCodes(locationId: $locationId) {
      edges {
        node {
          unitChargeCode {
            id
            name
            code
            amountDefault
            quantity
          }
          petChargeCode {
            id
            name
            code
            amountDefault
            quantity
          }
          vehicleChargeCode {
            id
            name
            code
            amountDefault
            quantity
          }
          depositChargeCode {
            id
            name
          }
        }
      }
    }
  }
`;

export const ChargeAccountingMutation = gql`
  mutation createChargeCode($input: CreateChargeCodeInput!) {
    createChargeCode(accounting: $input) {
      response
      chargeCode
    }
  }
`;

export const BalanceSheetCreateUpdate = gql`
  mutation balanceSheetCreateUpdate($input: BalanceSheetInput!) {
    balanceSheetCreateUpdate(inputs: $input) {
      response
    }
  }
`;

export const CreateSlJournal = gql`
mutation createSlJournal($input: CreateSLJournalInput!) {
  createSlJournal(accounting: $input) {
    response
    slJournal
  }
}
`;
export const EditSlJournal = gql`
mutation updateSlJournal($input: UpdateSLJournalInput!, $journalId:Int!) {
  updateSlJournal(accounting: $input, journalId:$journalId ) {
    response
    slJournal
  }
}
`;

export const DeleteSlJournal = gql`
mutation deleteSlJournal($input: DeleteJournalsInput!) {
  deleteSlJournal(accounting: $input) {
    response
    slJournal
  }
}
`;
export const PostSlJournal = gql`
mutation postSlJournal($input: PostJournalsInput!) {
  postSlJournal(accounting: $input) {
    response
    slJournal
  }
}
`;


export const ReconcileTransaction = gql`
  mutation reconcileTransaction($input: ReconcileTransactionInput!) {
    reconcileTransaction(accounting: $input) {
      response
    }
  }
`;


export const ReconcileData = gql`
  query slLocationTransactions(
    $location: String!
    $ledger: String!
    $start: String!
    $end: String!
    $limit: Int!
    $offset: Int!
  ) {
    slLocationTransactions(
      location: $location
      ledger: $ledger
      start: $start
      end: $end
      limit: $limit
      offset: $offset
    ) {
      totalItems
      startingBalance
      data
    }
  }
`;

export const LedgerTransactionsGql = gql`
  query slLocationTransactions(
    $location: String!
    $ledgerNumber: String!
    $start: String!
    $end: String!
    $limit: Int!
    $offset: Int!
  ) {
    slLocationTransactions(
      location: $location
      ledgerNumber: $ledgerNumber
      start: $start
      end: $end
      limit: $limit
      offset: $offset
    ) {
      totalItems
      startingBalance
      data
    }
  }
`;

export const ReconcileSumData = gql`
  query slSumLocationAccount(
    $location: String!
    $ledger: String!
    $start: String!
    $end: String!
  ) {
    slSumLocationAccount(
      location: $location
      ledger: $ledger
      start: $start
      end: $end
    )
  }
`;
export const LocationJournal = gql`
  query slLocationAccountJournal($location: String!, $journal: String!) {
    slLocationAccountJournal(location: $location, journal: $journal)
  }
`;

export const SumLocationAccount = gql`
  query slSumLocationAccount($location: String!, $ledger: String!, $start:String!, $end:String!) {
    slSumLocationAccount(location: $location, ledger:$ledger, start:$start, end:$end)
  }
`;

export const ProfitLossDetails = gql`
  query profitLossDetails($slLocationId: String!, $slLedgerId:String!) {
    profitLossDetails(slLocationId: $slLocationId, slLedgerId:$slLedgerId)
  }
`;

export const TotalBalanceSheet = gql`
  query totalBalanceSheet($slLocationId: String!) {
    totalBalanceSheet(slLocationId: $slLocationId)
  }
`;
export const TotalProfitLoss = gql`
  query totalProfitLoss($slLocationId: String!) {
    totalProfitLoss(slLocationId: $slLocationId)
  }
`;

export const SlJournals = gql`
  query slJournals($location: String!, $status:String!, $limit: Int!
    $offset: Int!)  {
    slJournals(location: $location, status:$status, limit: $limit
      offset: $offset)
  }
`;

export const UpdateAccountingMutation = gql`
  mutation updateChargeCode($input: UpdateChargeCodeInput!) {
    updateChargeCode(accounting: $input) {
      response
      chargeCode
    }
  }
`;
export const DeleteAccountingMutation = gql`
  mutation deleteChargeCode($input: DeleteChargeCodeInput!) {
    deleteChargeCode(accounting: $input) {
      response
    }
  }
`;

export const UpdatePurchaseOrder = gql`
  mutation updatePurchaseOrder($input: UpdatePurchaseOrderInput!, $purchaseOrder:Int!) {
    updatePurchaseOrder(accounting: $input, purchaseOrder:$purchaseOrder) {
      response
      purchaseOrder
    }
  }
`;



export const CreateLedgerMutation = gql`
  mutation createSlLedgerAccount($input: CreateSLLedgerAccountInput!) {
    createSlLedgerAccount(accounting: $input) {
      response
      slLedgerAccount
    }
  }
`;
export const UpdateLedgerMutation = gql`
  mutation updateSlLedgerAccount($input: UpdateSLLedgerAccountInput!) {
    updateSlLedgerAccount(accounting: $input) {
      response
      slLedgerAccount
    }
  }
`;
export const DeleteLedgerMutation = gql`
  mutation deleteSlLedgerAccount($input: DeleteSLLedgerAccountInput!) {
    deleteSlLedgerAccount(accounting: $input) {
      response
    }
  }
`;

export const InvoiceAccounting = gql`
  query slLocationInvoices(
    $location: String!
    $limit: Int!
    $offset: Int!
    $credits: Boolean!
  ) {
    slLocationInvoices(
      location: $location
      limit: $limit
      offset: $offset
      credits: $credits
    ) {
      totalItems
      data
    }
  }
`;

export const SLInvoiceAccounting = gql`
  query slLocationInvoice($location: String!, $id: Int!) {
    slLocationInvoice(location: $location, id: $id) {
      invoice
    }
  }
`;

export const PurchaseOrderLineItems = gql`
  query purchaseOrderLineItems($PurchaseOrderId: Int!) {
    purchaseOrderLineItems(PurchaseOrderId: $PurchaseOrderId) {
      data
      totalItems
    }
  }
`;

export const WorkReceive = gql`
  mutation receivePurchaseOrder($purchaseOrderId: Int!, $receiveDate: String!) {
    receivePurchaseOrder(purchaseOrderId: $purchaseOrderId, receiveDate: $receiveDate) {
      response
    }
  }
`;

export const BillsAccounting = gql`
  query slLocationBills($location: String!, $status: [String!]) {
    slLocationBills(location: $location, status: $status)
  }
`;
export const FinancialReport = gql`
  query financialReport($slLocationId: Int!, $startDate: String!, $endDate:String!) {
    financialReport(slLocationId: $slLocationId, startDate: $startDate, endDate:$endDate) 
  }
`;
export const BillAccounting = gql`
  query slLocationBills($location: String!, $status: [String!], $BillId: Int!) {
    slLocationBills(location: $location, status: $status, BillId: $BillId)
  }
`;

export const VendorsAccounting = gql`
  query slLocationVendors($location: String!) {
    slLocationVendors(location: $location)
  }
`;

export const PurchaseOrder = gql`
  query purchaseOrder($location: String!, $limit: Int!, $offset: Int!, $VendorId:Int,$id:Int) {
    purchaseOrder(location: $location, limit: $limit, offset: $offset, VendorId:$VendorId, id:$id){
      data
      totalItems
    }
  }
`;


export const CreatePurchaseOrder = gql`
  mutation createPurchaseOrder($input: CreatePurchaseOrderInput!) {
    createPurchaseOrder(accounting: $input) {
      response
      purchaseOrder
    }
  }
`;

export const slLocationInvoicePayments = gql`
  query slLocationInvoicePayments($location: String!, $InvoiceId: Int!) {
    slLocationInvoicePayments(location: $location, InvoiceId: $InvoiceId)
  }
`;

export const slLocationInvoiceTransactions = gql`
  query slLocationTransactions($location: String!, $InvoiceId: Int!) {
    slLocationTransactions(location: $location, InvoiceId: $InvoiceId) {
      totalItems
      startingBalance
      data
    }
  }
`;



export const paySlInvoice = gql`
  mutation paySlInvoice($input: PaySLInvoiceInput!) {
    paySlInvoice(accounting: $input) {
      response
    }
  }
`;

export const PaymentAccounting = gql`
  query slLocationPayments($location: String!, $limit: Int!, $offset: Int!) {
    slLocationPayments(location: $location, limit: $limit, offset: $offset) {
      totalItems
      data
    }
  }
`;

export const TenantPaymentHistory = gql`
  query slLocationTransactions($applicationId: String!,$location: String!, $limit: Int!, $offset: Int!) {
    slLocationTransactions(applicationId: $applicationId,location: $location, limit: $limit, offset: $offset) {
      totalItems
    startingBalance
    data
    }
  }
`;

export const transactionsGql = gql`
  query slLocationTransactions(
    $location: String!
    $applicationId:String!
    $limit: Int!
    $offset: Int!
  ) {
    slLocationTransactions(
      location: $location
      applicationId:$applicationId
      limit: $limit
      offset: $offset
    ) {
      totalItems
      startingBalance
      data
    }
  }
`;
export const financialsGql = gql`
  query slLocationFinancials(
    $location: String!
    $reportType: String!
    $start: String!
    $end: String!
  ) {
    slLocationFinancials(
      location: $location
      reportType: $reportType
      start: $start
      end: $end
    )
  }
`;
export const trialGql = gql`
  query slLocationTrialBalance(
    $location: String!
    $numType: Int!
    $journalStatus: String!
    $dateType: String!
  ) {
    slLocationTrialBalance(
      location: $location
      numType: $numType
      journalStatus: $journalStatus
      dateType: $dateType
    )
  }
`;

export const InvoiceInfoAccounting = gql`
  query slLocationInvoice($location: String!, $id: Int!) {
    slLocationInvoice(location: $location, id: $id) {
      location {
        name
        id
        contact
        addresses {
          zip
          state
          street
          city
        }
      }
      refundRequest {
        id
        accountId
        dsPersonId
        invoiceId
        slLocationId
        locationId
        approved
        approvedBy
        createdAt
        updatedAt
      }
      customer {
        nameUpper
        email
        addresses {
          zip
          state
          street
          city
        }
      }
      payments
      invoice {
        number
        status
        unit
        currency
        invoiceDate
        
        dueDate
        subtotal
        tax
        amount
      }
      items {
        subtotal
        total
        taxAmount
        description
        quantity
      }
      service {
        type
        service
      }
    }
  }
`;

export const customersAccounting = gql`
  query slCustomers($location: String!) {
    slCustomers(location: $location) {
      totalItems
      data
    }
  }
`;

export const CreateInvoiceMutation = gql`
  mutation createSlInvoice($input: CreateSLInvoiceInput!) {
    createSlInvoice(accounting: $input) {
      response
      slInvoice
    }
  }
`;
export const UpdateInvoiceMutation = gql`
  mutation updateSlInvoice($input: UpdateSLInvoiceInput!) {
    updateSlInvoice(accounting: $input) {
      response
    }
  }
`;

export const DeleteInvoiceMutation = gql`
  mutation deleteSlInvoice($input: DeleteSLInvoiceInput!) {
    deleteSlInvoice(accounting: $input) {
      response
    }
  }
`;
export const VoidInvoiceMutation = gql`
  mutation voidSlInvoice($input: SLInvoiceInput!) {
    voidSlInvoice(accounting: $input) {
      response
    }
  }
`;
export const IssueInvoiceMutation = gql`
  mutation issueSlInvoice($input: SLInvoiceInput!) {
    issueSlInvoice(accounting: $input) {
      response
    }
  }
`;

export const TaxAccounting = gql`
  query slLocationTaxCodes($location: String!, $limit: Int!, $offset: Int!) {
    slLocationTaxCodes(location: $location, limit: $limit, offset: $offset) {
      totalItems
      data
    }
  }
`;
export const TaxAccountingRoll = gql`
  query slLocationTaxCodes($location: String!, $limit: Int!, $offset: Int!) {
    slLocationTaxCodes(location: $location, limit: $limit, offset: $offset) {
      totalItems
      data
    }
  }
`;

export const RentRollGQL = gql`
  query rentRoll($locationId: String!) {
    rentRoll(locationId: $locationId)
  }
`;
export const TaxCreateMutation = gql`
  mutation createSlTaxCode($input: CreateSLTaxCodeInput!) {
    createSlTaxCode(accounting: $input) {
      response
      slTaxCode
    }
  }
`;

export const TaxUpdateMutation = gql`
  mutation updateSlTaxCode($input: UpdateSLTaxCodeInput!) {
    updateSlTaxCode(accounting: $input) {
      response
      slTaxCode
    }
  }
`;

export const TaxDeleteMutation = gql`
  mutation deleteSlTaxCode($input: DeleteSLTaxCodeInput!) {
    deleteSlTaxCode(accounting: $input) {
      response
    }
  }
`;

export const ManagerInvoice = gql`
  query invoiceCustomers($locationId: String!) {
    invoiceCustomers(locationId: $locationId) {
      name
      agentId
      referenceId
      applicationId
    }
  }
`;

export const RepayMutation = gql`
  mutation revertTransaction($transaction: RevertTransactionInput!) {
    revertTransaction(transaction: $transaction) {
      response
    }
  }
`;

export const VendorCreateMutation = gql`
  mutation createSlVendor($input: CreateSLVendorInput) {
    createSlVendor(accounting: $input) {
      response
    }
  }
`;

export const BillCreateMutation = gql`
  mutation createSlBill($input: CreateSLBillInput!) {
    createSlBill(accounting: $input) {
      response
    }
  }
`;

export const UpdateCharges = gql`
  mutation updateCharges($input: UpdateChargesInput!) {
    updateCharges(input: $input) {
      response
    }
  }
`;



export const BoxScoreGql = gql`
  query boxScoreResults(
    $locationId: String!
    $startDate: Date!
    $endDate: Date!
    $reportType: ReportTypes!
  ) {
    boxScoreResults(
      locationId: $locationId
      startDate: $startDate
      endDate: $endDate
      reportType: $reportType
    ) {
      edges {
        cursor
        node {
          id
          unit
          floorPlan
          name
          applyDate
          moveIn
          leaseTerm
          marketRent
          leaseRent
          credits
          otherCharges
          depositOnhand
          leasedBy
          effectiveRent
        }
      }
    }
  }
`;

export const approveSLBillMutation = gql`
  mutation approveSlBill($input: ApproveSLBillInput!) {
    approveSlBill(accounting: $input) {
      response
    }
  }
`;
export const deleteSLBillMutation = gql`
  mutation deleteSlBill($input: DeleteSLBillInput!) {
    deleteSlBill(accounting: $input) {
      response
    }
  }
`;
export const voidSLBillMutation = gql`
  mutation voidSlBill($input: VoidSLBillInput!) {
    voidSlBill(accounting: $input) {
      response
    }
  }
`;
export const IssuePurchaseOrder = gql`
  mutation issuePurchaseOrder($input: IssuePurchaseOrderInput!) {
    issuePurchaseOrder(accounting: $input) {
      response
      purchaseOrder
    }
  }
`;
export const DeletePurchaseOrder = gql`
  mutation deletePurchaseOrder($input: IssuePurchaseOrderInput!) {
    deletePurchaseOrder(accounting: $input) {
      response
      purchaseOrder
    }
  }
`;

export const paySLBillMutation = gql`
  mutation paySlBill($input: PaySLBillInput!) {
    paySlBill(accounting: $input) {
      response
    }
  }
`;
export const updateSLBillMutation = gql`
  mutation updateSlBill($input: UpdateSLBillInput!) {
    updateSlBill(accounting: $input) {
      response
    }
  }
`;

export const slLocationBillPayments = gql`
  query slLocationBillPayments($location: String!, $BillId: Int!) {
    slLocationBillPayments(location: $location, BillId: $BillId)
  }
`;
export const slLocationTransactions = gql`
  query slLocationTransactions($location: String!, $BillId: Int!) {
    slLocationTransactions(location: $location, BillId: $BillId) {
      totalItems
      startingBalance
      data
    }
  }
`;
export const approveSLPaymentToBillMutation = gql`
  mutation approveSlBillPayment($input: ApproveSLBillPaymentInput!) {
    approveSlBillPayment(accounting: $input) {
      response
    }
  }
`;
export const voidSLPaymentToBillMutation = gql`
  mutation voidSlBillPayment($accounting: VoidSLBillPaymentInput!) {
    voidSlBillPayment(accounting: $accounting) {
      response
    }
  }
`;
export const deleteSLPaymentToBillMutation = gql`
  mutation deleteSlBillPayment($input: DeleteSLBillPaymentInput!) {
    deleteSlBillPayment(accounting: $input) {
      response
    }
  }
`;

export const UploadWepayDocuments = gql`
  mutation uploadWepayDocuments($input: UploadWepayDocumentsInput!) {
    uploadWepayDocuments(documents: $input) {
      response
    }
  }
`;


export const Delinquencysql = gql`
  query delinquency($locationId: String!, $slLocationId: Int!) {
    delinquency(locationId: $locationId, slLocationId: $slLocationId) 
  }
`;

export const ConvenienceFeesWepay = gql`
  query convenienceFeesWepay {
    convenienceFeesWepay {
      type
      chargeType
      amount
      currency
      convenienceAmount
      totalAmount
    }
  }
`;

export const ApproveRefundRequest = gql`
  mutation approveRefundRequest($refundRequest: ApproveRefundRequestInput!) {
    approveRefundRequest(refundRequest: $refundRequest) {
      response
    }
  }
`;

export const RefundWePayTransaction = gql`
  mutation refundWepayTransaction($refund: RefundWePayTransactionInput!) {
    refundWepayTransaction(refund: $refund) {
      response
    }
  }
`;
export const cancelWepayTransaction = gql`
  mutation cancelWepayTransaction($cancel: CancelWePayTransactionInput!) {
    cancelWepayTransaction(cancel: $cancel) {
      response
    }
  }
`;
