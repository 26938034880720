import React from "react";
import { Grid, Table, Header, Button } from "semantic-ui-react";
import "react-datetime/css/react-datetime.css";
import { getClient } from "../../init-apollo-googleFn";
import { WEPAY_URL } from "../../utils/constants";
import {
  parseGraphQLErrors,
  toastFailMsg,
  toastSuccessMsg,
} from "../../utils/common";
import { ApproveRefundRequest } from "../../store/person/accounting";
const wePayClient = getClient(WEPAY_URL);
export default function ViewInvoiceDetails(props) {
  const approveDecline = (e) => {
    wePayClient
      .mutate({
        mutation: ApproveRefundRequest,
        variables: {
          refundRequest: {
            id: props?.invoice?.refundRequest?.id,
            approve: e,
          },
        },
      })
      .then((response) => {
        toastSuccessMsg("Success");
        props.getPayments();
      })
      .catch((error) => {
        // toastFailMsg("Failure");
      });
  };

  return (
    <>
      <br />
      <Grid columns={2}>
        <Grid.Column>
          <Table compact>
            <Table.Body>
              <Table.Row>
                <Table.Cell>
                  {props.invoice &&
                    props.invoice.location &&
                    props.invoice.location.name}
                </Table.Cell>
                <Table.Cell>
                  {props.invoice &&
                    props.invoice.location &&
                    props.invoice.location.contact.phone}
                </Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell>
                  {props.invoice &&
                    props.invoice.location &&
                    props.invoice.location.addresses.street}
                </Table.Cell>
                <Table.Cell>
                  {props.invoice &&
                    props.invoice.location &&
                    props.invoice.location.contact.email}
                </Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell>
                  {props.invoice &&
                    props.invoice.location &&
                    props.invoice.location.addresses &&
                    props.invoice.location.addresses.city}
                  ,{" "}
                  {props.invoice &&
                    props.invoice.location &&
                    props.invoice.location.addresses &&
                    props.invoice.location.addresses.state}
                  ,{" "}
                  {props.invoice &&
                    props.invoice.location &&
                    props.invoice.location.addresses &&
                    props.invoice.location.addresses.zip}
                </Table.Cell>
                <Table.Cell>{"Insert Website"}</Table.Cell>
              </Table.Row>
            </Table.Body>
          </Table>
        </Grid.Column>
        <Grid.Column>
          <Header>Notes</Header>
          {props.invoices && props.invoices.notes}
        </Grid.Column>
      </Grid>
      <Table compact>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Bill to</Table.HeaderCell>
            <Table.HeaderCell>Invoice Details</Table.HeaderCell>
          </Table.Row>
        </Table.Header>

        <Table.Body>
          <Table.Row>
            <Table.Cell>
              {props.invoice &&
                props.invoice.customer &&
                props.invoice.customer.nameUpper}
            </Table.Cell>
            <Table.Cell>
              Number#:{" "}
              {props.invoice &&
                props.invoice.customer &&
                props.invoice.invoice.number}
            </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>
              {props.invoice &&
                props.invoice.customer &&
                props.invoice.customer.addresses &&
                props.invoice.customer.addresses.street}
            </Table.Cell>
            <Table.Cell>
              Invoice Date:{" "}
              {props.invoice &&
                props.invoice.invoice &&
                props.invoice.invoice.invoiceDate}
            </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>
              {props.invoice &&
                props.invoice.customer &&
                props.invoice.customer.addresses &&
                props.invoice.customer.addresses.city}{" "}
              {props.invoice &&
                props.invoice.customer &&
                props.invoice.customer.addresses &&
                props.invoice.customer.addresses.state}{" "}
              {props.invoice &&
                props.invoice.customer &&
                props.invoice.customer.addresses &&
                props.invoice.customer.addresses.zip}
            </Table.Cell>
            <Table.Cell>
              {" "}
              Due:{" "}
              {props.invoices &&
                props.invoices.props &&
                props.invoices.props.dueDate}
            </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>
              {props.invoice &&
                props.invoice.customer &&
                props.invoice.customer.email}
            </Table.Cell>
            <Table.Cell></Table.Cell>
          </Table.Row>
        </Table.Body>
      </Table>
      <Table padded="very" compact>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Description</Table.HeaderCell>
            <Table.HeaderCell>Qty</Table.HeaderCell>
            <Table.HeaderCell>Tax</Table.HeaderCell>
            <Table.HeaderCell>Amount</Table.HeaderCell>
            <Table.HeaderCell>Total</Table.HeaderCell>
          </Table.Row>
        </Table.Header>

        <Table.Body>
          {props &&
            props.invoice &&
            props.invoice.items &&
            props.invoice.items.map((inv) => {
              return (
                <Table.Row>
                  <Table.Cell>{inv.description}</Table.Cell>
                  <Table.Cell>{inv.quantity}</Table.Cell>
                  <Table.Cell>${inv.taxAmount}</Table.Cell>
                  <Table.Cell>${inv.subtotal}</Table.Cell>
                  <Table.Cell>${inv.total}</Table.Cell>
                </Table.Row>
              );
            })}
        </Table.Body>
      </Table>

      <Grid columns={2}>
        <Grid.Column>
          {props.invoice &&
            props.invoice.refundRequest &&
            typeof props.invoice.refundRequest === "object" && (
              <>
                <Header textAlign="center">Refund Request</Header>
                {props.invoice.refundRequest.approved == false ? (
                  <Button fluid compact  onClick={() => approveDecline(true)}>Approve</Button>
                ) : (
                  <>
                    <Header textAlign="center" as="h5">
                      This refund request has been approved. Please check the
                      Payments tab to refund individual payments.
                    </Header>
                    <br></br>
                    <Button fluid compact onClick={() => approveDecline(false)}>
                      Decline
                    </Button>
                  </>
                )}
              </>
            )}
        </Grid.Column>
        <Grid.Column>
          <Table padded="very" compact>
            <Table.Body>
              <Table.Row>
                <Table.Cell>{"Subtotal"}</Table.Cell>
                <Table.Cell textAlign="right">
                  $
                  {props.invoice &&
                    props.invoice.invoice &&
                    props.invoice.invoice.subtotal}
                </Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell>{"Sales Tax"}</Table.Cell>
                <Table.Cell textAlign="right">
                  $
                  {props.invoice &&
                    props.invoice.invoice &&
                    props.invoice.invoice.tax}
                </Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell>Total</Table.Cell>
                <Table.Cell textAlign="right">
                  $
                  {props.invoice &&
                    props.invoice.invoice &&
                    props.invoice.invoice.amount}
                </Table.Cell>
              </Table.Row>
            </Table.Body>
          </Table>
        </Grid.Column>
      </Grid>
    </>
  );
}
