import React from "react";
import "./Error404.scss";
import { PORTALS } from "../../utils/constants";
import { Container, Header, Image } from "semantic-ui-react";
export const Error404 = (props) => {
  return (
    <Container textAlign="center" className="error404">
      <Image
        fluid
        label={{
          as: "a",
          size: "massive",
          content: "Aw Man!",
          icon: "alarm",
          ribbon: true,
          className: "alertRibbon",
        }}
        src="/assets/img/404-boxes-background-cropped.png"
      />
      <Header as="h2">
        Looks like the page you're trying to find has moved out
        <br />
        (or maybe it just never existed). No worries! You can
        <br />
        view listings <a href={PORTALS.search}>here</a> or head{" "}
        <a href={props && props.user ? "/#" : PORTALS.landing}>back home</a>.
      </Header>
    </Container>
  );
};
