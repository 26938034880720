import React from "react";
import "./Loader.scss";
import LeaLoader from "../PageLoader/LeaLoader";

/**
  Loader was called by previous Quill template, replaced with Semantic Semantic Loader
  Will keep here so we can style our loaders to look the same throughout the app
**/

export function Loader(props) {
  return (
    <LeaLoader
      text={props.text}
      inTable={props.inTable}
      stepper={props.stepper}
    />
  );
}
