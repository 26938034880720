/* eslint-disable */
import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Progress, Button, Modal, Header } from "semantic-ui-react";
import get from "lodash/get";
import { pluralize } from "../../../utils/common";
import { ON_BOARDING_STEPS, ROUTES } from "../../../utils/constants";
import PropertyCardModal from "../AddProperty/PropertyCardModal";
import { setupMicrosite } from "../../../store/person/properties";
import { MICROSITE } from "../../../utils/constants";
import { getClient } from "../../../init-apollo-googleFn";
import { toast } from "react-toastify";
import mixpanel from "mixpanel-browser";
const micrositeClient = getClient(MICROSITE);

const PropertyCard = ({
  id,
  name,
  addresses,
  photos,
  microsite,
  totalBaths,
  organizationId,
  totalBeds,
  totalSizes,
  totalPrices,
  totalUnits,
  status,
  disabled,
  templateId,
  locationTypeId,
  primaryLocations,
  onBoardingStep,
  navigateToDetails,
  onDeactivate,
}) => {
  const [showActions, setShowActions] = React.useState(false);
  const [paymentModalOpen, setPaymentModalOpen] = React.useState(false);
  const [toastMessage, setToastMessage] = useState(null);
  const [rerender, setRerender] = useState(null);

  const toggleActionDropDown = (event) => {
    event.stopPropagation();
    setShowActions((prev) => !prev);
  };

  const onCardClick = () => {
    if (status !== "draft" && !disabled) navigateToDetails(id);
  };

  const progress = React.useMemo(
    () => ON_BOARDING_STEPS.indexOf(onBoardingStep) + 1,
    [onBoardingStep]
  );

  const cardImg = Array.isArray(photos) && photos[0];

  const handleRerender = (update) => {
    setRerender(update);
  };

  const forward = () => {
    try {
      mixpanel.track("Manager Property Action", {
        sub: "Sign Up for Microsite",
      });
      micrositeClient
        .mutate({
          mutation: setupMicrosite,
          variables: {
            microsite: {
              locationId: id,
            },
          },
        })
        .then((res) => {
          setToastMessage(
            toast.success("Thank you for signing up for Microsites!")
          );
          setRerender("activated");
        })
        .catch((e) => {
          console.log(e);
        });
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <>
      <div className={`card ${disabled ? "disabled-card" : ""} properties-fourth-step`}>
        <div className={`img-box ${!cardImg ? "default-box" : ""}`}>
          {cardImg ? (
            <img src={cardImg} alt="property-img" />
          ) : (
            <img src="/assets/img/default-card-image.svg" alt="property-img" />
          )}
          {status !== "draft" && totalPrices && (
            <div className="black-strip">
              <span>{`$${get(totalPrices, "[0]", 0)}-$${get(
                totalPrices,
                "[1]",
                0
              )}`}</span>
              {!!totalUnits && (
                <>
                  <span className="bar" />
                  <span>{`${totalUnits} units`}</span>
                </>
              )}
            </div>
          )}
        </div>
        <div className="card-info">
          {status === "draft" && (
            <Progress className="progressBar-listings" style={{ marginBottom: '0px', marginTop: '0px' }} value={String(progress)} total="5" progress="percent" />
          )}
          <div className="card-titile">{name}</div>
          <div className="address">
            {addresses.replaceAll(", undefined", "")}
          </div>
          {status !== "draft" && (
            <div className="card-aminites">
              <span>
                {`${get(totalBeds, "[0]", 0)}+ ${pluralize(
                  get(totalBeds, "[0]", 0),
                  "Bed"
                )}`}
              </span>{" "}
              <span className="dot" />
              <span>
                {`${get(totalBaths, "[0]", 0)}+ ${pluralize(
                  get(totalBaths, "[0]", 0),
                  "Bath"
                )}`}
              </span>
              <span className="dot" />
              <span>{`${get(totalSizes, "[0]", 0)}-${get(
                totalSizes,
                "[1]",
                0
              )} sq.ft`}</span>
              <br />
              {(microsite && templateId !== null) ||
                rerender === "template-set" ? (
                <>
                  <div className="properties-fifth-step">
                    <br></br>
                    <Button
                      // compact
                      style={{
                        paddingTop: "1px",
                        paddingBottom: "1px",
                        float: 'right',
                        left: "15.75px",
                        height: "28px",
                        color: "white",
                        backgroundImage:
                          "linear-gradient(to left, #7127c4, #4c158a)",
                      }}
                      onClick={() =>
                        window
                          .open(
                            `https://dev.estates.leasera.com/${organizationId}/${id}/${templateId}`,
                            `_blank`
                          )
                          .focus()
                      }
                    >
                      Microsite
                    </Button>
                    <div className="custom-dropdown">
                      <Button
                        onClick={toggleActionDropDown}
                        style={{
                          paddingTop: "1px",
                          paddingBottom: "1px",
                          height: "28px",
                          float: 'left',
                          left: "15.75px",
                          color: "white",
                          backgroundImage:
                            "linear-gradient(to left, #7127c4, #4c158a)",
                        }}
                      >
                        Actions
                      </Button>
                      {showActions && (
                        <div className="dropdown-list">
                          <div onClick={onCardClick} className="list-item">
                            <Link>
                              <img
                                src="assets/img/icons-explore.svg"
                                alt="icons-edit"
                                style={{
                                  transform: "scale(1.3,1.3)",
                                  paddingLeft: "2px",
                                  paddingTop: "5px",
                                }}
                              />
                              View Property
                            </Link>
                          </div>

                          {!disabled && (
                            <div className="list-item" onClick={(e) => e.stopPropagation()}>
                              <Link
                                to={ROUTES.addProperty + `?propertyType=${locationTypeId}&locationId=${id}`}
                              >
                                <img src="assets/img/icons-edit.svg" alt="icons-edit" />{" "}
                                Edit Property
                              </Link>
                            </div>
                          )}
                          {((templateId === null && microsite) ||
                            (templateId === null && rerender === "activated")) && (
                              <PropertyCardModal
                                locationTypeId={locationTypeId}
                                onRerender={handleRerender}
                                locationId={id}
                                modalTrigger={
                                  <div className="list-item">
                                    <Link>
                                      <img
                                        src="assets/img/icons-upload-file.svg"
                                        alt="icons-edit"
                                      />
                                      Set Template
                                    </Link>
                                  </div>
                                }
                              />
                            )}
                          <div
                            className="list-item red-text"
                            onClick={(e) => {
                              toggleActionDropDown(e);
                              onDeactivate({
                                id,
                                disabled,
                                name,
                                photos,
                              });
                            }}
                          >
                            <a>
                              <img
                                src="assets/img/icons-red-delete.svg"
                                alt="icons-red-delete"
                              />
                              {` ${disabled ? "Activate" : "Deactivate"} Property`}
                            </a>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </>
              ) : (templateId === null && microsite) ||
                (templateId === null && rerender === "activated") ? (
                <PropertyCardModal
                  locationTypeId={locationTypeId}
                  onRerender={handleRerender}
                  locationId={id}
                  organizationId={organizationId}
                  modalTrigger={
                    <Button
                      compact
                      style={{
                        paddingTop: "1px",
                        paddingBottom: "1px",
                        position: "absolute",
                        left: "15.75px",
                        bottom: "10px",
                        color: "white",
                        backgroundImage:
                          "linear-gradient(to left, #7127c4, #4c158a)",
                      }}
                    >
                      Set Template
                    </Button>
                  }
                />
              ) : (
                primaryLocations &&
                primaryLocations.length >= 0 && (
                  <>
                    <div className="properties-fifth-step">
                      <div className="custom-dropdown">
                        <br></br>
                        <Button
                          onClick={toggleActionDropDown}
                          style={{
                            paddingTop: "1px",
                            paddingBottom: "1px",
                            height: "28px",
                            float: 'left',
                            left: "15.75px",
                            color: "white",
                            backgroundImage:
                              "linear-gradient(to left, #7127c4, #4c158a)",
                          }}
                        >
                          Actions
                        </Button>
                        {showActions && (
                          <div className="dropdown-list">
                            <div onClick={onCardClick} className="list-item">
                              <Link>
                                <img
                                  src="assets/img/icons-explore.svg"
                                  alt="icons-edit"
                                  style={{
                                    transform: "scale(1.3,1.3)",
                                    paddingLeft: "2px",
                                    paddingTop: "5px",
                                  }}
                                />
                                View Property
                              </Link>
                            </div>

                            {disabled && (
                              <div className="list-item" onClick={(e) => e.stopPropagation(e)}>
                                <Link
                                  to={ROUTES.addProperty + `?propertyType=${locationTypeId}&locationId=${id}`}
                                >
                                  <img src="assets/img/icons-edit.svg" alt="icons-edit" />{" "}
                                  Edit Property
                                </Link>
                              </div>
                            )}
                            {((templateId === null && microsite) ||
                              (templateId === null && rerender === "activated")) && (
                                <PropertyCardModal
                                  locationTypeId={locationTypeId}
                                  onRerender={handleRerender}
                                  locationId={id}
                                  modalTrigger={
                                    <div className="list-item">
                                      <Link>
                                        <img
                                          src="assets/img/icons-upload-file.svg"
                                          alt="icons-edit"
                                        />
                                        Set Template
                                      </Link>
                                    </div>
                                  }
                                />
                              )}
                            <div
                              className="list-item red-text"
                              onClick={(e) => {
                                toggleActionDropDown(e);
                                onDeactivate({
                                  id,
                                  disabled,
                                  name,
                                  photos,
                                });
                              }}
                            >
                              <a>
                                <img
                                  src="assets/img/icons-red-delete.svg"
                                  alt="icons-red-delete"
                                />
                                {` ${disabled ? "Activate" : "Deactivate"} Property`}
                              </a>
                            </div>
                          </div>
                        )}
                        <Modal
                          onClose={() => setPaymentModalOpen(false)}
                          onOpen={() => setPaymentModalOpen(true)}
                          open={paymentModalOpen}
                          trigger={
                            <Button
                              compact
                              style={{
                                paddingTop: "1px",
                                paddingBottom: "1px",
                                height: "28px",
                                float: 'right',
                                left: "15.75px",
                                bottom: "10px",
                                color: "white",
                                backgroundImage:
                                  "linear-gradient(to left, #7127c4, #4c158a)",
                              }}
                            >
                              Activate Microsite
                            </Button>
                          }
                          className="semanticModal"
                        >
                          <Modal.Header>Marketing with Microsites</Modal.Header>
                          <Modal.Content>
                            <Modal.Description>
                              <Header>
                                {microsite === null && microsite === undefined
                                  ? "Microsites Active"
                                  : "Would you like to activate property websites with REM?"}
                              </Header>
                              <p>
                                We've removed the hassle of setting up a website for
                                your properties. With our microsite option, we'll take
                                data you've already input when creating your
                                properties and create a website for you to generate
                                leads with. Select one of our templates and we will
                                handle the rest!
                              </p>
                            </Modal.Description>
                          </Modal.Content>
                          <Modal.Actions>
                            <Button
                              color="grey"
                              onClick={() => setPaymentModalOpen(false)}
                            >
                              CLOSE
                            </Button>
                            {microsite === null && microsite === undefined ? null : (
                              <Button
                                className="modal-save-button"
                                content="ACTIVATE"
                                onClick={() =>
                                  forward(organizationId, setPaymentModalOpen(false))
                                }
                                positive
                              />
                            )}
                          </Modal.Actions>
                        </Modal>
                      </div>
                    </div>
                  </>
                )
              )}

            </div>

          )}
          {status === "draft" && (
            <Link
              to={ROUTES.addProperty + `?propertyType=${locationTypeId}&locationId=${id}`}
            >
              <button style={{ height: '26px' }} type="button" compact className="btn grey-btn">
                Complete Listing
              </button>
            </Link>
          )}
        </div>
      </div>
    </>
  );
};

export default PropertyCard;
