import React, { useState } from "react";
import { Button, Modal } from "semantic-ui-react";
import { CLEAR_NOTIFICATIONS, CLEAR_NOTIFICATIONS_TEXT } from "../../utils";

function ClearNotificationModal(props) {
  const [open, setOpen] = useState(true);
  return (
    <Modal
      className="semanticModal modal-ui basic-modal-wrap"
      onOpen={() => setOpen(true)}
      open={open}
    >
      <Modal.Content>
        <Modal.Description className="w-100">
          <div className="modal-title">{CLEAR_NOTIFICATIONS}</div>
          <div className="modal-subtitle">{CLEAR_NOTIFICATIONS_TEXT}</div>

          <div className="d-flex align-items-center mt-5 modal-btn-row">
            <Button
              className="modal-close-button"
              content="Cancel"
              onClick={() => props.closeModal()}
              positive
            />
            <Button
              color="red"
              content="Yes"
              onClick={() => props.markUnread()}
            />
          </div>
        </Modal.Description>
      </Modal.Content>
    </Modal>
  );
}

export default ClearNotificationModal;
