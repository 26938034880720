import React from "react";

const EmergencyContact = ({ primaryApplicant }) => {
  return (
    <>
      <div className="row">
        <div className="col-md-12">
          <div className="profile-label-row">
            <div>
              <span className="profile-label-title">Emergency Contact</span>
              <span className="profile-label-subtitle">
                {primaryApplicant.emergencyContact &&
                  primaryApplicant.emergencyContact.relationship}
              </span>
            </div>
            <div className="profile-label-line"></div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-md-4">
          <div className="profile-basic">
            <div className="profile-label">Name</div>
            <div className="profile-label-value">
              {primaryApplicant.emergencyContact &&
                primaryApplicant.emergencyContact.name}
            </div>
          </div>
        </div>
        <div className="col-md-4">
          <div className="profile-basic">
            <div className="profile-label">Cell Phone</div>
            <div className="profile-label-value">
              {primaryApplicant.emergencyContact &&
              primaryApplicant.emergencyContact.homePhone !== "" ? (
                <a
                  href={`tel:${
                    primaryApplicant.emergencyContact &&
                    primaryApplicant.emergencyContact.mobilePhone
                  }`}
                >
                  {primaryApplicant.emergencyContact &&
                    primaryApplicant.emergencyContact.mobilePhone}
                </a>
              ) : (
                "N/A"
              )}
            </div>
          </div>
        </div>
        <div className="col-md-4">
          <div className="profile-basic">
            <div className="profile-label">Home Phone</div>
            <div className="profile-label-value">
              {primaryApplicant.emergencyContact &&
              primaryApplicant.emergencyContact.homePhone !== "" ? (
                <a
                  href={`tel:${
                    primaryApplicant.emergencyContact &&
                    primaryApplicant.emergencyContact.homePhone
                  }`}
                >
                  {primaryApplicant.emergencyContact &&
                    primaryApplicant.emergencyContact.homePhone}
                </a>
              ) : (
                "N/A"
              )}
            </div>
          </div>
        </div>
        <div className="col-md-4">
          <div className="profile-basic">
            <div className="profile-label">Work Phone</div>
            <div className="profile-label-value">
              {primaryApplicant.emergencyContact &&
              primaryApplicant.emergencyContact.workPhone !== "" ? (
                <a
                  href={`tel:${
                    primaryApplicant.emergencyContact &&
                    primaryApplicant.emergencyContact.workPhone
                  }`}
                >
                  {primaryApplicant.emergencyContact &&
                    primaryApplicant.emergencyContact.workPhone}
                </a>
              ) : (
                "N/A"
              )}
            </div>
          </div>
        </div>
        <div className="col-md-4">
          <div className="profile-basic">
            <div className="profile-label">Email</div>
            <div className="profile-label-value">
              {primaryApplicant.emergencyContact &&
              primaryApplicant.emergencyContact.email !== "" ? (
                <a
                  href={`mailto:${
                    primaryApplicant.emergencyContact &&
                    primaryApplicant.emergencyContact.email
                  }`}
                >
                  {primaryApplicant.emergencyContact &&
                    primaryApplicant.emergencyContact.email}
                </a>
              ) : (
                "N/A"
              )}
            </div>
          </div>
        </div>
        <div className="col-md-4">
          <div className="profile-basic">
            <div className="profile-label">Address</div>
            <div className="profile-label-value">
              {primaryApplicant &&
                primaryApplicant.emergencyContact &&
                primaryApplicant.emergencyContact.address &&
                primaryApplicant.emergencyContact.address.streetLine1}
              ,{" "}
              {primaryApplicant.emergencyContact &&
                primaryApplicant.emergencyContact.address &&
                primaryApplicant.emergencyContact.address.city}
              ,{" "}
              {primaryApplicant.emergencyContact &&
                primaryApplicant.emergencyContact.address &&
                primaryApplicant.emergencyContact.address.state}
              ,{" "}
              {primaryApplicant.emergencyContact &&
                primaryApplicant.emergencyContact.address &&
                primaryApplicant.emergencyContact.address.postcode}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default EmergencyContact;
