import React, { useState } from "react";
import "./MessageType.scss";
import { Icon } from "semantic-ui-react";

const messageTypes = [
  {
    key: "in-app",
    value: "In-App Message",
  },
  {
    key: "sms",
    value: "Text Message",
  },

];

const MessageType = ({ onChange }) => {
  const [active, setActive] = useState(messageTypes[0]);
  const [show, setShow] = useState(false);

  const onChangeSelect = (data) => {
    setActive(data);
    onChange(data.key);
    setShow(!show);
  };

  return (
    <div className="message-type-select ">
      <div
        className="active-type disable-select"
        onClick={() => setShow(!show)}
      >
        <span>{active.value.toUpperCase()}</span>
        <Icon name={show ? "chevron up" : "chevron down"} className="icon" />
      </div>
      {show && (
        <div className="options">
          {messageTypes.map((data, key) => {
            return (
              <div
                className="option"
                {...{ key }}
                onClick={() => onChangeSelect(data)}
              >
                {data.value}
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default MessageType;
