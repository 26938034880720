//LeftNav.js
import React, { Component, useState } from "react";
import { Menu, Icon } from "semantic-ui-react";
import { Link, NavLink, withRouter, matchPath } from "react-router-dom";
import "./LeftNav.scss";
import { getCookie } from "../../store/auth/authUtility";
import { ROUTES } from "../../utils";
import mixpanel from "mixpanel-browser";

export default class LeftNav extends Component {
  render() {
    const LeftNavItemWithRouter = withRouter(LeftNavItem);
    const ndbId = this.props.user && this.props.user.ndbId.toString();
    const submenu = {
      leads: [{ label: "Waitlist", icon: "payment", to: "/leads/waitlist" }],
      tenants: [{ label: "Prior", icon: "user", to: "/residents/prior" }],
      applications: [
        {
          label: "Existing",
          icon: "payment",
          to: "/applications/existingapplications",
        },
      ],
      residents: [
        { label: "Payments", icon: "payment", disabled: true },
        { label: "Communication", icon: "mail", disabled: true },
      ],

      accounting: [
        // {
        //   label: "Merchant Center",
        //   icon: "file chart line",
        //   to: "https://www.wepay.com/login",
        // },
        {
          label: "Reports",
          icon: "arrow circle down",
          to: "/accounting/disable",
        },
        {
          label: "Financials",
          icon: "file chart line",
          to: "/accounting/balancesheet",
        },
        {
          label: "Delinquency",
          icon: "file chart line",
          to: "/accounting/delinquency",
        },
        // {
        //   label: "Profit/Loss",
        //   icon: "file chart line",
        //   to: "/accounting/incomestatement",
        // },
        {
          label: "Rent Roll",
          icon: "file chart line",
          to: "/accounting/rentroll",
        },
        {
          label: "Journal Report",
          icon: "file chart line",
          to: "/accounting/journalreport",
        },
        // {
        //   label: "Financials",
        //   icon: "file chart line",
        //   to: "/accounting/financials",
        // },
        {
          label: "Trial Balance",
          icon: "file chart line",
          to: "/accounting/trialreport",
        },
        {
          label: "Cash Management",
          icon: "arrow circle down",
          to: "/accounting/disable",
        },
        {
          label: "Receive Cash",
          icon: "file chart line",
          to: "/accounting/arcash",
        },
        {
          label: "Pay Bills",
          icon: "file chart line",
          to: "/accounting/paybills",
        },

        {
          label: "Financial",
          icon: "arrow circle down",
          to: "/accounting/disable",
        },
        {
          label: "Reconcile",
          icon: "file chart line",
          to: "/accounting/reconcile",
        },
        {
          label: "General Ledger",
          icon: "file chart line",
          to: "/accounting/generalledger",
        },
        {
          label: "Accounts",
          icon: "file chart line",
          to: "/accounting/ledger",
        },
        {
          label: "Charge Codes",
          icon: "file chart line",
          to: "/accounting/charge",
        },
        {
          label: "Journal",
          icon: "file chart line",
          to: "/accounting/Journal",
        },
        {
          label: "Payables",
          icon: "arrow circle down",
          to: "/accounting/disable",
        },
        {
          label: "Vendors",
          icon: "file chart line",
          to: "/accounting/vendors",
        },
        {
          label: "Work Order",
          icon: "file chart line",
          to: "/accounting/workorder",
        },
        { label: "Bills", icon: "file chart line", to: "/accounting/bills" },

        {
          label: "Receivables",
          icon: "arrow circle down",
          to: "/accounting/disable",
        },
        // { label: 'Aging',  icon: 'payment',to: "/accounting/Aging" },
        {
          label: "Invoices",
          icon: "file chart line",
          to: "/accounting/invoices",
        },
        {
          label: "Credits",
          icon: "file chart line",
          to: "/accounting/Credits",
        },

        // { label: 'Customers', icon: 'payment', to: "/accounting/customers" },
        // { label: 'Reconcile', icon: 'payment', to: "/accounting/reconcile" },
        // { label: 'Ledger Accounts', icon: 'payment', to: "/accounting/ledger" },
        // {label:'Transactions', icon:'payment', to:"/accounting/transactions"},
        // { label: 'Tax Codes', icon: 'payment', to: "/accounting/tax" },
      ],
    };

    return (
      <Menu
        borderless
        vertical
        stackable
        fixed="left"
        className={
          this.props.collapsed ? "leftnav collapsed noPrint" : "leftnav noPrint"
        }
      >
        {getCookie().manager === "true" ? (
          <>
            <Link to="/">
              <img
                src="/assets/img/logos/2logo-white-2.svg"
                className="logo"
                alt="Leasera"
              />
            </Link>
            <Link to="/">
              <img
                src="/assets/img/logos/2logo-white-2.svg"
                className="logo-small"
                alt="Leasera"
              />
            </Link>
            <LeftNavItemWithRouter
              ndbId={ndbId}
              label="Explore"
              icon="binoculars"
              disabled={false}
              to="/explore"
            />
            <LeftNavItemWithRouter
              ndbId={ndbId}
              label="Dashboard"
              icon="dashboard"
              disabled={false}
              to="/dashboard"
            />
            {this.props.primaryLocations &&
              this.props.primaryLocations.length == 0 && (
                <LeftNavItemWithRouter
                  ndbId={ndbId}
                  label="Properties"
                  icon="home"
                  disabled={false}
                  to={ROUTES.propertiesListing}
                />
              )}
            <LeftNavItemWithRouter
              ndbId={ndbId}
              label="Leads"
              icon="phone"
              disabled={
                this.props.primaryLocations &&
                this.props.primaryLocations.length !== 0
                  ? false
                  : true
              }
              to={"/leads"}
              submenu={submenu.leads}
            />
            <LeftNavItemWithRouter
              ndbId={ndbId}
              label="Applications"
              icon="clipboard list"
              disabled={
                this.props.primaryLocations &&
                this.props.primaryLocations.length !== 0
                  ? false
                  : true
              }
              to={"/applications"}
              submenu={submenu.applications}
            />
            <LeftNavItemWithRouter
              ndbId={ndbId}
              label="Leases"
              icon="handshake"
              disabled={
                this.props.primaryLocations &&
                this.props.primaryLocations.length !== 0
                  ? false
                  : true
              }
              to={"/lease"}
            />
            <LeftNavItemWithRouter
              ndbId={ndbId}
              label="Maintenance"
              icon="hammer"
              disabled={
                this.props.primaryLocations &&
                this.props.primaryLocations.length !== 0
                  ? false
                  : true
              }
              to={"/maintenance"}
            />
            <LeftNavItemWithRouter
              ndbId={ndbId}
              label="Residents"
              icon="user"
              disabled={
                this.props.primaryLocations &&
                this.props.primaryLocations.length !== 0
                  ? false
                  : true
              }
              to={"/residents"}
              submenu={submenu.tenants}
            />
            <LeftNavItemWithRouter
              ndbId={ndbId}
              label="File Manager"
              icon="folder open"
              disabled={
                this.props.primaryLocations &&
                this.props.primaryLocations.length !== 0
                  ? false
                  : true
              }
              to={"/fileManager"}
            />
            <LeftNavItemWithRouter
              ndbId={ndbId}
              label="Loyalty"
              icon="star"
              disabled={
                this.props.primaryLocations &&
                this.props.primaryLocations.length !== 0
                  ? false
                  : true
              }
              to={"/loyalty"}
            />
            <LeftNavItemWithRouter
              ndbId={ndbId}
              label="Schedule"
              icon="calendar alternate"
              disabled={
                this.props.primaryLocations &&
                this.props.primaryLocations.length !== 0
                  ? false
                  : true
              }
              to={"/schedule"}
            />
            {/* <LeftNavItemWithRouter
                    ndbId={ndbId}
                    label="Third-party Api Config"
                    icon="setting"
                    to={ROUTES.thirdparty}
                  /> */}
            <LeftNavItemWithRouter
              ndbId={ndbId}
              label="Communication"
              icon="bell"
              matchModal="/communication/:tab"
              disabled={
                this.props.primaryLocations &&
                this.props.primaryLocations.length !== 0
                  ? false
                  : true
              }
              to={ROUTES.messages}
            />
            {this.props.primaryLocations &&
              this.props.primaryLocations.length !== 0 && (
                <LeftNavItemWithRouter
                  ndbId={ndbId}
                  label="Properties"
                  icon="home"
                  disabled={false}
                  to={ROUTES.propertiesListing}
                />
              )}
            <LeftNavItemWithRouter
              ndbId={ndbId}
              label="Settings"
              icon="setting"
              disabled={
                this.props.primaryLocations &&
                this.props.primaryLocations.length !== 0
                  ? false
                  : true
              }
              to={"/settings"}
            />
            <span data-toggle="false" data-target="false">
              <a href="https://stage.wepay.com/login" rel="noopener noreferrer" target="_blank">
                <div class="item">
                  <div class="alignment-container">
                    <span>
                      <i aria-hidden="true" class="store icon"></i>{" "}
                    </span>
                    <span class="leftnav-label">Merchant Center</span>
                  </div>
                </div>
              </a>
            </span>
            <LeftNavItemWithRouter
              ndbId={ndbId}
              label="Accounting"
              icon="money"
              disabled={
                this.props.primaryLocations &&
                this.props.primaryLocations.length !== 0
                  ? false
                  : true
              }
              to={"/accounting"}
              submenu={submenu.accounting}
            />
            {/* <LeftNavItemWithRouter disabled label='Reports' icon='newspaper outline' /> */}
            {/* <LeftNavItemWithRouter disabled label='Alerts' icon='alarm' /> */}
          </>
        ) : (
          <>
            <Link to="/maintenance">
              <img
                src="/assets/img/leasera-logonew-nav.png"
                className="logo"
                alt="Leasera"
              />
            </Link>
            <Link to="/maintenance">
              <img
                src="/assets/img/leasera-logonew-nav-icon.png"
                className="logo-small"
                alt="Leasera"
              />
            </Link>
            <LeftNavItemWithRouter
              label="Schedule"
              icon="calendar alternate"
              to="/schedule"
            />
            <LeftNavItemWithRouter
              label="Maintenance"
              icon="hammer"
              to="/maintenance"
            />
          </>
        )}
      </Menu>
    );
  }
}

class LeftNavItem extends Component {
  isActive = () => {
    if (!this.props.to) return false;
    let exact = false;
    if (this.props.to === "/") {
      exact = true;
    }
    if (
      matchPath(this.props.location && this.props.location.pathname, {
        path: this.props.matchModal ? this.props.matchModal : this.props.to,
        exact: exact,
      })
    ) {
      return true;
    } else {
      return false;
    }
  };

  render() {
    const submenuActiveClass = this.isActive() ? "submenu-active" : null;
    const mixTrack = (item) => {
      mixpanel.track("Manager Left Navbar Click", { sub: item });
    };

    return (
      <>
        <span
          className={submenuActiveClass}
          data-toggle={this.props.disabled && "modal"}
          data-target={this.props.disabled && "#exploreMore"}
        >
          {!this.props.disabled ? (
            <NavLink
              onClick={() => mixTrack(this.props.label)}
              exact
              to={this.props.to}
              isActive={() => {
                if (
                  matchPath(
                    this.props.location && this.props.location.pathname,
                    {
                      path: this.props.matchModal
                        ? this.props.matchModal
                        : this.props.to,
                    }
                  )
                ) {
                  return true;
                }
                return false;
              }}
            >
              <Menu.Item className={this.props.disabled ? "disabled" : null}>
                <div className="alignment-container">
                  <span>
                    <Icon name={this.props.icon} />{" "}
                  </span>
                  <span className="leftnav-label">{this.props.label}</span>
                </div>
              </Menu.Item>
            </NavLink>
          ) : (
            <span className={"disabled-link"}>
              <Menu.Item className={!this.props.disabled ? "disabled" : null}>
                <div className="alignment-container">
                  <span>
                    <Icon name={this.props.icon} />{" "}
                  </span>
                  <span className="leftnav-label">{this.props.label}</span>
                </div>
              </Menu.Item>
            </span>
          )}
          {this.props.submenu && this.isActive() ? (
            <Menu.Menu className="submenu">
              {this.props.submenu.map((item, i) => (
                <NavLink
                  onClick={() => mixTrack(this.props.label)}
                  exact
                  to={item.to}
                  disabled={item.disabled}
                  key={i}
                  className="disabled-link"
                >
                  <Menu.Item className={item.disabled ? "disabled" : null}>
                    <span>
                      <Icon name={item.icon} />
                    </span>
                    <span className="leftnav-label">{item.label}</span>
                  </Menu.Item>
                </NavLink>
              ))}
            </Menu.Menu>
          ) : null}
        </span>
      </>
    );
  }
}
