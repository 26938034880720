const isProd = process.env.NODE_ENV === "production";

const BFF_URL =
  process.env.BFF_URL ||
  (isProd
    ? "https://dev-dot-leasera-200719.appspot.com"
    : "https://dev-dot-leasera-200719.appspot.com");
const isStaging = process.env.CUSTOM_ENV === "dev";

module.exports = {
  heartland_api: "pkapi_cert_wcN2EUGynhQXaEP9xx",
  env: process.env.NODE_ENV,
  isProd,
  isStaging,
  PORT: process.env.PORT || (isProd ? 8080 : 3000),
  BFF_URL,
  CUSTOM_ENV: process.env.CUSTOM_ENV,
  domain: isProd ? 'leasera.com' : undefined,
  searchWebsiteUri: isStaging ? 'https://leasera.com' : 'https://leasera.com',
  renterPortalUri: isStaging ? 'https://renter.leasera.com' : 'https://renter.leasera.com',
  managerPortalUri: isStaging
    ? 'https://manager.leasera.com'
    : 'https://manager.leasera.com',
  GRAPHQL_URL: BFF_URL + '/graphql',
  SENTRY_URL: 'https://8375c4f116af410382124b89d8ffcbf5@sentry.io/1318669',
}
