import { getAuthToken } from "../store/auth/authUtility";
import { CHAT_URL } from "./constants";

export const uploadAttachedFiles = async ({
  locationId,
  chatRoomId,
  files,
}) => {
  let userToken = getAuthToken();

  const query = `mutation {
      uploadMedia(package: {
        locationId: "${locationId}",
        chatRoomId: "${chatRoomId}"
      } ) {response package}
    }`;

  const data = new FormData();
  data.append("query", query);
  files.map((file) => {
    data.append("", file);
  });

  return new Promise((resolve, reject) => {
    var xhr = new XMLHttpRequest();
    xhr.withCredentials = true;

    xhr.addEventListener("readystatechange", function () {
      if (this.readyState === XMLHttpRequest.DONE) {
        if (xhr.status === 0 || (xhr.status >= 200 && xhr.status < 400)) {
          try {
            resolve(JSON.parse(this.responseText));
          } catch (error) {
            reject(error);
          }
        } else {
          reject();
        }
      }
    });

    xhr.open("POST", CHAT_URL);
    xhr.setRequestHeader("Authorization", userToken);
    xhr.send(data);
  });
};
