import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import { useFormik } from "formik";
import Datetime from "react-datetime";
import { Label, Icon, Form } from "semantic-ui-react";
import "react-datetime/css/react-datetime.css";
import { getClient } from "../../init-apollo-googleFn";
import { CALENDAR_SERVICE_URL } from "../../utils/constants";
import { createEventGql } from "../../store/calendar/calendar";
import { Calendar, momentLocalizer } from "react-big-calendar";
import "./Schedule.scss";
import {
  toastFailMsg,
  toastSuccessMsg,
  validateEmail,
} from "../../utils/common";
import "./CreateEventModal.scss";
import moment from "moment";

const calendarClient = getClient(CALENDAR_SERVICE_URL);
const localizer = momentLocalizer(moment);

const TIMER_FIELDS = {
  START: "start",
  END: "end",
};

const defaultTimeSettings = {
  hours: 0,
  minutes: 0,
  seconds: 0,
  milliseconds: 0,
};

const CreateEventModal = ({
  isOpen,
  toggleModal,
  getCal,
  locationId,
  personId,
  activeDate,
}) => {
  const [loader, setLoader] = useState(false);
  const [eventName, setEvent] = useState("");
  const [eventStart, setStart] = useState(moment().set(defaultTimeSettings));
  const [eventEnd, setEnd] = useState(moment().set(defaultTimeSettings));
  const [tempAttendee, setTempAttendee] = useState("");
  const [eventNotes, setNotes] = useState("");

  const [isOpenDate, setIsOpenDate] = useState(false);
  const [isOpenStartTime, setIsOpenStartTime] = useState(false);
  const [isOpenEndTime, setIsOpenEndTime] = useState(false);

const createMutation = async () => {
  const values = {
    calendarTimeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    locationId,
    eventName,
    personId,
    start: moment(eventStart).format('MM/DD/YYYY HH:mm:ss'),
    end: moment(eventEnd).format('MM/DD/YYYY HH:mm:ss')
  };

  try {
    const res = await calendarClient.mutate({
      mutation: createEventGql,
      variables: { package: values }
    });
    if (res?.data?.addGeneralEvent?.response === '200') {
      toastSuccessMsg('Event created!');
      setTimeout(() => {
        getCal();
      }, 2000);
    }
  } catch (error) {
    console.log(error);
    if (error?.graphQLErrors?.length > 0) {
      toastFailMsg(error.graphQLErrors[0].message);
    }
  }
};

  return (
    <div
      className={`modal show create-event-modal`}
      tabindex="-1"
      aria-hidden="true"
      style={{ display: isOpen ? "block" : "none" }}
    >
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content">
          <div className="modal-title">
            Add Schedule <Icon name="delete" onClick={() => toggleModal()} />
          </div>

          <div className="modal-body">
            <form className="event-create-form">
              <div className="form-row">
                <label htmlFor="eventName">Schedule Event *</label>
                <div className="input">
                  <input
                    type="text"
                    id="eventName"
                    value={eventName}
                    onChange={(event) => setEvent(event.target.value)}
                  />
                </div>
              </div>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <div style={{ display: "flex", alignItems: "center", justifyContent:'center', marginLeft:'10px' }}>
                  Start
                </div>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <Datetime
                    viewMode="days"
                    // className="pick-date"
                    input={true}
                    value={eventStart}
                    onChange={setStart}
                    initialValue={activeDate}
                    initialViewDate={activeDate}
                    dateFormat={"MM/DD/YYYY"}
                  />
                </div>
              </div>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <div style={{ display: "flex", alignItems: "center", justifyContent:'center', marginLeft:'10px'}}>
                  End
                </div>
                <div style={{ display: "flex", alignItems: "center" }}>
                <Datetime
                  viewMode="days"
                  // className="pick-date"
                  input={true}
                  value={eventEnd}
                  onChange={setEnd}
                  initialValue={activeDate}
                  initialViewDate={activeDate}
                  dateFormat={"MM/DD/YYYY"}
                />
                </div>
              </div>
              <div className="form-row">
                <label htmlFor="attendees">Attendees</label>
                <div className="input">
                  <input
                    type="text"
                    id="attendees"
                    value={tempAttendee}
                    onChange={(e) => setTempAttendee(e.target.value)}
                    // onKeyDown={AddAttendees}
                  />
                </div>
              </div>
              <div className="form-row">
                <label htmlFor="description">Additional Notes *</label>
                <div className="input multiline">
                  <textarea
                    id="description"
                    type="text"
                    maxLength={255}
                    rows={5}
                    value={eventNotes}
                    onChange={(e) => setNotes(e.target.value)}
                  />
                </div>
              </div>
              <button type="button" onClick={createMutation}>
                ADD SCHEDULE
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreateEventModal;
