import React, { Component, createRef } from "react";
import { withApollo } from "react-apollo";
import moment from "moment";
import Datetime from "react-datetime";
import NumberFormat from "react-number-format";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";
import ReeValidate from "ree-validate";
import { CreateLead, UpdateLead } from "../../store/person/leads";
import { unitType, units } from "../../store/person/properties";
import {
  LEAD_SOURCE,
  ADD_LEAD_STATUS,
  LEADS_URL,
  DATE_FORMAT,
  US_STATES,
  UNIT_TYPE_API,
  UNITAPI,
} from "../../utils/constants";
import { qpTooltipPopover } from "../../utils/misc";
import {
  parseGraphQLErrors,
  disablePastDt,
  toastFailMsg,
  affordHousingApi,
} from "../../utils/common";
import LeadAlreadyCreated from "../../components/Modals/IsLeadCreated";
import "react-datetime/css/react-datetime.css";
import { getClient } from "../../init-apollo-googleFn";
import "./Leads.scss";
import "semantic-ui-css/semantic.css";
import {
  Form,
  Dropdown,
  Input,
  Button,
  TextArea,
  Modal,
  Grid,
  Header,
} from "semantic-ui-react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import mixpanel from "mixpanel-browser";
const createLeadsMutation = getClient(LEADS_URL);
const unitTypeManager = getClient(UNIT_TYPE_API);
const unitManager = getClient(UNITAPI);

class CreateLeads extends Component {
  constructor(props) {
    super(props);

    this.validator = new ReeValidate.Validator({
      email: "required|email",
      firstName: "required|min:3",
      lastName: "required|min:3",
      phoneMobile: "required|min:10",
      dateOfBirth: "required",
      adults: "numeric",
      children: "numeric",
      pets: "numeric",
    });

    this.state = {
      sending: false,
      statesList: [],
      isError: false,
      loading: false,
      showLoadingOnButton: false,
      leadId: "",
      date: "",
      lead: {
        firstName: props.leadData ? props.leadData.firstName : '',
        lastName: props.leadData ? props.leadData.lastName : '',
        comment: '',
        email: props.leadData ? props.leadData.email : '',
        phoneMobile: props.leadData ? props.leadData.phoneMobile : '',
        source: '',
        status: 'Prospect',
        dateOfBirth: props.leadData && props.leadData.dateOfBirth ? props.leadData.dateOfBirth : '',
        affordHousing: '',
        assign: "",
        address: {
          addressOne:
            props.leadData && props.leadData.address
              ? props.leadData.address.addressOne
              : "",
          addressTwo:
            props.leadData && props.leadData.address
              ? props.leadData.address.addressTwo
              : "",
          city:
            props.leadData && props.leadData.address
              ? props.leadData.address.city
              : "",
          state:
            props.leadData && props.leadData.address
              ? props.leadData.address.state
              : "",
          postcode:
            props.leadData && props.leadData.address
              ? props.leadData.address.postcode
              : "",
        },
        preference: {
          unitType: "",
          unit: "",
          moveInDate: "",
          adults: "",
          children: "",
          pets: "",
          location: "",
          area: {
            city: "",
            state: "",
          },
        },
        location: this.props.propertyCustomId,
      },
      errors: this.validator.errors,
      modalOpen: false,
      affordableText: "",
      unitTypeData: [],
      unitsData: [],
      affordableHousingData: [],
      showLeadCreatedModal: false,
      toastOptions: {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: true,
        newestOnTop: false,
        rtl: false,
        closeOnClick: false,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
      },
    };
  }

  componentDidMount() {
    const { unitTypeData, affordableHousingData } = this.props;
    qpTooltipPopover();
    if (!unitTypeData || !affordableHousingData) {
      this.fetchUnitTypeData();
      this.fetchAffordableHousing();
    } else {
      this.setState({
        unitTypeData: unitTypeData,
        affordableHousingData: affordableHousingData,
      });
    }
    this.statesListing();
  }

  statesListing() {
    let list = [];
    Object.keys(US_STATES).map((key, index) => {
      list.push({ key: US_STATES[key], text: key, value: key.toLowerCase() });
      return null;
    });
    this.setState({ statesList: list });
  }

  fetchAffordableHousing = async () => {
    const { propertyCustomId } = this.props;
    propertyCustomId &&
      affordHousingApi(propertyCustomId).then((result) =>
        this.setState({ affordableHousingData: result })
      ); //promise resolve
  };

  fetchUnitTypeData = async () => {
    const { propertyCustomId } = this.props;
    propertyCustomId &&
      (await unitTypeManager
        .query({
          query: unitType,
          variables: { locationId: propertyCustomId },
        })
        .then((res) => {
          const data = [];
          res.data.unitType &&
            res.data.unitType.edges.forEach((ele, index) => {
              data.push(
                ele && {
                  key: ele.node.type,
                  text: ele.node.type,
                  value: {
                    name: ele.node.type,
                    id: ele.node.id,
                    field: "unitType",
                  },
                }
              );
            });
          this.setState({ unitTypeData: data });
        })
        .catch((error) => {
          toast.error(error);
        }));
  };

  /* function to remove the error and revalidate the data */
  removeErrorAndReValidate = (name, type) => {
    const { errors } = this.validator;
    errors.remove(name);
    this.validator
      .validate(
        name,
        type && type === "preference"
          ? this.state.lead[type][name]
          : this.state.lead[name]
      )
      .then(() => {
        this.setState({ errors });
      });
  };

  /* function to set the source dropdown changed value */
  handleSourceChange = (event, { value }) => {
    this.setState({
      lead: { ...this.state.lead, source: value },
    });
  };
  // Handle new Unit Type
  handleUnitTypeChange = (event, { value }) => {
    const { propertyCustomId } = this.props;
    const data = this.state.lead;
    this.setState(
      {
        lead: {
          ...data,
          preference: { ...data.preference, unitType: value.id, unit: "" },
        },
      },
      () => {
        propertyCustomId &&
          unitManager
            .query({
              query: units,
              variables: {
                locationId: propertyCustomId,
                unitTypesId: value.id,
              },
            })
            .then((res) => {
              const units = res.data.units.edges;
              const unitsData = [];
              units &&
                units.forEach((ele, index) => {
                  unitsData.push(
                    ele && {
                      key: ele.node.number,
                      text: ele.node.number,
                      value: {
                        name: ele.node.number,
                        id: ele.node.id,
                        field: "unitNumber",
                      },
                    }
                  );
                });
              this.setState({ unitsData: unitsData });
            })
            .catch((error) => {
              toastFailMsg(error);
            });
      }
    );
  };

  /* function to set the selected unit id in state */
  handleUnitNumberChange = (event, { value }) => {
    const data = this.state.lead;
    this.setState({
      lead: { ...data, preference: { ...data.preference, unit: value.id } },
    });
  };

  /* function to set the status dropdown changed value */
  handleStatusChange = (event, { value }) => {
    this.setState({
      lead: { ...this.state.lead, status: value },
    });
  };

  /* function to set the affordable housing dropdown changed value */
  updateAffordableHousing = (event, { value }) => {
    this.setState({
      lead: { ...this.state.lead, affordHousing: value.id },
      affordableText: value.name,
    });
  };

  // Set Assigned to
  handleAssignedChange = (event, { value }) => {
    this.setState({
      lead: { ...this.state.lead, assign: value },
    });
  };

  /* function to set the states dropdown changed value */
  handleStateChange = (event, { value }) => {
    this.setState({
      lead: {
        ...this.state.lead,
        address: { ...this.state.lead.address, state: value },
      },
    });
  };

  /* function calls on comment field changed and set the comment value */
  handleCommentChange = (name, e) => {
    this.setState({
      lead: { ...this.state.lead, [name]: e.target.value },
    });
  };

  /* function to accepts the date selected and set it in local state after formatting change */
  handleDateChange = (event) => {
    const data = this.state.lead;
    if (event !== null) {
      const dateRes = moment(event).format(DATE_FORMAT);
      this.setState({
        lead: {
          ...data,
          preference: { ...data.preference, moveInDate: dateRes },
        },
        date: event._d,
      });
    } else {
      this.setState({
        lead: { ...data, preference: { ...data.preference, moveInDate: "" } },
        date: "",
      });
    }
  };
  handleDateChangeDob = (event) => {
    const data = this.state.lead;
    const dateRes = moment(event).format(DATE_FORMAT);
    this.setState({
      lead: { ...data, dateOfBirth: dateRes },
    });
  };

  /* function to update the addressTwo field */
  handleAddressChange = (field, value) => {
    const data = this.state.lead;
    this.setState({
      lead: { ...data, address: { ...data.address, [field]: value } },
    });
    if (field === "addressOne") {
      this.setState({ address: value, addressV: value });
    }
  };
  handleAddressChange = (field, value) => {
    const data = this.state.lead;
    this.setState({
      lead: { ...data, address: { ...data.address, [field]: value } },
    });
    if (field === "addressOne") {
      this.setState({ address: value, addressV: value });
    }
  };

  /* function to set the local state lead data on the basis of the fields data received */
  handleChange = (name, e, type) => {
    const { value } = e.target;
    if (type && type === "preference") {
      const data = this.state.lead;
      this.setState(
        {
          lead: { ...data, preference: { ...data.preference, [name]: value } },
        },
        () => {
          this.removeErrorAndReValidate(name, type);
        }
      );
    } else if (name === "phoneMobile") {
      this.setState(
        {
          lead: { ...this.state.lead, [name]: value.replace(/ /g, "") },
        },
        () => {
          this.removeErrorAndReValidate(name);
        }
      );
    } else {
      this.setState(
        {
          lead: { ...this.state.lead, [name]: value },
        },
        () => {
          this.removeErrorAndReValidate(name);
        }
      );
    }
  };

  /* function that validates the data captured, if data is valid, 
  it calls the handlesubmit function to integrate the mutation */
  validateAndSubmit = async (e) => {
    e.preventDefault();
    const { lead } = this.state;
    const { preference } = lead;
    const { unitType, unit } = preference;
    const { errors } = this.validator;
    const valid = await this.validator.validateAll(lead);
    if (
      valid &&
      errors.items.length === 0 &&
      unitType &&
      unit &&
      !this.props.leadData
    ) {
      this.handleSubmit(lead);
    } else if (valid && !errors.items.length && this.props.leadData) {
      this.updateContactInfo();
    }
    else if (!unitType && !this.props.leadData) {
      toastFailMsg('Select floor plan');
    }
    else if (this.state.lead.dateOfBirth == "") {
      toastFailMsg('Please input approx DoB');
    }
    else if (!unit && !this.props.leadData) {
      toastFailMsg("Select unit");
    } else {
      toastFailMsg(errors.items[0].msg);
      this.setState({ errors });
    }
  };

  /* function to delete the empty field from the state */
  removeEmptyData = (field) => {
    const leadData = this.state.lead;
    if (field === "preference" || field === "address") {
      Object.keys(leadData[field]).map((ele) => {
        if (leadData[field][ele] === "" || leadData[field][ele] === {}) {
          delete leadData[field][ele];
        }
        if (leadData[field] === {}) {
          delete leadData[field];
        }
        return null;
      });
    } else if (leadData[field] === "") {
      delete leadData[field];
    }
    return null;
  };

  updateLeadsQueryClient = () => {
    try {
      this.props.updateLeadsFetch();
    } catch (e) {
      console.log(e);
    }
  };

  /* function to call the CreateLead mutation */
  handleSubmit = async (lead) => {
    this.setState({ showLoadingOnButton: true });
    const stateFieldsArr = [
      "comment",
      "source",
      "status",
      "address",
      "preference",
    ];
    stateFieldsArr.forEach((ele) => {
      this.removeEmptyData(ele);
    });
    const selectedLead = { lead };
    mixpanel.track("Manager Lead Action", {
      sub: "Add Lead Modal Submit",
    });
    await createLeadsMutation
      .mutate({
        mutation: CreateLead,
        variables: { input: selectedLead },
      })
      .then((res) => {
        if (res.data.createLead && !res.data.createLead.isLeadExistsWithUnit) {
          setTimeout(() => {
            this.props.loaderChange(true, true);
            this.setState({ showLoadingOnButton: false, loading: false });
            this.updateLeadsQueryClient();
            this.props.onClose();
          }, 100);
        } else {
          this.setState({
            showLoadingOnButton: false,
            showLeadCreatedModal: true,
            leadId: res.data.createLead && res.data.createLead.lead.id,
          });
        }
      })
      .catch((error) => {
        this.setState({ showLoadingOnButton: false });
        toast.error(error, this.state.toastOptions);
        setTimeout(() => {
          this.props.loaderChange(false);
        }, 100);
      });
  };

  updateContactInfo = async () => {
    const { showLeadCreatedModal, leadId } = this.state;
    const stateFieldsArr = ["comment", "source", "status", "preference"];
    !showLeadCreatedModal &&
      stateFieldsArr.map((ele) => {
        delete this.state.lead[ele];
        return null;
      });
    const id = this.props.leadId || leadId;
    const lead = { ...this.state.lead, id };
    const selectedLead = { lead };
    mixpanel.track("Manager Lead Action", {
      sub: "Update Lead Data",
    });
    await createLeadsMutation
      .mutate({
        mutation: UpdateLead,
        variables: { input: selectedLead },
      })
      .then((res) => {
        this.setState({ modalOpen: false });
        showLeadCreatedModal
          ? setTimeout(() => {
            this.props.loaderChange(true, true);
            this.setState({ loading: false });
            this.updateLeadsQueryClient();
          }, 100)
          : this.props.fetchData();
        this.props.onClose();
        toast.success("Lead updated successfully", this.state.toastOptions);
      })
      .catch((error) => {
        toast.error(parseGraphQLErrors(error), this.state.toastOptions);
      });
  };

  /* function to set the location and area parameters under preference */
  handleLocationChange = (name, value) => {
    const localLeadData = this.state.lead;
    this.setState({
      lead: {
        ...localLeadData,
        preference: {
          ...localLeadData.preference,
          area: { ...localLeadData.preference.area, [name]: value },
        },
      },
    });
  };

  /* function to set the address parameters */
  setLeadLocalData = (field, value) => {
    const localLeadData = this.state.lead;
    this.setState({
      lead: {
        ...localLeadData,
        address: { ...localLeadData.address, [field]: value },
      },
    });
  };

  /* function to set the address field parameters on the basis of the data type received 
    calls setLeadLocalData to perform this action*/
  setAddressFields = (data) => {
    data.map((ele) => {
      switch (ele.types[0]) {
        case "locality":
          this.setLeadLocalData("city", ele.long_name);
          break;
        case "administrative_area_level_1":
          this.setLeadLocalData("state", ele.long_name);
          break;
        case "postal_code":
          this.setLeadLocalData("postcode", ele.long_name);
          break;
        default:
          break;
      }
      return null;
    });
  };

  /* function to find the geo locations and call the getAddressDetails function to get the complete address details */
  handleSelect = (address) => {
    geocodeByAddress(address)
      .then((results) => {
        const AddressResults = results && results[0].address_components;
        this.setAddressFields(AddressResults);
        this.handleAddressChange(
          "addressOne",
          AddressResults[0].long_name + " " + AddressResults[1].long_name
        );
        return getLatLng(results[0]);
      })
      .then((latLng) => {
        latLng["address"] = address;
      })
      .catch((error) => console.error("Error", error));
  };

  handleAddress = (address) => {
    this.setState({ address });
  };

  /* function to close the add lead modal */
  handleClose = () => {
    const { errors } = this.validator;
    errors.items = [];
    if (this.props.leadData) {
      this.setState({ modalOpen: false, showLeadCreatedModal: false });
    } else {
      this.setState({
        modalOpen: false,
        date: "",
        address: "",
        errors,
        showLeadCreatedModal: false,
        lead: {
          ...this.state.lead,
          status: "Prospect",
          address: {},
          preference: {
            ...this.state.lead.preference,
            moveInDate: "",
            area: {},
          },
        },
      });
      this.props.onClose();
    }
  };

  /* function to render the add leads form */
  renderLeadsForm = (errors, stateData, defaultData, pm) => (
    <>
      <Form ref="form" className="form-submit-leads">
        <Form.Group widths="equal" className="mb-0">
          <Form.Field width={16} inline required>
            <label>First name</label>
            <Input
              id="firstName"
              name="firstName"
              autoFocus
              defaultValue={defaultData ? defaultData.firstName : ""}
              onChange={(event) => {
                this.handleChange("firstName", event);
              }}
              placeholder="First name"
              className="height-40"
            />
          </Form.Field>
          <Form.Field width={16} inline required>
            <label>Last name</label>
            <Input
              id="lastName"
              name="lastName"
              defaultValue={defaultData ? defaultData.lastName : ""}
              onChange={(event) => {
                this.handleChange("lastName", event);
              }}
              placeholder="Last name"
              className="height-40"
            />
          </Form.Field>
        </Form.Group>

        <Form.Group widths="equal" className="mb-0">
          <Form.Field width={16} inline className="margin-top-20">
            <label>Street Line 1</label>

            <PlacesAutocomplete
              style={{ opacity: "0" }}
              value={this.state.address}
              onChange={this.handleAddress}
              onSelect={this.handleSelect}
            >
              {({
                getInputProps,
                suggestions,
                getSuggestionItemProps,
                loading,
              }) => (
                <div>
                  <Input
                    type="text"
                    defaultValue={
                      defaultData && defaultData.address
                        ? defaultData.address.addressOne
                        : ""
                    }
                    fluid
                    placeholder="Street Line 1"
                    {...getInputProps({
                      className: "form-control",
                    })}
                    className="height-40"
                  />
                  {this.state.isAddressValid && (
                    <span>{"Please enter the valid value"}</span>
                  )}

                  { }
                  <div className="autocomplete-dropdown-container">
                    {suggestions.map((suggestion) => {
                      const className = suggestion.active
                        ? "suggestion-item--active"
                        : "suggestion-item";
                      // inline style for demonstration purpose
                      const style = suggestion.active
                        ? { backgroundColor: "#4C158A", cursor: "pointer" }
                        : { backgroundColor: "#ffffff", cursor: "pointer" };
                      return (
                        <div
                          {...getSuggestionItemProps(suggestion, {
                            className,
                            style,
                          })}
                        >
                          <span>{suggestion.description}</span>
                        </div>
                      );
                    })}
                  </div>
                </div>
              )}
            </PlacesAutocomplete>
          </Form.Field>
          <Form.Field width={16} inline className="margin-top-20">
            <label>Street Line 2</label>
            <Input
              placeholder="Street Line 2"
              className="height-40"
              defaultValue={
                defaultData && defaultData.address
                  ? defaultData.address.addressTwo
                  : ""
              }
              onChange={(e) =>
                this.handleAddressChange("addressTwo", e.target.value)
              }
            />
          </Form.Field>
          <Form.Field
            width={16}
            inline
            required
            className="move-date margin-top-20"
          >
            <label>D.O.B.</label>
            <div className="datetTime">
              <Datetime
                timeFormat={false}
                defaultValue={defaultData && defaultData.dateOfBirth ? defaultData.dateOfBirth : ''}
                inputProps={{ readOnly: true, placeholder: 'MM/DD/YYYY' }}
                closeOnSelect={true}
                minDate={moment().toDate()}
                onChange={this.handleDateChangeDob} />
            </div>
          </Form.Field>
        </Form.Group>

        <Form.Group widths="equal" className="margin-top-20 mb-0">
          <Form.Field width={16}>
            <label>City</label>
            <Input
              placeholder="City"
              className="height-40"
              value={stateData.address.city}
              onChange={(e) => this.setLeadLocalData("city", e.target.value)}
            />
          </Form.Field>
          <Form.Field width={16}>
            <label>State</label>
            <Input
              placeholder="State"
              value={stateData.address.state}
              selection
              search
              className="height-40"
              options={this.state.statesList}
              onChange={this.handleStateChange}
            />
          </Form.Field>
          <Form.Field width={16}>
            <label>Zip Code</label>
            <Input
              placeholder="Zip Code"
              className="height-40"
              value={stateData.address.postcode}
              onChange={(e) =>
                this.setLeadLocalData("postcode", e.target.value)
              }
            />
          </Form.Field>
        </Form.Group>
        <Grid stretched columns="equal" className="margin-top-20 mx-0">
          <Grid.Column
            className={
              this.props.leadData ? "edit-contact-info p-0" : "custom-padding"
            }
            floated="left"
          >
            <Form.Field
              className={!this.props.leadData ? "mb-0" : "col-pd-right"}
              width={!this.props.leadData ? 16 : 8}
              required
            >
              <label>Email</label>
              <Input
                id="email"
                name="email"
                className="height-40"
                defaultValue={defaultData ? defaultData.email : ""}
                placeholder="Email"
                onChange={(event) => {
                  this.handleChange("email", event);
                }}
              />
            </Form.Field>
            <Form.Field
              width={!this.props.leadData ? 16 : 8}
              required
              className={
                !this.props.leadData ? "margin-top-20 mb-0" : "col-pd-left"
              }
            >
              <label>Mobile Phone</label>
              <Input
                id="phoneMobile"
                name="phoneMobile"
                className="height-40"
                placeholder="Mobile Phone"
              >
                <NumberFormat
                  defaultValue={defaultData ? defaultData.phoneMobile : ""}
                  onChange={(event) => {
                    this.handleChange("phoneMobile", event);
                  }}
                  format="### ### ####"
                />
              </Input>
            </Form.Field>
            {!this.props.leadData && (
              <>
                <Form.Field width={16} className="margin-top-20 mb-0">
                  <label>Assigned to</label>
                  <Dropdown
                    placeholder="Assigned To"
                    selection
                    search
                    options={pm}
                    onChange={this.handleAssignedChange}
                  />
                </Form.Field>
                <Form.Field width={16} className="margin-top-20 mb-0">
                  <label>Status</label>
                  <Dropdown
                    placeholder="Select..."
                    defaultValue={this.state.lead.status}
                    selection
                    search
                    options={ADD_LEAD_STATUS}
                    onChange={this.handleStatusChange}
                  />
                </Form.Field>
                <Form.Field width={16} className="margin-top-20 mb-0">
                  <label>Source</label>
                  <Dropdown
                    placeholder="Source"
                    selection
                    search
                    options={LEAD_SOURCE}
                    onChange={this.handleSourceChange}
                  />
                </Form.Field>
              </>
            )}
          </Grid.Column>
          {!this.props.leadData && (
            <Grid.Column
              floated="right"
              className="add-lead-requirements-preferences"
            >
              <Header as="h3" className="add-lead-requirements-heading">
                Requirements & Preferences
              </Header>
              <Form.Group widths="equal">
                <Form.Field width={8} className="required field">
                  <label>Floor Plan</label>
                  <Dropdown
                    placeholder="Floor Plan"
                    label={
                      !stateData.preference.unitType && {
                        tag: true,
                        content: "Required",
                        color: "orange",
                      }
                    }
                    selection
                    search
                    options={this.state.unitTypeData}
                    onChange={this.handleUnitTypeChange}
                  />
                </Form.Field>
                <Form.Field width={8} className="required field">
                  <label>Unit Number</label>
                  <Dropdown
                    placeholder="Unit Number"
                    label={
                      !stateData.preference.unit === "" && {
                        tag: true,
                        content: "Required",
                        color: "orange",
                      }
                    }
                    selection
                    search
                    options={this.state.unitsData}
                    onChange={this.handleUnitNumberChange}
                  />
                </Form.Field>
              </Form.Group>
              <Form.Field width={16} className="move-date">
                <label>Move In Date</label>
                <Datetime
                  timeFormat={false}
                  isValidDate={disablePastDt}
                  inputProps={{ readOnly: true, placeholder: "MM/DD/YYYY" }}
                  closeOnSelect={true}
                  minDate={moment().toDate()}
                  defaultValue={moment(this.state.date)}
                  onChange={this.handleDateChange}
                />
              </Form.Field>
              <Form.Group widths="equal">
                <Form.Field width={8} inline>
                  <label>Adults</label>
                  <Input
                    name="adults"
                    id="adults"
                    type="number"
                    className="height-40"
                    placeholder="Adults"
                    onChange={(event) => {
                      this.handleChange("adults", event, "preference");
                    }}
                  />
                </Form.Field>
                <Form.Field width={8} inline>
                  <label>Children</label>
                  <Input
                    name="children"
                    id="children"
                    type="number"
                    className="height-40"
                    placeholder="Children"
                    onChange={(event) => {
                      this.handleChange("children", event, "preference");
                    }}
                  />
                </Form.Field>
              </Form.Group>
              <Form.Field width={16}>
                <label>Pets</label>
                <Input
                  name="pets"
                  id="pets"
                  type="number"
                  className="height-40"
                  placeholder="Pets"
                  onChange={(event) => {
                    this.handleChange("pets", event, "preference");
                  }}
                />
              </Form.Field>
              <Form.Group widths="equal">
                <Form.Field width={8} inline>
                  <label>Location</label>
                  <Input
                    placeholder="Location"
                    className="height-40"
                    onChange={(e) =>
                      this.handleLocationChange("state", e.target.value)
                    }
                  />
                </Form.Field>
                <Form.Field width={8} inline>
                  <label>Area</label>
                  <Input
                    placeholder="Area"
                    className="height-40"
                    onChange={(e) =>
                      this.handleLocationChange("city", e.target.value)
                    }
                  />
                </Form.Field>
              </Form.Group>
            </Grid.Column>
          )}
        </Grid>
        {!this.props.leadData && (
          <Form.Field inline className="mt-30">
            <label>Comments</label>
            <TextArea
              className="create-lead-textarea"
              placeholder="Write a comment"
              onChange={(event) => {
                this.handleCommentChange("comment", event);
              }}
            />
          </Form.Field>
        )}
      </Form>
    </>
  );
  contextRef = createRef();
  render() {
    const pm = this.props.props
      ? [
        {
          key: this.props.props.firstName,
          text: this.props.props.firstName + " " + this.props.props.lastName,
          value: this.props.props.ndbId,
        },
      ]
      : [{}];
    const { errors, showLeadCreatedModal, showLoadingOnButton } = this.state;
    return (
      <>
        {!this.state.showLeadCreatedModal &&
          (!this.state.loading ? (
            <Modal
              className="semanticModal add-lead-modal modal-wid-700"
              open={this.props.showModal}
            >
              <Modal.Header
                textAlign="left"
                className="modal-header-bg position-sticky"
              >
                <Header
                  className="modal-heading-custom position-sticky"
                  textAlign="left"
                >
                  {!this.props.leadData ? "Add Lead" : "Edit Contact Info"}
                </Header>
              </Modal.Header>
              <Modal.Content>
                {this.renderLeadsForm(
                  errors,
                  this.state.lead,
                  this.props.leadData,
                  pm
                )}
              </Modal.Content>
              <>
                <Modal.Actions>
                  <Button
                    className="modal-close-button"
                    positive
                    onClick={this.props.onClose}
                  >
                    Cancel
                  </Button>
                  {this.state.loading ? (
                    <Button loader>Loading</Button>
                  ) : (
                    <Button
                      className="modal-save-button"
                      content={
                        showLoadingOnButton
                          ? "Loading..."
                          : !this.props.leadData
                            ? "Save"
                            : "Update"
                      }
                      onClick={(e) => this.validateAndSubmit(e)}
                      positive
                    />
                  )}
                </Modal.Actions>
              </>
            </Modal>
          ) : (
            this.props.loaderChange(true)
          ))}
        {showLeadCreatedModal && (
          <LeadAlreadyCreated
            closeModal={this.handleClose}
            updateLead={this.updateContactInfo}
          />
        )}
      </>
    );
  }
}

export default withApollo(CreateLeads);
