import React, { useState, useEffect } from "react";
import { withApollo } from "react-apollo";
import mixpanel from "mixpanel-browser";
import { getClient } from "../../init-apollo-googleFn";
import Datetime from "react-datetime";
import {
  GeneralLedgerRoll,
  TransAccounting,
} from "../../store/person/accounting";
import moment from "moment";
import { toastFailMsg } from "../../utils/common";
import "./Accounting.scss";
import { Button, Header, Table, Modal, Pagination } from "semantic-ui-react";
import { ACCOUNTING, ACCOUNTING_REPORTING } from "../../utils/constants";
import "react-toastify/dist/ReactToastify.css";
import { Loader } from "../../components/Loader/Loader";
import { jsPDF } from "jspdf";
import "jspdf-autotable";

const ledgerClient = getClient(ACCOUNTING_REPORTING);
const transClient = getClient(ACCOUNTING);
const DEFAULT_LIMIT = 99999999;

const GeneralLedger = (props) => {
  const [loading, setLoading] = useState(false);
  const [loadingTran, setLoadingTran] = useState(false);
  const [startDate, setStartDate] = useState(
    moment().startOf("month").format("YYYY-MM-DD")
  );
  const [endDate, setEndDate] = useState(
    moment().endOf("month").format("YYYY-MM-DD")
  );
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedAccount, setSelectedAccount] = useState(null);

  const [generalLedger, setGeneralLedger] = useState([]);
  const [generalLedgerTransactions, setGeneralLedgerTransactions] = useState(
    []
  );
  const [currentPage, setCurrentPage] = useState(0);
  const [itemsPerPage, setItemsPerPage] = useState(100);

  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;

  useEffect(() => {
    getLedger();
  }, [props?.selectedPrimary]);
  useEffect(() => {
    getLedgerTransactions();
  }, [selectedAccount]);

  const handleStartClick = (date) => {
    setStartDate(moment(date._d).format("YYYY-MM-DD"));
  };

  const handleEndClick = (date) => {
    setEndDate(moment(date._d).format("YYYY-MM-DD"));
  };

  const getLedger = (value) => {
    try {
      setLoading(true);
      ledgerClient
        .query({
          query: GeneralLedgerRoll,
          variables: {
            slLocationId: props?.selectedPrimary?.node?.slLocationId,
            startDate: startDate,
            endDate: endDate,
            status: "posted",
            consolidated: true,
          },
        })
        .then((res) => {
          const newLedger = res?.data?.generalLedger?.data;
          setGeneralLedger(newLedger);
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (e) {
      console.log(e);
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };
  const getLedgerTransactions = (value) => {
    try {
      setLoadingTran(true);
      transClient
        .query({
          query: TransAccounting,
          variables: {
            location: props?.selectedPrimary?.node?.id,
            offset: 0,
            limit: 999999,
          },
        })
        .then((res) => {
          const newLedgerTran = res?.data?.slLocationTransactions?.data;
          setGeneralLedgerTransactions(newLedgerTran);
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (e) {
      console.log(e);
      setLoadingTran(false);
    } finally {
      setLoadingTran(false);
    }
  };

  const handleModalOpen = (accountNumber) => {
    setSelectedAccount(accountNumber);
    setModalOpen(true);
  };

  const handleModalClose = () => {
    setModalOpen(false);
  };

  const stateDataToPDF = () => {
    // Convert state data to PDF logic here
  };

  return (
    <>
      <main className="main-content" role="main">
        <div className="tenants-container">
          <div className="row mb-4">
            <div className="col-md-12">
              <div className="page-header">
                <div className="d-flex align-items-left">
                  <div>
                    <div className="page-header-title">General Ledger</div>
                  </div>
                </div>
                <div className="d-flex align-items-end">
                  <div className="ml-3">
                    <Header textAlign="center">Start Date</Header>
                    <Datetime
                      inputProps={{ placeholder: "Select Start Date" }}
                      defaultValue={moment().startOf("month")}
                      timeFormat={false}
                      onChange={handleStartClick}
                    />
                  </div>
                  <div className="ml-3">
                    <Header textAlign="center">End Date</Header>
                    <Datetime
                      inputProps={{ placeholder: "Select End Date" }}
                      defaultValue={moment().endOf("month")}
                      timeFormat={false}
                      onChange={handleEndClick}
                    />
                  </div>
                </div>
                <div className="d-flex align-items-end">
                  <Button
                    className="ml-4 noPrint noPrint-button"
                    compact
                    style={{
                      backgroundImage:
                        "linear-gradient(110deg, #3b1c5a, #374db1 162%)",
                    }}
                    onClick={() => stateDataToPDF()}
                  >
                    Print
                  </Button>
                </div>
              </div>
            </div>
          </div>
          <Table>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>Account Number</Table.HeaderCell>
                <Table.HeaderCell>Account Name</Table.HeaderCell>
                <Table.HeaderCell>Type</Table.HeaderCell>
                <Table.HeaderCell>Natural Balance</Table.HeaderCell>
                <Table.HeaderCell>Starting</Table.HeaderCell>
                <Table.HeaderCell>Ending</Table.HeaderCell>
                <Table.HeaderCell>Activity</Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {loading ? (
                <Loader text inTable />
              ) : (
                generalLedger &&
                generalLedger.length !== 0 &&
                generalLedger.map((item) => {
                  return (
                    <Table.Row>
                      <Table.Cell>
                        <a
                          onClick={() => handleModalOpen(item.number)}
                          style={{
                            cursor: "pointer",
                            color: "blue",
                            textDecoration: "none",
                          }}
                          onMouseEnter={(e) =>
                            (e.target.style.color = "lightblue")
                          }
                          onMouseLeave={(e) => (e.target.style.color = "blue")}
                        >
                          {item.number}
                        </a>
                      </Table.Cell>
                      <Table.Cell>{item.name}</Table.Cell>
                      <Table.Cell>{item.type}</Table.Cell>
                      <Table.Cell>
                        {item.naturalBalance != null &&
                        item.naturalBalance !== "0"
                          ? item.naturalBalance < 0
                            ? `($${Math.abs(item.naturalBalance)})`
                            : `$${item.naturalBalance}`
                          : "-"}
                      </Table.Cell>
                      <Table.Cell>
                        {item.startingBalance != null &&
                        item.startingBalance !== "0"
                          ? item.startingBalance < 0
                            ? `($${Math.abs(item.startingBalance)})`
                            : `$${item.startingBalance}`
                          : "-"}
                      </Table.Cell>
                      <Table.Cell>
                        {item.endingBalance != null &&
                        item.endingBalance !== "0"
                          ? item.endingBalance < 0
                            ? `($${Math.abs(item.endingBalance)})`
                            : `$${item.endingBalance}`
                          : "-"}
                      </Table.Cell>
                      <Table.Cell>
                        {item.activity != null && item.activity !== "0"
                          ? item.activity < 0
                            ? `($${Math.abs(item.activity)})`
                            : `$${item.activity}`
                          : "-"}
                      </Table.Cell>
                    </Table.Row>
                  );
                })
              )}
            </Table.Body>
          </Table>
        </div>
      </main>
      <Modal
        className="semanticModal"
        open={modalOpen}
        onClose={handleModalClose}
        size="massive"
      >
        <Modal.Header>Transactions for {selectedAccount}</Modal.Header>
        <Modal.Content style={{ maxHeight: "70vh", overflowY: "auto" }}>
          <Table>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>Number</Table.HeaderCell>
                <Table.HeaderCell>Status</Table.HeaderCell>
                <Table.HeaderCell>Ledger</Table.HeaderCell>
                <Table.HeaderCell>Location Id</Table.HeaderCell>
                <Table.HeaderCell>Account Number</Table.HeaderCell>
                <Table.HeaderCell>Account Type</Table.HeaderCell>
                <Table.HeaderCell>Account Subtype</Table.HeaderCell>
                <Table.HeaderCell>TX Date</Table.HeaderCell>
                <Table.HeaderCell>Post Date</Table.HeaderCell>
                <Table.HeaderCell>Reference</Table.HeaderCell>
                <Table.HeaderCell>Description</Table.HeaderCell>
                <Table.HeaderCell>Debit</Table.HeaderCell>
                <Table.HeaderCell>Credit</Table.HeaderCell>
              </Table.Row>
            </Table.Header>

            <Table.Body
              style={{
                overflowX: "auto",
                overflowY: "hidden",
                maxHeight: "calc(100vh - 250px)",
                maxWidth: "calc(100vw - 100px)",
              }}
            >
              {loadingTran ? (
                <Loader text inTable />
              ) : (
                generalLedger &&
                generalLedger.length !== 0 &&
                generalLedger.map((item) => {
                  return (
                    <>
                      <Table.Row>
                        <Table.Cell>John Doe</Table.Cell>
                        <Table.Cell>35</Table.Cell>
                        <Table.Cell>Male</Table.Cell>
                        <Table.Cell>John Doe</Table.Cell>
                        <Table.Cell>35</Table.Cell>
                        <Table.Cell>Male</Table.Cell>
                        <Table.Cell>John Doe</Table.Cell>
                        <Table.Cell>35</Table.Cell>
                        <Table.Cell>Male</Table.Cell>
                        <Table.Cell>John Doe</Table.Cell>
                        <Table.Cell>35</Table.Cell>
                        <Table.Cell>Male</Table.Cell>
                        <Table.Cell>John Doe</Table.Cell>
                      </Table.Row>
                    </>
                  );
                })
              )}
            </Table.Body>
          </Table>
        </Modal.Content>
        <Modal.Actions>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <Pagination
            activePage={currentPage + 1}
            totalPages={Math.ceil(
              generalLedgerTransactions
                ? generalLedgerTransactions.length
                : 0 / itemsPerPage
            )}
            onPageChange={(event, { activePage }) =>
              setCurrentPage(activePage - 1)
            }
          />
          <Button onClick={handleModalClose}>Close</Button>
          </div>
        </Modal.Actions>
      </Modal>
    </>
  );
};

export default withApollo(GeneralLedger);
