import React from "react";

const VehicleDetails = ({ vehicles }) => {
  return (
    <>
      <div className="row">
        <div className="col-md-12">
          <div className="profile-label-row">
            <div>
              <span className="profile-label-title">Vehicles</span>
            </div>
            <div className="profile-label-line"></div>
          </div>
        </div>
      </div>
      {vehicles.map((vehicle) => {
        return (
          <div className="row">
            <div className="col-md-3">
              <div className="profile-basic">
                <div className="profile-label">Year</div>
                <div className="profile-label-value">{vehicle.year}</div>
              </div>
            </div>
            <div className="col-md-3">
              <div className="profile-basic">
                <div className="profile-label">Color</div>
                <div className="profile-label-value">{vehicle.color}</div>
              </div>
            </div>
            <div className="col-md-3">
              <div className="profile-basic">
                <div className="profile-label">Make</div>
                <div className="profile-label-value">{vehicle.make}</div>
              </div>
            </div>
            <div className="col-md-3">
              <div className="profile-basic">
                <div className="profile-label">Model</div>
                <div className="profile-label-value">{vehicle.model}</div>
              </div>
            </div>
            <div className="col-md-3">
              <div className="profile-basic">
                <div className="profile-label">Licence Plate</div>
                <div className="profile-label-value">
                  {vehicle.licensePlate}
                </div>
              </div>
            </div>
            <div className="col-md-3">
              <div className="profile-basic">
                <div className="profile-label">State</div>
                <div className="profile-label-value">{vehicle.state}</div>
              </div>
            </div>
          </div>
        );
      })}
    </>
  );
};

export default VehicleDetails;
