import React from "react";

function MerchantCenter(props) {
  return (
    <>
    <iframe
      src={"https://www.wepay.com/login"}
      title={props.title}
      width={props.width}
      height={props.height}
      frameBorder="0"
      allowFullScreen
    ></iframe>
    </>
  );
}

export default MerchantCenter;
