import React, { useState } from "react";
import get from "lodash/get";
import { Button, Card, Image, Message, Header, Progress, Divider, Icon, Label, Form, Loader } from 'semantic-ui-react'
import { getClient } from "../../../init-apollo-googleFn";
import {
  getPropertyInfoAndPolicies, AlterLocation
} from "../../../store/person/properties";
import {
  LOCATIONAPI,
} from "../../../utils";
import * as WepayUtils from "../../../utils/wepay";
import {
  parseGraphQLErrors,
  toastFailMsg,
  toastSuccessMsg,
} from "../../../utils/common";
const locationClient = getClient(LOCATIONAPI);

const AddPropertyStepper = ({
  user,
  orgDetails,
  compileData,
  changeFormStep,
  locationId,
}) => {

  React.useEffect(() => {
    fetchLocationDetails()
  }, []);
  const [dataSet, setData] = useState(false);
  const [trueModal, setTrueModal] = useState(false);
  const [trueLoad, setTrueLoad] = useState(false);
  const [paymentMethods, setPaymentMethods] = useState([]);
  const [accountPayoutMethodId, setAccountPayoutMethodId] = React.useState("");
  const [accountPayoutFrequency, setAccountPayoutFrequency] =
    React.useState("daily");
  const [cardCompleter, setCardCompleter] = useState({
    propertyCard: null,
    unitCard: null,
    companyCard: null,
    paymentCard: null,
    marketingCard: null,
    documentsCard: null
  });

  React.useEffect(() => {
    cardChecker()
  }, [dataSet]);

  const fetchPaymentMethods = async () => {
    setTrueModal(true)
    try {
      const res = await WepayUtils.getPaymentMethods({ locationId });
      setPaymentMethods(res);
    } catch (error) { }
  };

  const cardChecker = () => {
    const tempObj = {
      propertyCard: null,
      unitCard: null,
      companyCard: null,
      paymentCard: null,
      marketingCard: null,
      documentsCard: null
    };
    if (dataSet !== false) {
      for (const key in tempObj) {
        if (tempObj[key] === null) {
          if (key == "propertyCard") {
            if (dataSet.addresses.length == 1 && dataSet.customId.trim() !== "") {
              tempObj[key] = true;
            }
            else tempObj[key] = false;
          }
          if (key == "unitCard") {
            if (dataSet.totalUnits !== null || dataSet.totalUnits == 0) {
              tempObj[key] = true;
            }
            else tempObj[key] = false;
          }
          if (key == "companyCard") {
            // if (companyCounter().r !== 4) {
            tempObj[key] = true;
            // }
            // else tempObj[key] = false;
          }
          if (key == "paymentCard") {
            if (dataSet.wepaySetup == true) {
              tempObj[key] = true;
            }
            else tempObj[key] = false;
          }
          if (key == "marketingCard") {
            if (dataSet.contact !== null) {
              tempObj[key] = true;
            }
            else tempObj[key] = false;
          }
          if (key == "documentsCard") {
            if (dataSet.leaseDocPath !== null) {
              tempObj[key] = true;
            }
            else tempObj[key] = false;
          }
        }
      }
      setCardCompleter(tempObj)
    }

  }

  const fetchLocationDetails = async () => {
    const queryString = window.location.href
    const propId = new URLSearchParams(queryString);
    const product = propId.get('locationId')
    locationClient
      .query({
        query: getPropertyInfoAndPolicies,
        variables: { locationId: product },
      })
      .then((response) => {
        const locationData = get(response, "data.location.edges[0].node");
        if (locationData) {
          compileData(locationData)
          setData(locationData)
        }
      })
      .catch((error) => {

      })

  };

  const savePropertyInfo = async (status = "publish") => {
    setTrueLoad(true)
    const res = await WepayUtils.createWepayAccount({
      locationId: dataSet.id,
      payoutMethodId: accountPayoutMethodId,
      payoutFrequency: accountPayoutFrequency,
      resetPass:true
    });
    if (res === "200") {
      try {
        const response = await locationClient.mutate({
          mutation: AlterLocation,
          variables: {
            input: {
              disabled:false,
              id: dataSet.id,
              status,
            },
          },
        });
        if (response.data) {
          setTrueLoad(false)
          toastSuccessMsg("Property Created")
          window.location.href = '/#/properties/list'
        }
      } catch (error) {
        setTrueLoad(false)
      }
    }
    if(res == "300"){
      try {
        const response = await locationClient.mutate({
          mutation: AlterLocation,
          variables: {
            input: {
              disabled:false,
              id: dataSet.id,
              status,
            },
          },
        });
        if (response.data) {
          setTrueLoad(false)
          toastSuccessMsg("Property Updated")
          window.location.href = '/#/properties/list'
        }
      } catch (error) {
        setTrueLoad(false)
      }
    }
    else {
      setTrueLoad(false)
    }
  };

  const companyCounter = () => {
    const counter = {
      r: 1,
      o: 0
    }
    // if (user.addresses && user.addresses.length == 1) counter.r++;
    if (user.phoneMobile) counter.r++;
    if (user.email) counter.r++;
    if (orgDetails.name) counter.r++;
    if (orgDetails.contacts && orgDetails.contacts.length >= 1) counter.o++;
    return counter
  }
  const propertyCounter = () => {
    const counter = {
      r: 1,
      o: 0
    }
    if (dataSet.addresses && dataSet.addresses.length == 1) counter.r++;
    if (dataSet.customId) counter.r++;
    if (dataSet.description) counter.o++;
    if (dataSet.yearBuilt) counter.o++;
    if (dataSet.photos) counter.o++;
    if (dataSet.amenities) counter.o++;
    return counter
  }
  const unitCounter = () => {
    const counter = {
      r: 1,
      o: 0
    }
    if (dataSet.totalUnits == null || dataSet.totalUnits == 0) counter.r--
    return counter
  }

  const payCounter = () => {
    const counter = {
      r: 0,
      o: 0
    }
    if (dataSet.wepaySetup) counter.r++;
    return counter
  }
  const marketCounter = () => {
    const counter = {
      r: 0,
      o: 0
    }
    const contactParse = dataSet && dataSet.contact && JSON.parse(dataSet.contact)
    if (contactParse && contactParse.email) counter.r++;
    if (contactParse && contactParse.phone) counter.r++;
    if (contactParse && contactParse.schedule) counter.o++;
    if (contactParse && contactParse.socials) counter.o++;
    return counter
  }

  const documentCounter = () => {
    const counter = {
      r: 0,
      o: 0
    }
    const depo = dataSet && dataSet.depositRules && dataSet.depositRules[0]
    if (dataSet.leaseDocPath) counter.r++;
    if (depo && (depo.deposit_required == true || depo.deposit_required == false)) counter.o++;
    if (depo && (depo.deposit_required == true && depo.deposit_amount)) counter.o++;
    if (depo && (depo.deposit_required == true && (depo.deposit_required == true || depo.deposit_required == false))) counter.o++;
    if (depo && (depo.lease_guarantee == true || depo.lease_guarantee == false)) counter.o++;
    if (depo && (depo.fl_month_required == true || depo.fl_month_required == false)) counter.o++;
    if (depo && (depo.application_credit_score)) counter.o++;
    if (depo && (depo.income_x_time_rent)) counter.o++;
    if (depo && (depo.hoaPolicyDocPath)) counter.o++;
    if (dataSet.distributionNoticeDocPath) counter.o++;
    if (dataSet.paymentPolicyDocPath) counter.o++;
    if (dataSet.petPolicyDocPath) counter.o++;
    if (dataSet.cancellationPolicyDocPath) counter.o++;
    return counter
  }
  function checkValues() {
    for (var key in cardCompleter) {
      if (cardCompleter[key] === null || cardCompleter[key] === false) {
        return true;
      }
    }
    return false;
  }

  return (
    <>

      {
        dataSet &&
        <>
          <Message positive>
            <Header textAlign="center">{dataSet.customId} - {dataSet && dataSet.addresses && dataSet.addresses[0] && dataSet.addresses[0].streetOne},{" "}
              {dataSet && dataSet.addresses && dataSet.addresses[0] && dataSet.addresses[0].city},{" "}
              {dataSet && dataSet.addresses && dataSet.addresses[0] && dataSet.addresses[0].state},{" "}
              {dataSet && dataSet.addresses && dataSet.addresses[0] && dataSet.addresses[0].zip}</Header>
            <p>
              Lets get you started with setting up your property!  Each card completed moves you further along to publishing your property.
            </p>
            <Progress percent={String((Object.values(cardCompleter).reduce((a, item) => a + item, 0) / 6 * 100).toFixed(0))} color='violet' progress />
          </Message>
          {
            checkValues() &&
            <Divider horizontal>
              <Header as='h4'>
                <Icon name='exclamation' />
                To Do
                <Icon name='exclamation' />
              </Header>
            </Divider>
          }
          <Card.Group itemsPerRow="3">
            {
              !cardCompleter.propertyCard &&
              <Card>
                <Card.Content>
                  <div style={{ height: '35%' }}>
                    <Image src="/assets/img/dashboard/propertyStep.svg" wrapped size='small' />
                  </div>
                  <br></br><br></br>
                  <Card.Header textAlign="center">Property Information</Card.Header>
                  <Card.Description>
                    Add to your base property information. Additional information for the renter is always helpful. Things like descriptions, photos, year built, etc.
                  </Card.Description>
                </Card.Content>
                <Card.Content extra>
                  <Label size="mini" style={{ float: 'left' }}>
                    Required
                    <Label.Detail>{propertyCounter().r}/3</Label.Detail>
                  </Label>
                  <div className='ui one buttons'>
                    <Button basic color='green' onClick={() => changeFormStep(1)}>
                      Complete
                    </Button>
                  </div>
                  <Label size="mini" style={{ float: 'right' }}>
                    Optional
                    <Label.Detail>{propertyCounter().o}/4</Label.Detail>
                  </Label>
                </Card.Content>
              </Card>
            }
            {
              !cardCompleter.unitCard &&
              <Card>
                <Card.Content>
                  <div style={{ height: '35%' }}>
                    <Image src="/assets/img/dashboard/unitStep.svg" wrapped size='small' />
                  </div>
                  <br></br><br></br>
                  <Card.Header textAlign="center">Units and Size</Card.Header>
                  <Card.Description>
                    Have multiple units in your property? Fill them out here and create floor plans for future use and configuration. Define prices, pictures, and unit information here.
                  </Card.Description>
                </Card.Content>
                <Card.Content extra>
                  <Label size="mini" style={{ float: 'left' }}>
                    Required
                    <Label.Detail>{unitCounter().r}/1</Label.Detail>
                  </Label>
                  <div className='ui one buttons'>
                    <Button basic color='green' onClick={() => changeFormStep(2)}>
                      Complete
                    </Button>
                  </div>
                  <Label size="mini" style={{ float: 'right' }}>
                    Units
                    <Label.Detail>{dataSet.totalUnits}</Label.Detail>
                  </Label>
                </Card.Content>
              </Card>
            }
            {
              !cardCompleter.companyCard &&
              <Card>
                <Card.Content>
                  <div style={{ height: '35%' }}>
                    <Image src="/assets/img/dashboard/businessStep.svg" wrapped size='small' />
                  </div>
                  <br></br><br></br>
                  <Card.Header textAlign="center">Company Information</Card.Header>
                  <Card.Description>
                    Define your company information here so dataSet know where homebase is. Setup a single point of contact of questions, comments, and concerns.
                  </Card.Description>
                </Card.Content>
                <Card.Content extra>
                  <Label size="mini" style={{ float: 'left' }}>
                    Required
                    <Label.Detail>{companyCounter().r}/4</Label.Detail>
                  </Label>
                  <div className='ui one buttons'>
                    <Button basic color='green' onClick={() => changeFormStep(4)}>
                      Complete
                    </Button>
                  </div>
                  <Label size="mini" style={{ float: 'right' }}>
                    Optional
                    <Label.Detail>{companyCounter().o}/1</Label.Detail>
                  </Label>
                </Card.Content>
              </Card>
            }
            {
              !cardCompleter.paymentCard &&
              <Card >
                <Card.Content>
                  <div style={{ height: '35%' }}>
                    <Image src="/assets/img/dashboard/moneyStep.svg" wrapped size='small' />
                  </div>
                  <br></br><br></br>

                  <Card.Header textAlign="center">Payment Information</Card.Header>
                  <Card.Description>
                    Link your banking information so you get paid out sooner. This will also unlock our accounting services to better streamline your business.
                  </Card.Description>
                </Card.Content>
                <Card.Content extra>
                  <Label size="mini" style={{ float: 'left' }}>
                    Required
                    <Label.Detail>{payCounter().r}/1</Label.Detail>
                  </Label>
                  <div className='ui one buttons'>
                    <Button basic color='green' onClick={() => changeFormStep(6)}>
                      Complete
                    </Button>
                  </div>
                </Card.Content>
              </Card>
            }
            {
              !cardCompleter.marketingCard &&
              <Card >
                <Card.Content>
                  <div style={{ height: '35%' }}>
                    <Image src="/assets/img/dashboard/marketingStep.svg" wrapped size='small' />
                  </div>
                  <br></br><br></br>
                  <Card.Header textAlign="center">Marketing Information</Card.Header>
                  <Card.Description>
                    Setup how you want to advertise your property.  We offer a host of integrations to get eyes on your property.
                  </Card.Description>
                </Card.Content>
                <Card.Content extra>
                  <Label size="mini" style={{ float: 'left' }}>
                    Required
                    <Label.Detail>{marketCounter().r}/2</Label.Detail>
                  </Label>
                  <div className='ui one buttons'>
                    <Button basic color='green' onClick={() => changeFormStep(5)}>
                      Complete
                    </Button>
                  </div>
                  <Label size="mini" style={{ float: 'right' }}>
                    Optional
                    <Label.Detail>{marketCounter().o}/2</Label.Detail>
                  </Label>
                </Card.Content>
              </Card>
            }
            {
              !cardCompleter.documentsCard &&
              <Card>
                <Card.Content>
                  <div style={{ height: '35%' }}>
                    <Image src="/assets/img/dashboard/otherStep.svg" wrapped size='small' />
                  </div>
                  <br></br><br></br>

                  <Card.Header textAlign="center">Documents and Policies</Card.Header>
                  <Card.Description>
                    Upload any documents you may feel are important to both the renters and for your team. Define policies for your tenants and how you wish to show up in searches.
                  </Card.Description>
                </Card.Content>
                <Card.Content extra>
                  <Label size="mini" style={{ float: 'left' }}>
                    Required
                    <Label.Detail>{documentCounter().o}/1</Label.Detail>
                  </Label>
                  <div className='ui one buttons'>
                    <Button basic color='green' onClick={() => changeFormStep(3)}>
                      Complete
                    </Button>
                  </div>
                  <Label size="mini" style={{ float: 'right' }}>
                    Optional
                    <Label.Detail>{documentCounter().o}/12</Label.Detail>
                  </Label>
                </Card.Content>
              </Card>
            }
          </Card.Group>

          <Divider horizontal>
            <Header as='h4'>
              <Icon name='checkmark' />
              Completed
              <Icon name='checkmark' />
            </Header>
          </Divider>

          <Card.Group itemsPerRow="3">
            {
              cardCompleter.propertyCard &&
              <Card style={{ borderStyle: "solid", borderColor: '#fcfff5', background: "#fcfff5" }}>
                <Card.Content>
                  <div style={{ height: '35%' }}>
                    <Image src="/assets/img/dashboard/propertyStep.svg" wrapped size='small' />
                  </div>
                  <br></br><br></br>
                  <Card.Header textAlign="center">Property Information</Card.Header>
                  <Card.Description>
                    Add to your base property information. Additional information for the renter is always helpful. Things like descriptions, photos, year built, etc.
                  </Card.Description>
                </Card.Content>
                <Card.Content extra>
                  <Label size="mini" style={{ float: 'left' }}>
                    Required
                    <Label.Detail>{propertyCounter().r}/3</Label.Detail>
                  </Label>
                  <div className='ui one buttons'>
                    <Button basic color='green' onClick={() => changeFormStep(1)}>
                      Edit
                    </Button>
                  </div>
                  <Label size="mini" style={{ float: 'right' }}>
                    Optional
                    <Label.Detail>{propertyCounter().o}/4</Label.Detail>
                  </Label>
                </Card.Content>
              </Card>
            }
            {
              cardCompleter.unitCard &&
              <Card style={{ borderStyle: "solid", borderColor: '#fcfff5', background: "#fcfff5" }}>
                <Card.Content>
                  <div style={{ height: '35%' }}>
                    <Image src="/assets/img/dashboard/unitStep.svg" wrapped size='small' />
                  </div>
                  <br></br><br></br>
                  <Card.Header textAlign="center">Units and Size</Card.Header>
                  <Card.Description>
                    Have multiple units in your property? Fill them out here and create floor plans for future use and configuration. Define prices, pictures, and unit information here.
                  </Card.Description>
                </Card.Content>
                <Card.Content extra>
                  <Label size="mini" style={{ float: 'left' }}>
                    Required
                    <Label.Detail>{unitCounter().r}/1</Label.Detail>
                  </Label>
                  <div className='ui one buttons'>
                    <Button basic color='green' onClick={() => changeFormStep(2)}>
                      Edit
                    </Button>
                  </div>
                  <Label size="mini" style={{ float: 'right' }}>
                    Units
                    <Label.Detail>{dataSet.totalUnits}</Label.Detail>
                  </Label>
                </Card.Content>
              </Card>
            }
            {
              cardCompleter.companyCard &&
              <Card style={{ borderStyle: "solid", borderColor: '#fcfff5', background: "#fcfff5" }}>
                <Card.Content>
                  <div style={{ height: '35%' }}>
                    <Image src="/assets/img/dashboard/businessStep.svg" wrapped size='small' />
                  </div>
                  <br></br><br></br>
                  <Card.Header textAlign="center">Company Information</Card.Header>
                  <Card.Description>
                    Define your company information here so dataSet know where homebase is. Setup a single point of contact of questions, comments, and concerns.
                  </Card.Description>
                </Card.Content>
                <Card.Content extra>
                  <Label size="mini" style={{ float: 'left' }}>
                    Required
                    <Label.Detail>{companyCounter().r}/4</Label.Detail>
                  </Label>
                  <div className='ui one buttons'>
                    <Button basic color='green' onClick={() => changeFormStep(4)}>
                      Edit
                    </Button>
                  </div>
                  <Label size="mini" style={{ float: 'right' }}>
                    Optional
                    <Label.Detail>{companyCounter().o}/1</Label.Detail>
                  </Label>
                </Card.Content>
              </Card>
            }
            {
              cardCompleter.paymentCard &&
              <Card style={{ borderStyle: "solid", borderColor: '#fcfff5', background: "#fcfff5" }}>
                <Card.Content>
                  <div style={{ height: '35%' }}>
                    <Image src="/assets/img/dashboard/moneyStep.svg" wrapped size='small' />
                  </div>
                  <br></br><br></br>

                  <Card.Header textAlign="center">Payment Information</Card.Header>
                  <Card.Description>
                    Link your banking information so you get paid out sooner. This will also unlock our accounting services to better streamline your business.
                  </Card.Description>
                </Card.Content>
                <Card.Content extra>
                  <Label size="mini" style={{ float: 'left' }}>
                    Required
                    <Label.Detail>{payCounter().r}/1</Label.Detail>
                  </Label>
                  <div className='ui one buttons'>
                    <Button basic color='green' onClick={() => changeFormStep(6)}>
                      Edit
                    </Button>
                  </div>
                </Card.Content>
              </Card>
            }
            {
              cardCompleter.marketingCard &&
              <Card style={{ borderStyle: "solid", borderColor: '#fcfff5', background: "#fcfff5" }}>
                <Card.Content>
                  <div style={{ height: '35%' }}>
                    <Image src="/assets/img/dashboard/marketingStep.svg" wrapped size='small' />
                  </div>
                  <br></br><br></br>
                  <Card.Header textAlign="center">Marketing Information</Card.Header>
                  <Card.Description>
                    Setup how you want to advertise your property.  We offer a host of integrations to get eyes on your property.
                  </Card.Description>
                </Card.Content>
                <Card.Content extra>
                  <Label size="mini" style={{ float: 'left' }}>
                    Required
                    <Label.Detail>{marketCounter().r}/2</Label.Detail>
                  </Label>
                  <div className='ui one buttons'>
                    <Button basic color='green' onClick={() => changeFormStep(5)}>
                      Edit
                    </Button>
                  </div>
                  <Label size="mini" style={{ float: 'right' }}>
                    Optional
                    <Label.Detail>{marketCounter().o}/2</Label.Detail>
                  </Label>
                </Card.Content>
              </Card>
            }
            {
              cardCompleter.documentsCard &&
              <Card style={{ borderStyle: "solid", borderColor: '#fcfff5', background: "#fcfff5" }}>
                <Card.Content>
                  <div style={{ height: '35%' }}>
                    <Image src="/assets/img/dashboard/otherStep.svg" wrapped size='small' />
                  </div>
                  <br></br><br></br>

                  <Card.Header textAlign="center">Documents and Policies</Card.Header>
                  <Card.Description>
                    Upload any documents you may feel are important to both the renters and for your team. Define policies for your tenants and how you wish to show up in searches.
                  </Card.Description>
                </Card.Content>
                <Card.Content extra>
                  <Label size="mini" style={{ float: 'left' }}>
                    Required
                    <Label.Detail>{documentCounter().r}/1</Label.Detail>
                  </Label>
                  <div className='ui one buttons'>
                    <Button basic color='green' onClick={() => changeFormStep(3)}>
                      Edit
                    </Button>
                  </div>
                  <Label size="mini" style={{ float: 'right' }}>
                    Optional
                    <Label.Detail>{documentCounter().o}/12</Label.Detail>
                  </Label>
                </Card.Content>
              </Card>
            }
          </Card.Group>
          <br></br>
          <div className="btn-block">
            <div>
              <button
                type="button"
                className="btn cancel-btn"
              >
                Cancel
              </button>
            </div>
            <div>
              <button
                type="button"
                className="btn save-btn"
                onClick={() => changeFormStep(7)}
              >
                Preview
              </button>
              <button
                type="button"
                className="btn next-btn"
                data-toggle="modal"
                disabled={checkValues()}
                data-target="#finalize-payment-1"
                onClick={() => fetchPaymentMethods()}
              >
                Finalize
              </button>
            </div>
          </div>
          {
            trueModal &&

            <div
              class="modal fade hide"
              id="finalize-payment-1"
              tabindex="-1"
              role="dialog"
              aria-labelledby="finalizePaymentModal"
              aria-hidden="true"
              style={{ display: "block" }}
            >
              <div class="modal-dialog modal-dialog-centered" role="document">
                <div class="modal-content success-modal">
                  <div class="modal-header">
                    <div class="success-image">
                      <img src="assets/img/check-icon.svg" alt="Success icon" />
                    </div>
                    <h5 class="modal-title" id="forgotPasswordModalLabel">
                      One Final Thing!
                    </h5>

                    <div className="create-account-form" style={{ width: "322px", maxHeight: '243px', height: '243px' }}>
                      <div className="white-block">
                        <div className="header">Select Payout Method</div>
                        <br></br>
                        <Form>
                          <Form.Select
                            label="Payout Method"
                            value={accountPayoutMethodId}
                            onChange={(event, { value }) => {
                              setAccountPayoutMethodId(value);
                            }}
                            options={paymentMethods
                              ?.filter((m) => m.payoutMethod)
                              ?.map((method, i) => {
                                return {
                                  key: `payout-method-${i}`,
                                  text: `${method.name} - ******${method.lastFour}`,
                                  value: method.heartlandToken,
                                };
                              })}
                            placeholder="Payout Method"
                          />
                          <Form.Select
                            label="Payout Frequency"
                            value={accountPayoutFrequency}
                            onChange={(event, { value }) => {
                              setAccountPayoutFrequency(value);
                            }}
                            options={[
                              { key: "d", text: "Daily", value: "daily" },
                              { key: "w", text: "Weekly", value: "weekly" },
                            ]}
                            placeholder="Payout Frequency"
                          />
                        </Form>
                      </div>
                    </div>
                    <p class="modal-sutitle">
                      You are almost ready to publish! Simply select the payout method you want and click on Publish to officially launch the property.
                    </p>
                    <button
                      type="submit"
                      disabled={trueLoad}
                      class="btn modal-large-btn"
                      data-target="#finalize-payment-1"
                      data-dismiss="modal"
                      data-toggle="modal"
                      onClick={() => savePropertyInfo("publish")}
                    >
                    {trueLoad?<Loader active inline='centered' />:"Publish Property"} 
                    </button>
                  </div>
                </div>
              </div>
            </div>
          }
        </>
      }
    </>

  )
};

export default AddPropertyStepper;
