import React from "react";
import "./RentalHistory.scss";

function Occupants({ occupants }) {
  if (occupants && occupants.length === 0) return null;

  return (
    <>
      <div className="row">
        <div className="col-md-12">
          <div className="theme-card card">
            <div className="card-header">
              <div className="card-header-title">Occupants</div>
            </div>
            <div className="card-body pb-2">
              <div className="table-header">
                <div className="table-header-label">Full Name</div>
                <div className="table-header-label">Date Of Birth</div>
              </div>
              {/* div to be repeated */}
              {occupants &&
                occupants.map((item) => {
                  return (
                    <div className="table-col-row">
                      <div className="table-col">
                        {[item.firstName, item.middleName, item.lastName].join(
                          " "
                        ) || "-"}
                      </div>
                      <div className="table-col">{item.dateOfBirth || "-"}</div>
                    </div>
                  );
                })}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Occupants;
