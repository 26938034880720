import React from "react";
import { useEffect } from "react";
import { Loader } from "semantic-ui-react";
import { formatDateTo12Hours } from "../../../utils/common";
import { MediaContentType } from "../../../store/redux/chat";
import "./ChatLogs.scss";

const Media = ({ media }) => {
  const isImage = [
    MediaContentType.jpeg,
    MediaContentType.jpg,
    MediaContentType.pdf,
  ].includes(media.content_type);
  if (isImage) {
    return (
      <img
        className="media-img"
        onClick={() => window.open(media.uri, "_blank")}
        src={media.uri}
        alt="chat-media-log"
      />
    );
  } else {
    const fileName = media.uri.split("/").pop();
    return (
      <p className="attach" onClick={() => window.open(media.uri, "_blank")}>
        Attach: {fileName}
      </p>
    );
  }
};

export default function ChatLogs({ chatLogs, isLoading, application }) {
  const managerId = application.leasingAgent&&application.leasingAgent.id;

  useEffect(() => {
    const a = document.getElementById("chat-logs");
    a.scrollTop = a.scrollHeight;
  }, [chatLogs]);

  return (
    <>
      <div className="row">
        <div className="col-md-12">
          <div className="theme-card card">
            <div className="card-header">
              <div className="card-header-title">Chat Logs</div>
            </div>
            <div className="card-body">
              {isLoading && <Loader />}

              <div id="chat-logs" className="chat-logs">
                {chatLogs&&chatLogs.map(({ node }) => {
                  const date = new Date(node.timeStamp);
                  const dateNow = new Date();
                  const time = formatDateTo12Hours(date);
                  const isToday =
                    dateNow.toLocaleDateString() !== date.toLocaleDateString();
                  const isSender = managerId === node.senderId;
                  return (
                    <div
                      className={`chat-log ${isSender ? "sender" : ""}`}
                      key={node.id}
                    >
                      <div className="body">{node.body}</div>
                      <div className="media">
                        {JSON.parse(node.media).map((media) => (
                          <Media {...{ media }} />
                        ))}
                      </div>
                      <div className="datetime">
                        {isToday
                          ? `${time} - ${date.toLocaleDateString()}`
                          : `${time}`}
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
