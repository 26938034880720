import React, { Component } from "react";
import {
  ListItemsToDo,
  ListItemsCompleted,
} from "../../components/Lists/ListItems";
import { PageLoader } from "../../components/Loader/PageLoader";
import AccountingGraphs from "../../components/Graphs/AccountingGraphs";
import { withApollo } from "react-apollo";
import { unreadPersonNotificationsGql } from "../../store/person/notifications";
import { leadsGql, TourLocation } from "../../store/person/leads";
import { leasesExpiring, RenewLeaseNotification } from "../../store/person/leases";
import Slider from "react-slick";
import mixpanel from "mixpanel-browser";
import Schedule from "../Schedule/Schedule";
import {
  APPLICATION_MANAGER,
  HELLOSIGN_URL,
  TASK_URL,
  LEASE_MANAGER,
  CATEGORIES,
  ACCOUNTING,
  LEAD_STATUS,
  CATEGORIES_VALUES,
  LEADS_TOUR,
  MAINTENANCE_URL,
  UNITAPI,
  DATE_FORMAT,
  LEASE_EXPIRE_OPTIONS,
} from "../../utils/constants";
import { AccountingMetrics } from "../../store/person/accounting";

import "./Dashboard.scss";
import {
  ViewAllTasks,
  CreateTask,
  UpdateTask,
  DeleteTask,
} from "../../store/person/tasks";
import { toastSuccessMsg } from "../../utils/common";
import {
  getUnitsMetrics,
  getResidentsMetrics,
  getApplicationMetrics,
  getMaintenanceMetrics,
} from "../../store/person/person";
import moment from "moment";
import get from "lodash/get";
import { getClient } from "../../init-apollo-googleFn";
import getAuthToken from "../../store/auth/authUtility";
import HelloSign from "hellosign-embedded";
import "tui-calendar/dist/tui-calendar.css";
import "tui-date-picker/dist/tui-date-picker.css";
import "tui-time-picker/dist/tui-time-picker.css";
import { STATUS, EVENTS, ACTIONS } from "react-joyride";
import {
  Grid,
  Form,
  List, Tab, Table,
  Input,
  Popup,
  Card,
  Checkbox,
  Icon,
  Button,
  Dropdown,
  Header,
} from "semantic-ui-react";
import Tour from 'reactour'
import { dashboardSteps } from "../../utils/tourSteps";

const taskClient = getClient(TASK_URL);
const leaseClient = getClient(LEASE_MANAGER);
const toursClient = getClient(LEADS_TOUR);
const ledgerClient = getClient(ACCOUNTING);
const unitClient = getClient(UNITAPI);
const maintClient = getClient(MAINTENANCE_URL);
const appManClient = getClient(APPLICATION_MANAGER);
const helloClient = new HelloSign({
  clientId: process.env.REACT_APP_HELLOSIGN_ID,
});

class Dashboard extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedPrimary: "",
      selectedPropertyData: [],
      report: 'Upcoming Task',
      events: [],
      eventCache: [],
      popOpen: true,
      isTourOpen: false,
      notificationsToSend: [],
      eventsCal: [],
      loading: false,
      tasksLoading: true,
      applications: [],
      Metrics: {},
      alerts: [],
      message: "",
      tourData: [],
      isError: false,
      value: "",
      applicationsFetched: false,
      leadCount: "",
      tourCount: "",
      completedTourCount: "",
      loadingTours: true,
      appCount: "",
      appId: "",
      itemsToDo: [],
      itemsCompleted: [],
      deepDiveEvent: "",
      metricFilterMonth: { revenue: true, expenses: true },
      showCalDetails: false,
      currentItem: {
        text: "",
        key: "",
      },
      currentPriority: {
        text: "Low",
        key: "1",
      },
      statistics: [],
      maintStats: [],
      leadStats: [
        { x: "Total", y: 109 },
        { x: "Today", y: 14 },
        { x: "Follow Up", y: 9 },
        { x: "Canceled", y: 3 },
        { x: "Showing", y: 10 },
      ],
      revStats: [],
      maintData: [],
      selectedGraphData: "unit",
      applicant: {},
      signatureUrl: "",
      signLoader: false,
      run: false,
      expiredLeases: [],
      leaseLoading: true,
      leaseCount: '',
      leasePercent: '',
      toggleScheduleCreateEventModal: false,
    };
    this.handleInput = this.handleInput.bind(this);
    this.moveItem = this.moveItem.bind(this);
    this.setUpdate = this.setUpdate.bind(this);
    this.addItem = this.addItem.bind(this);
  }

  componentDidMount = async () => {
    if (this.props.selectedPrimary && this.props.selectedPrimary.length !== 0) {
      await this.propSetter();
      await this.getTopStatistics();
      await this.getLeads();
      await this.getAlerts();
      await this.getAgenda();
      await this.getTasks();
      await this.getLedger();
      await this.getExpiringLeases('event', 'value');
      mixpanel.track("PM Render", {
        sub: "Dashboard",
      });
      this.filterPropertyData(
        this.props.selectedPrimary ? this.props.selectedPrimary.node : ""
      );
    } else {
      this.setState({ tasksLoading: false });
      this.hideLoader();
    }
    this.hideLoader();
    setTimeout(() => {
      this.setState({ popOpen: false })
    }, "10000")
  };

  componentDidMount() {
    mixpanel.track("Manager Page Load", {
      sub: "Dashboard",
    });
  }


  propSetter = async () => {
    if (this.props.selectedPrimary.length !== 0) {
      const { selectedPrimary } = this.props;
      this.setState({
        statistics: [
          {
            x: "Total",
            y: selectedPrimary.node && selectedPrimary.node.totalUnits,
          },
          {
            x: "Occupied",
            y: selectedPrimary.node && selectedPrimary.node.totalOccupied,
          },
        ],
        selectedPrimary: selectedPrimary.node,
      });
    }
  };

  componentWillReceiveProps(prevProps) {
    if (prevProps.selectedPrimary) {
      const nodeData = prevProps.selectedPrimary.node;

      if (nodeData !== this.state.selectedPrimary) {
        this.filterPropertyData(nodeData);
        this.setState({ selectedPrimary: nodeData }, () => {
          this.getLeads();
          this.getAlerts();
          this.getTopStatistics();
          this.getAgenda();
          this.getTasks();
          this.getLedger();
          this.getExpiringLeases('event', 'value');
        });
      }
    } else {
      this.hideLoader();
      this.setState({ tasksLoading: false });
    }
  }

  getTopStatistics() {
    if (this.state.selectedPrimary) {
      this.getMaintenance();
      this.getResidents();
      this.getApplication();
      this.getUnits();
    }
  }

  getUnits = () => {
    unitClient
      .query({
        query: getUnitsMetrics,
        variables: { locationId: this.state.selectedPrimary.id },
      })
      .then((response) => {
        const unitMetrics = get(response, "data.dashboardUnitsMetrics", []);
        this.setState({ unitMetrics });
      })
      .catch((error) => {
        this.setState({ loading: false });
      });
  };

  getMaintenance = () => {
    maintClient
      .query({
        query: getMaintenanceMetrics,
        variables: { location: this.state.selectedPrimary.id },
      })
      .then((response) => {
        const maintMetrics = get(
          response,
          "data.dashboardMaintenanceMetrics",
          []
        );
        this.setState({ maintMetrics });
      })
      .catch((error) => {
        this.setState({ loading: false });
      });
  };

  getApplication = () => {
    appManClient
      .query({
        query: getApplicationMetrics,
        variables: { location: this.state.selectedPrimary.id },
      })
      .then((response) => {
        const appMetrics = get(
          response,
          "data.dashboardApplicationMetrics",
          []
        );
        this.setState({ appMetrics });
      })
      .catch((error) => {
        this.setState({ loading: false });
      });
  };
  getResidents = () => {
    leaseClient
      .query({
        query: getResidentsMetrics,
        variables: { locationId: this.state.selectedPrimary.id },
      })
      .then((response) => {
        const resMetrics = get(response, "data.dashboardResidentsMetrics", []);
        this.setState({ resMetrics });
      })
      .catch((error) => {
        this.setState({ loading: false });
      });
  };

  getExpiringLeases = (e, { value }) => {
    leaseClient
      .query({
        query: leasesExpiring,
        variables: {
          locationId: this.state.selectedPrimary.id,
          interval: value === undefined ? 'THREE_MONTH' : value
        }
      })
      .then((response) => {
        const data = response.data.leasesExpiringIntervalWise;
        this.setState({
          expiredLeases: data.leases,
          leaseCount: data.count,
          leasePercent: data.percentage,
          leaseLoading: false
        })
      })
      .catch((error) => {
        this.setState({ leaseLoading: false })
      })
  }
// Get Tasks
async getTasks() {
  if (!this.state.selectedPrimary) return;

  try {
    const res = await taskClient.query({ query: ViewAllTasks });

    const completed = (res?.data?.task?.edges || []).filter(
      (task) => task.node.complete === true
    );
    const incomplete = (res?.data?.task?.edges || []).filter(
      (task) => task.node.complete === false
    );

    this.setState({
      itemsCompleted: completed,
      itemsToDo: incomplete,
      tasksLoading: false,
    });
  } catch (error) {
    console.log(error);
  }
}

  dashboardList = (values, day) => {
    this.setState({ eventCache: values });
    this.setState({ selectedDate: day });
    var element = document.getElementById("schedule-list");
    if (element) {
      element.scrollTo(0, 0);
    }
  };

  /* function to filter out the selected property data from the properties list */
  filterPropertyData = (nodeData) => {
    if (this.props.selectedPrimary.length !== 0) {
      const { unitStats, maintStats } = this.state;
      const primaryLocations = this.props.primaryLocations;
      const locationFilter =
        primaryLocations &&
        primaryLocations.filter((data) => data.node.ndbId === nodeData.ndbId);
      this.setState({ propertyData: locationFilter }, () => {
        switch (this.state.selectedGraphData) {
          case "unit":
            this.setState({ statistics: unitStats });
            this.updateUnitStatisticsData();
            break;
          case "maint":
            this.setState({ statistics: maintStats });
            this.updateMaintData();
            break;
          default:
            break;
        }
      });
    }
  };

  getLedger = () => {
    ledgerClient
      .query({
        query: AccountingMetrics,
        variables: {
          location:
            this.props.selectedPrimary.node &&
            this.props.selectedPrimary.node.id,
        },
      })
      .then((res) => {
        if (res.data.error) {
          return
        }
        else if (res.data.slMetrics) {
          if (res.data.slMetrics.edges) {
            const dataMetrics = res?.data?.slMetrics?.edges[0]?.node
            this.setState({ Metrics: dataMetrics });
          }
          else return
        }
        else return
      })
      .catch((error) => {
        console.log(error);
      });
  };

  /* function to update unit statistics data on property selection */
  updateUnitStatisticsData = () => {
    const { propertyData, selectedGraphData, selectedPrimary } = this.state;
    if (this.props.selectedPrimary.length !== 0) {
      if (propertyData && propertyData.length > 0) {
        const newStatistics = [
          {
            x: "Occupied",
            y: selectedPrimary.totalOccupied
              ? selectedPrimary.totalOccupied
              : 0,
          },
          { x: "Total Units", y: selectedPrimary.totalUnits },
        ];
        this.setState({ unitStats: newStatistics });
        if (selectedGraphData === "unit") {
          this.setState({ statistics: newStatistics });
        }
      }
    }
  };

  /* function to filter the maintainence data according to the category passed
   and return the length of the specific filtered maintainence data */
  filterMaintCategory = (maintData, category) => {
    const filteredData =
      maintData && maintData.filter((ele) => ele.node.category === category);
    return filteredData.length;
  };

  /* function to update maintenance statistics data on property selection */
  updateMaintData = () => {
    const { maintData, selectedGraphData } = this.state;
    const tempArr = [];
    for (let i = 0; i < CATEGORIES.length; i++) {
      tempArr.push({
        x: CATEGORIES[i],
        y: this.filterMaintCategory(
          maintData,
          CATEGORIES_VALUES[i].toLowerCase()
        ),
      });
    }
    this.setState({ maintStats: tempArr });
    if (selectedGraphData === "maint") {
      this.setState({ statistics: tempArr });
    }
  };

  addNewProperty = () => {
    this.props.history.push(`/addProperty`);
  };

  getAlerts() {
    try {
      this.props.client
        .query({
          query: unreadPersonNotificationsGql,
        })
        .then((res) => {
          this.setState({ alerts: res.data.personNotifications.edges });
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (e) {
      console.log(e);
    }
  }

  // Gathers the Leads
  getLeads = () => {
    const { selectedPrimary } = this.state;
    try {
      this.props.leadsClient
        .query({
          query: leadsGql,
          variables: {
            location: selectedPrimary.id,
          },
        })
        .then((res) => {
          const eventFilter = get(res, "data.lead.edges", null);
          this.setState({ leadData: eventFilter });
          this.updateLeadData();
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (e) { }
  };

  showDetails = (event) => {
    this.setState({ showCalDetails: true });
    this.setState({ deepDiveEvent: event });
  };

  getAgenda = () => {
    const { selectedPrimary } = this.state;
    this.setState({ loadingTours: true });
    try {
      toursClient
        .query({
          query: TourLocation,
          variables: {
            location: selectedPrimary.id,
          },
        })
        .then((res) => {
          const eventFilter = res.data.tours.edges;
          const setAgenda = [];
          eventFilter.forEach((event) => {
            const eventObj = {};
            eventObj["primaryInfo"] = event.node;
            eventObj["convertedDate"] = moment(event.node.date).format(
              "MM/DD/YYYY"
            );
            setAgenda.push(eventObj);
          });
          const filterArr = setAgenda.filter(
            (task) => task.primaryInfo.status !== "cancel"
          );
          this.setState({ tourData: filterArr, loadingTours: false });
        })
        .catch((error) => {
          console.log(error);
          this.setState({ loadingTours: false });
        });
    } catch (e) {
      this.setState({ loadingTours: false });
    }
  };

  filterLeadCategory = (leadData, status) => {
    const filteredData =
      leadData && leadData.filter((ele) => ele.node.status === status);
    return filteredData.length;
  };

  // Updates the new lead amounts
  updateLeadData = () => {
    const { leadData, selectedGraphData } = this.state;
    const tempArr = [];
    for (let i = 0; i < LEAD_STATUS.length; i++) {
      tempArr.push({
        x: LEAD_STATUS[i],
        y: this.filterLeadCategory(leadData, LEAD_STATUS[i]),
      });
    }
    this.setState({ leadStats: tempArr });
    if (selectedGraphData === "maint") {
      this.setState({ statistics: tempArr });
    }
    if (selectedGraphData === "lead") {
      this.setState({ statistics: tempArr });
    }
  };

  hideLoader = () => this.setState({ loading: false });

  closeMessage = () => {
    this.setState({ message: "" });
  };

  updateApplicationStatus(id, status) {
    return this.state.applications.map((e) => {
      if (e.node.ndbId === id) {
        e.node = { ...e.node, status: status };
      }
      return e;
    });
  }

  handleClickStart = (e) => {
    e.preventDefault();

    this.setState({
      stepIndex: 0,
      run: true,
    });
  };

  handleJoyrideCallback = (data) => {
    const { action, index, status, type } = data;
    if ([EVENTS.STEP_AFTER, EVENTS.TARGET_NOT_FOUND].includes(type)) {
      this.setState({ stepIndex: index + (action === ACTIONS.PREV ? -1 : 1) });
    } else if (
      [STATUS.FINISHED, STATUS.SKIPPED].includes(status) ||
      action === "close"
    ) {
      this.setState({ run: false });
    }
  };

  sum = (obj) =>
    Object.keys(obj).reduce((sum, key) => sum + parseFloat(obj[key] || 0), 0);

  cumulateCounts(metrics) {
    let newMetrics = {};
    for (let key in metrics) {
      const count = JSON.parse(metrics[key]);
      newMetrics[key] = { All: this.sum(count), ...count };
    }
    return newMetrics;
  }

  fetchLeaseAgreement = async () => {
    const { user } = this.props;
    const { applicant } = this.state;
    try {
      this.setState({ signLoader: true });
      await fetch(HELLOSIGN_URL, {
        method: "POST",
        headers: {
          Authorization: getAuthToken(),
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          title: "Agreement Document",
          subject: "Signature request",
          message: "Signature request",
          signers: [
            {
              name: `${user.firstName} ${user.lastName}`,
              email_address: user.email,
              role_name: "Manager",
            },
            {
              name: `${applicant.firstName} ${applicant.lastName}`,
              email_address: applicant.email,
              role_name: "Renter",
            },
          ],
          application_id: this.state.appId,
        }),
      })
        .then((response) => {
          if (!response.ok) {
            this.setState({ message: response.status, isError: true });
            throw new Error("Failed with HTTP code " + response.status);
          }
          return response;
        })
        .then((result) => result.json())
        .then((data) => {
          helloClient.open(data.sign_url, {
            testMode: true,
          });
          this.setState({ signLoader: false });
          this.setState({
            signatureUrl: data.sign_url,
          });
        });
    } catch (e) {
      this.setState({ signLoader: false, message: e, isError: true });
      console.log(e);
    }
  };

  setModalLessee = (applicant, appId) => {
    this.setState({ applicant, appId });
    document.getElementById("openModalConfirm").click();
  };

  // Chart switching mechanic
  leaseraStats = (event) => {
    const { unitStats, maintStats, leadStats, revStats } = this.state;
    if (this.props.selectedPrimary.length !== 0) {
      if (event) {
        if (event.target.textContent === "Unit Stats") {
          this.updateUnitStatisticsData();
          this.setState({
            statistics: unitStats,
            value: event.target.textContent,
            selectedGraphData: "unit",
          });
        }
        if (event.target.textContent === "Maintenance Stats") {
          this.setState({
            statistics: maintStats,
            value: event.target.textContent,
            selectedGraphData: "maint",
          });
        }
        if (event.target.textContent === "Lead Stats") {
          this.setState({
            statistics: leadStats,
            value: event.target.textContent,
            selectedGraphData: "lead",
          });
        }
        if (event.target.textContent === "Revenue") {
          this.setState({
            statistics: revStats,
            value: event.target.textContent,
            selectedGraphData: "revenue",
          });
        }
      }
    }
  };

  // Adding item for Tasklist
  addItem = (e) => {
    const { selectedPrimary, currentItem, currentPriority } = this.state;
    try {
      mixpanel.track("Manager Task Action", {
        sub: "Create Task",
      });
      taskClient
        .mutate({
          mutation: CreateTask,
          variables: {
            input: {
              locationId: selectedPrimary.id,
              description: currentItem.text,
              priority: currentPriority.text,
            },
          },
        })
        .then((response) => {
          this.getTasks();
        });
    } catch (e) {
      console.log(e);
    }
  };
  // Item moving for Tasklist
  moveItem(item, key) {
    try {
      mixpanel.track("Manager Task Action", {
        sub: "Update Task",
      });
      taskClient
        .mutate({
          mutation: UpdateTask,
          variables: {
            input: {
              id: item.node.id,
              description: item.node.description,
              complete: item.node.complete === true ? false : true,
            },
          },
        })
        .then((response) => {
          // If Complete
          if (item.node.complete === true) {
            this.getTasks();
          }
          // If not Complete
          if (item.node.complete === false) {
            item.node.complete = true;
            const newState = this.state.itemsToDo.filter(
              (st) => st.node.id !== item.node.id
            );
            this.setState({ itemsToDo: newState });
            this.setState({
              itemsCompleted: [...this.state.itemsCompleted, item],
            });
          }
        });
    } catch (e) {
      console.log(e);
    }
  }

  deleteItem = (item, key) => {
    try {
      mixpanel.track("Manager Task Action", {
        sub: "Delete Task",
      });
      taskClient
        .mutate({
          mutation: DeleteTask,
          variables: {
            input: {
              id: item.node.id,
            },
          },
        })
        .then((response) => {
          const NewComplete = this.state.itemsCompleted.filter(
            (og) => og !== item
          );
          const newToDo = this.state.itemsToDo.filter((st) => st !== item);
          this.setState({ itemsCompleted: NewComplete });
          this.setState({ itemsToDo: newToDo });
        });
    } catch (e) {
      console.log(e);
    }
  };
  handleInput = (e) => {
    this.setState({
      currentItem: {
        text: e.target.value,
        key: Math.random(),
      },
    });
  };
  handlePriority = (e, { name, value, text }) => {
    this.setState({
      currentPriority: {
        text: value,
        key: Math.random(),
      },
    });
  };
  setUpdate(text, key) {
    const items = this.state.itemsToDo;
    items.forEach((item) => {
      if (item.key === key) {
        item.text = text;
      }
    });
    this.setState({
      itemsToDo: items,
    });
  }
  handleReportChange = (event, { value }) => {
    this.setState({ report: value });
  };

  navigateToResidentPage = (leaseId, appId) => {
    this.props.history.push({
      pathname: `tenantDetail/tenantId=${leaseId}/applicationId=${appId}`
    })
  }
  notifyUser = (id) => {
    try {
      mixpanel.track("Lease Task Action", {
        sub: "Lease Notification Sent",
      });
      leaseClient
        .mutate({
          mutation: RenewLeaseNotification,
          variables: {
            input: {
              lease: {
                leaseIds: this.state.notificationsToSend,
              },
            }
          },
        })
        .then((response) => {
          toastSuccessMsg("Notification sent to user")
          this.getExpiringLeases('event', 'value');
          this.setState({ notificationsToSend: [] })
        }).catch((error) => {
        });
    } catch (e) {
      console.log(e);
    }
  }
  isObject(val) {
    if (val === null) { return false; }
    return ((typeof val === 'function') || (typeof val === 'object'));
  }

  addNotify = (id) => {
    if (this.state.notificationsToSend.includes(id)) {
      var index = this.state.notificationsToSend.indexOf(id);
      if (index !== -1) {
        const newArr = this.state.notificationsToSend.splice(index, 1);
        this.setState({ newArr })
      }
    }
    else {
      const newArray = [...this.state.notificationsToSend, id];
      this.setState({ notificationsToSend: newArray })
    }
  }

  renderTableContent = () => (
    <>
      <div className="d-flex justify-content-end align-items-center flex-wrap">

        <div className="filter-lease-label">Lease Count: <span>{this.state.leaseCount}</span></div>
        <div className="filter-lease-label">Lease Percentage: <span>{this.state.leasePercent}</span></div>
        <Dropdown
          placeholder='NEXT 90 DAYS'
          fluid
          selection
          options={LEASE_EXPIRE_OPTIONS}
          onChange={this.getExpiringLeases}
          className="mr-0"
        />
      </div>
      <Table singleLine>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell width='1'><Button disabled={this.state.notificationsToSend.length == 0} onClick={() => this.notifyUser()} float="left" compact>Notify</Button></Table.HeaderCell>
            <Table.HeaderCell width="4">Full Name</Table.HeaderCell>
            <Table.HeaderCell width="3">Ending Day</Table.HeaderCell>
            <Table.HeaderCell width="2">Amount</Table.HeaderCell>
            <Table.HeaderCell width="2">Unit No.</Table.HeaderCell>
            <Table.HeaderCell width="2">Notify Date</Table.HeaderCell>
            <Table.HeaderCell width="2">Action</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
      </Table>
      <div style={{ maxHeight: '500px', overflowY: 'auto' }}>

        <Table>
          <Table.Body >
            {this.state.expiredLeases && this.state.expiredLeases.map((ele) => (
              <Table.Row>
                <Table.Cell textAlign="center" width='1'><Checkbox checked={this.state.notificationsToSend.includes(ele.id)} onChange={() => this.addNotify(ele.id)}></Checkbox></Table.Cell>
                <Table.Cell width="4">{ele.person.firstName + ' ' + ele.person.lastName}</Table.Cell>
                <Table.Cell width="3">{moment(ele.endDate).format(DATE_FORMAT)}</Table.Cell>
                <Table.Cell width="2">{ele.amount.toLocaleString()}</Table.Cell>
                <Table.Cell width="2">{ele.unit.number}</Table.Cell>
                <Table.Cell width="2">{this.isObject(ele.notificationDate) ? moment.utc(Object.values(ele.notificationDate)[0].date).local().format("MM/DD/YYYY h:m") : "--"}</Table.Cell>
                <Table.Cell width="2">
                  <Button icon="eye" size="mini" onClick={() => this.navigateToResidentPage(ele.id, ele.applicationId)} />
                </Table.Cell>
              </Table.Row>
            ))}
            {this.state.expiredLeases.length === 0 &&
              <Table.Row>
                <Table.Cell colspan="5" >
                  <h4 className="text-center py-2">No Data Found</h4>
                </Table.Cell>
              </Table.Row>
            }
          </Table.Body>
        </Table>
      </div>
    </>
  )

  render() {
    const {
      alerts,
      unitMetrics,
      appMetrics,
      resMetrics,
      maintMetrics,
      tourData,
    } = this.state;

    const descriptionArr =
      this.state.deepDiveEvent &&
      this.state.deepDiveEvent.description.split("::");

    const panes = [
      {
        menuItem: 'Lease Actions',
        render: () => <Tab.Pane attached={true}>
          {this.renderTableContent()}
        </Tab.Pane>,
      },
    ]

    const friendOptions = [
      {
        key: 'Upcoming Task',
        text: 'Upcoming Task',
        value: 'Upcoming Task',
      },
      // {
      //   key: 'Schedule',
      //   text: 'Schedule',
      //   value: 'Schedule',
      // },
    ]

    const ResidentsCard = () => (
      <div>
        {resMetrics && (
          <Grid celled="internally">
            <Grid.Column mobile={16} tablet={8} computer={4}>
              <Header as="h4">In</Header>
              <Header as="h1">{resMetrics.In}</Header>
            </Grid.Column>
            <Grid.Column mobile={16} tablet={8} computer={4}>
              <Header as="h4">Out</Header>
              <Header as="h1">{resMetrics.Out}</Header>
            </Grid.Column>
            <Grid.Column mobile={16} tablet={8} computer={4}>
              <Header as="h4">Ending</Header>
              <Header as="h1">{resMetrics.Ending}</Header>
            </Grid.Column>
            <Grid.Column mobile={16} tablet={8} computer={4}>
              <Header as="h4">Renewal</Header>
              <Header as="h1">{resMetrics.Renewal}</Header>
            </Grid.Column>
          </Grid>
        )}
      </div>
    );

    const PipelineCard = () => (
      <div>
        {appMetrics && (
          <Grid celled="internally">
            <Grid.Column mobile={16} tablet={16} computer={5}>
              <Header as="h4">Applied</Header>
              <Header as="h1">{appMetrics.Applied}</Header>
            </Grid.Column>
            <Grid.Column mobile={16} tablet={16} computer={6}>
              <Header as="h4">Prospects</Header>
              <Header as="h1">{appMetrics.Prospects}</Header>
            </Grid.Column>
            <Grid.Column mobile={16} tablet={16} computer={5}>
              <Header as="h4">Wait</Header>
              <Header as="h1">{appMetrics.Waitlist}</Header>
            </Grid.Column>
            <Grid.Column mobile={16} tablet={16} computer={5}>
              <Header as="h4">Channels</Header>
              <Header as="h1">{appMetrics.Channels}</Header>
            </Grid.Column>
          </Grid>
        )}
      </div>
    );

    const UnitsCard = () => (
      <div>
        {unitMetrics && (
          <Grid celled="internally">
            <Grid.Column mobile={16} tablet={16} computer={5}>
              <Header as="h4">Total</Header>
              <Header as="h1">{unitMetrics.Total}</Header>
            </Grid.Column>
            <Grid.Column mobile={16} tablet={16} computer={6}>
              <Header as="h4">Vacant</Header>
              <Header as="h1">{unitMetrics.Vacant}</Header>
            </Grid.Column>
            <Grid.Column mobile={16} tablet={16} computer={5}>
              <Header as="h4">Unlisted</Header>
              <Header as="h1">{unitMetrics.Unlisted}</Header>
            </Grid.Column>
            <Grid.Column mobile={16} tablet={16} computer={5}>
              <Header as="h4">Occupied</Header>
              <Header as="h1">{unitMetrics.Occupied}</Header>
            </Grid.Column>
          </Grid>
        )}
      </div>
    );

    const MaintenanceCard = () => (
      <div>
        {maintMetrics && (
          <Grid celled="internally">
            <Grid.Column mobile={16} tablet={16} computer={5}>
              <Header as="h4">Urgent</Header>
              <Header as="h1">{maintMetrics.Urgent}</Header>
            </Grid.Column>
            <Grid.Column mobile={16} tablet={16} computer={6}>
              <Header as="h4">Make Ready</Header>
              <Header as="h1">{maintMetrics["Make Ready"]}</Header>
            </Grid.Column>
            <Grid.Column mobile={16} tablet={16} computer={6}>
              <Header as="h4">Other</Header>
              <Header as="h1">{maintMetrics.Other}</Header>
            </Grid.Column>
            <Grid.Column mobile={16} tablet={16} computer={5}>
              <Header as="h4">Total</Header>
              <Header as="h1">{maintMetrics.Total}</Header>
            </Grid.Column>
          </Grid>
        )}
      </div>
    );
    const TodayCard = () => (
      <div>
        {alerts && (
          <Grid celled="internally">
            <Grid.Column mobile={16} tablet={16} computer={5}>
              <Header as="h4">Alerts</Header>
              <Header as="h1">{alerts.length}</Header>
            </Grid.Column>
            <Grid.Column mobile={16} tablet={16} computer={6}>
              <Header as="h4">Showings</Header>
              <Header as="h1">
                {
                  tourData.filter(
                    (tour) =>
                      tour.convertedDate === moment().format("MM/DD/YYYY")
                  ).length
                }
              </Header>
            </Grid.Column>
            <Grid.Column mobile={16} tablet={16} computer={5}>
              <Header as="h4">Tasks</Header>
              <Header as="h1">
                {this.state.itemsToDo && this.state.itemsToDo.length}
              </Header>
            </Grid.Column>
            <Grid.Column mobile={16} tablet={16} computer={5}>
              <Header as="h4">Notes</Header>
              <Header as="h1">
                {this.state.itemsToDo && this.state.itemsToDo.length}
              </Header>
            </Grid.Column>

          </Grid>
        )}
      </div>
    );
    const settings = {
      // dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
    };
    const priority = [
      { key: "low", text: "Low", value: "Low", className: "lowP" },
      { key: "medium", text: "Medium", value: "Medium", className: "medP" },
      { key: "urgent", text: "Urgent", value: "Urgent", className: "higP" },
    ];

    // revenue/expenses determined by error handling and filter.
    if (this.state.Metrics === null) return window.location.reload();

    return (
      <span className="dashboard">
        {(this.props.isLoading || this.state.tasksLoading) === true ||
          this.state.Metrics === {} ? (
          <PageLoader text />
        ) : (
          <>
            <div className="row">
              <div className="col-md-8">
                <div className="row">
                  <div className="col-md-12">
                    <h2 className="heading heading-md dashboard-first-step">Dashboard
                      <Popup
                        content='Click wherever you see a lightbulb to find out more about the page.'
                        open={this.state.popOpen}
                        position='top left'
                        trigger={<Icon size='small' circular name='lightbulb' onClick={() => this.setState({ isTourOpen: true })} />}
                      />
                    </h2>
                  </div>
                </div>
              </div>
            </div>
            <br></br>
            <Slider {...settings}>
              <div id="adDiv">
                <div>
                  <h4>Enter a new era of rental management.</h4>
                  <img src="assets/img/dashboard-banner.jpg" alt="banner" />
                  <div className="close-icon">
                  </div>
                </div>
              </div>
            </Slider>

            <Card.Group itemsPerRow={5} className="row-1 dashboard-second-step" stackable>
              <Card fluid className="dashboard-today">
                <Card.Content className="card-content">
                  <Card.Header
                    className="card-header"
                    as={this.props.selectedPrimary ? "a" : ""}
                    href={this.props.selectedPrimary ? "/#/notifications" : ""}
                  >
                    Today
                  </Card.Header>
                  <Card.Content className="card-content">
                    <TodayCard />
                  </Card.Content>
                </Card.Content>
              </Card>
              <Card fluid className="dashbord-maint">
                <Card.Content className="card-content">
                  <Card.Header
                    as={this.props.selectedPrimary ? "a" : ""}
                    href={this.props.selectedPrimary ? "/#/maintenance" : ""}
                    className="card-header"
                  >
                    Maintenance
                  </Card.Header>
                  <Card.Content className="card-content">
                    <MaintenanceCard />
                  </Card.Content>
                </Card.Content>
              </Card>
              <Card fluid className="dashboard-pipeline">
                <Card.Content className="card-content">
                  <Card.Header
                    as={this.props.selectedPrimary ? "a" : ""}
                    href={this.props.selectedPrimary ? "/#/leads" : ""}
                    className="card-header"
                  >
                    Pipeline
                  </Card.Header>
                  <Card.Content className="card-content">
                    <PipelineCard />
                  </Card.Content>
                </Card.Content>
              </Card>
              <Card fluid className="dashboard-residents">
                <Card.Content className="card-content">
                  <Card.Header
                    as={this.props.selectedPrimary ? "a" : ""}
                    href={this.props.selectedPrimary ? "/#/residents" : ""}
                    className="card-header"
                  >
                    Residents
                  </Card.Header>
                  <Card.Content className="card-content">
                    <ResidentsCard />
                  </Card.Content>
                </Card.Content>
              </Card>
              <Card fluid className="dashboard-units">
                <Card.Content className="card-content">
                  <Card.Header
                    className="card-header"
                    as={this.props.selectedPrimary ? "a" : ""}
                    href={
                      this.props.selectedPrimary ? "/#/properties/list" : ""
                    }
                  >
                    Units
                  </Card.Header>
                  <Card.Content className="card-content">
                    <UnitsCard />
                  </Card.Content>
                </Card.Content>
              </Card>
            </Card.Group>

            {/* Upcoming Events */}
            <Card.Group className="dashboard-third-step">
              <Card fluid>
                <Card.Content className="card-content">
                  <Tab menu={{ secondary: true, pointing: true }} panes={panes} className="events-tab" />
                </Card.Content>
              </Card>
            </Card.Group>

            <Card.Group
              itemsPerRow={1}
              className="upcoming-tasks-wrapper dashboard-fourth-step"
              stackable
            >
              <Card className="todo">
                <Card.Content className="card-content">
                  <Card.Header as="a" className="card-header">
                    <Dropdown
                      inline
                      options={friendOptions}
                      defaultValue={friendOptions[0].value}
                      onChange={this.handleReportChange}
                    />
                    {this.state.report === "Schedule" && <Button floated="right" onClick={() => this.setState({ toggleScheduleCreateEventModal: true })}>Add Event</Button>}
                  </Card.Header>
                  <Card.Content
                    className="content-todo schedule-rw" //this schedule-rw class is only for upcoming schedule
                    style={{ overflow: "auto" }}
                  >
                    {this.state.report === "Upcoming Task" && (
                      <>
                        {/* upcoming tasks */}
                        <Form id="to-do-form" onSubmit={this.addItem}>
                          <Input
                            label={
                              <Dropdown
                                defaultValue="Low"
                                className={`${this.state.currentPriority.text === "Low" &&
                                  "low"
                                  } ${this.state.currentPriority.text ===
                                  "Medium" && "med"
                                  } ${this.state.currentPriority.text ===
                                  "Urgent" && "hig"
                                  }`}
                                compact
                                selection
                                options={priority}
                                onChange={this.handlePriority}
                              />
                            }
                            type="text"
                            fluid
                            className="todo-inputnew"
                            placeholder="Enter Task"
                            value={this.state.currentItem.text}
                            onChange={this.handleInput}
                            action={""}
                          />
                        </Form>
                        <div className="todo-list">
                          <ListItemsToDo
                            style={{ overflowY: "auto" }}
                            items={this.state.itemsToDo}
                            moveItem={this.moveItem}
                            deleteItem={this.deleteItem}
                          />

                          <ListItemsCompleted
                            style={{ overflowY: "auto" }}
                            itemsDone={this.state.itemsCompleted}
                            moveItem={this.moveItem}
                            deleteItem={this.deleteItem}
                          />
                        </div>
                      </>
                    )}
                    {this.state.report === "Schedule" && (
                      <>
                        <div className="dashboard-calendar-wrap">
                          <div className="card-content schedule-rw">
                            <Grid celled>
                              <Grid.Row>
                                <Grid.Column
                                  mobile={16} tablet={8} computer={7}
                                  className="dashboard-cal"
                                >
                                  {this.state.selectedPrimary.id && (
                                    <Schedule
                                      primaryLocation={
                                        this.state.selectedPrimary.id
                                      }
                                      user={this.props.user}
                                      toggleCreateEvent={this.state.toggleScheduleCreateEventModal}
                                      dashboard
                                      dashboardList={this.dashboardList}
                                    />
                                  )}
                                </Grid.Column>
                                <Grid.Column
                                  mobile={16} tablet={8} computer={9}
                                  id="schedule-list"
                                  className="schedule-list"
                                >
                                  {/* This needs to be rethought out. Running maps this many times is not really efficient. */}
                                  {/* eslint-disable */}
                                  <List>
                                    <List.Header as="a">
                                      {this.state.selectedDate &&
                                        moment(this.state.selectedDate).format(
                                          "MMM DD YYYY"
                                        )}
                                    </List.Header>
                                    <List.Item>
                                      <span className="time-col">5:00 am+</span>
                                      <List.Content>
                                        <List.Header as="a"></List.Header>
                                        {this.state.eventCache.map((event) => {
                                          const eventTime =
                                            event.lodat.format("HHmm");
                                          if (eventTime <= 459) {
                                            const formattedTime =
                                              event.time.split("/");
                                            return (
                                              <List.Description
                                                as="a"
                                                onClick={() =>
                                                  this.showDetails(event)
                                                }
                                              >
                                                <span class="explore-icon">
                                                  <img
                                                    src="/assets/img/icons-explore.svg"
                                                    alt="icon"
                                                  />
                                                </span>
                                                {event.title} {formattedTime[1]}
                                                /{formattedTime[0]}/
                                                {formattedTime[2]}
                                              </List.Description>
                                            );
                                          }
                                        })}
                                      </List.Content>
                                    </List.Item>
                                    <List.Item>
                                      <span className="time-col">6:00 am</span>
                                      <List.Content>
                                        <List.Header as="a"></List.Header>
                                        {this.state.eventCache.map((event) => {
                                          const eventTime =
                                            event.lodat.format("HHmm");
                                          if (
                                            559 < eventTime &&
                                            eventTime < 700
                                          ) {
                                            const formattedTime =
                                              event.time.split("/");
                                            return (
                                              <List.Description
                                                as="a"
                                                onClick={() =>
                                                  this.showDetails(event)
                                                }
                                              >
                                                <span class="explore-icon">
                                                  <img
                                                    src="/assets/img/icons-explore.svg"
                                                    alt="icon"
                                                  />
                                                </span>
                                                {event.title} {formattedTime[1]}
                                                /{formattedTime[0]}/
                                                {formattedTime[2]}
                                              </List.Description>
                                            );
                                          }
                                        })}
                                      </List.Content>
                                    </List.Item>
                                    <List.Item>
                                      <span className="time-col">7:00 am</span>
                                      <List.Content>
                                        <List.Header as="a"></List.Header>
                                        {this.state.eventCache.map((event) => {
                                          const eventTime =
                                            event.lodat.format("HHmm");
                                          if (
                                            659 < eventTime &&
                                            eventTime < 800
                                          ) {
                                            const formattedTime =
                                              event.time.split("/");
                                            return (
                                              <List.Description
                                                as="a"
                                                onClick={() =>
                                                  this.showDetails(event)
                                                }
                                              >
                                                <span class="explore-icon">
                                                  <img
                                                    src="/assets/img/icons-explore.svg"
                                                    alt="icon"
                                                  />
                                                </span>
                                                {event.title} {formattedTime[1]}
                                                /{formattedTime[0]}/
                                                {formattedTime[2]}
                                              </List.Description>
                                            );
                                          }
                                        })}
                                      </List.Content>
                                    </List.Item>
                                    <List.Item>
                                      <span className="time-col">8:00 am</span>
                                      <List.Content>
                                        <List.Header as="a"></List.Header>
                                        {this.state.eventCache.map((event) => {
                                          const eventTime =
                                            event.lodat.format("HHmm");
                                          if (
                                            759 < eventTime &&
                                            eventTime < 900
                                          ) {
                                            const formattedTime =
                                              event.time.split("/");
                                            return (
                                              <List.Description
                                                as="a"
                                                onClick={() =>
                                                  this.showDetails(event)
                                                }
                                              >
                                                <span class="explore-icon">
                                                  <img
                                                    src="/assets/img/icons-explore.svg"
                                                    alt="icon"
                                                  />
                                                </span>
                                                {event.title} {formattedTime[1]}
                                                /{formattedTime[0]}/
                                                {formattedTime[2]}
                                              </List.Description>
                                            );
                                          }
                                        })}
                                      </List.Content>
                                    </List.Item>
                                    <List.Item>
                                      <span className="time-col">9:00 am</span>
                                      <List.Content>
                                        <List.Header as="a"></List.Header>
                                        {this.state.eventCache.map((event) => {
                                          const eventTime =
                                            event.lodat.format("HHmm");
                                          if (
                                            859 < eventTime &&
                                            eventTime < 1000
                                          ) {
                                            const formattedTime =
                                              event.time.split("/");
                                            return (
                                              <List.Description
                                                as="a"
                                                onClick={() =>
                                                  this.showDetails(event)
                                                }
                                              >
                                                <span class="explore-icon">
                                                  <img
                                                    src="/assets/img/icons-explore.svg"
                                                    alt="icon"
                                                  />
                                                </span>
                                                {event.title} {formattedTime[1]}
                                                /{formattedTime[0]}/
                                                {formattedTime[2]}
                                              </List.Description>
                                            );
                                          }
                                        })}
                                      </List.Content>
                                    </List.Item>
                                    <List.Item>
                                      <span className="time-col">10:00 am</span>
                                      <List.Content>
                                        <List.Header as="a"></List.Header>
                                        {this.state.eventCache.map((event) => {
                                          const eventTime =
                                            event.lodat.format("HHmm");
                                          if (
                                            959 < eventTime &&
                                            eventTime < 1100
                                          ) {
                                            const formattedTime =
                                              event.time.split("/");
                                            return (
                                              <List.Description
                                                as="a"
                                                onClick={() =>
                                                  this.showDetails(event)
                                                }
                                              >
                                                <span class="explore-icon">
                                                  <img
                                                    src="/assets/img/icons-explore.svg"
                                                    alt="icon"
                                                  />
                                                </span>
                                                {event.title} {formattedTime[1]}
                                                /{formattedTime[0]}/
                                                {formattedTime[2]}
                                              </List.Description>
                                            );
                                          }
                                        })}
                                      </List.Content>
                                    </List.Item>
                                    <List.Item>
                                      <span className="time-col">11:00 am</span>
                                      <List.Content>
                                        <List.Header as="a"></List.Header>
                                        {this.state.eventCache.map((event) => {
                                          const eventTime =
                                            event.lodat.format("HHmm");
                                          if (
                                            1059 < eventTime &&
                                            eventTime < 1200
                                          ) {
                                            const formattedTime =
                                              event.time.split("/");
                                            return (
                                              <List.Description
                                                as="a"
                                                onClick={() =>
                                                  this.showDetails(event)
                                                }
                                              >
                                                <span class="explore-icon">
                                                  <img
                                                    src="/assets/img/icons-explore.svg"
                                                    alt="icon"
                                                  />
                                                </span>
                                                {event.title} {formattedTime[1]}
                                                /{formattedTime[0]}/
                                                {formattedTime[2]}
                                              </List.Description>
                                            );
                                          }
                                        })}
                                      </List.Content>
                                    </List.Item>
                                    <List.Item>
                                      <span className="time-col">12:00 pm</span>
                                      <List.Content>
                                        <List.Header as="a"></List.Header>
                                        {this.state.eventCache.map((event) => {
                                          const eventTime =
                                            event.lodat.format("HHmm");
                                          if (
                                            1159 < eventTime &&
                                            eventTime < 1300
                                          ) {
                                            const formattedTime =
                                              event.time.split("/");
                                            return (
                                              <List.Description
                                                as="a"
                                                onClick={() =>
                                                  this.showDetails(event)
                                                }
                                              >
                                                <span class="explore-icon">
                                                  <img
                                                    src="/assets/img/icons-explore.svg"
                                                    alt="icon"
                                                  />
                                                </span>
                                                {event.title} {formattedTime[1]}
                                                /{formattedTime[0]}/
                                                {formattedTime[2]}
                                              </List.Description>
                                            );
                                          }
                                        })}
                                      </List.Content>
                                    </List.Item>
                                    <List.Item className="move-out-col">
                                      <span className="time-col">1:00 pm</span>
                                      <List.Content>
                                        <List.Header as="a"></List.Header>
                                        {this.state.eventCache.map((event) => {
                                          const eventTime =
                                            event.lodat.format("HHmm");
                                          if (
                                            1259 < eventTime &&
                                            eventTime < 1400
                                          ) {
                                            const formattedTime =
                                              event.time.split("/");
                                            return (
                                              <List.Description
                                                as="a"
                                                onClick={() =>
                                                  this.showDetails(event)
                                                }
                                              >
                                                <span class="explore-icon">
                                                  <img
                                                    src="/assets/img/icons-explore.svg"
                                                    alt="icon"
                                                  />
                                                </span>
                                                {event.title} {formattedTime[1]}
                                                /{formattedTime[0]}/
                                                {formattedTime[2]}
                                              </List.Description>
                                            );
                                          }
                                        })}
                                      </List.Content>
                                    </List.Item>
                                    <List.Item>
                                      <span className="time-col">2:00 pm</span>
                                      <List.Content>
                                        <List.Header as="a"></List.Header>
                                        {this.state.eventCache.map((event) => {
                                          const eventTime =
                                            event.lodat.format("HHmm");
                                          if (
                                            1359 < eventTime &&
                                            eventTime < 1500
                                          ) {
                                            const formattedTime =
                                              event.time.split("/");
                                            return (
                                              <List.Description
                                                as="a"
                                                onClick={() =>
                                                  this.showDetails(event)
                                                }
                                              >
                                                <span class="explore-icon">
                                                  <img
                                                    src="/assets/img/icons-explore.svg"
                                                    alt="icon"
                                                  />
                                                </span>
                                                {event.title} {formattedTime[1]}
                                                /{formattedTime[0]}/
                                                {formattedTime[2]}
                                              </List.Description>
                                            );
                                          }
                                        })}
                                      </List.Content>
                                    </List.Item>
                                    <List.Item>
                                      <span className="time-col">3:00 pm</span>
                                      <List.Content>
                                        <List.Header as="a"></List.Header>
                                        {this.state.eventCache.map((event) => {
                                          const eventTime =
                                            event.lodat.format("HHmm");
                                          if (
                                            1459 < eventTime &&
                                            eventTime < 1600
                                          ) {
                                            const formattedTime =
                                              event.time.split("/");
                                            return (
                                              <List.Description
                                                as="a"
                                                onClick={() =>
                                                  this.showDetails(event)
                                                }
                                              >
                                                <span class="explore-icon">
                                                  <img
                                                    src="/assets/img/icons-explore.svg"
                                                    alt="icon"
                                                  />
                                                </span>
                                                {event.title} {formattedTime[1]}
                                                /{formattedTime[0]}/
                                                {formattedTime[2]}
                                              </List.Description>
                                            );
                                          }
                                        })}
                                      </List.Content>
                                    </List.Item>
                                    <List.Item>
                                      <span className="time-col">4:00 pm</span>
                                      <List.Content>
                                        <List.Header as="a"></List.Header>
                                        {this.state.eventCache.map((event) => {
                                          const eventTime =
                                            event.lodat.format("HHmm");
                                          if (
                                            1559 < eventTime &&
                                            eventTime < 1700
                                          ) {
                                            const formattedTime =
                                              event.time.split("/");
                                            return (
                                              <List.Description
                                                as="a"
                                                onClick={() =>
                                                  this.showDetails(event)
                                                }
                                              >
                                                <span class="explore-icon">
                                                  <img
                                                    src="/assets/img/icons-explore.svg"
                                                    alt="icon"
                                                  />
                                                </span>
                                                {event.title} {formattedTime[1]}
                                                /{formattedTime[0]}/
                                                {formattedTime[2]}
                                              </List.Description>
                                            );
                                          }
                                        })}
                                      </List.Content>
                                    </List.Item>
                                    <List.Item>
                                      <span className="time-col">5:00 pm</span>
                                      <List.Content>
                                        <List.Header as="a"></List.Header>
                                        {this.state.eventCache.map((event) => {
                                          const eventTime =
                                            event.lodat.format("HHmm");
                                          if (
                                            1659 < eventTime &&
                                            eventTime < 1800
                                          ) {
                                            const formattedTime =
                                              event.time.split("/");
                                            return (
                                              <List.Description
                                                as="a"
                                                onClick={() =>
                                                  this.showDetails(event)
                                                }
                                              >
                                                <span class="explore-icon">
                                                  <img
                                                    src="/assets/img/icons-explore.svg"
                                                    alt="icon"
                                                  />
                                                </span>
                                                {event.title} {formattedTime[1]}
                                                /{formattedTime[0]}/
                                                {formattedTime[2]}
                                              </List.Description>
                                            );
                                          }
                                        })}
                                      </List.Content>
                                    </List.Item>
                                    <List.Item>
                                      <span className="time-col">6:00 pm</span>
                                      <List.Content>
                                        <List.Header as="a"></List.Header>
                                        {this.state.eventCache.map((event) => {
                                          const eventTime =
                                            event.lodat.format("HHmm");
                                          if (
                                            1759 < eventTime &&
                                            eventTime < 1900
                                          ) {
                                            const formattedTime =
                                              event.time.split("/");
                                            return (
                                              <List.Description
                                                as="a"
                                                onClick={() =>
                                                  this.showDetails(event)
                                                }
                                              >
                                                <span class="explore-icon">
                                                  <img
                                                    src="/assets/img/icons-explore.svg"
                                                    alt="icon"
                                                  />
                                                </span>
                                                {event.title} {formattedTime[1]}
                                                /{formattedTime[0]}/
                                                {formattedTime[2]}
                                              </List.Description>
                                            );
                                          }
                                        })}
                                      </List.Content>
                                    </List.Item>
                                    <List.Item>
                                      <span className="time-col">7:00 pm</span>
                                      <List.Content>
                                        <List.Header as="a"></List.Header>
                                        {this.state.eventCache.map((event) => {
                                          const eventTime =
                                            event.lodat.format("HHmm");
                                          if (
                                            1859 < eventTime &&
                                            eventTime < 2000
                                          ) {
                                            const formattedTime =
                                              event.time.split("/");
                                            return (
                                              <List.Description
                                                as="a"
                                                onClick={() =>
                                                  this.showDetails(event)
                                                }
                                              >
                                                <span class="explore-icon">
                                                  <img
                                                    src="/assets/img/icons-explore.svg"
                                                    alt="icon"
                                                  />
                                                </span>
                                                {event.title} {formattedTime[1]}
                                                /{formattedTime[0]}/
                                                {formattedTime[2]}
                                              </List.Description>
                                            );
                                          }
                                        })}
                                      </List.Content>
                                    </List.Item>
                                    <List.Item>
                                      <span className="time-col">8:00 pm</span>
                                      <List.Content>
                                        <List.Header as="a"></List.Header>
                                        {this.state.eventCache.map((event) => {
                                          const eventTime =
                                            event.lodat.format("HHmm");
                                          if (
                                            1959 < eventTime &&
                                            eventTime < 2100
                                          ) {
                                            const formattedTime =
                                              event.time.split("/");
                                            return (
                                              <List.Description
                                                as="a"
                                                onClick={() =>
                                                  this.showDetails(event)
                                                }
                                              >
                                                <span class="explore-icon">
                                                  <img
                                                    src="/assets/img/icons-explore.svg"
                                                    alt="icon"
                                                  />
                                                </span>
                                                {event.title} {formattedTime[1]}
                                                /{formattedTime[0]}/
                                                {formattedTime[2]}
                                              </List.Description>
                                            );
                                          }
                                        })}
                                      </List.Content>
                                    </List.Item>
                                    <List.Item>
                                      <span className="time-col">9:00 pm+</span>
                                      <List.Content>
                                        <List.Header as="a"></List.Header>
                                        {this.state.eventCache.map((event) => {
                                          const eventTime =
                                            event.lodat.format("HHmm");
                                          if (eventTime >= 2100) {
                                            const formattedTime =
                                              event.time.split("/");
                                            return (
                                              <List.Description
                                                as="a"
                                                onClick={() =>
                                                  this.showDetails(event)
                                                }
                                              >
                                                <span class="explore-icon">
                                                  <img
                                                    src="/assets/img/icons-explore.svg"
                                                    alt="icon"
                                                  />
                                                </span>
                                                {event.title} {formattedTime[1]}
                                                /{formattedTime[0]}/
                                                {formattedTime[2]}
                                              </List.Description>
                                            );
                                          }
                                        })}
                                      </List.Content>
                                    </List.Item>
                                  </List>
                                  {/* eslint-enable */}
                                </Grid.Column>
                              </Grid.Row>
                              {this.state.showCalDetails && (
                                <>
                                  <div className="event-detail">
                                    <div className="header">
                                      <h6>{this.state.deepDiveEvent.title}</h6>
                                    </div>
                                    <div className="content">
                                      <div
                                        className="list-rw time-rw"
                                        style={{ marginTop: "30px" }}
                                      >
                                        <div className="list-col">
                                          <label>Start Time</label>
                                          <span>
                                            {moment(
                                              this.state.deepDiveEvent.lodat
                                            ).format("hh:mm A")}
                                          </span>
                                        </div>
                                      </div>
                                      <div className="list-rw">
                                        <label>Other Information</label>
                                        <span>
                                          {this.state.deepDiveEvent.time}
                                        </span>
                                      </div>
                                      <div className="list-rw">
                                        <label>Additional Notes</label>
                                        <span>
                                          {this.state.deepDiveEvent.title ===
                                            "Maintenance Request"
                                            ? `${descriptionArr[0]} by ${descriptionArr[1]} for a(n) ${descriptionArr[4]} issue at ${descriptionArr[2]}. Description: ${descriptionArr[5]}`
                                            : this.state.deepDiveEvent.title ===
                                            "Tour Request" &&
                                            `${descriptionArr[1]} ${descriptionArr[0]} with ${descriptionArr[3]} at ${descriptionArr[2]}`}
                                        </span>
                                      </div>
                                    </div>
                                    <div className="footer-wrap">
                                      <button
                                        onClick={() =>
                                          this.setState({
                                            showCalDetails: false,
                                          })
                                        }
                                        class="ui button reschedule-btn"
                                      >
                                        Close
                                      </button>
                                    </div>
                                  </div>
                                </>
                              )}
                            </Grid>
                          </div>
                        </div>
                      </>
                    )}
                  </Card.Content>
                </Card.Content>
              </Card>
            </Card.Group>
            <Card.Group
              itemsPerRow={2}
              stackable
              className="graph-data-wrapper"
            >
            </Card.Group>
            <div className=" dashboard-fifth-step">

              <AccountingGraphs dashboard={true} selectedPrimary={this.state.selectedPrimary} />
            </div>
            <Tour
              steps={dashboardSteps}
              isOpen={this.state.isTourOpen}
              onRequestClose={() => this.setState({ isTourOpen: false })}
              className="helper"
              startAt={0}
              rounded={5}
            />
          </>
        )}
      </span>
    );
  }
}

export default withApollo(Dashboard);