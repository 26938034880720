import React from "react";
import { Button, Header, Modal } from "semantic-ui-react";
import { getClient } from "../../../init-apollo-googleFn";
import { getLocationType } from "../../../store/person/properties";
import GoogleMapReact from "google-map-react";
import { LOCATIONAPI, LOCATION_TYPE_API } from "../../../utils";
import { parseGraphQLErrors, toastFailMsg } from "../../../utils/common";
import { MAP_DEFAULT_CENTER } from "../../../utils/constants";
import { MapMarker } from "../../GoogleMaps/MapMarker";
import { useForm } from "react-hook-form";
import PropertyAddress from "../AddProperty/PropertyAddress";
import { getAuthToken, getCookie } from "../../../store/auth/authUtility";
import { handleMangerLogin } from "../../../store/auth/loginAction";

const locationTypeClient = getClient(LOCATION_TYPE_API);

const PropertyTypeSelection = ({ isOpen, onClose, onSelect }) => {
  const [propertyTypes, setPropertyTypes] = React.useState([]);
  const [selected, setSelected] = React.useState();
  const [loads, setLoads] = React.useState(false);
  const [propertyName, setPropertyName] = React.useState();
  const [changeView, onChangeView] = React.useState(true);
  const [addressDetails, setAddressDetails] = React.useState();
  const [lat, setLat] = React.useState();
  const [lng, setLng] = React.useState();

  const fetchPropertyTypes = () => {
    locationTypeClient
      .query({ query: getLocationType })
      .then((response) => {
        if (response.data.locationType) {
          setPropertyTypes(response.data.locationType.edges || []);
        }
      })
      .catch((error) => toastFailMsg(parseGraphQLErrors(error)));
  };

  React.useEffect(() => {
    fetchPropertyTypes();
  }, [isOpen]);

  const formMethods = useForm({ mode: "onChange" });
  const { setValue, control } = formMethods;


  const savePropertyInfo = () => {
    setLoads(true)
    const formData = new FormData();
    formData.append(
      "query",
      `mutation ${"CreateLocation"}{
        ${"createLocation"}(location: {
            name: "${propertyName}",
            customId:"${propertyName}",
            locationTypeId : "${selected}",
            organizationId:${getCookie().claims}
            googlePlaceId: "${addressDetails.googlePlaceId}",
              addresses: [
                {
                  streetOne: "${addressDetails.streetOne}",
                  city: "${addressDetails.city}",
                  country: "${addressDetails.country}",
                  state: "${addressDetails.state}",
                  zip: "${addressDetails.zip}"
                }
              ]
          }){
            response
            location{
              id
              organizationId
            }
          }
        }`
    );
    const requestOptions = {
      method: "POST",
      headers: {
        Authorization: getAuthToken(),
      },
      body: formData,
    };

    fetch(LOCATIONAPI, requestOptions)
      .then((response) => response.json())
      .then(async (result) => {
        setLoads(false)
        onSelect(selected, result.data.createLocation.location)
        handleMangerLogin()
      })
      .catch((error) => {
        setLoads(false)
        toastFailMsg(parseGraphQLErrors(error))
      }
      )
  };
  return (
    <Modal
      className="semanticModal custom-modal amenitie-modal add-property-modal"
      onClose={onClose}
      closeIcon
      open={isOpen}
      size="mini"
      closeOnEscape={false}
      closeOnDimmerClick={false}
    >
      <Modal.Header>
        <Header>Let&apos;s get started</Header>
        <p>
          We&apos;re as excited as you to get your property listed with REM!
        </p>
        <p>
          We&apos;ll walk you through what you need to. To get started, we need
          to know what kind of property you are adding.
        </p>
      </Modal.Header>
      <Modal.Content>
        {changeView ?
          <>
            {propertyTypes.map(({ node }) => {
              const iconClasses = {
                Apartment: "apartment-rw",
                Condo: "condo-rw",
                House: "house-rw",
                Office: "office-rw",
                "Multi-Family": "multi-rw",
              };
              return (
                <h4
                  key={node.id}
                  onClick={() => setSelected(node.id)}
                  className={`p-3 white-block ${iconClasses[node.type]}`}
                  style={{
                    color: selected === node.id ? "#3b1c5a" : "#000",
                    border:
                      selected === node.id
                        ? "1px solid rgba(59, 28, 90, 0.15)"
                        : "none",
                    backgroundColor: selected === node.id ? "#f0eef2" : "#fff",
                  }}
                >
                  {node.type}
                </h4>
              );
            })}
            <Button
              type="button"
              className="btn btn-primary w-100"
              disabled={!selected}
              onClick={() => onChangeView(false)}
            >
              Proceed
            </Button>
          </> :
          <>
            <div className="form-group">
              <div className="has-float-label">
                <input
                  className="form-control"
                  id="name"
                  name="name"
                  type="text"
                  placeholder="Property Name"
                  autoComplete="off"
                  onChange={(e) => setPropertyName(e.target.value)}
                />
                <label htmlFor="name">Property Name</label>
              </div>
            </div>
            <div className="form-group">
              <div className="has-float-label">
                <PropertyAddress
                  control={control}
                  setValue={setValue}
                  setLat={setLat}
                  setLng={setLng}
                  setAddressDetails={setAddressDetails}
                />
              </div>
            </div>
            <div className="map-container step4">
              <div
                className="map"
                style={{ height: "300px", width: "400px" }}
              >
                <GoogleMapReact
                  bootstrapURLKeys={{
                    key: process.env.REACT_APP_GMAP_API_KEY,
                  }}
                  defaultCenter={MAP_DEFAULT_CENTER}
                  center={{ lat, lng }}
                  defaultZoom={13}
                >
                  <MapMarker lat={lat} lng={lng} />
                </GoogleMapReact>
              </div>
            </div>
            <Button
              type="button"
              className="btn btn-primary w-100"
              style={{maxHeight:'50px', maxWidth:"400px"}}
              disabled={!lat || !lng || !propertyName || loads}
              loading={loads}
              onClick={() => savePropertyInfo()}
            >
              Draft Property
            </Button>
          </>
        }
      </Modal.Content>
    </Modal>
  );
};

export default PropertyTypeSelection;
