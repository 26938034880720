import React from "react";
import { Form } from "semantic-ui-react";

const placesAutoCompleteData = (
  getInputProps,
  suggestions,
  getSuggestionItemProps
) => (
  <div className="pets-col">
    <Form.Input
      type="text"
      required
      label="Street Line 1"
      fluid
      placeholder="Street Line 1"
      {...getInputProps({
        className: "form-control border-0 py-0 px-2 height-38 margin-top-2",
      })}
    />
    {}
    <div
      className="autocomplete-dropdown-container"
      style={{
        zIndex: "10",
        position: "absolute",
        background: "white",
        border: "2px solid #F2FCFB",
        borderRadius: "5px",
      }}
    >
      {suggestions.map((suggestion) => {
        const className = suggestion.active
          ? "suggestion-item--active"
          : "suggestion-item";
        // inline style for demonstration purpose
        const style = suggestion.active
          ? { backgroundColor: "#4C158A", cursor: "pointer" }
          : { backgroundColor: "#ffffff", cursor: "pointer" };
        return (
          <div
            style={{ position: "absolute" }}
            {...getSuggestionItemProps(suggestion, {
              className,
              style,
            })}
          >
            <span>{suggestion.description}</span>
          </div>
        );
      })}
    </div>
  </div>
);

export default placesAutoCompleteData;
