import React, { Component } from "react";
export const exploreSteps = [
    {
        selector: '.explore-first-step',
        content: 'The Explore page is where you will be able to catch up on new developments and get started with our service.',
    },
    {
        selector: '.explore-second-step',
        content: 'This is where all your news will occur. Any new exciting features we want to bring to your attention will be shown here.',
    },
    {
        selector: '.explore-third-step',
        content: 'Quick add your property here. This will bring you to the Properties page where you can begin the setup of a new property.',
    },
    {
        selector: '.explore-fourth-step',
        content: 'As we steadily build out our features we will be including more descriptive additions to our website here. Major site overhauls and guides will be located in this section',
    },
]
export const dashboardSteps = [
    {
        selector: '.dashboard-first-step',
        content: 'The purpose of the Dashboard is to give you a homepage to come back to. This is where you can branch out to any place on our website.',
    },
    {
        selector: '.dashboard-second-step',
        content: 'This is where all your high level alerts will be located.  The quick info you need will always be displayed and allow you to click the the relevant page.',
    },
    {
        selector: '.dashboard-third-step',
        content: 'Here you will find you current leases for the selected property. You can break it down by what lease is coming to a close in the next 90 days and take action. By clicking the "Eye" icon you can navigate to their page.',
    },
    {
        selector: '.dashboard-fourth-step',
        content: 'In this section you will be able to see your current schedule and your checklist. You schedule will show you what you have upcoming on the calendar. Your checklist is a daily run through of tasks you might have for yourself.',
    },
    {
        selector: '.dashboard-fifth-step',
        content: 'Here you have the high level financial graphs you may need to look into at a glance.  We collect all data from a yearly perspective and display them to you.',
    },

]
export const leadsSteps = [
    {
        selector: '.leads-first-step',
        content: 'Leads is where you can take a look at all consolidated interested parties for your property.',
    },
    {
        selector: '.leads-second-step',
        content: 'Here you have your active leads and waitlisted leads. These will remain open until the status is changed and they have moved down the pipeline or off of it.',
    },
    {
        selector: '.leads-third-step',
        content: 'Here we let you know where those leads are coming from. This allows you to better optimize your sources and reach the right people.',
    },
    {
        selector: '.leads-fourth-step',
        content: 'This gives you a quick glance of the leads generated in the last 7 days.  It allows you to check out which days were the most impactful.',
    },
    {
        selector: '.leads-fifth-step',
        content: () => (
            <div>
                If you have a <span style={{ textDecoration: 'underline' }}>walk in</span> Lead you can add them through this button.
            </div>
        )
    },
    {
        selector: '.leads-sixth-step',
        content: 'This is where all current leads will be listed and can be sorted. If you wish to view them or move them through the pipeline then press the "Eye" button.',
    },
]
export const applicationSteps = [
    {
        selector: '.application-first-step',
        content: 'This is where you applications live. Its the next step after an interested Lead.',
    },
    {
        selector: '.application-second-step',
        content: 'Here you will find a quick break down of Applications that need attention and ones that are currently in progress.',
    },
    {
        selector: '.application-third-step',
        content: 'This gives you a quick glance of the applications generated in the last 7 days.  It allows you to check out which days were the most impactful.',
    },
    {
        selector: '.application-fourth-step',
        content: () => (
            <div>
                If you have a <span style={{ textDecoration: 'underline' }}>walk in</span> Applicant you can add them through this button.
            </div>
        )
    },
    {
        selector: '.application-fifth-step',
        content: 'This is where all current applications will be listed and can be sorted. If you wish to view them or move them through the pipeline then press the "Eye" button.',
    },

]
export const leasesSteps = [

    {
        selector: '.leases-first-step',
        content: 'Leases is for the applicants you have decided to move forward. On this page you will be able to move them all the way through to Resident.',
    },
    {
        selector: '.leases-second-step',
        content: 'This is a key for each step in the Leasing process. Each step is clickable to filter what you need and comes with a tooltip on the purpose of each step.',
    },
    {
        selector: '.leases-third-step',
        content: 'If you wish to bulk edit Leases select the checkbox and this button will light up.',
    },
    {
        selector: '.leases-fourth-step',
        content: 'Here you will see the list of Leases you have in process. The status will help guide you on where they are in the process. You can click the "Eye" to view and the "Pencil" to edit the lease. If there is a button in the action column then clicking it will allow a status change.',
    },
]
export const maintenanceSteps = [
    {
        selector: '.maintenance-first-step',
        content: 'Maintenance requests externally and internally will be shown here. You can view resident and manager submitted requests.',
    },
    {
        selector: '.maintenance-second-step',
        content: 'Large buildings can have many maintenance requests. Filter out what you are looking for through this search bar and dropdown.',
    },
    {
        selector: '.maintenance-third-step',
        content: 'Maintenance Invite allows you to invite your in-house maintenance professional to the platform.',
    },
    {
        selector: '.maintenance-fourth-step',
        content: 'If you need to make a maintenance request through the Manager portal this is where you will be able to. It will ask you for basic information as well as offer picture uploads.',
    },
    {
        selector: '.maintenance-fifth-step',
        content: 'Here you will see the list of all Maintenance requests for the selected property. Cliking View allows for a deep dive into the request. Actions bring you to the Work Order modal where you can turn it into an actionable item for a maintenance professional.',
    },

]
export const residentsSteps = [
    {
        selector: '.residents-first-step',
        content: 'Current residents are located on this page. Users that are currently in your system and can actively pay rent can be found here.',
    },
    {
        selector: '.residents-second-step',
        content: 'Large buildings can have many Residents. Filter out what you are looking for through this search bar and dropdown.',
    },
    {
        selector: '.residents-third-step',
        content: 'Here you can find a quick glance of and key for how many residents have actived their account, setup autopay, and paid their current invoice.',
    },
    {
        selector: '.residents-fourth-step',
        content: 'If you know you have a resident who is not yet on the platform you can invite them. This will allow you to send an invite link to their email.',
    },
    {
        selector: '.residents-fifth-step',
        content: 'Here you will see the list of Residents you have.  This will give you an idea of their rent and their statuses. Clicking the "Eye" button will let you look into their user profile more.',
    },

]
export const fileManagerSteps = [
    {
        selector: '.fileManager-first-step',
        content: 'File Manager is where you can steadily grow your portfolio of saved documents and images.',
    },
    {
        selector: '.fileManager-second-step',
        content: 'This is a breakdown of general categories of the saved Files you have. Maintenance photos, Leases and others are all saved here.',
    },
    {
        selector: '.fileManager-third-step',
        content: 'This is the subfolder section',
    },

]
export const loyaltySteps = [
    {
        selector: '.loyalty-first-step',
        content: 'Loyalty is what powers our platform and gives both you and renter the tools to succeed. Points can be used on a variety of services.',
    },
    {
        selector: '.loyalty-second-step',
        content: 'This is your points wallet. You can gift these points to renter or perspective applicants. Points allow the user to empower their purching through our partners.',
    },
    {
        selector: '.loyalty-third-step',
        content: 'Here you have your points history. The red signifies points spent (giving gifts) and the green signifies points gained (adding to your wallet). You can see a breakdown of when, what, how much, and any message tied to it here.',
    },
    {
        selector: '.loyalty-fourth-step',
        content: 'Buy loyalty packages here to add to your wallet. You can use the walleted points to gift to prospective clients.',
    },

]

export const scheduleSteps = [
    {
        selector: '.schedule-first-step',
        content: 'Schedule is broken down into month and day view',
    },
    {
        selector: '.schedule-second-step',
        content: 'This is your calendar and it allows you to see what events you have coming up. By clicking the date you will be taken to a schedule screen with a breakdown of times and what sort of event it is.',
    },

]
export const pushSteps = [
    {
        selector: '.push-first-step',
        content: 'Push notifications allow you to directly send reminders to your residents. These are one way messages that allow you to send to all residents on the selected property.',
    },
    {
        selector: '.push-second-step',
        content: 'Include the title of the message you want to send along with the body message. This is the primary text the user will see.',
    },
    {
        selector: '.push-third-step',
        content: 'Selected what location you wish to send this push notification to. All users at this location will be alerted.',
    },
    {
        selector: '.push-fourth-step',
        content: 'Select the platform you wish to send it out to. You can select Web, IOS, or Android.',
    },
    {
        selector: '.push-fifth-step',
        content: 'If you wish to include an image or a link to something external you can do so here.',
    },
]
export const propertiesSteps = [
    {
        selector: '.properties-first-step',
        content: 'Properties page is where all your properties are located. You can choose to adjust any settings you need and add more properties here.',
    },
    {
        selector: '.properties-second-step',
        content: 'Toggle between Published properties and properties you have in Draft mode. Published properties are considered live, while Draft properties are still being built out and are invisible to prospects or renters.',
    },
    {
        selector: '.properties-third-step',
        content: 'If you wish to add a new property to the platform you can do so here. This will lead you to the property walkthrough where you can setup and adjust any settings you need.',
    },
    {
        selector: '.properties-fourth-step',
        content: 'These are your current properties. At a glance you will be able to see all needed details.',
    },
    {
        selector: '.properties-fourth-step',
        content: 'From this tile you can Take Action, Visit Microsite, View or Deactivate your property. You are also able to see all at a glance info on the card.',
    },

]
export const messagesSteps = [
    {
        selector: '.messages-first-step',
        content: 'This is where your messages are collected and responded to. Users who are on your platform, renters and managers, can interact through this page. ',
    },
    {
        selector: '.messages-second-step',
        content: 'To start a new conversation you can click this button. By clicking this button you are able to select which user to start a discussion with.',
    },
    {
        selector: '.messages-third-step',
        content: 'Here you can find your current discussions.',
    },
    {
        selector: '.messages-fourth-step',
        content: 'This is where the text of the discussion will take place. You have the option of how you wish to interact with the user. Either In-App or Text messaging can be used.',
    },
]
export const settingsSteps = [
    {
        selector: '.settings-first-step',
        content: 'This is where the property wide settings will be location. This is mostly settings that arent property specific but still valuable to the user.',
    },
    {
        selector: '.settings-second-step',
        content: 'Here you will see the modal to create Tour Types. This lets you adjust what sort of tours both you can see and what the renter can see through external sources.',
    },
    // {
    //     selector: '.settings-third-step',
    //     content: 'This is where you can adjust the payout method and bank information. For those with the right credentials you can enable those settings here.',
    // },

]
export const accountingSteps = [
    {
        selector: '.accounting-first-step',
        content: 'For new accounting news or things we think you should know we will send messages here. ',
    },
    {
        selector: '.accounting-second-step',
        content: 'This section gives you an at a glance of your total assets. We break it down through both last 30 days as well as last 365 days.',
    },
    {
        selector: '.accounting-third-step',
        content: 'The top section here is devoted to Income and Expenses. The income graph is gathering both the last 30 days as well as a breakdown of the current year. The Expenses graph is comparing both expenses and income against each other. ',
    },
    {
        selector: '.accounting-fourth-step',
        content: 'The bottom section is just Expenses alone as well as Invoices. Invoices is the totalled value of the Invoices received by month.',
    },

]