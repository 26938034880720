import React, {useEffect} from "react";
import { Button, Modal } from "semantic-ui-react";
import LeaLoader from "../../PageLoader/LeaLoader";
import { US_STATES, WEPAY_URL } from "../../../utils/constants";
import * as yup from "yup";
import { useFormik } from "formik";
import "./LeaseModal.scss";
import { CreatePaymentMethodGql, wepayMerchantGql } from "../../../store/wepay/wepay";
import { getClient } from "../../../init-apollo-googleFn";

const wePayClient = getClient(WEPAY_URL);

const validationSchema = yup.object().shape({
  account: yup.string().min(5, "Account can be minimum 5 digits").required(),
  name: yup.string().required(),
  zip: yup.string().required(),
  routing: yup.string().required(), // cannot be 9 character. more or less ok
  zip: yup.string().min(5, "Zip code should be minimum 5 digits").required(),
  city: yup.string().required(),
  type: yup.string().oneOf(["personal", "business"]).required(),
  achType: yup.string().oneOf(["checking", "saving"]).required(),
  address1: yup.string().required(),
  state: yup.string().required(),
  nickname: yup.string().required(),
  preferredPayment: yup.boolean().oneOf([false, true]).required(),
});

const initialValues = {
  routing: " ",
  account: " ",
  name: " ",
  zip: " ",
  city: " ",
  type: "personal",
  achType: "checking",
  address1: " ",
  state: " ",
  nickname: " ",
  preferredPayment: false,
};

export function AddBankAccountMethod(props) {
  const [process, setProcess] = React.useState(false);
  useEffect(() => {
    fetchMerchants();
  }, []);
// Is this being used?
  const fetchMerchants = async () => {
    try {
      const res = await wePayClient.query({
        query: wepayMerchantGql,
        variables: {
          locationId:props.locationId
        },
        fetchPolicy: "no-cache",
      });
    } catch (error) {
      console.log(error);
    }
  };

  const createBankAccountMethod = async (values, options) => {
    setProcess(true);
    try {
      const {
        achType,
        type,
        routing,
        name,
        account,
        address1,
        city,
        state,
        zip,
        nickname,
        preferredPayment,
      } = values;
      const res = await wePayClient.mutate({
        mutation: CreatePaymentMethodGql,
        variables: {
          paymentMethod: {
            payoutMethod: true,
            type: "bank",
            locationId: props.locationId,
            nickname,
            bank: {
              achType: achType,
              accountType: type,
              routingNumber: routing,
              nameOnAccount: name,
              accountNumber: account,
              addressLine1: address1,
              addressLine2: "",
              city,
              stateProvince: state,
              zipPostalCode: zip,
              country: "US",
              driversLicenseNumber: "",
              accountHolderYOB: "1990",
              paymentMethodIdentifier: "",
              preferredPayment,
              paymentStatus: "active",
            },
          },
        },
      });

      if (res?.data?.createPaymentMethod?.response === "200") {
        props.toggleIsShowing();
      } else {
        console.log(res);
      }
    } catch (error) {
      console.log(error);
      if (error.message.includes("Already Exists")) {
        //exist
      } else {
        //error
      }
    } finally {
      setProcess(false);
      if (props?.fetchPaymentMethods) props.fetchPaymentMethods();
    }
  };

  const { handleSubmit, values, errors, touched, setFieldValue } = useFormik({
    validationSchema,
    initialValues,
    onSubmit: createBankAccountMethod,
  });

  const {
    routing,
    name,
    account,
    zip,
    city,
    address1,
    state,
    type,
    achType,
    nickname,
    preferredPayment,
  } = values;

  return (
    <Modal
      open={props.showModal}
      onClose={() => props.toggleIsShowing(false)}
      className="semanticModal addbank-account-modal"
    >
      <Modal.Header>
        Add Bank Account
        <img
          src="/assets/img/close-modal.svg"
          id="close"
          alt="close"
          onClick={() => props.toggleIsShowing(false)}
        ></img>
      </Modal.Header>
      <Modal.Content>
        <Modal.Description>
          <div className="form-group">
            <label>Name on account</label>
            <input
              type="text"
              onChange={(event) => {
                setFieldValue("name", event.target.value);
              }}
              name="name"
              id="name"
              value={name}
              className="form-control"
            ></input>
          </div>
          <div className="form-group">
            <label>Bank name</label>
            <input
              onChange={(event) => {
                setFieldValue("nickname", event.target.value);
              }}
              type="text"
              id="nickname"
              name="nickname"
              value={nickname}
              className="form-control"
            ></input>
          </div>
          <div className="form-group">
            <label>Account Number</label>
            <input
              id="account"
              onChange={(event) => {
                setFieldValue("account", event.target.value);
              }}
              type="number"
              name="account"
              value={account}
              className="form-control"
            ></input>
          </div>
          <div className="form-group">
            <label>Routing Number</label>
            <input
              onChange={(event) => {
                setFieldValue("routing", event.target.value);
              }}
              type="number"
              id="routing"
              name="routing"
              value={routing}
              className="form-control"
            ></input>
          </div>
          <div className="form-group" style={{height:'100px'}}>
            <label>Usage Type</label>
            <br></br>
            <select
              style={{ border: "none", width: "100%" }}
              id="type"
              name="type"
              defaultValue={type}
              onChange={(event) => setFieldValue("type", event.target.value)}
            >
              <option value="personal">Personal</option>
              <option value="business">Business</option>
            </select>
          </div>
          <div className="form-group" style={{height:'100px'}}>
            <label>Account Type</label>
            <br></br>
            <select
              style={{ border: "none", width: "100%" }}
              id="accType"
              name="accType"
              defaultValue={achType}
              onChange={(event) => setFieldValue("achType", event.target.value)}
            >
              <option value="checking">Checking</option>
              <option value="saving">Saving</option>
            </select>
          </div>
          <div className="form-group" style={{height:'100px'}}>
            <label>Preferred Payment</label>
            <br></br>
            <select
              style={{ border: "none", width: "100%" }}
              id="preferredPayment"
              name="preferredPayment"
              defaultValue={preferredPayment ? "yes" : "no"}
              onChange={(event) =>
                setFieldValue("preferredPayment", event.target.value === "yes")
              }
            >
              <option value="yes">Yes</option>
              <option value="no">No</option>
            </select>
          </div>
          <div className="form-rw">
            <div className="form-group" style={{height:'100px'}}>
              <label>Billing Address Line</label>
              <input
                type="text"
                id="address1"
                value={address1}
                onChange={(event) => {
                  setFieldValue("address1", event.target.value);
                }}
                name="address1"
                className="form-control"
              />
            </div>
            <div className="form-group" style={{height:'100px'}}>
              <label htmlFor="state">State</label>
              <select
                style={{ border: "none", width: "100%" }}
                id="state"
                name="state"
                defaultValue={state}
                onChange={(event) => setFieldValue("state", event.target.value)}
              >
                {Object.keys(US_STATES).map((key) => {
                  return <option value={key}>{key}</option>;
                })}
              </select>
            </div>
          </div>

          <div className="form-rw">
            <div className="form-group">
              <label>Billing City</label>
              <input
                type="text"
                id="city"
                onChange={(event) => {
                  setFieldValue("city", event.target.value);
                }}
                name="city"
                value={city}
                className="form-control"
              ></input>
            </div>
            <div className="form-group">
              <label>Billing Address ZIP Code</label>
              <input
                type="text"
                onChange={(event) => {
                  setFieldValue("zip", event.target.value);
                }}
                name="zip"
                id="zip"
                value={zip}
                className="form-control"
              ></input>
            </div>
          </div>
        </Modal.Description>
      </Modal.Content>
      <Modal.Actions>
        <Button
          color="black"
          disabled={Object.keys(errors).length > 0 || process}
          type="button"
          onClick={handleSubmit}
        >
          {process ? (
            <LeaLoader
              wrapperStyle={{
                position: "relative",
                left: "-10px",
                bottom: "16px",
              }}
              logoStyle={{ height: "60px", width: "60px" }}
            />
          ) : (
            "Add Bank Account"
          )}
        </Button>
      </Modal.Actions>
    </Modal>
  );
}

export default AddBankAccountMethod;
