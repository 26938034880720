import { startCase } from "lodash";
import React, { useState } from "react";
import { decodeBase64ID } from "../../../utils";
import { pluralize } from "../../../utils/common";
import { Checkbox, Loader } from "semantic-ui-react";

const UnitCard = ({ unit, unitImg, onClick, getStatus, setStatus }) => {
  const [loading, setLoading] = useState(false);

  const onChange = async () => {
    setLoading(true);
    await setStatus(decodeBase64ID(unit.node.id), !getStatus);
    setLoading(false);
  };

  const unitStatus = React.useMemo(() => {
    let text = startCase(unit.node.status).replace(" ", "");
    if (unit.node.status === "off_the_market") text = "Off";
    if (unit.node.status === "signed") text = "Created";
    return text;
  }, [unit.node.status]);
  return (
    <div className="small-card-block cursor-pointer">
      <div className="top-chip" onClick={onClick}>
        {unitStatus}
      </div>
      <div className="small-switch">
        {/* <div className="toggle-loading-container">
          {loading ? (
            <Loader active inline />
          ) : (
            <Checkbox toggle checked={getStatus} onChange={onChange} />
          )}
        </div> */}
      </div>
      <div className="img-container" onClick={onClick}>
        <img src={unitImg || "assets/img/renter-bg.jpg"} alt="unit-img" />
      </div>
      <div onClick={onClick}>
        <p className="normal-text">{`Unit No: ${unit.node.number}`}</p>
        <p className="bold-text">{`$${unit.node.price.toLocaleString()} /month`}</p>
        <div className="small-text">
          <span>{`${Number(unit.node.bedrooms)} ${pluralize(
            unit.bedrooms,
            "Bed"
          )}`}</span>{" "}
          <div className="dot" />
          <span>{`${Number(unit.node.bathrooms)} ${pluralize(
            unit.bathrooms,
            "Bath"
          )}`}</span>{" "}
          <div className="dot" /> <span>{`${Number(unit.node.sqft)} sq. ft.`}</span>
        </div>
      </div>
    </div>
  );
};

export default UnitCard;
