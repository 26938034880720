import React, { useEffect, useState, useReducer } from "react";
import moment from "moment";
import { Button, Icon, Modal } from "semantic-ui-react";
import InvoicesModal from "../../../components/Modals/InvoicesModal";
import EditAmount from "./subComponents/ChangeChargeAmount";

function Charges({ tenantCharges, props, application, fetchLeaseCharges,tenant, leaseID }) {
  
  return (
    <>
      <div className="row">
        <div className="col-md-12">
          <div className="theme-card card">
            <div className="card-header">
              <div className="card-header-title">Tenant Charges <InvoicesModal
              fromResidents={true}
              user={props.user}
              application={application.primaryApplicant}
              applicationId={application.id}
              tenant={tenant}
              leaseID={leaseID}
              propertyId={
              props.selectedPrimary.node &&
              props.selectedPrimary.node.id
            }
            /></div>
            </div>
            <div className="card-body pb-2">
              <div className="payment-table-header">
                <div className="table-header-label-tenant">Name</div>
                <div className="table-header-label-tenant">Description</div>
                <div className="table-header-label-tenant">Start Date</div>
                <div className="table-header-label-tenant">Frequency</div>
                <div className="table-header-label-tenant">Amount</div>
              </div>
              <div className="payment-table-body">
                {/* div to be repeated */}
                {tenantCharges &&
                  tenantCharges.map((item) => {
                    const frequency = JSON.parse(item.node.schedulePattern)
                    return (
                      <div className="payment-table-row" >
                        <div className="table-col">
                            {item.node.chargeCode&&item.node.chargeCode.name}
                          {/* <div className="payment-property-subtitle">11311 19th Ave SE, 98208, US</div> */}
                        </div>
                        <div className="table-col">
                          {item.node.chargeCode&&item.node.chargeCode.desc}
                        </div>
                        <div className="table-col">{frequency.start_date}</div>
                        <div className="table-col">{frequency.frequency[0]}</div>
                        <div className="table-col">$ {item.node.amount} <EditAmount tenant={tenant} fetchLeaseCharges={fetchLeaseCharges} id={item.node.chargeCode.id} date={frequency.start_date} amount={item.node.amount} chargeCode={item.node.chargeCode.name}/></div>
                      </div>
                    );
                  })}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Charges;
