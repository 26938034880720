/* eslint-disable */
import React, { useEffect, useState } from "react";
import { Button, Label, Modal, Header, List } from "semantic-ui-react";
import {
  leaseHistoryGql,
  LeaseCharges,
  updateBulkEdit,
} from "../../store/person/leases";
import { pmSingleApplicationGql } from "../../store/person/applications";
import {
  transactionsGql,
  ConcessionsAmountRenter, LeaseMetaData
} from "../../store/person/accounting";
import { getClient } from "../../init-apollo-googleFn";
// Components
import EmergencyContact from "./Components/EmergencyContact";
import Employment from "./Components/Employment";
import ProfileCard from "./Components/ProfileCard";
import LeaseHistory from "./Components/LeaseHistory";
import Payments from "./Components/Payments";
import Charges from "./Components/Charges";
import PetDetails from "./Components/PetDetails";
import VehicleDetails from "./Components/VehicleDetails";
import CoApplicant from "./Components/CoApplicant";
import Occupants from "./Components/Occupants";
import ChatLogs from "./Components/ChatLogs";
import { toastFailMsg, toastSuccessMsg } from "../../utils/common";
import {
  LEASE_MANAGER,
  APPLICATION_MANAGER,
  ROUTES,
  ACCOUNTING,
  CHAT_URL,
} from "../../utils";
import { PageLoader } from "../../components/Loader/PageLoader";
import userIcon from "../../assets/images/user-icon.svg";
import backIcon from "../../assets/images/back-arrow.svg";
import GiftPointsModal from "../../components/Modals/GiftPointsModal";
import ConcessionsModal from "../../components/Modals/ConcessionModal";
import MoveInChecklist from "../../components/Modals/MoveInChecklist";
import mixpanel from "mixpanel-browser";
import RentalHistory from "./Components/RentalHistory";
import { getChatLogsByApplicationId } from "../../store/chat/chat";

const leaseManager = getClient(LEASE_MANAGER);
const accountingManager = getClient(ACCOUNTING);
const applicationManager = getClient(APPLICATION_MANAGER);
const chatApi = getClient(CHAT_URL);

function TenantDetail(props) {
  const [tenant, setTenant] = useState(null);
  const [application, setApplication] = useState(null);

  const [theChecklist, setChecklist] = React.useState(false)
  const [open, setOpen] = React.useState(false);
  const [isLoad, setIsLoad] = React.useState(false);
  const [showResults, setShowResults] = React.useState(false);
  const [concessions, setConcessions] = React.useState(0);
  const [chatLogs, setChatLogs] = useState(null);
  const [chatLogsLoading, setChatLogsLoading] = useState(false);
  const [primaryApplicant, setPrimaryApplicant] = useState(null);
  const [tenantResponse, setTenantResponse] = useState(false);
  const [personResponse, setPersonResponse] = useState(false);
  const [tenantPayments, setTenantPayments] = useState([]);
  const [tenantCharges, setTenantCharges] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [leaseID, setLease] = useState(true);
  const [leaseHistoryData, setLeaseHistoryData] = useState([]);

  const fetchLeaseDetails = () => {
    const leaseId = props.match.params.tenantId.slice(
      1,
      props.match.params.tenantId.length
    );
    leaseManager
      .query({
        query: leaseHistoryGql,
        variables: { leaseId },
      })
      .then((response) => {
        setLease(leaseId);
        setLeaseHistoryData(response.data.leaseHistory.edges);

        setTenant(response.data.leaseHistory.edges[0].node);
        const value = response.data.leaseHistory.edges[0].node
        if (value && value.moveInChecklist == true) {
          fetchLeaseChecklist()
        }
        localStorage.setItem(
          "tenantData",
          JSON.stringify(response.data.leaseHistory.edges[0].node)
        );
        setTenantResponse(true);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const fetchLeaseConcessions = (e) => {
    leaseManager
      .query({
        query: ConcessionsAmountRenter,
        variables: {
          renterId: e && e.primaryApplicant && e.primaryApplicant.email
        },
      })
      .then((response) => {
        const conc = response.data.concessionsAmountRenter && response.data.concessionsAmountRenter.edges[0] && response.data.concessionsAmountRenter.edges[0].node
        setConcessions(conc)
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const fetchLeaseChecklist = (e) => {
    const leaseId = props.match.params.tenantId.slice(
      1,
      props.match.params.tenantId.length
    );
    leaseManager
      .query({
        query: LeaseMetaData,
        variables: {
          leaseId: leaseId
        },
      })
      .then((response) => {
        const checklist = response.data.leaseMetaData.edges[0].node
        setChecklist(checklist)
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const fetchLeaseCharges = () => {
    const leaseId = props.match.params.tenantId.slice(
      1,
      props.match.params.tenantId.length
    );
    leaseManager
      .query({
        query: LeaseCharges,
        variables: { leaseId },
      })
      .then((response) => {
        const payments = response.data.leaseCharges.edges;
        setTenantCharges(payments);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleMoveOutSubmit = (e) => {
    setIsLoad(true);
    try {
      mixpanel.track("Manager Lease Action", {
        sub: "Move Out Lease",
      });
      leaseManager
        .mutate({
          mutation: updateBulkEdit,
          variables: {
            input: {
              lease: {
                leaseIds: [leaseID],
                status: "MoveOut",
              },
            },
          },
        })
        .then((res) => {
          toastSuccessMsg("Tenant Moved Out");
        })
        .catch((error) => {
          toastFailMsg("Something Went Wrong");
        })
        .finally(() => {
          setTimeout(() => {
            window.location.href = "/#/residents";
          }, 1000);
          setIsLoad(false);
          setOpen(false);
        });
    } catch (e) {
      console.log(e);
    }
  };

  const fetchPayments = (id) => {
    const leaseId = props.match.params.tenantId.slice(
      1,
      props.match.params.tenantId.length
    );
    console.log(id)
    accountingManager
      .query({
        query: transactionsGql,
        variables: {
          location: id.locationId,
          email: id.primaryApplicant.email,
          limit: 999999,
          offset: 0,
        },
      })
      .then((response) => {
        const payments = response.data.slLocationTransactions.data;
        setTenantPayments(payments);
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        console.log(err);
      });
  };

  const fetchPersonDetails = () => {
    const applicationId = props.match.params.applicationId.slice(
      1,
      props.match.params.applicationId.length
    );
    applicationManager
      .query({
        query: pmSingleApplicationGql,
        variables: { applicationId },
      })
      .then((response) => {
        setApplication(response.data.application.edges[0].node);
        setPrimaryApplicant(
          response.data.application.edges[0].node.primaryApplicant
        );
        fetchLeaseConcessions(response.data.application.edges[0].node);
        const id = response.data.application.edges[0].node;
        fetchPayments(id);
        setPersonResponse(true);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const fetchChatLogs = async () => {
    setChatLogsLoading(true);
    const applicationId = props.match.params.applicationId.slice(1);
    try {
      const logs = await chatApi.query({
        query: getChatLogsByApplicationId,
        variables: { applicationId },
      });
      if (logs.data.getChatRoomLogsManagerTenantDetail && logs.data.getChatRoomLogsManagerTenantDetail.edges) {
        setChatLogs(logs.data.getChatRoomLogsManagerTenantDetail.edges);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setChatLogsLoading(false);
    }
  };

  const createdAtFormat = "DD-MM-YYYY";
  const leaseDate = new Date();
  const createdAtDate =
    tenant !== null && moment(leaseDate).format(createdAtFormat);

  useEffect(() => {
    fetchLeaseDetails();
    fetchPersonDetails();
    fetchChatLogs();
    fetchLeaseCharges();
    mixpanel.track("Manager Page Load", {
      ndbId: props.user.ndbId,
      sub: "Tenant Detail",
    });
  }, []);

  useEffect(() => {
    if (personResponse && tenantResponse) {
      setIsLoading(false);
    }
  }, [personResponse, tenantResponse]);

  const navigateToLeaseRenewal = () => {
    const leaseId = props.match.params.tenantId.slice(
      1,
      props.match.params.tenantId.length
    );
    props.history.push({
      pathname: `${ROUTES.leaseRenewal}/leaseId:${leaseId}`,
      state: tenant,
    });
  };

  const redirectToPushNotification = React.useCallback(() => {
    props.history.push({
      pathname: `${ROUTES.pushNotification}/person/${primaryApplicant.id}`,
      state: primaryApplicant,
    });
  }, [primaryApplicant]);

  const { selectedPrimary } = props;
  return !isLoading && primaryApplicant !== undefined && tenant !== null ? (
    <main className="main-content pt-4 tenants-container" role="main">
      {/* Resident Detail */}
      <div className="row">
        <div className="col-md-12">
          <div className="page-header mb-5">
            <div className="d-flex align-items-center">
              <span
                className="page-back"
                onClick={() => props.history.goBack()}
              >
                <img src={backIcon} alt="icon" />
              </span>
              <div className="page-header-title pl-0">Resident Detail </div>
            </div>
            <div className="d-flex align-items-center">
              <ConcessionsModal
                location={selectedPrimary.node.id}
                user={tenant}
                leaseID={leaseID}
                recipient={tenant.person.email}
              />
              <GiftPointsModal
                location={selectedPrimary.node.id}
                user={props.user.ndbId}
                recipient={tenant.person.email}
              />
              <div className="resident-account-status mr-3">
                Primary Applicant
              </div>
              <Button onClick={navigateToLeaseRenewal}>Renew Lease</Button>
              <Modal
                className="semanticModal"
                onClose={() => setOpen(false)}
                onOpen={() => setOpen(true)}
                open={open}
                size="small"
                trigger={<Button>Move Out</Button>}
              >
                <Modal.Header>Confirm Move Out?</Modal.Header>
                <Modal.Content>
                  <Modal.Description>
                    <Header>By Confirming this will..</Header>
                    <List bulleted>
                      <List.Item>
                        All upcoming scheduled renter transactions will be
                        cancelled.
                      </List.Item>
                      <List.Item>
                        No further invoices will be generated for this user.
                      </List.Item>
                      <List.Item>
                        If unit is allocated to another following lease it will
                        be marked as unavailable until the next lease starts.
                      </List.Item>
                      <List.Item>
                        If the unit is not allocated to another lease then it
                        will be changed to available.
                      </List.Item>
                      <List.Item>Resident logs will be generated.</List.Item>
                    </List>
                  </Modal.Description>
                </Modal.Content>
                <Modal.Actions>
                  <Button
                    onClick={() => handleMoveOutSubmit()}
                    positive
                    disabled={isLoad}
                    loading={isLoad}
                  >
                    Confirm
                  </Button>
                </Modal.Actions>
              </Modal>
              <Button onClick={redirectToPushNotification}>
                Send Notification
              </Button>
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-md-11">
          <div className="theme-card card">
            <div className="card-header">
              <div className="card-profile-header d-flex justify-content-between align-items-center">
                <div className="d-flex align-items-center">
                  <div className="resident-profile-icon">
                    <img src={userIcon} alt="icon" />
                  </div>
                  <div>
                    <div className="profile-title">
                      {tenant.person.firstName} {tenant.person.lastName} &nbsp;
                      <Label
                        circular
                        style={{
                          background: "#3b1f5e",
                          color: "white",
                          textTransform: "capitalize",
                        }}
                      >
                        {tenant.status.replace(/_/g, " ")}
                      </Label>
                    </div>
                    <div className="profile-subtitle">
                      Email:{" "}
                      <a href={`mailto:${tenant.person.email}`}>
                        {tenant.person.email}
                      </a>
                      . Phone Number:{" "}
                      <a href={`tel:${tenant.person.phoneMobile}`}>
                        {tenant.person.phoneMobile}.
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-1">
          <div className="theme-card card">

            {
              theChecklist.moveInChecklist ?
                <MoveInChecklist checklist={theChecklist.moveInChecklist} /> :
                <Label color='red' horizontal>
                  No Move In Checklist
                </Label>
            }
          </div>
        </div>
      </div>
      {/* Payment Row */}
      <Payments {...{ tenantPayments, concessions }} />

      {/* Charge Codes */}
      <Charges {...{ tenantCharges, props, application, fetchLeaseCharges, tenant, leaseID }} />


      <ChatLogs {...{ chatLogs, isLoading: chatLogsLoading, application }} />

      <RentalHistory {...{ rentalHistory: primaryApplicant.rentalHistory }} />
      <Occupants {...{ occupants: application.occupants }} />

      {/* Lease History Row */}
      <LeaseHistory {...{ leaseHistoryData, history: props.history }} />


      <div className="row">
        <div className="col-md-12">
          <div className="theme-card card">
            <div className="card-header">
              <div className="card-header-title">
                Lease History{" "}
                <Button
                  size="mini"
                  onClick={() => setShowResults(!showResults)}
                  floated="right"
                  icon="toggle down"
                />
              </div>
              <div className="card-profile-header d-flex justify-content-between align-items-center">
                {showResults && (
                  <div class="card-body">
                    <div className="card-profile-body">
                      <ProfileCard {...{ primaryApplicant, application }} />

                      <PetDetails {...{ pets: application.pets }} />
                      <VehicleDetails {...{ vehicles: application.vehicles }} />
                      <EmergencyContact {...{ primaryApplicant }} />
                      <Employment {...{ primaryApplicant }} />

                      {application.coApplicants.map((applicant) => (
                        <CoApplicant {...{ applicant }} />
                      ))}
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  ) : !isLoading && tenant !== null ? (
    <main className="main-content pt-4 tenants-container" role="main">
      <div className="row">
        <div className="col-md-12">
          <div className="theme-card card">
            <div class="card-header">
              <div className="card-profile-header d-flex justify-content-between align-items-center">
                <div className="d-flex align-items-center">
                  <div className="resident-profile-icon">
                    <img src={userIcon} alt="icon" />
                  </div>
                  <div>
                    <div className="profile-title">
                      {tenant.person.firstName.charAt(0) +
                        tenant.person.firstName.slice(1).toLowerCase()}{" "}
                      {tenant.person.lastName.charAt(0) +
                        tenant.person.lastName.slice(1).toLowerCase()}
                    </div>
                    <div className="profile-subtitle">
                      Email:{" "}
                      <a href={`mailto:${tenant.person.email}`}>
                        {tenant.person.email}
                      </a>
                      . Phone Number:{" "}
                      <a href={`tel:${tenant.person.phoneMobile}`}>
                        {tenant.person.phoneMobile}.
                      </a>
                    </div>
                  </div>
                </div>
                <GiftPointsModal
                  location={selectedPrimary.node.id}
                  user={props.user.ndbId}
                  recipient={tenant.person.email}
                />
                <div className="resident-account-status">Primary Applicant</div>
              </div>
            </div>
            <div class="card-body">
              <div className="card-profile-body">
                <div className="row">
                  <div className="col-md-12">
                    <div className="profile-label-row">
                      <div>
                        <span className="profile-label-title">Lease Info</span>
                        <span className="profile-label-subtitle">
                          Lease signed on {createdAtDate}
                        </span>
                      </div>
                      <div className="profile-label-line"></div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-4">
                    <div className="profile-basic">
                      <div className="profile-label">Monthly Rent</div>
                      <div className="profile-label-value">
                        ${tenant.amount}/month
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="profile-basic">
                      <div className="profile-label">Lease Frequency</div>
                      <div className="profile-label-value">
                        {tenant.leaseFrequency}
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="profile-basic">
                      <div className="profile-label">Deposit</div>
                      <div className="profile-label-value">
                        ${tenant.deposit}
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="profile-basic">
                      <div className="profile-label">Non-Refundable</div>
                      <div className="profile-label-value">
                        ${tenant.nonRefundable}
                      </div>
                    </div>
                  </div>
                  {tenant.extLeaseInfo !== null && (
                    <>
                      <div className="col-md-4">
                        <div className="profile-basic">
                          <div className="profile-label">Sign Method</div>
                          <div className="profile-label-value">
                            {tenant.extLeaseInfo.type.charAt(0).toUpperCase() +
                              tenant.extLeaseInfo.type.slice(1)}
                          </div>
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="profile-basic">
                          <div className="profile-label">Sign Status</div>
                          <div className="profile-label-value">
                            {tenant.extLeaseInfo.status
                              .charAt(0)
                              .toUpperCase() +
                              tenant.extLeaseInfo.status.slice(1)}
                          </div>
                        </div>
                      </div>
                    </>
                  )}
                  <div className="col-md-4">
                    <div className="profile-basic">
                      <div className="profile-label">Lease Guarantee</div>
                      <div className="profile-label-value">
                        {tenant.guarantee ? "Yes" : "No"}
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="profile-basic">
                      <div className="profile-label">Pro Rate</div>
                      <div className="profile-label-value">
                        {tenant.proRata ? "Yes" : "No"}
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="profile-basic">
                      <div className="profile-label">Pets</div>
                      <div className="profile-label-value">
                        {tenant.petQuantity === 0 ? "N/A" : tenant.petQuantity}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  ) : !isLoading && primaryApplicant !== undefined ? (
    <main className="main-content pt-4 tenants-container" role="main">
      <div className="row">
        <div className="col-md-12">
          <div className="theme-card card">
            <div class="card-header">
              <div className="card-profile-header d-flex justify-content-between align-items-center">
                <div className="d-flex align-items-center">
                  <div className="resident-profile-icon">
                    <img src={userIcon} alt="icon" />
                  </div>
                  <div>
                    <div>
                      <div className="profile-title">
                        {primaryApplicant.firstName} {primaryApplicant.lastName}
                      </div>
                      <div className="profile-subtitle">
                        Email:{" "}
                        <a href={`mailto:${primaryApplicant.email}`}>
                          {primaryApplicant.email}
                        </a>
                        . Phone Number:{" "}
                        <a href={`tel:${primaryApplicant.phoneMobile}`}>
                          {primaryApplicant.phoneMobile}.
                        </a>
                      </div>
                    </div>
                  </div>
                  <GiftPointsModal
                    location={selectedPrimary.node.id}
                    user={props.user.ndbId}
                    recipient={tenant&&tenant.person.email}
                  />
                  <div className="resident-account-status">
                    Primary Applicant
                  </div>
                </div>
              </div>
              <div class="card-body">
                <div className="card-profile-body">
                  <div class="card-body">
                    <div className="card-profile-body">
                      <div className="row">
                        <div className="col-md-4">
                          <div className="profile-basic">
                            <div className="profile-label">
                              Goverment ID Type
                            </div>
                            <div className="profile-label-value">
                              {primaryApplicant.drivingLicence.idType}
                            </div>
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="profile-basic">
                            <div className="profile-label">
                              Drivers License/ID Number
                            </div>
                            <div className="profile-label-value">
                              {primaryApplicant.drivingLicence.number}
                            </div>
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="profile-basic">
                            <div className="profile-label">Issuing State</div>
                            <div className="profile-label-value">
                              {primaryApplicant.drivingLicence.issuingState}
                            </div>
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="profile-basic">
                            <div className="profile-label">Date of Birth</div>
                            <div className="profile-label-value">
                              {primaryApplicant.dateOfBirth}
                            </div>
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="profile-basic">
                            <div className="profile-label">Marital Status</div>
                            <div className="profile-label-value">
                              {primaryApplicant.maritalStatus === ""
                                ? "N/A"
                                : primaryApplicant.maritalStatus}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-12">
                          <div className="profile-label-row">
                            <div>
                              <span className="profile-label-title">
                                Emergency Contact
                              </span>
                              <span className="profile-label-subtitle">
                                {primaryApplicant.emergencyContact.relationship}
                              </span>
                            </div>
                            <div className="profile-label-line"></div>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-4">
                          <div className="profile-basic">
                            <div className="profile-label">Name</div>
                            <div className="profile-label-value">
                              {primaryApplicant.emergencyContact.name}
                            </div>
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="profile-basic">
                            <div className="profile-label">Cell Phone</div>
                            <div className="profile-label-value">
                              {primaryApplicant.emergencyContact.homePhone !==
                                "" ? (
                                <a
                                  href={`tel:${primaryApplicant.emergencyContact.mobilePhone}`}
                                >
                                  {
                                    primaryApplicant.emergencyContact
                                      .mobilePhone
                                  }
                                </a>
                              ) : (
                                "N/A"
                              )}
                            </div>
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="profile-basic">
                            <div className="profile-label">Home Phone</div>
                            <div className="profile-label-value">
                              {primaryApplicant.emergencyContact.homePhone !==
                                "" ? (
                                <a
                                  href={`tel:${primaryApplicant.emergencyContact.homePhone}`}
                                >
                                  {primaryApplicant.emergencyContact.homePhone}
                                </a>
                              ) : (
                                "N/A"
                              )}
                            </div>
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="profile-basic">
                            <div className="profile-label">Work Phone</div>
                            <div className="profile-label-value">
                              {primaryApplicant.emergencyContact.workPhone !==
                                "" ? (
                                <a
                                  href={`tel:${primaryApplicant.emergencyContact.workPhone}`}
                                >
                                  {primaryApplicant.emergencyContact.workPhone}
                                </a>
                              ) : (
                                "N/A"
                              )}
                            </div>
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="profile-basic">
                            <div className="profile-label">Email</div>
                            <div className="profile-label-value">
                              {primaryApplicant.emergencyContact.email !==
                                "" ? (
                                <a
                                  href={`mailto:${primaryApplicant.emergencyContact.email}`}
                                >
                                  {primaryApplicant.emergencyContact.email}
                                </a>
                              ) : (
                                "N/A"
                              )}
                            </div>
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="profile-basic">
                            <div className="profile-label">Address</div>
                            <div className="profile-label-value">
                              {primaryApplicant &&
                                primaryApplicant.emergencyContact &&
                                primaryApplicant.emergencyContact.address
                                  .streetLine1}
                              , {primaryApplicant.emergencyContact.address.city}
                              ,{" "}
                              {primaryApplicant.emergencyContact.address.state},{" "}
                              {
                                primaryApplicant.emergencyContact.address
                                  .postcode
                              }
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-12">
                          <div className="profile-label-row">
                            <div>
                              <span className="profile-label-title">
                                Employment
                              </span>
                            </div>
                            <div className="profile-label-line"></div>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-4">
                          <div className="profile-basic">
                            <div className="profile-label">Occupation</div>
                            <div className="profile-label-value">
                              {primaryApplicant &&
                                primaryApplicant.employmentHistory &&
                                primaryApplicant.employmentHistory[0] &&
                                primaryApplicant.employmentHistory[0].title}
                            </div>
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="profile-basic">
                            <div className="profile-label">
                              Employer/Company
                            </div>
                            <div className="profile-label-value">
                              {primaryApplicant &&
                                primaryApplicant.employmentHistory &&
                                primaryApplicant.employmentHistory[0] &&
                                primaryApplicant.employmentHistory[0]
                                  .employerName}
                            </div>
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="profile-basic">
                            <div className="profile-label">Monthly Income</div>
                            <div className="profile-label-value">
                              $
                              {primaryApplicant &&
                                primaryApplicant.employmentHistory &&
                                primaryApplicant.employmentHistory[0] &&
                                primaryApplicant.employmentHistory[0]
                                  .monthlyIncome}
                            </div>
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="profile-basic">
                            <div className="profile-label">From Date</div>
                            <div className="profile-label-value">
                              {primaryApplicant &&
                                primaryApplicant.employmentHistory &&
                                primaryApplicant.employmentHistory[0] &&
                                primaryApplicant.employmentHistory[0].startDate}
                            </div>
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="profile-basic">
                            <div className="profile-label">To Date</div>
                            <div className="profile-label-value">
                              {primaryApplicant &&
                                primaryApplicant.employmentHistory &&
                                primaryApplicant.employmentHistory[0] &&
                                primaryApplicant.employmentHistory[0].endDate}
                            </div>
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="profile-basic">
                            <div className="profile-label">
                              Currently Employed
                            </div>
                            <div className="profile-label-value">
                              {primaryApplicant &&
                                primaryApplicant.employmentHistory &&
                                primaryApplicant.inCurrentlyEmployed
                                ? "Yes"
                                : primaryApplicant &&
                                  primaryApplicant.employmentHistory &&
                                  !primaryApplicant.inCurrentlyEmployed
                                  ? "No"
                                  : "N/A"}
                            </div>
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="profile-basic">
                            <div className="profile-label">Supervisor Name</div>
                            <div className="profile-label-value">
                              {primaryApplicant &&
                                primaryApplicant.employmentHistory &&
                                primaryApplicant.employmentHistory[0] &&
                                primaryApplicant.employmentHistory[0]
                                  .supervisorName}
                            </div>
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="profile-basic">
                            <div className="profile-label">Address</div>
                            <div className="profile-label-value">
                              {
                                primaryApplicant.employmentHistory[0].address
                                  .streetLine1
                              }
                              ,{" "}
                              {
                                primaryApplicant.employmentHistory[0].address
                                  .city
                              }
                              ,{" "}
                              {
                                primaryApplicant.employmentHistory[0].address
                                  .state
                              }
                              , {primaryApplicant.employmentHistory[0].postcode}
                            </div>
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="profile-basic">
                            <div className="profile-label">
                              Supervisor Email
                            </div>
                            <div className="profile-label-value">
                              {primaryApplicant &&
                                primaryApplicant.employmentHistory &&
                                primaryApplicant.employmentHistory[0] &&
                                primaryApplicant.employmentHistory[0]
                                  .supervisorEmail !== "" ? (
                                <a
                                  href={`mailto:${primaryApplicant &&
                                    primaryApplicant.employmentHistory &&
                                    primaryApplicant.employmentHistory[0] &&
                                    primaryApplicant.employmentHistory[0]
                                      .supervisorEmail
                                    }`}
                                >
                                  {primaryApplicant &&
                                    primaryApplicant.employmentHistory &&
                                    primaryApplicant.employmentHistory[0] &&
                                    primaryApplicant.employmentHistory[0]
                                      .supervisorEmail}
                                </a>
                              ) : (
                                "N/A"
                              )}
                            </div>
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="profile-basic">
                            <div className="profile-label">
                              Supervisor Phone
                            </div>
                            <div className="profile-label-value">
                              {primaryApplicant &&
                                primaryApplicant.employmentHistory &&
                                primaryApplicant.employmentHistory[0] &&
                                primaryApplicant.employmentHistory[0]
                                  .supervisorPhone !== "" ? (
                                <a
                                  href={`tel:${primaryApplicant &&
                                    primaryApplicant.employmentHistory &&
                                    primaryApplicant.employmentHistory[0] &&
                                    primaryApplicant.employmentHistory[0]
                                      .supervisorPhone
                                    }`}
                                >
                                  {primaryApplicant &&
                                    primaryApplicant.employmentHistory &&
                                    primaryApplicant.employmentHistory[0] &&
                                    primaryApplicant.employmentHistory[0]
                                      .supervisorPhone}
                                </a>
                              ) : (
                                "N/A"
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  ) : (
    <PageLoader text />
  );
}

export default TenantDetail;
