import React, { useEffect, useMemo } from "react";
import { Link } from "react-router-dom";
import { MobileView, BrowserView } from "react-device-detect";
import { useSelector, useDispatch } from "react-redux";

import { getClient } from "../../../../store/auth";
import { CHAT_URL } from "../../../../utils";
import { getChatRoomDetailsQuery } from "../../../../store/chat/chat";
import { SET_CHAT_ROOM_DETAIL } from "../../../../store/redux/chat";

import { CHAT_URL_IMAGE_PATH, ROUTES } from "../../../../utils/constants";

import { ALL } from "../RoomsListFilterTabs";

const chatApi = getClient(CHAT_URL);
const ChatRoomListItem = ({ item, isActive, labelFilter }) => {
  const [contactData, setContactData] = React.useState(null);
  const { person, chat } = useSelector((store) => store);

  const dispatch = useDispatch();

  const getChatRoomDetails = async (chatRoomId) => {
    try {
      const detail = await chatApi.query({
        query: getChatRoomDetailsQuery,
        variables: { chatRoomId },
      });
      const contact = detail?.data?.getChatRoomDetails?.edges?.filter(
        (item) => item.node.personId.toString() !== person.ndbId.toString()
      );
      if (contact.length > 0) {
        setContactData(contact[0].node);
        dispatch({
          type: SET_CHAT_ROOM_DETAIL,
          payload: {
            chatRoomId: item.chatRoomId,
            detail: contact[0].node,
          },
        });
      }
    } catch (error) {
      console.log("Error", error);
    }
  };

  useMemo(() => {
    if (chat?.chatRoomDetails[item.chatRoomId] === undefined)
      getChatRoomDetails(item.chatRoomId);
    else {
      const { picture, name, role } = chat?.chatRoomDetails[item.chatRoomId];
      setContactData({ picture, name, role });
    }
  }, []);

  if (labelFilter !== contactData?.role && labelFilter !== ALL) return null;

  return (
    <Link
      to={`/communication/messages/chat/${item.chatRoomId}`}
      className={`room-list-item ${isActive ? "active" : ""} messages-third-step`}
    >
      <div className="avatar">
        <img
          src={`${CHAT_URL_IMAGE_PATH}${contactData?.picture || item.avatar}`}
          alt="img"
        />
      </div>
      <div className="info-wrapper">
        <div className="room-title">{contactData?.name || item.roomTitle}</div>
        <div className="preview">{item.previewMessage}</div>
        <BrowserView>
          <span
            className="chat-contact-label"
            type={contactData?.role || item.label.toUpperCase()}
          >
            {contactData?.role || item.label}
          </span>
        </BrowserView>
      </div>

      <MobileView>
        <span
          className="chat-contact-label"
          type={contactData?.role || item.label.toUpperCase()}
        >
          {contactData?.role || item.label}
        </span>
      </MobileView>

      {/* <Actions
            options={[
              {
                onClick: () => {
                  setShowArchiveModal(true);
                  setArchiveModalRoomId(item.id);
                },
                label: "Archive Conversation",
              },
            ]}
          /> */}
    </Link>
  );
};

export default ChatRoomListItem;
