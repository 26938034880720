import React, { useEffect, useMemo } from "react";
import { Icon } from "semantic-ui-react";
import { BrowserView, MobileView } from "react-device-detect";
import { useSelector, useDispatch } from "react-redux";

import { createToken } from "../Auth/Firebase/index";

import ChatRoomList from "./components/ChatRoomList";
import "./Messages.scss";

import { getClient } from "../../store/auth";
import { CHAT_URL, PERSON_URL } from "../../utils";
import {
  getLocationContactsGql,
} from "../../store/chat/chat";
import { personGql, FCMAttachDevice } from "../../store/person/person";

import { SET_PERSON_DATA } from "../../store/redux/person";
import {
  SET_CHAT_CONTACT_LIST,
} from "../../store/redux/chat";
import { PageLoader } from "../../components/Loader/PageLoader";
import Tour from 'reactour'
import { messagesSteps } from "../../utils/tourSteps";

export const MOBILE_CHAT_VIEW = "MOBILE_CHAT_VIEW";
export const MOBILE_CHAT_ROOM_VIEW = "MOBILE_CHAT_ROOM_VIEW";

const chatApi = getClient(CHAT_URL);
const personApi = getClient(PERSON_URL);

const Search = ({ className }) => (
  <div className={`${className}`}>
    <Icon name="search" />
    <input
      className="search-user"
      type="text"
      name="search-user"
      id="search-user"
      placeholder="Search users..."
    />
  </div>
);

const Button = ({ className, onClick }) => (
  <div className={`${className}`} {...{ onClick }}>
    <button type="button" className="btn btn-gradient" name="new-conversation">
      <BrowserView>
        <span>NEW CONVERSATION</span>
      </BrowserView>
      <MobileView>
        <span>+</span>
      </MobileView>
    </button>
  </div>
);

const Messages = ({ archived, mode, selectedPrimary }) => {
  const [showNewConversationModal, setShowNewConversationModal] =
    React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [tourOpen, setTourOpen] = React.useState(false);
  const [isAttached, setIsAttached] = React.useState(null);

  const { person } = useSelector((state) => state);
  const PMActiveLocationId = selectedPrimary?.node?.id;

  const dispatch = useDispatch();

  const getContacts = async () => {
    setLoading(true);
    try {
      const contactsDataQuery = await chatApi.query({
        query: getLocationContactsGql,
        variables: {
          locationId: PMActiveLocationId,
        },
      });

      const contactData = contactsDataQuery.data.getLocationContacts.edges.map(
        ({ node }) => {
          const {
            id,
            locationId,
            personId,
            role,
            email,
            name,
            isDelete,
            phone,
            picture,
          } = node;

          return {
            id,
            personId,
            locationId,
            title: name,
            avatar: picture,
            label: role,
          };
        }
      );

      dispatch({
        type: SET_CHAT_CONTACT_LIST,
        payload: contactData,
      });
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const getPerson = async () => {
    setLoading(true);
    try {
      const personDataQuery = await personApi.query({
        query: personGql,
      });
      const personData = personDataQuery.data.person.edges[0].node;
      dispatch({
        type: SET_PERSON_DATA,
        payload: personData,
      });
    } catch (error) {
      console.log("ERROR[getPerson]:", error);
    } finally {
      setLoading(false);
    }
  };

  const attachDeviceToken = async () => {
    const personMainClient = getClient(PERSON_URL);
    const currentToken = await createToken();
    try {
      await personMainClient.mutate({
        mutation: FCMAttachDevice,
        variables: {
          input: {
            device: {
              deviceType: "WEB",
              deviceToken: currentToken,
            },
          },
        },
      });
      setIsAttached(true);
    } catch (e) {
      setIsAttached(false);
    }
  };

  useMemo(() => {
    if (isAttached === null && !isAttached) attachDeviceToken();
    if (person.ndbId === null) getPerson();
    getContacts();
  }, []);

  useEffect(() => {
    getContacts();
  }, [person.ndbId, PMActiveLocationId]);

  if (loading || selectedPrimary === null) return <PageLoader />;

  return (
    <div className="messages">
      <div className="row">
        <div className="col-lg-6 col-md-12">
          <h3 className="messages-first-step">Messages <Icon size='small' circular name='lightbulb' onClick={() => setTourOpen(true)} /></h3>
          <h6>Here’s the overview of your messages</h6>
        </div>
        <div className="col-lg-6 col-md-12 flex-row">
          <Search className="search-input-wrapper" />
          <Button
            className="new-conversation-wrapper messages-second-step"
            onClick={() => setShowNewConversationModal(true)}
          />
        </div>

        <div className="row">
          <ChatRoomList {...{ archived, mode, selectedPrimary }} />
        </div>
      </div>
      <Tour
        steps={messagesSteps}
        isOpen={tourOpen}
        onRequestClose={() => setTourOpen(false)}
        className="helper"
        startAt={0}
        rounded={5}
      />
    </div>

  );
};

export default Messages;
