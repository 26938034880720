import React from "react";
import Datetime from "react-datetime";
import "react-datetime/css/react-datetime.css";

const UnitInformation = (props) => {
  const {
    sqFeet,
    rent,
    unitNum,
    floorNum,
    beds,
    baths,
    maxOccupancy,
    onChange,
    formSubmit,
    errors,
    numbersList,
    petsPermitted,
    handleChange,
    availDate,
    handleDayClick,
    description,
    latestSmokeDetectTest,
    latestCarbonMonoxTest,
  } = props;
  const today = Datetime.moment().subtract(1, "day");
  const valid = function (current) {
    return current.isAfter(today);
  };
  return (
    <div className="row mb-5">
      <div className="col-md-12">
        <form noValidate>
          <div className="row pb-2">
            <div className="form-group col-md-3">
              <label htmlFor="size">Unit Num</label>
              <input
                type="text"
                className={`form-control ${
                  ((formSubmit && unitNum === "") || errors.unitNum) &&
                  " is-invalid"
                }`}
                id="unitNum"
                name="unitNum"
                onChange={onChange}
                value={unitNum}
              />
              {((formSubmit && unitNum === "") || errors.unitNum) && (
                <label className="error text-danger" htmlFor="unitNum">
                  {errors.unitNum || "unit num is missing"}
                </label>
              )}
            </div>
            <div className="form-group col-md-3">
              <label htmlFor="floorNum">Floor Number</label>
              <input
                type="text"
                className={`form-control ${
                  ((formSubmit && floorNum === "") || errors.floorNum) &&
                  " is-invalid"
                }`}
                id="floorNum"
                name="floorNum"
                onChange={onChange}
                value={floorNum}
              />
              {((formSubmit && floorNum === "") || errors.floorNum) && (
                <label className="error text-danger" htmlFor="floorNum">
                  {errors.floorNum || "floor num is missing"}
                </label>
              )}
            </div>
            <div className="form-group col-md-3">
              <label htmlFor="sqFeet">Size (sq ft.)</label>
              <input
                type="text"
                className={`form-control ${
                  ((formSubmit && sqFeet === "") || errors.sqFeet) &&
                  " is-invalid"
                }`}
                id="sqFeet"
                name="sqFeet"
                onChange={onChange}
                value={sqFeet}
              />
              {((formSubmit && sqFeet === "") || errors.sqFeet) && (
                <label className="error text-danger" htmlFor="sqFeet">
                  {errors.sqFeet || "sq feet is missing"}
                </label>
              )}
            </div>
            <div className="form-group col-md-3">
              <label htmlFor="rent">Rent ($)</label>
              <input
                type="text"
                className={`form-control ${
                  ((formSubmit && rent === "") || errors.rent) && " is-invalid"
                }`}
                id="rent"
                name="rent"
                onChange={onChange}
                value={rent}
              />
              {((formSubmit && rent === "") || errors.rent) && (
                <label className="error text-danger" htmlFor="rent">
                  {errors.rent || "rent is missing"}
                </label>
              )}
            </div>
          </div>
          <div className="row pb-2">
            <div className="form-group col-md-3">
              <label htmlFor="beds">Beds</label>
              <select
                className="form-control"
                id="beds"
                name="beds"
                onChange={onChange}
                value={beds}
              >
                {numbersList}
              </select>
            </div>
            <div className="form-group col-md-3">
              <label htmlFor="baths">Baths</label>
              <select
                className="form-control"
                id="baths"
                name="baths"
                onChange={onChange}
                value={baths}
              >
                {numbersList}
              </select>
            </div>
            <div className="form-group col-md-3">
              <label htmlFor="maxOccupancy">Max Occ</label>
              <select
                className="form-control"
                id="maxOccupancy"
                name="maxOccupancy"
                onChange={onChange}
                value={maxOccupancy}
              >
                {numbersList}
              </select>
            </div>
            <div className="form-group col-md-3">
              <div className="custom-control custom-checkbox">
                <input
                  type="checkbox"
                  className="custom-control-input"
                  id="customControlValidation"
                  name="petsPermitted"
                  checked={petsPermitted}
                  onChange={handleChange}
                />
                <label
                  className="custom-control-label"
                  htmlFor="customControlValidation"
                >
                  Pets Permitted
                </label>
              </div>
            </div>
          </div>
          <div className="row pb-2">
            <div className="form-group col-md-4">
              <label htmlFor="availDate">Availability Date</label>
              <Datetime
                id="availDate"
                name="availDate"
                isValidDate={valid}
                onDayChange={handleDayClick}
                selectedDays={availDate}
                value={availDate}
                classNames={`form-control ${
                  ((formSubmit && availDate === "") || errors.availDate) &&
                  " is-invalid"
                }`}
              />
              {((formSubmit && availDate === "") || errors.availDate) && (
                <label className="error text-danger" htmlFor="availDate">
                  {errors.availDate || "avail date is missing"}
                </label>
              )}
            </div>
            <div className="form-group col-md-4">
              <label htmlFor="latestCarbonMonoxTest">
                Latest Carbon Monoxide test
              </label>
              <Datetime
                id="latestCarbonMonoxTest"
                name="latestCarbonMonoxTest"
                isValidDate={valid}
                onDayChange={handleDayClick}
                selectedDays={latestCarbonMonoxTest}
                value={latestCarbonMonoxTest}
                classNames={`form-control ${
                  ((formSubmit && latestCarbonMonoxTest === "") ||
                    errors.latestCarbonMonoxTest) &&
                  " is-invalid"
                }`}
              />
              {((formSubmit && latestCarbonMonoxTest === "") ||
                errors.latestCarbonMonoxTest) && (
                <label
                  className="error text-danger"
                  htmlFor="latestCarbonMonoxTest"
                >
                  {errors.latestCarbonMonoxTest ||
                    "latest carbon monox test is missing"}
                </label>
              )}
            </div>
            <div className="form-group col-md-4">
              <label htmlFor="latestSmokeDetectTest">
                Latest Smoke detector test
              </label>
              <Datetime
                id="latestSmokeDetectTest"
                name="latestSmokeDetectTest"
                onDayChange={handleDayClick}
                isValidDate={valid}
                selectedDays={latestSmokeDetectTest}
                value={latestSmokeDetectTest}
                classNames={`form-control ${
                  ((formSubmit && latestSmokeDetectTest === "") ||
                    errors.latestSmokeDetectTest) &&
                  " is-invalid"
                }`}
              />
              {((formSubmit && latestSmokeDetectTest === "") ||
                errors.latestSmokeDetectTest) && (
                <label
                  className="error text-danger"
                  htmlFor="latestSmokeDetectTest"
                >
                  {errors.latestSmokeDetectTest ||
                    "latest smoke detect test is missing"}
                </label>
              )}
            </div>
          </div>
          <div className="row pb-2">
            <div className="form-group col-md-12">
              <label htmlFor="description">Description</label>
              <textarea
                className={`form-control ${
                  ((formSubmit && description === "") || errors.description) &&
                  " is-invalid"
                }`}
                placeholder="Please input a description about your property"
                id="description"
                rows="3"
                name="description"
                onChange={onChange}
                value={description.replace(/(?:\r\n|\r|\n)/g, " ")}
              />
              {((formSubmit && description === "") || errors.description) && (
                <label className="error text-danger" htmlFor="description">
                  {errors.description || "description is missing"}
                </label>
              )}
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};
export default UnitInformation;
