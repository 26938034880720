import React from "react";

const PetDetails = ({ pets }) => {
  return (
    <>
      <div className="row">
        <div className="col-md-12">
          <div className="profile-label-row">
            <div>
              <span className="profile-label-title">Pets</span>
            </div>
            <div className="profile-label-line"></div>
          </div>
        </div>
      </div>
      {pets.map((pet) => {
        return (
          <div className="row">
            <div className="col-md-3">
              <div className="profile-basic">
                <div className="profile-label">Name</div>
                <div className="profile-label-value">{pet.name}</div>
              </div>
            </div>
            <div className="col-md-3">
              <div className="profile-basic">
                <div className="profile-label">Pet Type</div>
                <div className="profile-label-value">{pet.petType}</div>
              </div>
            </div>
            <div className="col-md-3">
              <div className="profile-basic">
                <div className="profile-label">Breed</div>
                <div className="profile-label-value">{pet.breed}</div>
              </div>
            </div>
            <div className="col-md-3">
              <div className="profile-basic">
                <div className="profile-label">Age</div>
                <div className="profile-label-value">{pet.age}</div>
              </div>
            </div>
          </div>
        );
      })}
    </>
  );
};

export default PetDetails;
