/* eslint-disable jsx-a11y/label-has-associated-control */
import React from "react";
import { useForm } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import {
  Grid,
  Icon,
  Popup,
  Dropdown,
  Loader,
  Header,
  Message,
} from "semantic-ui-react";
import { camelCase, get } from "lodash";
import axios from "axios";
import { getClient } from "../../../init-apollo-googleFn";
import {
  LOCATIONAPI,
  NUMERIC_REGEX,
  NUMERIC_REGEX_DECIMAL,
  PRICE_REGEX,
  BLUEMOON_API,
  BLUEMOON_PATH,
  LOCATIONDOC,
  DOCUSIGN_VIEW,
  PERSON_WEPAY,
} from "../../../utils/constants";
import {
  bluemoonInfo,
  bluemoonProperties,
} from "../../../store/bluemoon/bluemoon";
import { DocusignView } from "../../../store/person/leases";
import { CreateLegal } from "../../../store/properties/properties";
import { getAuthToken } from "../../../store/auth/authUtility";
import {
  parseGraphQLErrors,
  toastFailMsg,
  toastSuccessMsg,
} from "../../../utils/common";
import BluemoonSettings from "./Settings";

const bluemoonApi = getClient(BLUEMOON_API);
const docViewApi = getClient(DOCUSIGN_VIEW);
const paymentApi = getClient(PERSON_WEPAY);

const prepareDepositMutation = ({
  depositRequired,
  depositAmount,
  depositNonrefundableAmount,
}) => {
  if (depositRequired === "Yes") {
    return `depositAmount: ${depositAmount},
            depositRequired: true,
            depositRefundable: true,
            depositNonrefundableAmount: ${depositNonrefundableAmount}`;
  }
  return `depositRequired: false,
          depositRefundable: false`;
};

const PriceInput = ({ name, label, placeholder, amount, innerRef }) => {
  const [isFocused, setIsFocused] = React.useState(false);

  return (
    <div className="has-float-label  with-doller">
      {(amount || isFocused) && <span className="doller-sign">$</span>}
      <input
        className="form-control"
        type="number"
        name={name}
        id={name}
        placeholder={placeholder}
        autoComplete="nope"
        min="0"
        onKeyDown={(evt) =>
          ["e", "E", "+", "-"].includes(evt.key) && evt.preventDefault()
        }
        onFocus={() => setIsFocused(true)}
        onBlur={() => !amount && setIsFocused(false)}
        ref={innerRef}
      />
      <label htmlFor={name}>{label}</label>
    </div>
  );
};

const PaymentAndPoliciesForm = ({
  depositRules,
  details,
  locationId,
  user,
  handleSubmitting,
  changeFormStep,
  onSave,
  propertyData,
  refetchDetails,
}) => {
  const {
    register,
    handleSubmit,
    errors,
    watch,
    setValue,
    getValues,
    formMethods,
  } = useForm({ mode: "onChange", defaultValues: { depositRequired: "Yes" } });

  const [addedDocumentsPath, setAddedDocumentsPaths] = React.useState({});
  const [bluemoonData, setBluemoonData] = React.useState({});
  const [docuUrl, setURL] = React.useState(false);
  const [fileLease, setFileLease] = React.useState(false);
  const [fileApp, setFileApp] = React.useState(false);
  const [loads, setLoads] = React.useState(false);
  const [filePayment, setFilePayment] = React.useState(false);
  const [fileCancel, setFileCancel] = React.useState(false);
  const [filePet, setFilePet] = React.useState(false);
  const [toggleSwitch, setToggleSwitch] = React.useState(
    (details.esginType || propertyData.esginType) === "bluemoon"
  );
  const [bluemoonPropertiesData, setBluemoonProperties] = React.useState([]);
  const [bmLocationId, setBmLocationId] = React.useState(
    details.bmLocationId || propertyData.esginType || ""
  );
  const [bluemoonName, setBluemoonName] = React.useState([]);

  const storeData = async () => {
    await localStorage.setItem("bluemoonData", JSON.stringify(bluemoonData));
  };

  const fetchCredentialDetail = async () => {
    await bluemoonApi
      .query({
        query: bluemoonInfo,
      })
      .then((res) => {
        const data = res.data.bluemoonInfo.edges[0].node;
        localStorage.setItem("bluemoonData", JSON.stringify(data));
        setBluemoonData(data);
      })
      .catch((error) => {
        toastFailMsg(parseGraphQLErrors(error));
      });
  };

  const fetchBluemoonProperties = async () => {
    await bluemoonApi
      .query({
        query: bluemoonProperties,
      })
      .then((res) => {
        const data = res.data.bluemoonProperties.edges;
        const finalData = [];
        setBluemoonName(data[0].node.name);
        setBmLocationId(data[0].node.id);
        data.map((ele) => {
          if (
            ele.node.id === (details.bmLocationId || propertyData.bmLocationId)
          ) {
            setBluemoonName(ele.node.name);
          }
          finalData.push({
            key: ele.node.id,
            text: ele.node.name,
            value: ele.node.name,
          });
          return null;
        });
        setBluemoonProperties(finalData);
      })
      .catch((error) => {
        toastFailMsg(parseGraphQLErrors(error));
      });
  };

  const {
    depositRequired,
    depositAmount,
    depositNonrefundableAmount,
    HOA,
    petPolicy,
    paymentPolicy,
    hoaPolicy,
    distributionPolicy,
    cancellationPolicy,
    esginType,
    applicationPolicy,
    leasePolicy,
  } = watch();

  const initializeEditForm = (data) => {
    Object.entries(data).forEach(([key, value]) => {
      const fieldName = camelCase(key);
      switch (fieldName) {
        case "depositRequired":
        case "leaseGuarantee":
        case "flMonthRequired":
          setValue(fieldName, value ? "Yes" : "No");
          break;
        case "hoa":
          setValue("HOA", value ? "Yes" : "No");
          if (value) setValue("hoaChargeCode", Number(data.hoa_charge_code));
          break;
        case "hoaChargeCode":
          setValue(fieldName, Number(value));
          break;
        case "esginType":
        case "petPolicyDocPath":
        case "paymentPolicyDocPath":
        case "cancellationPolicyDocPath":
        case "hoaPolicyDocPath":
        case "distributionNoticeDocPath":
        case "applicationDocPath":
        case "leaseDocPath":
          setAddedDocumentsPaths((prev) => ({ ...prev, [fieldName]: value }));
          break;
        default:
          setValue(fieldName, value);
          break;
      }
    });
  };

  const checkFormInputs = () => {
    if (loads) return true;
    if ((addedDocumentsPath && addedDocumentsPath.leaseDocPath) || fileLease)
      return false;
    if (!fileLease && !addedDocumentsPath.leaseDocPath && !leasePolicy) {
      return true;
    }
    if (
      addedDocumentsPath &&
      !addedDocumentsPath.leaseDocPath &&
      !leasePolicy
    ) {
      return true;
    } else return false;
  };

  /* eslint-disable */
  React.useEffect(() => {
    if (propertyData && Object.keys(propertyData).length !== 0) {
      initializeEditForm(propertyData);
    } else if (depositRules) initializeEditForm(depositRules);
  }, [depositRules, propertyData]);

  React.useEffect(() => {
    if (!bluemoonData.id) fetchCredentialDetail();
  }, []);

  React.useEffect(() => {
    if (addedDocumentsPath.leaseDocPath) {
      GetDocusign("LEASE_POLICY");
    }
  }, [addedDocumentsPath.leaseDocPath]);
  React.useEffect(() => {
    if (addedDocumentsPath.applicationDocPath) {
      GetDocusign("APPLICATION_POLICY");
    }
  }, [addedDocumentsPath.applicationDocPath]);
  React.useEffect(() => {
    if (addedDocumentsPath.petPolicyDocPath) {
      GetDocusign("PET_POLICY");
    }
  }, [addedDocumentsPath.petPolicyDocPath]);
  React.useEffect(() => {
    if (addedDocumentsPath.paymentPolicyDocPath) {
      GetDocusign("PAYMENT_POLICY");
    }
  }, [addedDocumentsPath.paymentPolicyDocPath]);
  React.useEffect(() => {
    if (addedDocumentsPath.cancellationPolicyDocPath) {
      GetDocusign("CANCELLATION_POLICY");
    }
  }, [addedDocumentsPath.cancellationPolicyDocPath]);

  React.useEffect(() => {
    fetchBluemoonProperties();
  }, [toggleSwitch]);
  /* eslint-enable */

  const onSubmit = (data) => {
    setLoads(true);
    if (toggleSwitch && !bmLocationId) {
      toastFailMsg("Select Bluemoon Property");
    } else {
      const {
        leaseGuarantee,
        flMonthRequired,
        applicationCreditScore,
        incomeXTimeRent,
        petPolicy: petPolicyFile,
        paymentPolicy: paymentPolicyFile,
        cancellationPolicy: cancellationPolicyFile,
        hoaPolicy: hoaPolicyFile,
        distributionPolicy: distributionPolicyFile,
        applicationPolicy: applicationPolicyFile,
        leasePolicy: leasePolicyFile,
        hoaChargeCode,
      } = data;
      const formData = new FormData();
      if (esginType === "bluemoon") {
        formData.append(
          "query",
          `mutation uploadPolicies{
          uploadPolicies(policies:{
            id: "${locationId}",
            depositRules: {
             ${prepareDepositMutation(data)},
             leaseGuarantee: ${leaseGuarantee === "Yes"},
             flMonthRequired: ${flMonthRequired === "Yes"},
             HOA: ${data.HOA === "Yes"},
             applicationCreditScore: "${applicationCreditScore}",
             hoaChargeCode: "${hoaChargeCode}",
             incomeXTimeRent: "${incomeXTimeRent}",
            },
            bmLocationId: "${bmLocationId}",
            esginType: bluemoon,
          }) {
            response
          }
        }`
        );
      } else if (esginType === "docusign") {
        formData.append(
          "query",
          `mutation uploadPolicies{
          uploadPolicies(policies:{
            id: "${locationId}",
            depositRules: {
             ${prepareDepositMutation(data)},
             leaseGuarantee: ${leaseGuarantee === "Yes"},
             flMonthRequired: ${flMonthRequired === "Yes"},
             HOA: ${data.HOA === "Yes"},
             applicationCreditScore: "${applicationCreditScore}",
             hoaChargeCode: "${hoaChargeCode}",
             incomeXTimeRent: "${incomeXTimeRent}",
            },
            esginType: docusign,
          }) {
            response
          }
        }`
        );
      } else {
        formData.append(
          "query",
          `mutation uploadPolicies{
          uploadPolicies(policies:{
            id: "${locationId}",
            depositRules: {
             ${prepareDepositMutation(data)},
             leaseGuarantee: ${leaseGuarantee === "Yes"},
             flMonthRequired: ${flMonthRequired === "Yes"},
             HOA: ${data.HOA === "Yes"},
             applicationCreditScore: "${applicationCreditScore}",
             hoaChargeCode: "${hoaChargeCode}",
             incomeXTimeRent: "${incomeXTimeRent}",
            },
          }) {
            response
          }
        }`
        );
      }
      if (petPolicyFile && petPolicyFile.length)
        formData.append("petPolicy", petPolicyFile[0]);
      if (paymentPolicyFile && paymentPolicyFile.length)
        formData.append("paymentPolicy", paymentPolicyFile[0]);
      if (cancellationPolicyFile && cancellationPolicyFile.length)
        formData.append("cancellationPolicy", cancellationPolicyFile[0]);
      if (hoaPolicyFile && hoaPolicyFile.length)
        formData.append("hoaPolicy", hoaPolicyFile[0]);
      if (distributionPolicyFile && distributionPolicyFile.length)
        formData.append("distributionPolicy", distributionPolicyFile[0]);
      if (applicationPolicyFile && applicationPolicyFile.length)
        formData.append("applicationPolicy", applicationPolicyFile[0]);
      if (leasePolicyFile && leasePolicyFile.length)
        formData.append("leasePolicy", leasePolicyFile[0]);

      const requestOptions = {
        method: "POST",
        headers: {
          Authorization: getAuthToken(),
        },
        body: formData,
      };

      handleSubmitting(true);
      fetch(LOCATIONAPI, requestOptions)
        .then((response) => response.json())
        .then((result) => {
          setLoads(false);
          if (result.data) changeFormStep(0);
        })
        .catch((e) => {
          setLoads(false);
          toastFailMsg(parseGraphQLErrors(e));
        })
        .finally(() => {
          if (refetchDetails) refetchDetails("policies");
          handleSubmitting(false);
        });
    }
  };

  const onRemoveFile = (name) => {
    const updatedFiles = { ...addedDocumentsPath };
    delete updatedFiles[name];
    setAddedDocumentsPaths(updatedFiles);
  };

  const propertyOnChangeHandler = (event, { value }) => {
    bluemoonPropertiesData.map((ele) => {
      if (ele.value === value) {
        setBmLocationId(ele.key);
        setBluemoonName(ele.value);
      }
      return null;
    });
  };

  const saveAsDraft = () => {
    const data = getValues();
    toastSuccessMsg("Saved");
    if (esginType === "bluemoon") {
      data.bmLocationId = bmLocationId;
      data.esginType = "bluemoon";
    } else if (esginType === "docusign") {
      data.esginType = "docusign";
    }
    onSave(data);
  };

  const GetDocusign = (e) => {
    const url = new URL(LOCATIONDOC);
    url.searchParams.append("doc_type", e);
    url.searchParams.append("location_id", locationId);
    axios({
      method: "get",
      url: url.href,
      headers: {
        authorization: getAuthToken(),
      },
    })
      .then((response) => {
        console.log(response)
        const fileGet = response.data.pages[0];
        if (e == "LEASE_POLICY") {
          setFileLease(fileGet);
        }
        if (e == "APPLICATION_POLICY") {
          setFileApp(fileGet);
        }
        if (e == "PAYMENT_POLICY") {
          setFilePayment(fileGet);
        }
        if (e == "CANCEL_POLICY") {
          setFileCancel(fileGet);
        }
        if (e == "PET_POLICY") {
          setFilePet(fileGet);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const DocusignV = (e) => {
    docViewApi
      .query({
        query: DocusignView,
        variables: {
          locationId: locationId,
        },
      })
      .then((res) => {
        const docusignVal = res.data.docusignTemplateView.edges[0].node;
        setURL(docusignVal);
      })
      .catch((e) => {});
  };

  return (
    <>
      <div className="form-section m-t-3">
        <Message>
          <Header textAlign="center">Documents and Policies</Header>
          <p>
            Upload any documents you may feel are important to both the renters
            and for your team. Define policies for your tenants and how you wish
            to show up in searches.
          </p>
        </Message>
        <form onSubmit={handleSubmit(onSubmit)} noValidate>
          <Grid>
            <Grid.Column mobile={16} tablet={16} computer={16}>
              <div className="white-block">
                <div className="head">Deposit Required</div>
                <div className="radio-container">
                  <label className="custom-radio">
                    Yes
                    <input
                      type="radio"
                      name="depositRequired"
                      defaultChecked
                      value="Yes"
                      ref={register({
                        required: "Please select one option",
                      })}
                    />
                    <span className="checkmark" />
                  </label>
                  <label className="custom-radio">
                    No
                    <input
                      type="radio"
                      name="depositRequired"
                      value="No"
                      ref={register({
                        required: "Please select one option",
                      })}
                    />
                    <span className="checkmark" />
                  </label>
                </div>
              </div>
              <ErrorMessage
                errors={errors}
                name="depositRequired"
                as="span"
                className="error-msg"
              />
            </Grid.Column>
            {depositRequired === "Yes" && (
              <>
                <Grid.Column mobile={16} tablet={8} computer={8}>
                  <div className="form-group">
                    <PriceInput
                      name="depositAmount"
                      placeholder="Deposit Amount"
                      label="Deposit Amount *"
                      amount={depositAmount}
                      innerRef={register({
                        required: "Deposit amount is required.",
                        valueAsNumber: true,
                        pattern: {
                          value: PRICE_REGEX,
                          message: "Please enter valid number.",
                        },
                      })}
                    />
                    <ErrorMessage
                      errors={errors}
                      name="depositAmount"
                      as="span"
                      className="error-msg"
                    />
                  </div>
                </Grid.Column>
                <Grid.Column mobile={16} tablet={8} computer={8}>
                  <div className="form-group">
                    <PriceInput
                      name="depositNonrefundableAmount"
                      placeholder="Non-Refundable Deposit"
                      label="Non-Refundable Deposit *"
                      amount={depositNonrefundableAmount}
                      innerRef={register({
                        required: "Non-Refundable Deposit is required.",
                        valueAsNumber: true,
                        validate: (value) =>
                          Number(value) <= Number(depositAmount) ||
                          "Non-Refundable Deposit must be less than Deposit Required",
                      })}
                    />
                    <ErrorMessage
                      errors={errors}
                      name="depositNonrefundableAmount"
                      as="span"
                      className="error-msg"
                    />
                  </div>
                </Grid.Column>
              </>
            )}
            <Grid.Column mobile={16} tablet={8} computer={8}>
              <div className="white-block">
                <div className="head">Lease Guarantee</div>
                <div className="radio-container">
                  <label className="custom-radio">
                    Yes
                    <input
                      type="radio"
                      name="leaseGuarantee"
                      value="Yes"
                      ref={register({
                        //  required: "Please select one option"
                      })}
                    />
                    <span className="checkmark" />
                  </label>
                  <label className="custom-radio">
                    No
                    <input
                      type="radio"
                      name="leaseGuarantee"
                      value="No"
                      ref={register({
                        // required: "Please select one option"
                      })}
                    />
                    <span className="checkmark" />
                  </label>
                </div>
              </div>
              <ErrorMessage
                errors={errors}
                name="leaseGuarantee"
                as="span"
                className="error-msg"
              />
            </Grid.Column>
            <Grid.Column mobile={16} tablet={8} computer={8}>
              <div className="white-block">
                <div className="head">First and Last Month Rent Required</div>
                <div className="radio-container">
                  <label className="custom-radio">
                    Yes
                    <input
                      type="radio"
                      name="flMonthRequired"
                      value="Yes"
                      ref={register({
                        // required: "Please select one option"
                      })}
                    />
                    <span className="checkmark" />
                  </label>
                  <label className="custom-radio">
                    No
                    <input
                      type="radio"
                      name="flMonthRequired"
                      value="No"
                      ref={register({
                        // required: "Please select one option"
                      })}
                    />
                    <span className="checkmark" />
                  </label>
                </div>
              </div>
              <ErrorMessage
                errors={errors}
                name="flMonthRequired"
                as="span"
                className="error-msg"
              />
            </Grid.Column>
            <Grid.Column mobile={16} tablet={8} computer={8}>
              <div className="form-group">
                <div className="has-float-label">
                  <input
                    className="form-control"
                    type="text"
                    name="applicationCreditScore"
                    id="applicationCreditScore"
                    placeholder="Applicant Credit Score"
                    autoComplete="nope"
                    ref={register({
                      // required: "Credit Score is required.",
                      pattern: {
                        value: NUMERIC_REGEX,
                        message: "Please enter valid number",
                      },
                    })}
                  />
                  <label htmlFor="applicationCreditScore">
                    Applicant Credit Score
                  </label>
                </div>
                <ErrorMessage
                  errors={errors}
                  name="applicationCreditScore"
                  as="span"
                  className="error-msg"
                />
              </div>
            </Grid.Column>
            <Grid.Column mobile={16} tablet={8} computer={8}>
              <div className="form-group">
                <div className="has-float-label">
                  <input
                    className="form-control"
                    type="text"
                    name="incomeXTimeRent"
                    id="incomeXTimeRent"
                    placeholder="Income x times of rent"
                    autoComplete="nope"
                    ref={register({
                      // required: "Income is required.",
                      pattern: {
                        value: NUMERIC_REGEX_DECIMAL,
                        message: "Please enter valid number",
                      },
                    })}
                  />
                  <label htmlFor="incomeXTimeRent">
                    Income x times of rent
                    <Popup
                      trigger={
                        <Icon
                          corner="top right"
                          fitted
                          name="question circle"
                          className="px-1"
                        />
                      }
                      content="Income x times of rent"
                      size="mini"
                    />
                  </label>
                </div>
                <ErrorMessage
                  errors={errors}
                  name="incomeXTimeRent"
                  as="span"
                  className="error-msg"
                />
              </div>
            </Grid.Column>
            <Grid.Column mobile={8} tablet={8} computer={8}>
              <div className="white-block">
                <div className="head">
                  HOA
                  <Popup
                    trigger={
                      <Icon
                        corner="top right"
                        fitted
                        name="question circle"
                        className="px-1"
                      />
                    }
                    content="House owner association?"
                    size="mini"
                  />
                </div>
                <div className="radio-container">
                  <label className="custom-radio">
                    Yes
                    <input
                      type="radio"
                      name="HOA"
                      value="Yes"
                      ref={register({
                        //  required: "Please select one option"
                      })}
                    />
                    <span className="checkmark" />
                  </label>
                  <label className="custom-radio">
                    No
                    <input
                      type="radio"
                      name="HOA"
                      value="No"
                      ref={register({
                        //  required: "Please select one option"
                      })}
                    />
                    <span className="checkmark" />
                  </label>
                </div>
              </div>
              <ErrorMessage
                errors={errors}
                name="HOA"
                as="span"
                className="error-msg"
              />
            </Grid.Column>
            {HOA === "Yes" && (
              <>
                <Grid.Column mobile={16} tablet={16} computer={16}>
                  {addedDocumentsPath.distributionNoticeDocPath ? (
                    <div className="white-block policy-wrap">
                      <h3 className="m-t-4">Distribution Notice</h3>
                      <div className="policy-col">
                        <p>
                          <i className="fa fa-file" aria-hidden="true" />
                          <a
                            href={addedDocumentsPath.distributionNoticeDocPath}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            View Distribution Notice
                          </a>
                        </p>
                        <Icon
                          name="cancel"
                          className="cursor-pointer"
                          onClick={() =>
                            onRemoveFile("distributionNoticeDocPath")
                          }
                        />
                      </div>
                    </div>
                  ) : (
                    <div className="form-group">
                      <div className="white-block textarea-label fileupload-rw">
                        <label>Distribution of notices</label>
                        <div className="file-upload">
                          <input
                            className="form-control"
                            type="file"
                            name="distributionPolicy"
                            ref={register({
                              required: "Please upload distribution policy.",
                            })}
                          />
                          <label>
                            {get(distributionPolicy, "[0].name") ||
                              "upload distribution notice file"}
                          </label>
                        </div>
                      </div>
                      <ErrorMessage
                        errors={errors}
                        name="distributionPolicy"
                        as="span"
                        className="error-msg"
                      />
                    </div>
                  )}
                </Grid.Column>
                <Grid.Column mobile={16} tablet={16} computer={16}>
                  {addedDocumentsPath.hoaPolicyDocPath ? (
                    <div className="white-block policy-wrap">
                      <h3 className="m-t-4">HOA Policy</h3>
                      <div className="policy-col">
                        <p>
                          <i className="fa fa-file" aria-hidden="true" />
                          <a
                            href={addedDocumentsPath.hoaPolicyDocPath}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            View HOA Policy
                          </a>
                        </p>
                        <Icon
                          name="cancel"
                          className="cursor-pointer"
                          onClick={() => onRemoveFile("hoaPolicyDocPath")}
                        />
                      </div>
                    </div>
                  ) : (
                    <div className="form-group">
                      <div className="white-block textarea-label fileupload-rw">
                        <label>HOA Policies</label>
                        <div className="file-upload">
                          <input
                            className="form-control"
                            type="file"
                            name="hoaPolicy"
                            ref={register({
                              required: "Please upload HOA policy.",
                            })}
                          />
                          <label>
                            {get(hoaPolicy, "[0].name") ||
                              "upload HOA policies file"}
                          </label>
                        </div>
                      </div>
                      <ErrorMessage
                        errors={errors}
                        name="hoaPolicy"
                        as="span"
                        className="error-msg"
                      />
                    </div>
                  )}
                </Grid.Column>
                <Grid.Column mobile={16} tablet={16} computer={16}>
                  <div className="form-group">
                    <div className="has-float-label">
                      <input
                        className="form-control"
                        type="number"
                        name="hoaChargeCode"
                        id="hoaChargeCode"
                        placeholder="HOA due charge code"
                        autoComplete="nope"
                        min="0"
                        ref={register({
                          required: "HOA due charge code is required.",
                          pattern: {
                            value: NUMERIC_REGEX,
                            message: "Please enter valid number",
                          },
                        })}
                      />
                      <label htmlFor="hoaChargeCode">HOA due charge code</label>
                    </div>
                    <ErrorMessage
                      errors={errors}
                      name="hoaChargeCode"
                      as="span"
                      className="error-msg"
                    />
                  </div>
                </Grid.Column>
              </>
            )}
            <Grid.Column mobile={16} tablet={16} computer={16}>
              <div className="white-block">
                <div className="head">
                  Application, Lease, and Policy Documents *
                </div>
                <div className="radio-container">
                  <label className="custom-radio">
                    Upload your own application and lease documents
                    <input
                      type="radio"
                      name="esginType"
                      value="docusign"
                      defaultChecked={
                        details.esginType === "docusign" ||
                        propertyData.esginType === "docusign"
                      }
                      onChange={() => setToggleSwitch(false)}
                      ref={register({ required: "Please select one option" })}
                    />
                    <span className="checkmark" />
                  </label>
                  <label className="custom-radio">
                    Use automated form creation by Bluemoon
                    <input
                      type="radio"
                      name="esginType"
                      value="bluemoon"
                      defaultChecked={
                        details.esginType === "bluemoon" ||
                        propertyData.esginType === "bluemoon"
                      }
                      onChange={() => setToggleSwitch(true)}
                      ref={register({ required: "Please select one option" })}
                    />
                    <span className="checkmark" />
                  </label>
                </div>
              </div>
              <ErrorMessage
                errors={errors}
                name="esginType"
                as="span"
                className="error-msg"
              />
            </Grid.Column>
            {esginType === "docusign" && (
              <Grid.Column mobile={16} tablet={16} computer={16}>
                <div className="connect-bluemoon">
                  <div className="left-wrap">
                    <p>
                      Please ensure that the policy documents that you are
                      uploading have designated placeholders for renters and
                      property owners signature. This is to ensure that both
                      parties know exactly where to sign on different policy
                      documents.
                    </p>
                  </div>
                  <a href="/assets/multipleSample.pdf" target="_blank">
                    Example
                  </a>
                </div>
              </Grid.Column>
            )}
            {esginType === "bluemoon" && (
              <Grid.Column mobile={16} tablet={16} computer={16}>
                <div className="connect-bluemoon">
                  <div className="left-wrap">
                    <h6>Connect Bluemoon</h6>
                    <p>
                      Please connect your Bluemoon account from the settings
                      section and reveal the benefits of features like
                      e-signature and others.
                      <div className="benefits-txt-wrap">
                        <span data-toggle="modal" data-target="#BenefitsModal">
                          View Benefits
                        </span>{" "}
                        |
                        <span
                          data-toggle="modal"
                          data-target="#InstructionsModal"
                        >
                          See Instructions
                        </span>
                      </div>
                    </p>
                  </div>
                  <button
                    type="button"
                    onClick={() => storeData()}
                    data-toggle="modal"
                    data-target="#CredentialsModal"
                  >
                    Setup Bluemoon
                  </button>
                </div>
                {bluemoonData.id && (
                  <div className="enable-bluemoon">
                    <div className="top-header">
                      <div className="left-data">
                        <h6>Enable Bluemoon</h6>
                      </div>
                    </div>
                    <div className="has-float-label select-float-label">
                      <Dropdown
                        fluid
                        disabled={!toggleSwitch}
                        selection
                        value={bluemoonName}
                        options={bluemoonPropertiesData}
                        onChange={propertyOnChangeHandler}
                      />
                      <label htmlFor="locationTypeId">
                        Bluemoon Properties
                      </label>
                    </div>
                  </div>
                )}
              </Grid.Column>
            )}

            <div
              className="modal fade benefits-modal-docu"
              id="DocusignModal"
              tabIndex="-1"
              role="dialog"
              aria-labelledby="exampleModalLabel"
              aria-hidden="true"
            >
              <div className="modal-dialog" role="document">
                <div className="modal-content">
                  <div className="modal-header">
                    <button
                      type="button"
                      className="close"
                      data-dismiss="modal"
                      aria-label="Close"
                    >
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                  <div className="modal-body">
                    <iframe
                      src={docuUrl.documentUrl}
                    ></iframe>
                  </div>
                </div>
              </div>
            </div>

            {/* Benefits modal */}
            <div
              className="modal fade benefits-modal"
              id="BenefitsModal"
              tabIndex="-1"
              role="dialog"
              aria-labelledby="exampleModalLabel"
              aria-hidden="true"
            >
              <div className="modal-dialog" role="document">
                <div className="modal-content">
                  <div className="modal-header">
                    <button
                      type="button"
                      className="close"
                      data-dismiss="modal"
                      aria-label="Close"
                    >
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                  <div className="modal-body">
                    <img
                      src="/assets/img/bluemoon-logo.png"
                      alt="bluemoon-logo"
                    />
                    <h5>Benefits of bluemoon</h5>
                    <div className="content-wrap">
                      <ul>
                        <li>
                          <p>
                            The “Blue Moon” program is a national technology
                            platform that allows customers to access NAA Click &
                            Lease, TAA Click & Lease and GAA Forms Online
                            (Georgia only). This solution is an optional benefit
                            to members of the appropriate local apartment
                            association. To learn more about available forms and
                            options, please reach out to Blue Moon Software
                          </p>
                        </li>
                        <li>
                          <p>
                            There would be a potentially incremental cost for
                            the larger properties.
                          </p>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="modal-footer">
                    <a
                      href={BLUEMOON_PATH}
                      target="_blank"
                      rel="noopener noreferrer"
                      type="button"
                      className="btn btn-primary"
                    >
                      Register on Bluemoon
                    </a>
                  </div>
                </div>
              </div>
            </div>

            <div
              className="modal fade benefits-modal-docu"
              id="DocusignModal"
              tabIndex="-1"
              role="dialog"
              aria-labelledby="exampleModalLabel"
              aria-hidden="true"
            >
              <div className="modal-dialog" role="document">
                <div className="modal-content">
                  <div className="modal-body">
                    <iframe
                      src={docuUrl.documentUrl}
                      title="W3Schools Free Online Web Tutorials"
                    ></iframe>
                  </div>
                </div>
              </div>
            </div>
            {/* Instruction Modal */}
            <div
              className="modal fade benefits-modal instruction-modal"
              id="InstructionsModal"
              tabIndex="-1"
              role="dialog"
              aria-labelledby="exampleModalLabel"
              aria-hidden="true"
            >
              <div className="modal-dialog" role="document">
                <div className="modal-content">
                  <div className="modal-header">
                    <button
                      type="button"
                      className="close"
                      data-dismiss="modal"
                      aria-label="Close"
                    >
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                  <div className="modal-body">
                    <img
                      src="/assets/img/bluemoon-logo.png"
                      alt="bluemoon-logo"
                    />
                    <h5>Create account on Bluemoon</h5>
                    <div className="content-wrap">
                      <ul>
                        <li>
                          <p>
                            The “Blue Moon” program is a national technology
                            platform that allows customers to access NAA Click &
                            Lease, TAA Click & Lease and GAA Forms Online
                            (Georgia only). This solution is an optional benefit
                            to members of the appropriate local apartment
                            association. To learn more about available forms and
                            options, please reach out to Blue Moon Software
                          </p>
                        </li>
                        <li>
                          <p>
                            There would be a potentially incremental cost for
                            the larger properties.
                          </p>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="modal-footer">
                    <a
                      href={BLUEMOON_PATH}
                      target="_blank"
                      rel="noopener noreferrer"
                      type="button"
                      className="btn btn-primary"
                    >
                      Register on Bluemoon
                    </a>
                  </div>
                </div>
              </div>
            </div>
            {/* Enable Bluemoon Modal */}
            <div
              className="modal fade enable-modal"
              id="EnableModal"
              tabIndex="-1"
              role="dialog"
              aria-labelledby="exampleModalLabel"
              aria-hidden="true"
            >
              <div
                className="modal-dialog modal-dialog-centered"
                role="document"
              >
                <div className="modal-content">
                  <div className="modal-body">
                    <div className="left-data">
                      <img src="/assets/img/icons-info.svg" alt="info" />
                      <div className="content-data">
                        <h5>Upload policy documents on Bluemoon separately</h5>
                        <p>
                          After enabling Bluemoon you have to submit your policy
                          documents on Bluemoon portal separately. You can still
                          upload documents on REM as well for your future
                          references.
                        </p>
                      </div>
                    </div>
                    <div className="right-data">
                      <button
                        type="button"
                        data-dismiss="modal"
                        aria-label="Close"
                      >
                        Got It
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {fileLease.imageBytes && (
              <div class="container-lease">
                <img
                  src={"data:image/png;base64," + fileLease.imageBytes}
                  alt="Avatar"
                  class="image"
                  style={{ width: "100%" }}
                />
                <span
                  data-toggle="modal"
                  data-target="#DocusignModal"
                  class="btn"
                  onClick={() => DocusignV()}
                >
                  Edit
                </span>
              </div>
            )}
            {fileApp.imageBytes && (
              <div class="container-lease">
                <img
                  src={"data:image/png;base64," + fileApp.imageBytes}
                  alt="Avatar"
                  class="image"
                  style={{ width: "100%" }}
                />
                <span
                  data-toggle="modal"
                  data-target="#DocusignModal"
                  onClick={() => DocusignV()}
                  class="btn"
                >
                  Edit
                </span>
              </div>
             )} 
            {filePayment.imageBytes && (
              <div class="container-lease">
                <img
                  src={"data:image/png;base64," + filePayment.imageBytes}
                  alt="Avatar"
                  class="image"
                  style={{ width: "100%" }}
                />
                <span
                  data-toggle="modal"
                  data-target="#DocusignModal"
                  onClick={() => DocusignV()}
                  class="btn"
                >
                  Edit
                </span>
              </div>
           )} 
            {fileCancel.imageBytes && (
              <div class="container-lease">
                <img
                  src={"data:image/png;base64," + fileCancel.imageBytes}
                  alt="Avatar"
                  class="image"
                  style={{ width: "100%" }}
                />
                <span
                  data-toggle="modal"
                  data-target="#DocusignModal"
                  onClick={() => DocusignV()}
                  class="btn"
                >
                  Edit
                </span>
              </div>
            )}
            {filePet.imageBytes && (
              <div class="container-lease">
                <img
                  src={"data:image/png;base64," + filePet.imageBytes}
                  alt="Avatar"
                  class="image"
                  style={{ width: "100%" }}
                />
                <span
                  data-toggle="modal"
                  data-target="#DocusignModal"
                  onClick={() => DocusignV()}
                  class="btn"
                >
                  Edit
                </span>
              </div>
            )}
            {esginType === "docusign" && (
              <>
                <Grid.Column mobile={16} tablet={16} computer={16}>
                  {addedDocumentsPath.leaseDocPath ? (
                    <div
                      className="form-group"
                      style={{ border: "2px solid #3b1e5e" }}
                    >
                      <div className="white-block policy-wrap">
                        <h3 className="m-t-4">Lease Policy</h3>
                        <div className="policy-col">
                          <p>
                            <i className="fa fa-file" aria-hidden="true" />
                            <a
                              href={addedDocumentsPath.leaseDocPath}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              View Lease Policy
                            </a>
                          </p>
                          <Icon
                            name="cancel"
                            className="cursor-pointer"
                            onClick={() => onRemoveFile("leaseDocPath")}
                          />
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div className="form-group">
                      <div className="white-block textarea-label fileupload-rw">
                        <label>Upload lease *</label>
                        <div className="file-upload">
                          <input
                            className="form-control"
                            type="file"
                            name="leasePolicy"
                            ref={
                              esginType === "docusign" &&
                              register({
                                required: "Lease policy is required.",
                              })
                            }
                          />
                          <label>
                            {get(leasePolicy, "[0].name") ||
                              "upload lease policy file"}
                          </label>
                        </div>
                      </div>
                      <ErrorMessage
                        errors={errors}
                        name="leasePolicy"
                        as="span"
                        className="error-msg"
                      />
                    </div>
                  )}
                </Grid.Column>
                {esginType === "docusign" && (
                  <Grid.Column mobile={16} tablet={16} computer={16}>
                    {addedDocumentsPath.applicationDocPath ? (
                      <div className="form-group">
                        <div className="white-block policy-wrap">
                          <h3 className="m-t-4">Application Policy</h3>
                          <div className="policy-col">
                            <p>
                              <i className="fa fa-file" aria-hidden="true" />
                              <a
                                href={addedDocumentsPath.applicationDocPath}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                View Application Policy
                              </a>
                            </p>
                            <Icon
                              name="cancel"
                              className="cursor-pointer"
                              onClick={() => onRemoveFile("applicationDocPath")}
                            />
                          </div>
                        </div>
                      </div>
                    ) : (
                      <div className="form-group">
                        <div className="white-block textarea-label fileupload-rw">
                          <label>Upload application </label>
                          <div className="file-upload">
                            <input
                              className="form-control"
                              type="file"
                              name="applicationPolicy"
                              ref={esginType === "docusign" && register()}
                            />
                            <label>
                              {get(applicationPolicy, "[0].name") ||
                                "upload application policy file"}
                            </label>
                          </div>
                        </div>
                        <ErrorMessage
                          errors={errors}
                          name="applicationPolicy"
                          as="span"
                          className="error-msg"
                        />
                      </div>
                    )}
                  </Grid.Column>
                )}
                <Grid.Column mobile={16} tablet={16} computer={16}>
                  {addedDocumentsPath.petPolicyDocPath ? (
                    <div className="form-group">
                      <div className="white-block policy-wrap">
                        <h3 className="m-t-4">Pet Policy</h3>
                        <div className="policy-col">
                          <p>
                            <i className="fa fa-file" aria-hidden="true" />
                            <a
                              href={addedDocumentsPath.petPolicyDocPath}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              View Pet Policy
                            </a>
                          </p>
                          <Icon
                            name="cancel"
                            className="cursor-pointer"
                            onClick={() => onRemoveFile("petPolicyDocPath")}
                          />
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div className="form-group">
                      <div className="white-block textarea-label fileupload-rw">
                        <label>Pet Policy</label>
                        <div className="file-upload">
                          <input
                            className="form-control"
                            type="file"
                            name="petPolicy"
                            ref={esginType === "docusign" && register()}
                          />
                          <label>
                            {get(petPolicy, "[0].name") ||
                              "upload pet policy file"}
                          </label>
                        </div>
                      </div>
                      <ErrorMessage
                        errors={errors}
                        name="petPolicy"
                        as="span"
                        className="error-msg"
                      />
                    </div>
                  )}
                </Grid.Column>
                <Grid.Column mobile={16} tablet={16} computer={16}>
                  {addedDocumentsPath.paymentPolicyDocPath ? (
                    <div className="form-group">
                      <div className="white-block policy-wrap">
                        <h3 className="m-t-4">Payment Policy</h3>
                        <div className="policy-col">
                          <p>
                            <i className="fa fa-file" aria-hidden="true" />
                            <a
                              href={addedDocumentsPath.paymentPolicyDocPath}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              View Payment Policy
                            </a>
                          </p>
                          <Icon
                            name="cancel"
                            className="cursor-pointer"
                            onClick={() => onRemoveFile("paymentPolicyDocPath")}
                          />
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div className="form-group">
                      <div className="white-block textarea-label fileupload-rw">
                        <label>Payment Policy</label>
                        <div className="file-upload">
                          <input
                            className="form-control"
                            type="file"
                            name="paymentPolicy"
                            ref={esginType === "docusign" && register()}
                          />
                          <label>
                            {get(paymentPolicy, "[0].name") ||
                              "upload payment policy file"}
                          </label>
                        </div>
                      </div>
                      <ErrorMessage
                        errors={errors}
                        name="paymentPolicy"
                        as="span"
                        className="error-msg"
                      />
                    </div>
                  )}
                </Grid.Column>
                <Grid.Column mobile={16} tablet={16} computer={16}>
                  {addedDocumentsPath.cancellationPolicyDocPath ? (
                    <div className="white-block policy-wrap">
                      <h3 className="m-t-4">Cancellation Policy</h3>
                      <div className="policy-col">
                        <p>
                          <i className="fa fa-file" aria-hidden="true" />
                          <a
                            href={addedDocumentsPath.cancellationPolicyDocPath}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            View Cancellation Policy
                          </a>
                        </p>
                        <Icon
                          name="cancel"
                          className="cursor-pointer"
                          onClick={() =>
                            onRemoveFile("cancellationPolicyDocPath")
                          }
                        />
                      </div>
                    </div>
                  ) : (
                    <div className="form-group">
                      <div className="white-block textarea-label fileupload-rw">
                        <label>Cancellation Policy</label>
                        <div className="file-upload">
                          <input
                            className="form-control"
                            type="file"
                            name="cancellationPolicy"
                            ref={esginType === "docusign" && register()}
                          />
                          <label>
                            {get(cancellationPolicy, "[0].name") ||
                              "upload cancellation policy file"}
                          </label>
                        </div>
                      </div>
                      <ErrorMessage
                        errors={errors}
                        name="cancellationPolicy"
                        as="span"
                        className="error-msg"
                      />
                    </div>
                  )}
                </Grid.Column>
              </>
            )}
            <Grid.Column mobile={16} tablet={16} computer={16}>
              <div className="btn-block">
                <div>
                  <button
                    type="button"
                    className="btn cancel-btn"
                    onClick={() => changeFormStep(0)}
                  >
                    Back
                  </button>
                </div>
                <div>
                  <button
                    type="submit"
                    disabled={checkFormInputs()}
                    className="btn next-btn"
                  >
                    Complete
                  </button>
                </div>
              </div>
            </Grid.Column>
          </Grid>
        </form>
      </div>
      {/* Credentials Modal */}
      <div
        className="modal fade benefits-modal credentials-modal"
        id="CredentialsModal"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <h5>Bluemoon Credentials</h5>
              <BluemoonSettings
                user={user}
                bluemoonData={bluemoonData}
                renderForm
                fetchCrDetails={fetchCredentialDetail}
                fetchBmProperties={fetchBluemoonProperties}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PaymentAndPoliciesForm;
