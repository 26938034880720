import React, { Component } from "react";
import { withApollo } from "react-apollo";
import mixpanel from "mixpanel-browser";
import { PaymentAccounting } from "../../store/person/accounting";
import { getClient } from "../../init-apollo-googleFn";
import get from "lodash/get";
import { Loader } from "../../components/Loader/Loader";
import { GeneralAccountingTable } from "../../components/Tables/GeneralAccountingTable";
import { ACCOUNTING } from "../../utils/constants";
import "react-toastify/dist/ReactToastify.css";
import "./Accounting.scss";
import { Button } from "semantic-ui-react";
import moment from "moment";
const invoiceClient = getClient(ACCOUNTING);

class ARCash extends Component {
  constructor(props) {
    super(props);
    this.state = {
      primary: this.props.selectedPrimary
        ? this.props.selectedPrimary.node
        : "",
      loading: false,
      offset: 0,
      tableData: [],
    };
  }

  componentDidMount() {
    this.getLedger();
    mixpanel.track("Manager Page Load", {
      sub: "AR Cash",
    });
  }
  componentDidUpdate(prevProps) {
    if (prevProps.selectedPrimary !== this.props.selectedPrimary) {
      const nodeData = this.props.selectedPrimary ? this.props.selectedPrimary.node : '';
      this.setState(
        { primary: nodeData, selectedMonthEvents: { ...this.state.selectedMonthEvents, montEvents: [] } },
        () => {
          this.getLedger();
        }
      );
    }
  }

  getLedger = () => {
    const { selectedPrimary } = this.props;
    const { offset } = this.state;
    const { cashCache } = this.state;

    this.setState({ loading: true });

    invoiceClient
      .query({
        query: PaymentAccounting,
        variables: {
          location: selectedPrimary.node.id,
          offset,
          limit: 999999999,
        },
      })
      .then(({ data }) => {
        const dataToPass = data.slLocationPayments.data.map(
          ({
            _id,
            type,
            status,
            paymentDate,
            postedDate,
            amount,
            memo,
            Location: { name },
            Invoice: { Agent, number },
            Unit,
          }) => {
            const uiType = `${type[0].toUpperCase()}${type.slice(1)}`;
            const person = Agent && Agent.name;
            const unit = Unit && Unit.number;
            const uiStatus = `${status[0].toUpperCase()}${status.slice(1)}`;
            const uiPaymentDate = moment(paymentDate).format("l");
            const uiPostedDate = moment(postedDate).format("l");
            const uiAmount = `$ ${Number.parseFloat(amount).toLocaleString(
              "en"
            )}`;

            return {
              _id,
              uiType,
              uiStatus,
              name,
              person,
              unit,
              number,
              uiPaymentDate,
              uiPostedDate,
              memo,
              uiAmount,
            };
          }
        );

        this.setState({
          tableData: dataToPass,
          totalItems: data.slLocationPayments.totalItems,
          loading: false,
        });
      })
      .catch((error) => {
        console.log(error);
        this.setState({ loading: false });
      });
  };

  dataPush = (response) => {
    this.setState((prevState) => ({
      cashCache: [
        response.data.createSlInvoice.slInvoice,
        ...prevState.cashCache,
      ],
    }));
  };
  updateInvoice = (status, invoiceId) => {
    const { cashCache } = this.state;

    const updatedCache = cashCache.map((invoice) => {
      if (invoice._id === invoiceId) {
        return { ...invoice, status };
      }
      return invoice;
    });

    this.setState({
      cashCache:
        status === "Delete"
          ? updatedCache.filter((invoice) => invoice._id !== invoiceId)
          : updatedCache,
    });
  };

  handleOffset = (x) => {
    this.setState({ offset: x });
  };

  render() {
    const { loading } = this.state;
    const mainHeader = [
      "Id",
      "Type",
      "Status",
      "Location",
      "User",
      "Unit",
      "Invoice",
      "Payment Date",
      "Posted Date",
      "Memo",
      "Amount",
    ];
    // !: Error handling.
    // TODO: Format data for user.
    return (
      <>
        <main className="main-content balance-sheet-layout" role="main">
          <div className="row mb-4">
            <div className="col-md-12">
              <div className="page-header flex-row mb-3">
                <div className="d-flex align-items-center">
                  <div>
                    <div className="page-header-title pl-0">Receive Cash</div>
                  </div>
                </div>
                <div className="d-flex align-items-end">
                  <Button
                    onClick={() => window.print()}
                    compact
                    className="ml-4 noPrint noPrint-button"
                    style={{
                      backgroundImage:
                        "linear-gradient(110deg, #3b1c5a, #374db1 162%)",
                    }}
                  >
                    Print
                  </Button>
                </div>
              </div>
            </div>
          </div>
          {loading ? (
            <Loader text inTable />
          ) : (
            <div className="balance-sheet-table">
              <GeneralAccountingTable
                mainCellData={this.state.tableData}
                mainHeader={mainHeader}
              />
            </div>
          )}
        </main>
      </>
    );
  }
}

export default withApollo(ARCash);
