import React, { useEffect } from "react";
import { Dropdown, Grid, Button, Modal, Icon } from "semantic-ui-react";
import { useState } from "react";

const templates = [
  {
    name: "Real Deal",
    templateId: 1,
  },
  {
    name: "Mindgrand",
    templateId: 2,
  },
  {
    name: "Quarter",
    templateId: 3,
  },
];


const Marketing = ({ locationId, organizationId, setTemplateMutation }) => {
  const [selectedTemplate, setSelectedTemplate] = React.useState("");
  const [modalOpen, setModalOpen] = React.useState(false);
  const [closeBtn, setCloseBtn] = useState(false);

  const templateOptions = templates.map((t, index) => ({
    key: index,
    text: t.name,
    value: t.name,
  }));

  const handleOnChange = (e, { value }) => {
    e.preventDefault();
    setSelectedTemplate(value);
  };

  const handleSetTemplate = () => {
    setTemplateMutation(
      templates.filter((t) => t.name === selectedTemplate)[0].templateId
    );
    setModalOpen(false);
  };

  /* eslint-disable */
  useEffect(() => {
    if (selectedTemplate !== "") {
      setTemplateMutation(
        templates.filter((t) => t.name === selectedTemplate)[0].templateId,
        true
      );
    }
  }, [selectedTemplate]);

  useEffect(() => {
    if (closeBtn === true) {
      setCloseBtn(false);
      setModalOpen(false);
    }
  }, [closeBtn]);
  /* eslint-enable */

  const closeFunction = () => {
    setTemplateMutation(null, true);
    setModalOpen(false)
  }

  return (
    <div className="microsite-height">
      <div className="property-manager-wrapper">
        <Grid className="microsite" divided="half-image-section">
          <Grid.Row>
            <Grid.Column className="microsites-modal-col-spacing">
              <h3>Marketing with Microsites!</h3>
              <p className="text">
                We've removed the hassle of setting up a website for your
                properties. With our microsite option, we'll take data you've
                already input when creating your properties and create a website
                for you to generate leads with. Select one of our templates and
                we will handle the rest!
              </p>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </div>
      <div className="white-block csv-block">
        <h4 className="microsites-modal-templates">
          {templates.length} {templates.length === 1 ? "Template" : "Templates"}
        </h4>
        <Dropdown
          placeholder="Select a Template"
          search
          selection
          className="microsites-modal-dropdown"
          options={templateOptions}
          onChange={handleOnChange}
          value={selectedTemplate}
        />
      </div>
      <div className="white-block modal-block">
        {selectedTemplate === "" ? (
          <p className="microsites-modal-not-selected">No template selected</p>
        ) : (
          <div>
            <Modal
              centered
              size="fullscreen"
              onClose={() => setModalOpen(false)}
              onOpen={() => setModalOpen(true)}
              open={modalOpen}
              trigger={
                <Button className="microsites-modal-trigger-button">
                  Show Template
                </Button>
              }
            >
              <Modal.Content>
                <Icon onClick={() => setModalOpen(false)} className="close" style={{color: 'red', position: 'absolute', right: '60px', top: '40px', height: '20px', width: '20px', backgroundColor: 'white', borderRadius: '50%', paddingTop: '2px'}}/>
                <div className="iframe-container">
                  <iframe
                    title="template-preview"
                    src={`https://estates.leasera.com/${organizationId}/${locationId}`}
                  />
                </div>
              </Modal.Content>
              <Modal.Actions className="microsites-modal-actions">
                <Button onClick={() => closeFunction()} color="black">Close</Button>
                <Button
                  onClick={() => handleSetTemplate()}
                >
                  Set Template
                </Button>
              </Modal.Actions>
            </Modal>
          </div>
        )}
      </div>
    </div>
  );
};

export default Marketing;
