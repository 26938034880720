import React from "react";
import {
  Button,
  Header,
  Image,
  Modal,
  Table,
  Grid,
  Tab,
  Form,
  Dropdown,
  Progress,
  Segment,
  Input,
  Select,
} from "semantic-ui-react";
import get from "lodash/get";
import {
  PurchaseOrderLineItems,
  WorkReceive,
} from "../../store/person/accounting";
import { getClient } from "../../init-apollo-googleFn";
import { INVOICE_ACCOUNTING_PAYMENT, ACCOUNTING } from "../../utils/constants";
import Datetime from "react-datetime";
import "react-datetime/css/react-datetime.css";
import moment from "moment";
import { toastFailMsg, toastSuccessMsg } from "../../utils/common";
import mixpanel from "mixpanel-browser";
import ViewInvoiceDetails from "../Accounting/ViewInvoiceDetails";
const paymentClient = getClient(INVOICE_ACCOUNTING_PAYMENT);
const accountingClient = getClient(ACCOUNTING);

function ReceiveOrder(props) {
  const [open, setOpen] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [POLineItems, setPOLItemArray] = React.useState([]);
  const [postingDate, setPostingDate] = React.useState(
    moment().format("MM/DD/YYYY")
  );
  const [refValue, changeRef] = React.useState("");
  const order = props.order;

  const actionReceive = () => {
    console.log(props);
    setLoading(true);
    accountingClient
      .mutate({
        mutation: WorkReceive,
        variables: {
          purchaseOrderId: props.order._id,
          receiveDate: moment(postingDate).format("YYYY-MM-DD"),
        },
      })
      .then((response) => {
        const responseData = response.data;
        props.getLedger();
        setLoading(false);
        setOpen(false);
        toastSuccessMsg("Bill has been received");
      })
      .catch((error) => {
        console.log(error);
        toastFailMsg("Something went wrong");
        setLoading(false);
      });
  };

  const getLineItems = () => {
    console.log(props);
    try {
      paymentClient
        .query({
          query: PurchaseOrderLineItems,
          variables: {
            PurchaseOrderId: props.order._id,
          },
        })
        .then((res) => {
          const purchaseData = get(
            res,
            "data.purchaseOrderLineItems.data",
            null
          );
          const purchaseArray = purchaseData.map((item) => ({
            description: item.description,
            ItemId: item._id,
            quantity: parseInt(item.quantity),
            amount: parseFloat(item.total).toFixed(2),
            LedgerAccountId: 0,
          }));
          if (res.data) {
            setPOLItemArray(purchaseArray);
          }
        })
        .catch((error) => {});
    } catch (e) {}
  };

  /* eslint-disable */
  return (
    <Modal
      className="semanticModal  overflow-y-visible"
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}
      size="small"
      open={open}
      trigger={
        <Button
          compact
          icon={props.bill ? true : false}
          onClick={() => getLineItems()}
          className="ml-3 button-hover-universal"
          size="tiny"
          id="getLedger"
        >
          <span>Receive</span>
        </Button>
      }
    >
      <Modal.Header></Modal.Header>
      <Modal.Content className="receiveContent">
        <div className="receiveLeft">
          {/* <Form.Field required> */}
            <h3 style={{textAlign:'center'}}>Receive Date</h3>
            <Datetime
              input={false}
              className="receive-times"
              value={postingDate}
              timeFormat={false}
              onChange={(e) => setPostingDate(e._d)}
            />
          {/* </Form.Field> */}
        </div>
        <div className="receiveRight">
        <h3 style={{textAlign:'center'}}>Line Items</h3>
          <Table padded="very" compact>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>#</Table.HeaderCell>
                <Table.HeaderCell>Description</Table.HeaderCell>
                <Table.HeaderCell>Quantity</Table.HeaderCell>
                <Table.HeaderCell>Amount</Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {POLineItems &&
                POLineItems.map((item) => {
                  return (
                    <Table.Row>
                      <Table.Cell>{item.ItemId}</Table.Cell>
                      <Table.Cell>{item.description}</Table.Cell>
                      <Table.Cell>{item.quantity}</Table.Cell>
                      <Table.Cell>$ {item.amount}</Table.Cell>
                    </Table.Row>
                  );
                })}
            </Table.Body>
          </Table>
        </div>
      </Modal.Content>
      <Modal.Actions>
        <Button onClick={() => setOpen(false)} content="Close" />
        <Button
          style={{ maxHeight: "36px", maxWidth: "86.45px", width: "86.45px" }}
          disabled={loading}
          loading={loading}
          onClick={() => actionReceive()}
          content="Receive"
        />
      </Modal.Actions>
    </Modal>
  );
}

export default ReceiveOrder;
