import gql from "graphql-tag";

export const leasesGql = gql`
  query Leases($locationId: String!, $sort: [LeaseTypeSortEnum]) {
    leases(locationId: $locationId, sort: $sort) {
      edges {
        node {
          id
          applicationId
          status
          amount
          localLease
          status
          moveInReady
          startDate
          lgState
          lgCompleted
          skipGuarantee
          backgroudCheckId
          insuranceCompleted
          extLeaseInfo
          insurancePolicy
          endDate
          leaseFrequency
          nonRefundable
          isProRata
          billOnDate
          unitProRata
          petProRata
          billOnDate
          vehicleProRata
          unit {
            id
            number
            deposit
            unitType {
              id
              type
            }
          }
          unitChargeCode {
            id
            name
            code
            amountDefault
            quantity
          }
          petChargeCode {
            id
            name
            code
            amountDefault
            quantity
          }
          vehicleChargeCode {
            id
            name
            code
            amountDefault
            quantity
          }
          depositChargeCode {
            id
            name
          }
          deposit
          guarantee
          firstLastInvoice
          extraCharges
          vehicleAmount
          petAmount
          unitAmount
          vehicleQuantity
          petQuantity
          unitQuantity
          extLeaseInfo
          person {
            firstName
            lastName
          }
          createdAt
        }
      }
    }
  }
`;


export const  leasePaidGql = gql`
  query paidLeases($locationId: String!) {
    paidLeases(locationId: $locationId)
  }
`;
export const  leaseActiveGql = gql`
  query  activatedResidents($locationId: String!) {
    activatedResidents(locationId: $locationId)
  }
`;
export const  leaseAutopayGql = gql`
  query  autopayResidents($locationId: String!) {
    autopayResidents(locationId: $locationId)
  }
`;

export const leaseHistoryGql = gql`
query leaseHistory($leaseId: String!) {
  leaseHistory(leaseId: $leaseId) {
    edges {
      node {
        id
        applicationId
        status
        amount
        status
        startDate
        unitProRata
        petProRata
        isProRata
        vehicleProRata
        billOnDate
        endDate
        moveInDate
        leaseFrequency
        nonRefundable
        firstLastInvoice
        moveInChecklist
        guarantee
        lgState
        application {
          renterPrimaryApplicant {
            id
            __typename
          }
          __typename
        }
        leaseType
        unit {
          id
          number
          deposit
          unitType {
            id
            type
            __typename
          }
          __typename
        }
        unitChargeCode {
          id
          name
          code
          amountDefault
          quantity
          __typename
        }
        petChargeCode {
          id
          name
          code
          amountDefault
          quantity
          __typename
        }
        vehicleChargeCode {
          id
          name
          code
          amountDefault
          quantity
          __typename
        }
        depositChargeCode {
          id
          name
          __typename
        }
        deposit
        extraCharges
        vehicleAmount
        petAmount
        unitAmount
        vehicleQuantity
        petQuantity
        unitQuantity
        person {
          firstName
          lastName
          email
          phoneMobile
          __typename
        }
        extLeaseInfo
        createdAt
        __typename
      }
      __typename
    }
    __typename
  }
}
`;

export const leaseDetails = gql`
  query Lease($leaseId: String!) {
    lease(leaseId: $leaseId) {
      edges {
        node {
          id
          applicationId
          status
          amount
          status
          startDate
          unitProRata
          petProRata
          isProRata
          vehicleProRata
          billOnDate
          endDate
          moveInDate
          leaseFrequency
          nonRefundable
          firstLastInvoice
          guarantee
          lgState
          application {
            renterPrimaryApplicant {
              id
            }
          }
          leaseType
          unit {
            id
            number
            deposit
            unitType {
              id
              type
            }
          }
          unitChargeCode {
            id
            name
            code
            amountDefault
            quantity
          }
          petChargeCode {
            id
            name
            code
            amountDefault
            quantity
          }
          vehicleChargeCode {
            id
            name
            code
            amountDefault
            quantity
          }
          depositChargeCode {
            id
            name
          }
          deposit
          extraCharges
          vehicleAmount
          petAmount
          unitAmount
          vehicleQuantity
          petQuantity
          unitQuantity
          person {
            firstName
            lastName
            email
            phoneMobile
          }
          extLeaseInfo
          createdAt
        }
      }
    }
  }
`;

export const createLease = gql`
  mutation createLease($input: CreateLeaseInput!) {
    createLease(input: $input) {
      response
    }
  }
`;



export const updateBulkEdit = gql`
  mutation bulkUpdateLease($input: BulkUpdateLeaseInput!) {
    bulkUpdateLease(input: $input) {
      response
    }
  }
`;

export const unitAvailabilityCheck = gql`
  query isUnitAvailable(
    $unitId: String!
    $startDate: DateTime!
    $endDate: DateTime!
  ) {
    isUnitAvailable(unitId: $unitId, startDate: $startDate, endDate: $endDate)
  }
`;

export const updateLease = gql`
  mutation updateLease($input: UpdateLeaseInput!) {
    updateLease(input: $input) {
      response
    }
  }
`;

export const getLeaseComments = gql`
  query comments($leaseId: String!) {
    comments(leaseId: $leaseId) {
      edges {
        node {
          id
          subject
          message
          commentType
          person {
            id
            firstName
            lastName
          }
          created
          updated
        }
      }
    }
  }
`;

export const createLeaseComment = gql`
  mutation ct($input: CreateLeaseCommentInput!) {
    createComment(input: $input) {
      response
    }
  }
`;

export const updateLeaseComment = gql`
  mutation updateCommet($input: UpdateLeaseCommentInput!) {
    updateComment(input: $input) {
      response
    }
  }
`;

export const deleteLeaseComment = gql`
  mutation DL($input: DeleteLeaseCommentInput!) {
    deleteComment(input: $input) {
      response
    }
  }
`;

export const getLeaseClaim = gql`
  query leaseClaim($leaseId: String!) {
    leaseClaim(leaseId: $leaseId)
  }
`;

export const proRate = gql`
  mutation PP($input: ProRataMutationInput!) {
    proRata(input: $input) {
      results {
        inputDate
        billOnDate
        finalAmount
      }
    }
  }
`;

export const locationPolicies = gql`
  query locationPolicies($locationId: String!) {
    locationPolicies(id: $locationId) {
      edges {
        node {
          id
          name
          url
        }
      }
    }
  }
`;

export const leaseDocuments = gql`
  mutation sendLeaseDocuments($input: SendLeaseDocumentsInput!) {
    sendLeaseDocuments(input: $input) {
      response
    }
  }
`
export const leaseDocumentsLocal = gql`
  mutation localLease($input: LocalLeaseInput!) {
    localLease(input: $input) {
      response
    }
  }
`
export const lgavailable = gql`
  query isLgAvailable($applicationId: String!) {
    isLgAvailable(applicationId: $applicationId)
  }
`;


export const renewLease = gql`
  mutation renewLease($input: RenewLeaseInput!) {
  renewLease(input: $input) {
        response
        __typename
  }
}
`;

export const RenewLeaseNotification = gql`
  mutation renewLeaseNotification($input:  RenewLeaseNotificationInput!) {
    renewLeaseNotification(input: $input) {
        response
  }
}
`;

export const LeaseCharges = gql`
  query leaseCharges($leaseId: String!) {
    leaseCharges(leaseId: $leaseId){
      edges{
        node{
          id
          scheduleTypeId
          chargeCodeId
          accountId
          lastDate
          nextDate
          schedulePattern
          amount
          refNum
          isDelete
          createdAt
          updatedAt
            chargeCode{
              id,
              name,
              type,
              quantity,
              code,
              desc,
              amountDefault
              metaData,
              extLedgerId,
              extProductId,
              extTaxcodeId
            }
          }
        }
    }
  }
`;


export const leasesExpiring = gql`
query leasesExpiringIntervalWise($locationId: String!, $interval: LeaseExpiringIntervals!) {
  leasesExpiringIntervalWise(locationId: $locationId, interval: $interval) {
    count
    percentage
    all
    leases {
        id
        applicationId
        amount
        endDate
        unit {
          id
          number
        }
        person {
          firstName
          lastName
        }
        notificationDate
    }
  }
}
`;

export const CreateEnvelop = gql`
  mutation createEnvelop($input:CreateEnvelopeInput!) {
    envelopeByTemplate(lease: $input) {
    response
    lease{
      accessToken
      tokenType
    }
  }
}
`;

export const RecipientView = gql`
  mutation recipientView($input: CreateEnvelopeInput!) {
    recipientView(lease: $input) {
      response
      docusign{
        url
      }
    }
  }
`;

export const DocusignView = gql`
 query docusignTemplateView($locationId: String!) {
  docusignTemplateView(locationId: $locationId) {
    edges{
      node {
        documentUrl
      }
    }
    }
  }
`;

export const AddConcessions = gql`
  mutation addConcessions($package: AddConcessionsInput!) {
    addConcessions(package: $package) {
      response
    }
  }
`

export const concessionsAmount = gql`
  query concessionsAmount($locationId: String!) {
    concessionsAmount(locationId: $locationId) {
      edges {
        node {
          id
          leaseId
          locationId
          concessionsAmount
          renterPersonId
          description
        }
      }
    }
  }
`;