import React, { useState, useEffect } from "react";
import { withApollo } from "react-apollo";
import mixpanel from "mixpanel-browser";
import { PaymentAccounting, TrialBalance } from "../../store/person/accounting";
import { getClient } from "../../init-apollo-googleFn";
import get from "lodash/get";
import { Loader } from "../../components/Loader/Loader";
import { GeneralAccountingTable } from "../../components/Tables/GeneralAccountingTable";
import { ACCOUNTING, ACCOUNTING_REPORTING } from "../../utils/constants";
import "react-toastify/dist/ReactToastify.css";
import "./Accounting.scss";
import { Button, Dropdown, Table, Pagination } from "semantic-ui-react";
import moment from "moment";
import { jsPDF } from "jspdf";
import "jspdf-autotable";

const invoiceClient = getClient(ACCOUNTING_REPORTING);

const TrialReport = (props) => {
  const [primary, setPrimary] = useState(
    props.selectedPrimary ? props.selectedPrimary.node : ""
  );
  const [month, setMonth] = useState(3);
  const [status, setStatus] = useState("posted");
  const [loading, setLoading] = useState(false);
  const [offset, setOffset] = useState(0);
  const [tableData, setTableData] = useState([]);

  const [currentPage, setCurrentPage] = useState(0);
  const [itemsPerPage, setItemsPerPage] = useState(100);

  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;

  const paginatedTableData = tableData.slice(startIndex, endIndex);

  useEffect(() => {
    getTrialBalance();
  }, [props.selectedPrimary]);
  useEffect(() => {
    getTrialBalance();
  }, [month]);
  useEffect(() => {
    getTrialBalance();
  }, [currentPage]);

  const getTrialBalance = (location) => {
    setLoading(true)
    const currentDate = moment();
    const ranges = [];

    for (let i = 0; i < month; i++) {
      const start = moment(currentDate).subtract(i, "months").startOf("month");
      const end = moment(currentDate).subtract(i, "months").endOf("month");
      ranges.push({
        start: start.format("YYYY-MM-DD"),
        end: end.format("YYYY-MM-DD"),
      });
    }
    invoiceClient
      .query({
        query: TrialBalance,
        variables: {
          slLocationId: props?.selectedPrimary?.node?.slLocationId,
          dateRanges: ranges,
          status: status,
          consolidated: true,
          limit: 100,
          offset: currentPage,
        },
      })
      .then(({ data }) => {
        const tableData = data.trialBalance.data.map((item) => ({
          id: item._id,
          number: item.number,
          name: item.name,
          type: item.type,
          balance: item.balances,
        }));

        console.log(tableData);
        setTableData(tableData);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      }).finally(() => {
        setLoading(false);
      });
  };

  const stateDataToPDF = () => {
    const doc = new jsPDF();
    const header = [
      "Number",
      "Name",
      "Type",
      ...Array.from(
        { length: Math.max(...tableData.map((item) => item.balance.length)) },
        (_, i) => {
          const monthIndex = moment().month();
          const monthName = moment().month(monthIndex - (Math.max(...tableData.map((item) => item.balance.length)) - i - 1)).format("MMM");
          return `${monthName}`;
        }
      ),
    ];
  
    // Add table header
    doc.setFontSize(15);
    doc.setTextColor(40);
    doc.text("Trial Balance", 14, 15);
  
    // Convert data to array of arrays
    const data = tableData.map((item) => {
      const balanceFields = item.balance.map((balance) => {
        return(
         `$`+ parseFloat(balance)

        )
      });
      return [
        item.number,
        item.name,
        item.type,
        ...balanceFields,
      ];
    });
  
    // Add table
    doc.setFontSize(11);
    doc.setTextColor(0);
    doc.autoTable({
      head: [header],
      body: data,
      theme: "grid",
      margin: { top: 20 },
      styles: { overflow: "linebreak", fontSize: 8 },
      minCellWidth: 30, // Set a minimum width for all columns
      headStyles: {
        fillColor: [214, 202, 232], // Set the background color to light purple
        textColor: 0, // Set the text color to black
      },
    });
  
    // Output PDF
    doc.save("Trial Balance.pdf");
  };
  
  
  const monthOptions = [
    { key: 1, text: "1", value: 1 },
    { key: 2, text: "2", value: 2 },
    { key: 3, text: "3", value: 3 },
    { key: 4, text: "4", value: 4 },
    { key: 5, text: "5", value: 5 },
    { key: 6, text: "6", value: 6 },
    { key: 7, text: "7", value: 7 },
    { key: 8, text: "8", value: 8 },
    { key: 9, text: "9", value: 9 },
    { key: 10, text: "10", value: 10 },
    { key: 11, text: "11", value: 11 },
    { key: 12, text: "12", value: 12 },
  ];
  const statusOptions = [
    { key: "created", text: "Created", value: "created" },
    { key: "posted", text: "Posted", value: "posted" },
  ];
  const handleMonthChange = (e, { value }) => {
    setMonth(value);
  };

  const handleStatusChange = (e, { value }) => {
    setStatus(value);
  };

  const TableProp = () => {
    if (tableData.length === 0) {
      return null;
    }

    const today = new Date();
    const months = Array.from(
      { length: tableData[0].balance.length },
      (_, i) => {
        const date = new Date(today.getFullYear(), today.getMonth() - i, 1);
        return date.toLocaleString("default", { month: "short" });
      }
    ).reverse();

    return (
      <>
        <div>
          <Table celled>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>Id</Table.HeaderCell>
                <Table.HeaderCell>Number</Table.HeaderCell>
                <Table.HeaderCell>Name</Table.HeaderCell>
                <Table.HeaderCell>Type</Table.HeaderCell>
                {[
                  ...months.map((month, index) => ({
                    key: `balance${index + 1}`,
                    header: month,
                  })),
                ].map((column, index, array) => (
                  <>
                    <Table.HeaderCell key={column.key}>
                      {array.length - index <= tableData[0].balance.length
                        ? column.header
                        : ""}
                    </Table.HeaderCell>
                  </>
                ))}
              </Table.Row>
            </Table.Header>

            <Table.Body>
              {tableData.map((item) => (
                <Table.Row key={item.id}>
                  {[
                    { key: "id", value: item.id },
                    { key: "number", value: item.number },
                    { key: "name", value: item.name },
                    { key: "type", value: item.type },
                    ...item.balance.map((balance, index) => ({
                      key: `balance${index + 1}`,
                      value: balance,
                    })),
                  ].map((column, index, array) => (
                    <Table.Cell key={column.key}>{column.value}</Table.Cell>
                  ))}
                </Table.Row>
              ))}
            </Table.Body>
          </Table>
        </div>
      </>
    );
  };

  return (
    <>
      <main className="main-content balance-sheet-layout" role="main">
        <div className="row mb-4">
          <div className="col-md-12">
            <div className="page-header flex-row mb-3">
              <div className="d-flex align-items-center">
                <div>
                  <div className="page-header-title pl-0">Trial Balance</div>
                </div>
              </div>
              <div className="d-flex align-items-end">
                <Dropdown
                  placeholder="Select Months"
                  fluid
                  selection
                  options={monthOptions}
                  value={month}
                  onChange={handleMonthChange}
                />
                <Dropdown
                  placeholder="Select Status"
                  fluid
                  selection
                  options={statusOptions}
                  value={status}
                  onChange={handleStatusChange}
                />
                <Button
                   onClick={() => stateDataToPDF()}
                  compact
                  className="ml-4 noPrint noPrint-button"
                  style={{
                    backgroundImage:
                      "linear-gradient(110deg, #3b1c5a, #374db1 162%)",
                  }}
                >
                  Print
                </Button>
              </div>
            </div>
          </div>
        </div>
        {loading ? (
          <Loader text inTable />
        ) : (
          <>
            <div style={{ maxHeight: "600px", minHeight: "600px" }} className="balance-sheet-table">
              {TableProp()}
            </div>
            <Pagination
              activePage={currentPage + 1}
              totalPages={Math.ceil(tableData.length / itemsPerPage)}
              onPageChange={(event, { activePage }) =>
                setCurrentPage(activePage - 1)
              }
            />
          </>
        )}
      </main>
    </>
  );
};

export default withApollo(TrialReport);
