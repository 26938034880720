import React, { useState, useEffect } from "react";
import { withApollo } from "react-apollo";
import {
  Button,
  Header,
  Icon,
  Label,
  Menu,
  Dropdown,
  Table,
  Checkbox,
} from "semantic-ui-react";
import mixpanel from "mixpanel-browser";
import moment from "moment";
import { toast } from "react-toastify";
import { FinancialReport } from "../../store/person/accounting";
import { getClient } from "../../init-apollo-googleFn";
import get from "lodash/get";
import Datetime from "react-datetime";
import _ from "lodash";
import { toastSuccessMsg, toastFailMsg } from "../../utils/common";
import { Loader } from "../../components/Loader/Loader";
import StripedBalanceTable from "../../components/Tables/NewBalanceTable";
import { ACCOUNTING, ACCOUNTING_REPORTING } from "../../utils/constants";
import "react-toastify/dist/ReactToastify.css";
import { jsPDF } from "jspdf";
import "jspdf-autotable";

const ledgerClient = getClient(ACCOUNTING);
const balanceClient = getClient(ACCOUNTING_REPORTING);

const BalanceSheet = (props) => {
  const [primary, setPrimary] = useState(
    props.selectedPrimary ? props.selectedPrimary.node : ""
  );
  const [loading, setLoading] = useState(false);
  const [propType, setPropType] = useState("Balance");
  const [totalBalance, setTotalBalance] = useState({});
  const [ledgerCache, setLedgerCache] = useState([]);
  const [startDate, setStartDate] = useState(
    moment().startOf("month").format("YYYY-MM-DD")
  );
  const [endDate, setEndDate] = useState(
    moment().endOf("month").format("YYYY-MM-DD")
  );
  const [singleLedger, setSingleLedger] = useState([]);
  const [ledgerCacheStable, setLedgerCacheStable] = useState({});
  const [ledgerCacheStablePL, setLedgerCacheStablePL] = useState({});
  const [loadingCompute, setLoadingCompute] = useState(false);
  const [offset, setOffset] = useState(0);
  const [view, setView] = useState(false);

  useEffect(() => {
    mixpanel.track("Manager Page Load", {
      sub: "Ledger",
    });
  }, []);

  useEffect(() => {
    getBalance();
  }, [props.selectedPrimary]);
  const stateDataToPDF = () => {
    const doc = new jsPDF();
    const header = ["Type","Id", "Name", "Subtype", "Balance"];

    // Add table header
    doc.setFontSize(15);
    doc.setTextColor(40);
    doc.text("Financial Report", 14, 15);
    console.log(ledgerCacheStable);
    // Convert data to array of arrays
    const arrData = [];
    const cache = propType === "Balance" ? ledgerCacheStable : ledgerCacheStablePL;
    Object.keys(cache).forEach((key) => {
      const rows = cache[key].map((item) => [
        item.type,
        item._id,
        item.name,
        item.subtype,
        item.balance < 0 ? `($${-item.balance})` : `$${item.balance}`,
      ]);
      arrData.push(...rows);
    });

    // Add table
    doc.setFontSize(11);
    doc.setTextColor(0);
    doc.autoTable({
      head: [header],
      body: arrData,
      theme: "grid",
      margin: { top: 20 },
      styles: { overflow: "linebreak", fontSize: 8 },
      minCellWidth: 30, // Set a minimum width for all columns
      headStyles: {
        fillColor: [214, 202, 232], // Set the background color to light purple
        textColor: 0, // Set the text color to black
      },
    });

    // Output PDF
    doc.save("Financial.pdf");
  };
  const getBalance = () => {
    setLoading(true);
    balanceClient
      .query({
        query: FinancialReport,
        variables: {
          startDate: startDate,
          endDate: endDate,
          slLocationId: props?.selectedPrimary?.node?.slLocationId,
        },
      })
      .then((res) => {
        const FinanceValues = res.data.financialReport;
        sortObjectsByType(FinanceValues);
        toastSuccessMsg("Generated");
      })
      .catch((error) => {
        toastFailMsg("Something went wrong");
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const sortObjectsByType = (objects) => {
    const sortedArrays = {};
    const sortedArraysPL = {};
    objects.forEach((object) => {
      const objectType = object.type;
      if (objectType === "Revenue" || objectType === "Expense") {
        sortedArraysPL[objectType] = sortedArraysPL[objectType] || [];
        sortedArraysPL[objectType].push(object);
      } else {
        sortedArrays[objectType] = sortedArrays[objectType] || [];
        sortedArrays[objectType].push(object);
      }
    });
    setLedgerCacheStable(sortedArrays);
    setLedgerCacheStablePL(sortedArraysPL);
  };

  const handleStartClick = (date) => {
    const startDate = moment(date).format("YYYY-MM-DD");
    setStartDate(startDate);
  };

  const handleEndClick = (date) => {
    const endDate = moment(date).format("YYYY-MM-DD");
    setEndDate(endDate);
  };

  return (
    <>
      <main className="main-content balance-sheet-layout" role="main">
        <div className="row mb-4">
          <div className="col-md-12">
            <div className="page-header">
              <div className="d-flex align-items-center">
                <div>
                  <div className="page-header-title pl-0">Financials</div>
                </div>
              </div>
              <div className="d-flex align-items-end">
                <div className="ml-3">
                  <Header textAlign="center">Start Date</Header>
                  <Datetime
                    inputProps={{ placeholder: "Select Start Date" }}
                    defaultValue={moment().startOf("month")}
                    timeFormat={false}
                    onChange={handleStartClick}
                  />
                </div>
                <div className="ml-3">
                  <Header textAlign="center">End Date</Header>
                  <Datetime
                    inputProps={{ placeholder: "Select End Date" }}
                    defaultValue={moment().endOf("month")}
                    timeFormat={false}
                    onChange={handleEndClick}
                  />
                </div>{" "}
                &nbsp;&nbsp;&nbsp;
                <Dropdown text="Select Type" style={{ marginBottom: "10px" }}>
                  <Dropdown.Menu>
                    <Dropdown.Item
                      onClick={() => setPropType("Balance")}
                      text="Balance Sheet"
                    />
                    <Dropdown.Item
                      onClick={() => setPropType("Income")}
                      text="Income Statement"
                    />
                  </Dropdown.Menu>
                </Dropdown>
              </div>
              <div className="d-flex align-items-end mt-3 mt-md-0">
                <Button
                  disabled={loadingCompute}
                  compact
                  onClick={() => getBalance()}
                  className="ml-0 ml-md-3 button-hover-universal"
                >
                  {loadingCompute ? (
                    <span>
                      Processing...
                      <Button
                        style={{ paddingBottom: "0px" }}
                        compact
                        loading
                      ></Button>
                    </span>
                  ) : (
                    <span>Generate</span>
                  )}
                </Button>
                <Button
                  onClick={() => stateDataToPDF()}
                  compact
                  className="ml-4 noPrint noPrint-button"
                  style={{
                    backgroundImage:
                      "linear-gradient(110deg, #3b1c5a, #374db1 162%)",
                  }}
                >
                  Print
                </Button>
              </div>
            </div>
          </div>
        </div>
        {loading ? (
          <Loader text inTable />
        ) : (
          <>
            {propType == "Balance" ? (
              <div className="balance-sheet-simplified">
                <Header textAlign="center">Balance Sheet</Header>
                <Table compact="very" celled>
                  <Table.Body>
                    {Object.entries(ledgerCacheStable).map(
                      ([header, objects]) => (
                        <React.Fragment key={header}>
                          <Table.Row>
                            <Table.HeaderCell colSpan="4">
                              {header}
                            </Table.HeaderCell>
                          </Table.Row>
                          <Table.Row>
                            <Table.HeaderCell>Id</Table.HeaderCell>
                            <Table.HeaderCell>Name</Table.HeaderCell>
                            <Table.HeaderCell>Subtype</Table.HeaderCell>
                            <Table.HeaderCell>Balance</Table.HeaderCell>
                          </Table.Row>
                          {objects.map((object) => (
                            <Table.Row key={object._id}>
                              <Table.Cell>{object._id}</Table.Cell>
                              <Table.Cell>{object.name}</Table.Cell>
                              <Table.Cell>{object.subtype}</Table.Cell>
                              <Table.Cell
                                style={{
                                  color: object.balance < 0 ? "red" : "blue",
                                }}
                              >
                                $
                                {object.balance < 0
                                  ? `(${-object.balance})`
                                  : object.balance > 0
                                  ? `${object.balance}`
                                  : 0}
                              </Table.Cell>
                            </Table.Row>
                          ))}
                        </React.Fragment>
                      )
                    )}
                  </Table.Body>
                </Table>
              </div>
            ) : (
              <div className="balance-sheet-simplified">
                <Header textAlign="center">Income Statement</Header>
                <Table compact="very" celled>
                  <Table.Body>
                    {Object.entries(ledgerCacheStablePL).map(
                      ([header, objects]) => (
                        <React.Fragment key={header}>
                          <Table.Row>
                            <Table.HeaderCell colSpan="4">
                              {header}
                            </Table.HeaderCell>
                          </Table.Row>
                          <Table.Row>
                            <Table.HeaderCell>Id</Table.HeaderCell>
                            <Table.HeaderCell>Name</Table.HeaderCell>
                            <Table.HeaderCell>Subtype</Table.HeaderCell>
                            <Table.HeaderCell>Balance</Table.HeaderCell>
                          </Table.Row>
                          {objects.map((object) => (
                            <Table.Row key={object._id}>
                              <Table.Cell>{object._id}</Table.Cell>
                              <Table.Cell>{object.name}</Table.Cell>
                              <Table.Cell>{object.subtype}</Table.Cell>
                              <Table.Cell
                                style={{
                                  color: object.balance < 0 ? "red" : "blue",
                                }}
                              >
                                $
                                {object.balance < 0
                                  ? `(${-object.balance})`
                                  : object.balance > 0
                                  ? `${object.balance}`
                                  : 0}
                              </Table.Cell>
                            </Table.Row>
                          ))}
                        </React.Fragment>
                      )
                    )}
                  </Table.Body>
                </Table>
              </div>
            )}
          </>
        )}
      </main>
    </>
  );
};

export default withApollo(BalanceSheet);
