import React, { Component } from "react";
import {
  Icon,
  Form,
  Button,
  Modal,
  Input,
  Transition,
  Image,
  Header,
  Radio,
  Grid,
  List,
  Divider,
  Feed,
  Tab,
  Checkbox,
  Dropdown,
  Card,
} from "semantic-ui-react";
import { withApollo } from "react-apollo";
import {
  createTourType,
  TourTypes,
  deleteTourType,
} from "../../store/person/leads";
import { MONTH_NUMS, YEAR_NUMS, LEADS_TOUR_TYPE } from "../../utils/constants";
import _ from "lodash";
import { getClient } from "../../init-apollo-googleFn";
import { toast } from "react-toastify";
import moment from "moment";

import "react-toastify/dist/ReactToastify.css";

function BucketExpansion(props) {
  const [open, setOpen] = React.useState(false);
  const [gig, setGig] = React.useState(0);
  const [step, PaymentGig] = React.useState(1);
  const [ccNumber, setCCNumber] = React.useState("");
  const [logo, setLogo] = React.useState("");
  const [userName, setUserName] = React.useState("");
  const [expirationMonth, setExpirationMonth] = React.useState(MONTH_NUMS[0]);
  const [expirationYear, setExpirationYear] = React.useState(YEAR_NUMS[0]);
  const [cvv, setCVV] = React.useState("");
  const [postalCode, setPostalCode] = React.useState("");

  const resetAll = () => {
    PaymentGig(1);
    setGig(0);
  };

  const alternateStep = (step) => {
    PaymentGig(0);
    setTimeout(() => {
      PaymentGig(2);
    }, 1000);
  };

  React.useEffect(() => {
    GetCardType();
  });

  const GetCardType = () => {
    if (/^4/.test(ccNumber)) {
      setLogo("logo-visa.svg");
    } else if (
      /^(5[1-5][0-9]{14}|2(22[1-9][0-9]{12}|2[3-9][0-9]{13}|[3-6][0-9]{14}|7[0-1][0-9]{13}|720[0-9]{12}))$/.test(
        ccNumber
      )
    ) {
      setLogo("master-logo.svg");
    } else if (/^3[47][0-9]{13}$/.test(ccNumber)) {
      setLogo("logo-amex.svg");
    } else if (
      /^(6011|622(12[6-9]|1[3-9][0-9]|[2-8][0-9]{2}|9[0-1][0-9]|92[0-5]|64[4-9])|65)/.test(
        ccNumber
      )
    ) {
      setLogo("logo-discover.svg");
    } else if (/^35(2[89]|[3-8][0-9])/.test(ccNumber)) {
      setLogo("logo-jcb.svg");
    } else if (/^3(?:0[0-5]|[68][0-9])[0-9]{11}$/.test(ccNumber)) {
      setLogo("logo-diners.svg");
    }
    return "";
  };

  return (
    <>
      <Modal
        className="semanticModal"
        style={{ maxHeight: "542px", minHeight: "542px", overflow: "hidden" }}
        onClose={() => setOpen(false)}
        onOpen={() => setOpen(true)}
        open={open}
        size="tiny"
        trigger={
          <Card
            onClick={() => resetAll()}
            className="settings-second-step"
            style={{ border: "2px solid" }}
          >
            <Card.Content>
              <Card.Header textAlign="center">Storage Size</Card.Header>
            </Card.Content>
          </Card>
        }
      >
        <Modal.Header>
          <Header
            // style={{ background: "#343c49", color: "#F2FCFB" }}
            textAlign="center"
          >
            Increase Storage Size
          </Header>
        </Modal.Header>
        <Modal.Content style={{ paddingBottom: "8px" }}>
          <Header textAlign="center">Storage</Header>
          <Grid columns="2">
            <Grid.Column>
              <List divided relaxed>
                <List.Item>
                  <List.Icon
                    name="dollar sign"
                    size="large"
                    verticalAlign="middle"
                  />
                  <List.Content className="overridelist">
                    <List.Header as="a">Current Cost</List.Header>
                    <List.Description as="a">$25.00/Monthly</List.Description>
                  </List.Content>
                </List.Item>
                <List.Item>
                  <List.Icon
                    name="database"
                    size="large"
                    verticalAlign="middle"
                  />
                  <List.Content className="overridelist">
                    <List.Header as="a">Usage</List.Header>
                    <List.Description as="a">150G/256G</List.Description>
                  </List.Content>
                </List.Item>
                <List.Item>
                  <List.Icon name="image" size="large" verticalAlign="middle" />
                  <List.Content className="overridelist">
                    <List.Header as="a">Image Usage</List.Header>
                    <List.Description as="a">3000/5000</List.Description>
                  </List.Content>
                </List.Item>
              </List>
            </Grid.Column>
            <Grid.Column>
              <List divided relaxed>
                <List.Item>
                  <List.Icon
                    name="calendar alternate outline"
                    size="large"
                    verticalAlign="middle"
                  />
                  <List.Content className="overridelist">
                    <List.Header as="a">Per Location</List.Header>
                    <List.Description as="a">~5/Location</List.Description>
                  </List.Content>
                </List.Item>
                <List.Item>
                  <List.Icon name="image" size="large" verticalAlign="middle" />
                  <List.Content className="overridelist">
                    <List.Header as="a">Updated</List.Header>
                    <List.Description as="a">3/31/2023</List.Description>
                  </List.Content>
                </List.Item>
                <List.Item>
                  <List.Icon
                    name="cancel"
                    size="large"
                    verticalAlign="middle"
                  />
                  <List.Content className="overridelist">
                    <List.Header style={{ color: "red" }}>
                      Remove Data
                    </List.Header>
                    <List.Description as="a"></List.Description>
                  </List.Content>
                </List.Item>
              </List>
            </Grid.Column>
          </Grid>
          <Transition.Group animation={"scale"} duration={500}>
            {step == 1 && (
              <Grid columns="2">
                <Grid.Column>
                  <Card
                    onClick={() => setGig(1)}
                    style={{ background: `${gig == 1 ? "#c4bbcf" : "white"}` }}
                  >
                    <Card.Content>
                      <Card.Header textAlign="center">1G</Card.Header>
                      <Card.Meta textAlign="center">~500 Images</Card.Meta>
                      <Card.Description></Card.Description>
                    </Card.Content>
                  </Card>
                </Grid.Column>
                <Grid.Column>
                  <Card
                    onClick={() => setGig(2)}
                    style={{ background: `${gig == 2 ? "#c4bbcf" : "white"}` }}
                  >
                    <Card.Content>
                      <Card.Header textAlign="center">3G</Card.Header>
                      <Card.Meta textAlign="center">~1500 Images</Card.Meta>
                      <Card.Description></Card.Description>
                    </Card.Content>
                  </Card>
                </Grid.Column>
                <Grid.Column>
                  <Card
                    onClick={() => setGig(3)}
                    style={{ background: `${gig == 3 ? "#c4bbcf" : "white"}` }}
                  >
                    <Card.Content>
                      <Card.Header textAlign="center">5G</Card.Header>
                      <Card.Meta textAlign="center">~2500 Images</Card.Meta>
                      <Card.Description></Card.Description>
                    </Card.Content>
                  </Card>
                </Grid.Column>
                <Grid.Column>
                  <Card
                    onClick={() => setGig(4)}
                    style={{ background: `${gig == 4 ? "#c4bbcf" : "white"}` }}
                  >
                    <Card.Content>
                      <Card.Header textAlign="center">10G</Card.Header>
                      <Card.Meta textAlign="center">~10000 Images</Card.Meta>
                      <Card.Description></Card.Description>
                    </Card.Content>
                  </Card>
                </Grid.Column>
              </Grid>
            )}
            {step == 2 && (
              <>
                <div className="card-holder">
                  <input
                    style={{ display: "none" }}
                    type="text"
                    // value={email}
                    name="email"
                    id="email"
                    className="form-control"
                  ></input>
                  {/* <img src={logo && `/assets/img/${logo}`} alt=""></img> */}
                  <div className="form-rw">
                    <input
                      type="text"
                      value={
                        ccNumber.length === 15
                          ? ccNumber.replace(
                              /\b(\d{4})(\d{6})(\d{5})\b/,
                              "$1-$2-$3"
                            )
                          : ccNumber.toString().replace(/\d{4}(?=.)/g, "$&-")
                      }
                      onChange={(event) => {
                        setCCNumber(event.target.value.replace(/[^0-9]/g, ""));
                      }}
                      name="number"
                      id="cc-number"
                      className="form-control"
                    ></input>
                  </div>
                  <div className="footer-data">
                    <div className="left-data">
                      <label>Card Holder Name</label>
                      <input
                        type="text"
                        value={userName}
                        onChange={(e) => setUserName(e.target.value)}
                        name="name"
                        id="name"
                        className="form-control name-field"
                      ></input>
                    </div>
                    <div className="right-data">
                      <label>Month</label>
                      <select
                        style={{ border: "none" }}
                        name="month"
                        id="cc-month"
                        className="form-control-select"
                        value={expirationMonth}
                        onChange={(e) => setExpirationMonth(e.target.value)}
                      >
                        {Object.keys(MONTH_NUMS).map((key, val) => {
                          return (
                            <option value={MONTH_NUMS[val]}>
                              {MONTH_NUMS[val]}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                    <div className="right-data">
                      <label>Year</label>
                      <select
                        style={{ border: "none" }}
                        name="year"
                        id="cc-year"
                        className="form-control-select"
                        value={expirationYear}
                        onChange={(e) => setExpirationYear(e.target.value)}
                      >
                        {Object.keys(YEAR_NUMS).map((key, val) => {
                          return (
                            <option value={YEAR_NUMS[val]}>
                              {YEAR_NUMS[val]}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                  </div>
                  <div className="form-rws">
                    <div className="form-group">
                      <label>Billing Zip</label>
                      <input
                        type="tel"
                        value={postalCode}
                        maxlength="5"
                        onChange={(event) => {
                          setPostalCode(
                            event.target.value.replace(/[^0-9]/g, "")
                          );
                        }}
                        name="zip"
                        id="postal-code"
                        className="form-control"
                      ></input>
                    </div>
                    <div className="form-group">
                      <label>CVV</label>
                      <input
                        type="tel"
                        value={cvv}
                        onChange={(event) => {
                          setCVV(event.target.value.replace(/[^0-9]/g, ""));
                        }}
                        name="cvv"
                        id="cc-cvv"
                        className="form-control"
                      ></input>
                    </div>
                  </div>
                </div>
                <Grid columns="6" syle={{ paddingBottom: "5px" }}>
                  <Grid.Column
                    style={{ paddingRight: "0px", paddingLeft: "0px" }}
                    textAlign="center"
                  >
                    <Image
                      style={{
                        transition:
                          logo === "logo-visa.svg" &&
                          `transform 0.3s ease-in-out, border-color 0.3s ease-in-out`,
                        transform: logo === "logo-visa.svg" && `scale(1.5)`,
                        border: logo === "logo-visa.svg" && `1px solid #c4bbcf`,
                      }}
                      inline
                      src={`/assets/img/logo-visa.svg`}
                    />
                  </Grid.Column>
                  <Grid.Column
                    style={{ paddingRight: "0px", paddingLeft: "0px" }}
                    textAlign="center"
                  >
                    <Image
                    style={{
                      marginTop: "5px",
                      transition:
                        logo === "master-logo.svg" &&
                        `transform 0.3s ease-in-out, border-color 0.3s ease-in-out`,
                      transform: logo === "master-logo.svg" && `scale(1.5)`,
                      border: logo === "master-logo.svg" && `1px solid #c4bbcf`,
                    }}
                      inline
                      src={`/assets/img/master-logo.svg`}
                    />
                  </Grid.Column>
                  <Grid.Column
                    style={{ paddingRight: "0px", paddingLeft: "0px" }}
                    textAlign="center"
                  >
                    <Image
                     style={{
                      transition:
                        logo === "logo-amex.svg" &&
                        `transform 0.3s ease-in-out, border-color 0.3s ease-in-out`,
                      transform: logo === "logo-amex.svg" && `scale(1.5)`,
                      border: logo === "logo-amex.svg" && `1px solid #c4bbcf`,
                    }}
                      inline
                      src={`/assets/img/logo-amex.svg`}
                    />
                  </Grid.Column>
                  <Grid.Column
                    style={{ paddingRight: "0px", paddingLeft: "0px" }}
                    textAlign="center"
                  >
                    <Image
                    style={{
                      transition:
                        logo === "logo-discover.svg" &&
                        `transform 0.3s ease-in-out, border-color 0.3s ease-in-out`,
                      transform: logo === "logo-discover.svg" && `scale(1.5)`,
                      border: logo === "logo-discover.svg" && `1px solid #c4bbcf`,
                    }}
                      inline
                      src={`/assets/img/logo-discover.svg`}
                    />
                  </Grid.Column>
                  <Grid.Column
                    style={{ paddingRight: "0px", paddingLeft: "0px" }}
                    textAlign="center"
                  >
                    <Image
                    style={{
                      transition:
                        logo === "logo-diners.svg" &&
                        `transform 0.3s ease-in-out, border-color 0.3s ease-in-out`,
                      transform: logo === "logo-diners.svg" && `scale(1.5)`,
                      border: logo === "logo-diners.svg" && `1px solid #c4bbcf`,
                    }}
                      inline
                      src={`/assets/img/logo-diners.svg`}
                    />
                  </Grid.Column>
                  <Grid.Column
                    style={{ paddingRight: "0px", paddingLeft: "0px" }}
                    textAlign="center"
                  >
                    <Image
                      style={{
                        transition:
                          logo === "logo-jcb.svg" &&
                          `transform 0.3s ease-in-out, border-color 0.3s ease-in-out`,
                        transform: logo === "logo-jcb.svg" && `scale(1.5)`,
                        border: logo === "logo-jcb.svg" && `1px solid #c4bbcf`,
                      }}
                      inline
                      src={`/assets/img/logo-jcb.svg`}
                    />
                  </Grid.Column>
                </Grid>
              </>
            )}
          </Transition.Group>
        </Modal.Content>
        {/* <Modal.Actions style={{padding:'0px', margin:'0px'}}> */}
        <Button
          attached="bottom"
          style={{
            background: "#c4bbcf",
            position: "fixed",
            bottom: "0px",
            width: "100%",
          }}
          disabled={gig == 0}
          fluid
          onClick={() => alternateStep(2)}
        >
          Purchase
        </Button>
        {/* </Modal.Actions> */}
      </Modal>
    </>
  );
}

export default withApollo(BucketExpansion);
