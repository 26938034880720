import React from "react";

export const TenantKeyModal = (props) => {
  return (
    <div
      className="modal fade"
      id="tenantKeyModal"
      tabIndex="-1"
      role="dialog"
      aria-labelledby="cardModal"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content">
          <div className="modal-header">
            <h5
              className="modal-title"
              id="exampleModalLabel"
              style={{ textTransform: `capitalize` }}
            >
              REM
            </h5>
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <center>
              <p style={{ fontSize: "1.5rem" }}>Key given to Tenant</p>
            </center>
            <center>
              <p style={{ color: "red" }}>
                Confirming will move Tenant to designated Tenants list
              </p>
            </center>
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-primary"
              data-dismiss="modal"
            >
              Confirm
            </button>
            <button
              type="button"
              className="btn btn-danger"
              data-dismiss="modal"
            >
              {" "}
              Cancel
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
