import React, { Component } from "react";
import { qpAddScrollbar } from "../../utils/misc";
import { RentalHistory } from "./InformationComponent/RentalHistory.jsx";
import { Employment } from "./InformationComponent/Employment.jsx";
import { CriminalHistory } from "./InformationComponent/CriminalHistory.jsx";
import { Credit } from "./InformationComponent/Credit.jsx";
import { EvictionHistory } from "./InformationComponent/EvictionHistory.jsx";
import { References } from "./InformationComponent/References.jsx";
import "./ApplicantDetails.scss";
/*global $*/

import "./ApplicantDetails.scss";
class ApplicationHist extends Component {
  componentDidMount() {
    qpAddScrollbar(".card-media-list");
    this.ficoChart("#fico-dial-chart");
  }

  ficoChart(chartID) {
    if ($(chartID).length) {
      var color1 = $(chartID).data("chart-color-1");
      var color2 = $(chartID).data("chart-color-2");
      var color3 = $(chartID).data("chart-color-3");
      var color4 = $(chartID).data("chart-color-4");
      var color5 = $(chartID).data("chart-color-5");

      var chartSize = $(chartID).height() * 2;

      // Set the width of the Chart chartID
      $(chartID).width(chartSize);

      // Set inner text line-height
      $(chartID)
        .find(".score")
        .css({ "line-height": chartSize + "px" });

      $(chartID).easyPieChart({
        barColor: function (percent) {
          if (color2 === undefined) {
            return color1;
          } else {
            if (percent < 68.235) {
              return color5;
            } else if (percent >= 68.235 && percent < 78.823) {
              return color4;
            } else if (percent >= 78.823 && percent < 87.058) {
              return color3;
            } else if (percent >= 87.058 && percent < 94) {
              return color2;
            } else {
              return color1;
            }
          }
        },
        size: chartSize,
        lineCap: "round",
        lineWidth: 3,
        scaleColor: "#7A7A7A",
        trackColor: "#E8E8E8",
        animate: 2000,
        onStep: function (from, to, percent) {
          $(this.el)
            .find(".score")
            .text(Math.round(percent * 8.5));
        },
      });
    }
  }

  render() {
    return (
      <div className="applicant-hist-container">
        <div className="row">
          <div className="col-lg-6 mb-4">
            <RentalHistory />
          </div>
          <div className="col-lg-6 mb-4">
            <Employment />
          </div>
        </div>
        <div className="row">
          <div className="col-lg-6 mb-4">
            <CriminalHistory />
          </div>
          <div className="col-lg-6 mb-4">
            <Credit />
          </div>
        </div>
        <div className="row mb-4">
          <div className="col-lg-6 mb-4">
            <EvictionHistory />
          </div>
          <div className="col-lg-6 mb-4">
            <References />
          </div>
        </div>
      </div>
    );
  }
}

export default ApplicationHist;
