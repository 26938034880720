import React, { useEffect, useState } from "react";
import moment from "moment";
import { Button, Table } from "semantic-ui-react";
function Payments({ tenantPayments, concessions }) {
  const [currentBalance, setNumber] = useState(0);
  useEffect(() => {
   const addAll = tenantPayments&&tenantPayments.reduce((partialSum, a) => partialSum + +a.credit, 0)
    const subAll = tenantPayments&&tenantPayments.reduce((partialSum, a) => partialSum - +a.debit, 0)
    const Final = addAll + subAll
    setNumber(Final)
  },[tenantPayments]);
  return (
    <>
    <div className="theme-card card">
          <div className="card-body pb-2">
          <div className="payment-table-header">
          <div className="table-header-label">Total Concessions</div>
          <div className="table-header-label"></div>
          <div className="table-header-label"></div>
          <div className="table-header-label"></div>
          <div className="table-header-label"></div>
          <div className="table-header-label" style={{color:'blue', fontSize:'14px'}}>$
          {isNaN(concessions&&concessions.concessionsAmount)?"0":Math.abs(parseFloat(concessions&&concessions.concessionsAmount).toFixed(2)).toLocaleString("en")}
          </div>
       
      </div>
      </div>
    </div>
      <div className="row">
        <div className="col-md-12">
          <div className="theme-card card">
            <div className="card-header">
              <div className="card-header-title">Payment History</div>
            </div>
            <div className="card-body pb-2">
              <div className="payment-table-header">
              <div className="table-header-label-payment">Status</div>
                <div className="table-header-label-payment">Date</div>
                <div className="table-header-label-payment">Payer</div>
                <div className="table-header-label-payment">Description</div>
                <div className="table-header-label-payment">Reference</div>
                <div className="table-header-label-payment">Charges</div>
                <div className="table-header-label-payment">Payments</div>
                <div className="table-header-label-payment">Balance</div>
              </div>
              <div style={{maxHeight:'250px', overflowY:'auto'}}>

              
              <Table>
              <Table.Body>
                {tenantPayments &&
                  tenantPayments.map((item) => {
                    return (
                 <Table.Row>
                {/* Status */}
                  <Table.Cell width="1" style={{textTransform:'capitalize'}}>{item.Journal.status}</Table.Cell>
                  <Table.Cell width="2">{item.transactionDate}</Table.Cell>
                  <Table.Cell width="2">{tenantPayments[0].Agent_name}</Table.Cell>
                  <Table.Cell width="7">{item.description !== null ?item.description:item.LedgerAccount_name}</Table.Cell>
                  <Table.Cell width="1">{item.Journal&&item.Journal.reference}</Table.Cell>
                  <Table.Cell width="1"> {item.credit !== "0"?<span style={{color:"red"}}>${item.credit}</span>:"--"}</Table.Cell>
                  <Table.Cell width="1"> {item.debit !== "0"?<span style={{color:'green'}}>${item.debit}</span>:"--"}</Table.Cell>
                  <Table.Cell width="1" style={{color:'blue'}}>$ {Number(item.Running_Balance) <0 ? `(${Math.abs(parseFloat(item.Running_Balance).toFixed(2)).toLocaleString("en")})` : `${Math.abs(parseFloat(item.Running_Balance).toFixed(2)).toLocaleString("en")}`}</Table.Cell>
                </Table.Row>
                );
                  })}
              </Table.Body>
              </Table>
              </div>
            </div>
          </div>
          <div className="theme-card card">
            <div className="card-body pb-2">
            <div className="payment-table-header">
              <div className="table-header-label">Total Balance</div>
              <div className="table-header-label"></div>
              <div className="table-header-label"></div>
              <div className="table-header-label"></div>
              <div className="table-header-label"></div>
              <div className="table-header-label" style={{color:'blue', fontSize:'14px'}}>$
              {Number(currentBalance) <0 ? `(${Math.abs(parseFloat(currentBalance).toFixed(2)).toLocaleString("en")})` : `${Math.abs(parseFloat(currentBalance).toFixed(2)).toLocaleString("en")}`}
              </div>
            </div>
            </div>
          </div>
          
        </div>
      </div>
    </>
  );
}

export default Payments;