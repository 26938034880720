import React, { useEffect, useState } from "react";
import LeaLogo from "../../assets/images/loader-logo.svg";

const LoadingText = () => {
  const [text, setText] = useState(null);
  const options = [
    "Herding cats...",
    "Sifting through mountains of paper...",
    "Constructing additional pylons...",
    "Programming the flux capacitor...",
    "Spinning up the hamster...",
    "Testing your patience...",
    "Twiddling thumbs...",
    "Winter is coming...",
    "Waking up the minions...",
    "Generating witty dialog...",
    "(Pay no attention to the man behind the curtain)",
    "...at least you're not on hold...",
    "It's still faster than you could draw it",
    "My other loading screen is much faster.",
    "Are we there yet?",
    "It's not you. It's me.",
    "Creating time-loop inversion field",
    "I feel like im supposed to be loading something...",
    "What do you call 8 Hobbits? A Hobbyte.",
    "Cleaning off the cobwebs...",
    "Pushing pixels...",
    "Where did all the internets go",
    "Work, work...",
    "Granting wishes...",
    "Stay awhile and listen..",
    "Feeding unicorns...",
    "Load it and they will come",
    "Optimizing the optimizer...",
    "You shall not pass! yet...",
    "Convincing AI not to turn evil..",
    "How did you get here?",
    "I’ve got problem for your solution...",
    "Initializing the initializer...",
    "TODO- Insert elevator music",
    "Discovering new ways of making you wait...",
    "I think I am, therefore, I am. I think.",
    "Dividing by zero...",
    "Entangling superstrings...",
    "Searching for plot device...",
    "Ordering 1s and 0s...",
    "Please wait... Consulting the manual...",
  ];

  /* eslint-disable */
  useEffect(() => {
    setText(options[Math.floor(Math.random() * (44 + 1))]);
  }, []);
  /* eslint-enable */

  return <div className="lea-loader-text">{text}</div>;
};

function LeaLoader(props) {
  /* eslint-disable */
  return (
    <div
      style={props.wrapperStyle}
      className={
        `lea-loader ${props.inTable ? "in-table" : ""}` +
        `${props.pageTable ? " page-table" : ""}` +
        `${props.stepper ? " stepper" : ""}`
      }
    >
      <img className="lea-loader-logo" style={props.logoStyle} alt="Leasera Logo" src={LeaLogo} />
      {props.text && <LoadingText />}
    </div>
  );
  /* eslint-enable */
}

export default LeaLoader;
