import React from "react";

const NoTourTypes = () => {
  return (
    <div className="no-conversation">
      <div className="mw-container">
        <img src="/assets/img/noData.svg" alt="img" />
        <div className="title">No Tour Types Yet!</div>
        <div className="description">
          You haven’t added any tour types. Please add a tour type by clicking the <b>Add Tour</b> button.
        </div>
      </div>
    </div>
  );
};

export default NoTourTypes;
