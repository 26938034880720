import React, { Component } from "react";
import { withApollo } from "react-apollo";
import get from "lodash/get";
import {
  tenantsByLocationGql,
  billsGql,
  personPaymentActionGql,
} from "../../store/tenants/tenants";
import { leasesGql, leasePaidGql, leaseActiveGql, leaseAutopayGql } from "../../store/person/leases";
import { getClient } from "../../init-apollo-googleFn";
import { LEASE_MANAGER } from "../../utils/constants";
import { qpDatatables, qpTooltipPopover } from "../../utils/misc";
import { primaryLocations } from "../../store/person/properties";
import { telephoneFormatter } from "../../utils/common";
import { PageLoader } from "../../components/Loader/PageLoader";
import { Table } from "../../components/Tenants/TenantsTableData";
import { FAKE_LEASE, ALL } from "../../utils/constants";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { TenantKeyModal } from "../../components/Modals/TenantKeyModal";
import InviteResident from "../../components/Modals/InviteResident";
import moment from "moment";
import { Input, Icon, Header, Label, List, Popup, Menu, Dropdown } from "semantic-ui-react";
import "./Tenants.scss";
import mixpanel from "mixpanel-browser";
import Tour from 'reactour'
import { residentsSteps } from "../../utils/tourSteps";
const newClient = getClient(LEASE_MANAGER);

class Tenants extends Component {
  constructor(props) {
    super(props);
    this.state = {
      lease: FAKE_LEASE,
      filter: { onlyCurrentTenants: true },
      tenantsCache: [],
      primaryLocations: [],
      tenantStable: [],
      isTourOpen: false,
      loading: false,
      value: '',
      message: "",
      selectedPrimary: this.props.selectedPrimary
        ? this.props.selectedPrimary.node
        : "",
      tenant: {},
      leaseHistory: {},
      paid: "",
      billId: "",
      transBillId: "",
      refundAmount: "",
      transaction: [],
      tenantsAutopay: [],
      tenantsPaid: [],
      tenantsActive: [],
      tableData: [],
    };
  }

  componentDidMount() {
    qpDatatables();
    qpTooltipPopover();
    this.getTenants4()
    this.getTenants3()
    this.getTenants2();


    mixpanel.track("Manager Page Load", {
      sub: "Tenants",
    });
  }

  componentWillReceiveProps(prevProps) {
    const nodeData = prevProps.selectedPrimary
      ? prevProps.selectedPrimary.node
      : "";
    if (nodeData !== this.state.selectedPrimary) {
      this.setState({ selectedPrimary: nodeData }, () => {
        this.getTenants4()
        this.getTenants3()
        this.getTenants2();
      });
    }
  }
  componentDidUpdate(props) {
    if (props.selectedPrimary.node !== this.state.selectedPrimary) {
      this.setState({ selectedPrimary: this.props.selectedPrimary.node });
      this.getTenants4()
      this.getTenants3()
      this.getTenants2();
    }
  }

  setModalTenant = (lease) => this.setState({ lease });
  // Get Tenants
  getTenants = () => {
    this.setState({ loading: true });
    try {
      newClient
        .query({
          query: leasesGql,
          variables: {
            locationId:
              this.props.selectedPrimary && this.props.selectedPrimary.node.id,
          },
        })
        .then((response) => {
          const Tenants = response.data.leases.edges;
          const tenantFilter = Tenants.filter(
            (node) => node.node.status === "move_in" && moment().isSameOrBefore(node.node.endDate)
          );

          this.setState({ tenantsCache: tenantFilter, loading: false, tenantStable: tenantFilter });
        })
        .catch((error) => {
          this.setState({ loading: false });
        })
    } catch (e) {
      console.log(e);
    }
  };
  getTenants2 = () => {
    this.setState({ loading: true });
    try {
      newClient
        .query({
          query: leasePaidGql,
          variables: {
            locationId:
              this.props.selectedPrimary && this.props.selectedPrimary.node.id,
          },
        })
        .then((response) => {
          this.setState({ tenantsPaid: response.data.paidLeases })
        })
        .catch((error) => {
          console.log(error)
        })
    } catch (e) {
      console.log(e);
    }
  };

  getTenants3 = () => {
    this.setState({ loading: true });
    try {
      newClient
        .query({
          query: leaseActiveGql,
          variables: {
            locationId:
              this.props.selectedPrimary && this.props.selectedPrimary.node.id,
          },
        })
        .then((response) => {
          this.setState({ tenantsActive: response.data.activatedResidents })
        })
        .catch((error) => {

        }).finally(() => {
          this.getTenants()
        });
    } catch (e) {
      console.log(e);
    }
  };

  getTenants4 = () => {
    this.setState({ loading: true });
    try {
      newClient
        .query({
          query: leaseAutopayGql,
          variables: {
            locationId:
              this.props.selectedPrimary && this.props.selectedPrimary.node.id,
          },
        })
        .then((response) => {
          this.setState({ tenantsAutopay: response.data.autopayResidents })
        })
        .catch((error) => {

        })
    } catch (e) {
      console.log(e);
    }
  };

  filteredTenants = () => {
    const { filter, currentLocation, tenantsCache } = this.state;

    const { ndbId } = currentLocation;

    const filteredTenantData = filter.onlyCurrentTenants
      ? tenantsCache[ndbId].current
      : filter.onlyPastTenants
        ? tenantsCache[ndbId].past
        : [...tenantsCache[ndbId].past, ...tenantsCache[ndbId].current];
    return filteredTenantData;
  };

  getPrimaryLocations = () => {
    this.props.primaryLocationClient
      .query({
        query: primaryLocations,
      })
      .then((res) => {
        const prime = get(res, "data.primaryLocations.edges", null);
        this.setState({
          primaryLocations: prime,
          currentLocation: prime[0],
        });
        this.setLocation(1);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  setLocation = (locationIndex = ALL) => {
    const location = this.state.primaryLocations[locationIndex - 1];
    this.setState({
      currentLocation: locationIndex !== ALL ? location : { name: "All" },
    });
    this.getTenants(location.node.ndbId);
  };

  getPaymentHistory = () => {
    const { billId } = this.state;
    this.setState({ loading: true });
    this.props.invoicesClient
      .query({
        query: billsGql,
        variables: {
          role: "manager",
          leaseId: billId,
        },
      })
      .then((res) => {
        if (res.data.bills) {
          this.setState({ leaseHistory: res.data.bills, loading: false });
        } else {
          this.setState({ leaseHistory: ["No Bills"], loading: false });
        }
      })
      .catch((error) => {
        this.setState({ loading: false });
        console.log(error);
      });
  };

  getPaymentHistory = () => {
    const { billId } = this.state;
    this.setState({ loading: true });
    this.props.invoicesClient
      .query({
        query: billsGql,
        variables: {
          role: "manager",
          leaseId: billId,
        },
      })
      .then((res) => {
        if (res.data.bills) {
          this.setState({ leaseHistory: res.data.bills, loading: false });
        } else {
          this.setState({ leaseHistory: ["No Bills"], loading: false });
        }
      })
      .catch((error) => {
        this.setState({ loading: false });
        console.log(error);
      });
  };

  getId = (locationNdbId, filter = this.state.filter) => {
    this.setState({ loading: true });
    this.props.leaseClient
      .query({
        query: tenantsByLocationGql,
        variables: {
          primaryLocationId: locationNdbId,
          filter: filter,
        },
      })
      .then((res) => {
        const billId = res.data.tenants.edges[0]
          ? res.data.tenants.edges[0].node.ndbId
          : [];
        this.getPaymentHistory(billId);
      });
  };
  fail = () =>
    toast.error("Charge has already been fully refunded!", {
      position: "top-center",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  unable = () =>
    toast.error("Alert: Amount exceeds possible transaction amount!", {
      position: "top-center",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });

  selectedTrans = (transaction) => this.setState({ transaction });
  selectedBill = (transBillId) => this.setState({ transBillId });
  handleChange = (e) => {
    const custom = parseFloat(e.target.value).toFixed(2);
    this.setState({ refundAmount: custom });
  };
  submitRefund = () => {
    const { transaction, transBillId, refundAmount } = this.state;
    if (
      refundAmount === "" ||
      transBillId.paid === 0 ||
      refundAmount > parseFloat(transaction.amount)
    ) {
      return this.unable();
    }
    if (transaction.trans_type === "CCCHAR") {
      this.setState({ loading: true });
      this.props.personClient
        .mutate({
          mutation: personPaymentActionGql,
          variables: {
            input: {
              transactionId: transaction.transaction_reference.transaction_id,
              amount: parseFloat(refundAmount).toFixed(2),
              transType: "cc_refund",
              actionType: "lease",
              billNdbId: transBillId.ndbId,
            },
          },
        })
        .then((results) => {
          if (results.data.personPaymentAction.response.GatewayException) {
            this.fail();
          }
          this.setState({ loading: false });
          setTimeout(() => {
            this.getPaymentHistory();
          }, 1000);
        })
        .catch((e) => {
          console.log(e);
          alert(e);
        });
    } else {
      this.props.personClient
        .mutate({
          mutation: personPaymentActionGql,
          variables: {
            input: {
              transactionId: transaction.transaction_reference.transaction_id,
              amount: parseFloat(refundAmount).toFixed(2),
              transType: "ach_refund",
              actionType: "lease",
              billNdbId: transBillId.ndbId,
            },
          },
        })
        .then((results) => {
          if (results.data.personPaymentAction.response.GatewayException) {
            this.fail();
          }
          this.setState({ loading: false });
          setTimeout(() => {
            this.getPaymentHistory();
          }, 1000);
        })
        .catch((e) => {
          console.log(e);
          alert(e);
        });
    }
  };

  phoneFormatter = (data) => {
    return telephoneFormatter(data._cell.value);
  };

  rentFilter = (headerValue, rowValue, rowData, filterParams) => {
    const headerInt = parseInt(headerValue);
    const valueInt = parseInt(rowValue);

    switch (headerValue) {
      //All
      case "0":
        return true;

      //Selections with 500 increments
      case "500":
      case "1000":
      case "1500":
      case "2000":
      case "2500":
      case "3000":
        if (valueInt < headerInt && valueInt >= headerInt - 500) return true;
        break;

      //Selections with 1000 increments
      case "4000":
      case "5000":
      case "6000":
      case "7000":
      case "8000":
        if (valueInt < headerInt && valueInt >= headerInt - 1000) return true;
        break;

      // Selections with 2000 increments
      case "10000": {
        if (valueInt < headerInt && valueInt >= headerInt - 2000) return true;
        break;
      }

      // Selections with +
      case "10001": {
        if (valueInt > headerInt) return true;
        break;
      }

      default:
        return false;
    }
  };

  viewIconFormatter = () => {
    return `<i class="fa fa-eye action-icon" title="View" />`;
  };

  setTenant = (tenant) => this.setState({ tenant });

  walkIconFormatter = () => {
    return `<i class="fa fa-binoculars action-icon" title="Walkthrough" />`;
  };

  keyIconFormatter = () => {
    return `<i class="fa fa-key action-icon" title="Key" />`;
  };

  openKeyModal = (e, cell) => {
    document.getElementById("tenantKey-modal-btn").click();
  };

  redirectToView = (item) => {
    const customId = item.node.id;
    const applicationId = item.node.applicationId;
    this.props.history.push({
      pathname:
        "/tenantDetail/tenantId=" +
        customId +
        "/applicationId=" +
        applicationId,
      state: { item },
      data: item,
    });
  };

  setFilter = (value) => {
    const { tenantStable } = this.state
    if (this.state.value == 1) {
      const newArr = []
      tenantStable.map(item => {
        if (item.node.person && item.node.person.firstName.includes(value) || item.node.person && item.node.person.lastName.includes(value)) {
          newArr.push(item)
        }
      })
      this.setState({ tenantsCache: newArr })
    }
    if (this.state.value == 2) {
      const newArr = []
      tenantStable.map(item => {
        if (item.node.unit.number.includes(value)) {
          newArr.push(item)
        }
      })
      this.setState({ tenantsCache: newArr })
    }
    if (this.state.value == 3) {
      const newArr = []
      tenantStable.map(item => {
        if (item.node.billOnDate.includes(value)) {
          newArr.push(item)
        }
      })
      this.setState({ tenantsCache: newArr })
    }
    if (this.state.value == 4) {
      const newArr = []
      tenantStable.map(item => {
        if (item.node.startDate.includes(value)) {
          newArr.push(item)
        }
      })
      this.setState({ tenantsCache: newArr })
    }
    if (this.state.value == 5) {
      const newArr = []
      tenantStable.map(item => {
        if (item.node.endDate.includes(value)) {
          newArr.push(item)
        }
      })
      this.setState({ tenantsCache: newArr })
    }
    if (this.state.value == 6) {
      const newArr = []
      tenantStable.map(item => {
        if (item.node.unitAmount.includes(value)) {
          newArr.push(item)
        }
      })
      this.setState({ tenantsCache: newArr })
    }
  }
  handleChange = (e, { value }) => this.setState({ value })
  render() {
    const { tenant, loading } = this.state;
    const mainHeader = ["RESIDENT INFORMATION", "UNIT", "PHONE NUMBER", "START DATE", "END DATE", "AMOUNT", "ACTION"];
    const options = [
      { key: 1, text: 'Resident', value: 1 },
      { key: 2, text: 'Unit', value: 2 },
      { key: 3, text: 'Bill', value: 3 },
      { key: 4, text: 'Start', value: 4 },
      { key: 5, text: 'End', value: 5 },
      { key: 6, text: 'Amount', value: 6 },
    ]
    return loading ? (
      <PageLoader
        text
        active
        style={{ marginTop: "200px" }}
        inline="centered"
      />
    ) : (
      <main className="main-content" role="main">
        <div className="roll-container">
          <div className="button-group-row">
            <Header className="residents-first-step" as="h2">Residents <Icon size='small' circular name='lightbulb' onClick={() => this.setState({ isTourOpen: true })} /></Header>
            <Input className="residents-second-step" compact onChange={(e) => this.setFilter(e.target.value)} size='mini' placeholder='Search' label={<Dropdown
              compact
              onChange={this.handleChange}
              style={{ zIndex: "101" }}
              options={options}
              placeholder='Choose an option'
              value={this.state.value}
            />}
              labelPosition='left' />
            <div className="lead-action-wrapper">
              <div className="residents-third-step" style={{ display: 'table' }}>
                <div style={{ display: 'table-cell' }}>
                  <Popup trigger={<Label circular>
                    <Icon size="large" fitted name='lightbulb outline' style={{ color: 'green' }} /> - {this.state.tenantsActive.length}
                  </Label>} >
                    <Header>User Activation</Header>
                    <List>
                      <List.Item>
                        <Icon style={{ color: 'green' }} size="large" name="lightbulb outline"></Icon>
                        <List.Content>User Active</List.Content>
                      </List.Item>
                      <List.Item>
                        <Icon size="large" name="lightbulb outline"></Icon>
                        <List.Content>User Inactive</List.Content>
                      </List.Item>
                    </List>
                  </Popup>

                </div>
                &nbsp;
                <div style={{ display: 'table-cell' }}>
                  <Popup trigger={<Label circular>
                    <Icon size="large" fitted name='calendar check outline' style={{ color: 'green' }} /> - {this.state.tenantsAutopay.length}
                  </Label>} >
                    <Header>Autopay Activation</Header>
                    <List>
                      <List.Item>
                        <Icon style={{ color: 'green', background: '#ffffff', padding: '0px' }} size="large" name="calendar check outline"></Icon>
                        <List.Content>Autopay Active</List.Content>
                      </List.Item>
                      <List.Item>
                        <Icon style={{ background: '#ffffff', padding: '0px' }} size="large" name="calendar check outline"></Icon>
                        <List.Content>Autopay Inactive</List.Content>
                      </List.Item>
                    </List>
                  </Popup>

                </div>
                &nbsp;
                <div style={{ display: 'table-cell' }}>
                  <Popup trigger={<Label circular>
                    <Icon size="large" fitted name='dollar sign' style={{ color: 'green' }} /> - {this.state.tenantsPaid && this.state.tenantsPaid.length}
                  </Label>} >
                    <Header>Current Invoice</Header>
                    <List>
                      <List.Item>
                        <Icon style={{ color: 'green', background: '#ffffff', padding: '0px' }} size="large" name="dollar sign"></Icon>
                        <List.Content>Invoice Paid</List.Content>
                      </List.Item>
                      <List.Item>
                        <Icon style={{ background: '#ffffff', padding: '0px' }} size="large" name="dollar sign"></Icon>
                        <List.Content>Invoice Unpaid</List.Content>
                      </List.Item>
                    </List>
                  </Popup>

                </div>
              </div>
              &nbsp;&nbsp;
              {/* <InviteResident location={this.props.selectedPrimary.node} /> */}
            </div>
          </div>
          <Tour
            steps={residentsSteps}
            isOpen={this.state.isTourOpen}
            onRequestClose={() => this.setState({ isTourOpen: false })}
            startAt={0}
            className="helper"
            rounded={5}
          />
          <Table
            tenantsCache={this.state}
            view={this.redirectToView}
            refreshTable={this.refreshTable}
            getRequest={this.getMaintenanceRequests}
            cancel={this.openCancelModal}
          />

        </div>
        {tenant && <TenantKeyModal />}
        <button
          id="tenant-modal-btn"
          className="invisible"
          data-target="#TenantModal"
          data-toggle="modal"
        ></button>
        <button
          id="tenantKey-modal-btn"
          className="invisible"
          data-target="#tenantKeyModal"
          data-toggle="modal"
        ></button>
      </main>
    );
  }
}

export default withApollo(Tenants);
