import React, { Component } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import "react-toastify/dist/ReactToastify.css";
import { getClient } from "../../../init-apollo-googleFn";
import { BLUEMOON_API, BLUEMOON_PATH } from "../../../utils/constants";
import { bluemoonAuth, updateBmAuth } from "../../../store/bluemoon/bluemoon";
import { toastFailMsg, toastSuccessMsg } from "../../../utils/common";
import mixpanel from "mixpanel-browser";
const bluemoonApi = getClient(BLUEMOON_API);
const params = {
  username: Yup.string().required("UserName is required"),
  password: Yup.string().required("Password is required"),
};
const ValidateSchema = {
  schema: Yup.object().shape({
    ...params,
  }),
};
class BluemoonSettings extends Component {
  constructor(props) {
    super(props);

    this.state = {
      id: "",
      username: "",
      password: "",
      loading: false,
    };
  }

  componentDidMount() {
    mixpanel.track("Manager Page Load", {
      sub: "Settings",
    });
    const data = JSON.parse(localStorage.getItem("bluemoonData"));
    if (data) {
      this.setState({
        id: data.id,
        password: data.password,
        username: data.username,
      });
    } else {
      this.props.fetchCrDetails();
    }
  }

  handleChange = (name, e) => {
    const { value } = e.target;
    this.setState({ [name]: value });
  };

  onClickedBack = (e, handleSubmit) => {
    if (e.key === "Enter") {
      e.preventDefault();
      handleSubmit();
    }
  };

  /* function that decides whether to call submitCredential function, 
  or updateCredential function on the basis of parameter values */
  submitForm = () => {
    const { id } = this.state;
    if (id) {
      this.updateCredential();
    } else {
      this.submitCredential();
    }
  };

  renderForm = (username, password, loading) => (
    <Formik
      enableReinitialize
      initialValues={{ username, password }}
      onSubmit={(values, { setSubmitting }) => {
        setSubmitting(false);
        this.submitForm();
      }}
      validationSchema={ValidateSchema.schema}
      render={({ setFieldValue, errors, submitForm, handleSubmit }) => (
        <Form
          textAlign="left"
          onKeyDown={(e) => this.onClickedBack(e, handleSubmit)}
          class="text-left"
        >
          <div class="form-group">
            <label for="username">User Name</label>
            <Field
              type="text"
              className={`form-control ${errors.username && "is-invalid"}`}
              id="username"
              value={username}
              onChange={({ target: { value } }) => {
                setFieldValue("username", value);
                this.setState({ username: value });
              }}
            />
            <ErrorMessage
              className="invalid-feedback"
              name="username"
              component="div"
            />
          </div>
          <div class="form-group">
            <label for="password">Password</label>
            <Field
              type="text"
              className={`form-control ${errors.password && "is-invalid"}`}
              id="password"
              value={password}
              onChange={({ target: { value } }) => {
                setFieldValue("password", value);
                this.setState({ password: value });
              }}
            />
            <ErrorMessage
              className="invalid-feedback"
              name="password"
              component="div"
            />
          </div>
          <div class="modal-footer">
            <button
              type="submit"
              class="btn btn-primary"
              onClick={handleSubmit}
            >
              {loading ? "Saving..." : "Save"}
            </button>
          </div>
        </Form>
      )}
    />
  );

  submitCredential = async () => {
    const { username, password } = this.state;
    this.setState({ loading: true });
    const dataToSend = {
      username,
      password,
    };
    mixpanel.track("Manager Settings Action", {
      sub: "Create Bluemoon Auth",
    });
    await bluemoonApi
      .mutate({
        mutation: bluemoonAuth,
        variables: { input: dataToSend },
      })
      .then((res) => {
        const data = res.data.bluemoonAuth.bluemoon;
        /* eslint-disable */
        {
          this.props.fetchCrDetails && this.props.fetchCrDetails();
        }
        {
          this.props.fetchBmProperties && this.props.fetchBmProperties();
        }
        /* eslint-enable */
        toastSuccessMsg("Credentials Saved Successfully");
        this.setState({
          id: data.id,
          password: data.password,
          username: data.username,
          loading: false,
        });
        document.getElementById("CredentialsModal").classList.remove("show");
        document.getElementById("CredentialsModal").click(function () {
          document.getElementById("CredentialsModal").modal("hide");
        });
      })
      .catch((error) => {
        this.setState({ loading: false });
        toastFailMsg("Invalid Credentials");
      });
  };

  updateCredential = async () => {
    const { username, password, id } = this.state;
    this.setState({ loading: true });
    const dataToSend = {
      id,
      username,
      password,
    };
    mixpanel.track("Manager Settings Action", {
      sub: "Update Bluemoon Auth",
    });
    await bluemoonApi
      .mutate({
        mutation: updateBmAuth,
        variables: { input: dataToSend },
      })
      .then((res) => {
        const data = res.data.updateBmAuth.bluemoon;
        /* eslint-disable */
        {
          this.props.fetchCrDetails && this.props.fetchCrDetails();
        }
        {
          this.props.fetchBmProperties && this.props.fetchBmProperties();
        }
        /* eslint-enable */
        toastSuccessMsg("Credentials Updated Successfully");
        this.setState({
          id: data.id,
          password: data.password,
          username: data.username,
          loading: false,
        });
        document.getElementById("CredentialsModal").classList.remove("show");
        document.getElementById("CredentialsModal").click(function () {
          document.getElementById("CredentialsModal").modal("hide");
        });
      })
      .catch((error) => {
        this.setState({ loading: false });
        toastFailMsg("Invalid Credentials");
      });
  };

  render() {
    const { username, password, clientId, clientSecret, loading } = this.state;

    if (this.props.renderForm) {
      return (
        <>
          {this.renderForm(username, password, clientId, clientSecret, loading)}
        </>
      );
    }

    return (
      <>
        <div className="bluemoon-settings-wrap">
          <h5>Settings</h5>
          <div className="settings-list">
            <ul>
              <li>
                <div className="left-data">
                  <h6>Bluemoon Integration</h6>
                  {/* eslint-disable */}
                  <p>
                    Connect your bluemoon credentials and avail the benefits of
                    e-signature.
                    <a
                      href="#"
                      data-toggle="modal"
                      data-target="#BenefitsModal"
                    >
                      View Benefits
                    </a>
                  </p>
                  {/* eslint-enable */}
                </div>
                <div className="right-data">
                  <button
                    type="button"
                    className="primary-btn"
                    data-toggle="modal"
                    data-target="#InstructionsModal"
                  >
                    Instructions
                  </button>
                  <img
                    src="/assets/img/icons-arrow-down.svg"
                    alt="icon"
                    data-toggle="modal"
                    data-target="#CredentialsModal"
                  />
                </div>
              </li>
              <li>
                <div className="left-data">
                  <h6>Tour Management</h6>
                  <p>View/manage your tour types</p>
                </div>
                <div className="right-data">
                  <img
                    src="/assets/img/icons-arrow-down.svg"
                    alt="icon"
                    data-toggle="modal"
                    data-target="#CredentialsModal"
                  />
                </div>
              </li>
              
            </ul>
          </div>
          {/* Benefits Modal */}
          <div
            className="modal fade benefits-modal"
            id="BenefitsModal"
            tabIndex="-1"
            role="dialog"
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
          >
            <div className="modal-dialog" role="document">
              <div className="modal-content">
                <div className="modal-header">
                  <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div className="modal-body">
                  <img
                    src="/assets/img/bluemoon-logo.png"
                    alt="bluemoon-logo"
                  />
                  <h5>Benefits of bluemoon</h5>
                  <p>
                    Providing Industry Standard Lease Forms to Properties Across
                    the Country.
                  </p>
                  <div className="content-wrap">
                    <ul>
                      <li>
                        <p>
                          Keep using your current property management software
                          as your primary interface to save time, data entry,
                          and training costs.
                        </p>
                      </li>
                      <li>
                        <p>
                          Built in e-signature functionality allows you to send,
                          receive, track and electronically sign lease packages
                          with no transaction fees.
                        </p>
                      </li>
                      <li>
                        <p>
                          Deploy a secure link on your property web site to
                          capture rental application information and speed the
                          application process.
                        </p>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="modal-footer">
                  <a
                    href={BLUEMOON_PATH}
                    target="_blank"
                    rel="noopener noreferrer"
                    type="button"
                    className="btn btn-primary"
                  >
                    Register on Bluemoon
                  </a>
                </div>
              </div>
            </div>
          </div>
          {/* Instruction Modal */}
          <div
            className="modal fade benefits-modal instruction-modal"
            id="InstructionsModal"
            tabIndex="-1"
            role="dialog"
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
          >
            <div className="modal-dialog" role="document">
              <div className="modal-content">
                <div className="modal-header">
                  <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div className="modal-body">
                  <img
                    src="/assets/img/bluemoon-logo.png"
                    alt="bluemoon-logo"
                  />
                  <h5>Create account on Bluemoon</h5>
                  <p>
                    Save time, save money, save on legal costs by using industry
                    standard leasing forms, electronically enabled to make you
                    more productive, integrate with your property management
                    software, including e-signature functionality, and built-in
                    document storage.
                  </p>
                  <div className="content-wrap">
                    <ul>
                      <li>
                        <p>
                          In order to have a Bluemoon account you need to be
                          either a registered NAA, TAA, or GAA member
                        </p>
                      </li>
                      <li>
                        {/* eslint-disable */}
                        <p>
                          Go to{" "}
                          <a
                            href="https://www.bluemoonforms.com/?p=home"
                            target="_blank"
                          >
                            Bluemoon.com
                          </a>{" "}
                          and click on the Products dropdown to get started
                          through our process.
                        </p>
                        {/* eslint-enable */}
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="modal-footer">
                  <a
                    href={BLUEMOON_PATH}
                    target="_blank"
                    rel="noopener noreferrer"
                    type="button"
                    className="btn btn-primary"
                  >
                    Register on Bluemoon
                  </a>
                </div>
              </div>
            </div>
          </div>
          {/* Credentials Modal */}
          <div
            className="modal fade benefits-modal credentials-modal"
            id="CredentialsModal"
            tabIndex="-1"
            role="dialog"
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
          >
            <div className="modal-dialog" role="document">
              <div className="modal-content">
                <div className="modal-header">
                  <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div className="modal-body">
                  <h5>Bluemoon Credentials</h5>
                  {this.renderForm(username, password, loading)}
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default BluemoonSettings;
