import React from "react";
import { Grid, Table } from "semantic-ui-react";
import AddBankAccountMethod from "./AddBankAccountMethod";
import * as WepayUtils from "../../../utils/wepay";

const modalTypes = {
  bank: "bank",
  credit_card: "credit_card",
};

export default function AddPayoutMethods({ organizationId, locationId, setMethods }) {
  const [showModal, setShowModal] = React.useState(false);
  const [modalType, setModalType] = React.useState(modalTypes.bank);
  const [paymentMethods, setPaymentMethods] = React.useState(null);

  const fetchPaymentMethods = async () => {
    try {
      const res = await WepayUtils.getPaymentMethods({ locationId });
      setPaymentMethods(res);
      setMethods(res)
    } catch (error) { }
  };


  React.useEffect(() => {
    if (paymentMethods === null) fetchPaymentMethods();
  }, [paymentMethods]);

  return (
    <Grid.Column mobile={16} tablet={16} computer={16}>
      <div className="white-block">
        <div className="head">Add Payout Methods</div>


        <Table>
          <thead>
            <tr>
              <td>Name</td>
              <td>Last Four</td>
              <td>Account Type</td>
              <td cellSpacing={4}>Address</td>
              <td>Actions</td>
            </tr>
          </thead>
          {paymentMethods?.map((method, i) => {
            return (
              <tr>
                <td key={`payoutMethod-${i}`}>{method.name}</td>
                <td>***{method.lastFour}</td>
                <td>{method.achType}</td>
                <td>{method?.metaData?.billing_address.street_address}</td>
                <td>{method?.metaData?.billing_address.city}</td>
                <td>{method?.metaData?.billing_address.state}</td>
                <td>{method?.metaData?.billing_address.zip}</td>
              </tr>
            );
          })}
        </Table>
        <div>
          <Grid>
            <button
              type="button"
              onClick={() => {
                setShowModal(true);
                setModalType(modalTypes.bank);
              }}
              className="btn next-btn"
            >
              Add Bank Account
            </button>
          <div style={{marginTop:'15px'}}>
            <a
              href="https://go.wepay.com/terms-of-service-us/"
              target="_blank"
              style={{ color: 'blue' }}
              >
              Terms of Service
            </a>{" "}
            for Wepay
          </div>
          <div style={{marginTop:'15px'}}>
            <a
              href="https://go.wepay.com/privacy-policy/"
              target="_blank"
              style={{ color: 'blue' }}
              >
              Privacy Policy
            </a>{" "}
            for Wepay
          </div>
          </Grid>
          <AddBankAccountMethod
            showModal={showModal && modalType === modalTypes.bank}
            toggleIsShowing={() => setShowModal(false)}
            organizationId={organizationId}
            locationId={locationId}
            fetchPaymentMethods={fetchPaymentMethods}
          />
        </div>
      </div>
    </Grid.Column>
  );
}
