import React from "react";
import "./RentalHistory.scss";

function RentalHistory({ rentalHistory }) {
  return (
    <>
      <div className="row">
        <div className="col-md-12 card-scroll-y">
          <div className="theme-card card min-750">
            <div className="card-header min-750">
              <div className="card-header-title">Rental History</div>
            </div>
            <div className="card-body pb-2">
              <div className="table-header min-750">
                <div className="table-header-label">Landlord Name</div>
                <div className="table-header-label">Landlord Email</div>
                <div className="table-header-label">Landlord Phone</div>
                <div className="table-header-label">Start Date</div>
                <div className="table-header-label">End Date</div>
                <div className="table-header-label x2">Address</div>
                <div className="table-header-label">Currently Residing</div>
              </div>
              {/* div to be repeated */}
              {rentalHistory &&
                rentalHistory.map((item) => {
                  return (
                    <div className="table-col-row min-750">
                      <div className="table-col">
                        {item?.landlordName || "-"}
                      </div>
                      <div className="table-col">
                        {item?.landlordEmail || "-"}{" "}
                      </div>
                      <div className="table-col">
                        {item?.landlordPhone || "-"}{" "}
                      </div>
                      <div className="table-col">{item?.startDate || "-"}</div>
                      <div className="table-col">{item?.endDate || "-"}</div>
                      <div className="table-col x2">
                        {[
                          item.address.streetLine1,
                          item.address.streetLine2,
                          item.address.city,
                          item.address.state,
                          item.address.postcode,
                        ].join(" ")}
                      </div>
                      <div className="table-col">
                        {item.isCurrentlyResiding ? "Yes" : "No"}
                      </div>
                    </div>
                  );
                })}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default RentalHistory;