import {
  ApolloClient,
  ApolloLink,
  InMemoryCache,
  HttpLink,
} from "apollo-boost";
import { getAuthToken } from "./store/auth/authUtility";
import config from "./config";

export const getLink = (uri) =>
  new HttpLink({ uri: uri || config.GRAPHQL_URL });

const authLink = new ApolloLink((operation, forward) => {
  // Retrieve the authorization token from local storage.
  const token = getAuthToken();
  // Use the setContext method to set the HTTP headers.
  operation.setContext({
    headers: {
      Authorization: token ? `${token}` : "",
    },
  });

  // Call the next link in the middleware chain.
  return forward(operation);
});

export const getClient = (uri) =>
  new ApolloClient({
    link: authLink.concat(getLink(uri)), // Chain it with the HttpLink
    cache: new InMemoryCache({
      addTypename: false,
    }),
    defaultOptions: {
      watchQuery: {
        fetchPolicy: "no-cache",
        errorPolicy: "ignore",
      },
      query: {
        fetchPolicy: "no-cache",
        errorPolicy: "all",
      },
    },
  });
