import React from 'react'
import { Button, Grid, Header, Modal, Checkbox, Input } from 'semantic-ui-react'
import { LEASES_URL } from "../../utils/constants";
import getAuthToken from "../../store/auth/authUtility";
import { toastFailMsg, toastSuccessMsg } from "../../utils/common";

function MoveInChecklist(props) {
    const [open, setOpen] = React.useState(false)
    const [Checklist, setChecklist] = React.useState(props.checklist && JSON.parse(props.checklist))

    const Kitchen = [
        "Floor",
        "Walls",
        "Ceiling",
        "Refrigerator",
        "Range",
        "Sinks",
        "Windows",
        "Lighting",
        "Outlets",
        "Cabinets",
        "Other"
    ]
    const Living = [
        "Floor",
        "Walls",
        "Ceiling",
        "Windows",
        "Lighting",
        "Outlets",
        "Other",
    ]

    const Bedroom = [
        "Floor",
        "Walls",
        "Ceiling",
        "Windows",
        "Lighting",
        "Outlets",
        "Doors",
        "Closets",
        "Other"
    ]

    const Bathroom = [
        "Floor",
        "Walls",
        "Ceiling",
        "Windows",
        "Lighting",
        "Outlets",
        "Doors",
        "Closets",
        "Sinks",
        "Shower",
        "Bathroom Door",
        "Fan",
        "Other"
    ]

    const Other = [
        "Carbon MOnoxide Detector",
        "Smoke Alarms",
        "Air-Conditioning",
        "Water Heater",
        "Thermostat",
        "Doorbell",
        "Front Door",
        "Balcony",
        "Other",

    ]


    return (
        <Modal
            className="semanticModal"
            onClose={() => setOpen(false)}
            onOpen={() => setOpen(true)}
            size="medium"
            open={open}
            trigger={<Button
                basic
                compact
                content='Checklist'
                icon='clipboard'
                style={{ background: '#cce2ff' }}
            />}
        >
            <Modal.Header>Move In Checklist</Modal.Header>
            <Modal.Content className="checklist-modal">
                <Modal.Description>
                    <Grid>
                        <br></br>
                        <Header>Kitchen</Header>
                        {
                            Checklist && Checklist.slice(0, 10).map((item, index) => {
                                return (
                                    <Grid.Row>
                                        <Grid.Column width="4">{Kitchen[index]}</Grid.Column>
                                        <Grid.Column width="2"><Checkbox label='Good' disabled
                                            checked={item.value == true} /></Grid.Column>
                                        <Grid.Column width="2"><Checkbox label='Poor' disabled
                                            checked={item.value == false}
                                        /></Grid.Column>
                                        <Grid.Column width="8"><Input fluid disabled value={item.explain} /></Grid.Column>
                                    </Grid.Row>
                                )
                            })
                        }
                        <Header>Living Room</Header>
                        {
                            Checklist && Checklist.slice(11, 16).map((item, index) => {
                                return (
                                    <Grid.Row>
                                        <Grid.Column width="4">{Living[index]}</Grid.Column>
                                        <Grid.Column width="2"><Checkbox label='Good' disabled
                                            checked={item.value == true} /></Grid.Column>
                                        <Grid.Column width="2"><Checkbox label='Poor' disabled
                                            checked={item.value == false}
                                        /></Grid.Column>
                                        <Grid.Column width="8"><Input fluid disabled value={item.explain} /></Grid.Column>
                                    </Grid.Row>
                                )
                            })
                        }


                        <Header>Bedrooms</Header>
                        {
                            Checklist && Checklist.slice(17, 25).map((item, index) => {
                                return (
                                    <Grid.Row>
                                        <Grid.Column width="4">{Bedroom[index]}</Grid.Column>
                                        <Grid.Column width="2"><Checkbox label='Good' disabled
                                            checked={item.value == true} /></Grid.Column>
                                        <Grid.Column width="2"><Checkbox label='Poor' disabled
                                            checked={item.value == false}
                                        /></Grid.Column>
                                        <Grid.Column width="8"><Input fluid disabled value={item.explain} /></Grid.Column>
                                    </Grid.Row>
                                )
                            })
                        }

                        <Header>Bathrooms</Header>

                        {
                            Checklist && Checklist.slice(26, 38).map((item, index) => {
                                return (
                                    <Grid.Row>
                                        <Grid.Column width="4">{Bathroom[index]}</Grid.Column>
                                        <Grid.Column width="2"><Checkbox label='Good' disabled
                                            checked={item.value == true} /></Grid.Column>
                                        <Grid.Column width="2"><Checkbox label='Poor' disabled
                                            checked={item.value == false}
                                        /></Grid.Column>
                                        <Grid.Column width="8"><Input fluid disabled value={item.explain} /></Grid.Column>
                                    </Grid.Row>
                                )
                            })
                        }


                        <Header>Other</Header>
                        {
                            Checklist && Checklist.slice(39, 47).map((item, index) => {
                                return (
                                    <Grid.Row>
                                        <Grid.Column width="4">{Other[index]}</Grid.Column>
                                        <Grid.Column width="2"><Checkbox label='Good' disabled
                                            checked={item.value == true} /></Grid.Column>
                                        <Grid.Column width="2"><Checkbox label='Poor' disabled
                                            checked={item.value == false}
                                        /></Grid.Column>
                                        <Grid.Column width="8"><Input fluid disabled value={item.explain} /></Grid.Column>
                                    </Grid.Row>
                                )
                            })
                        }

                    </Grid>
                </Modal.Description>
            </Modal.Content>
            <Modal.Actions>
                <Button
                    onClick={() => setOpen(false)}
                    positive
                >Ok</Button>
            </Modal.Actions>
        </Modal>
    )
}

export default MoveInChecklist