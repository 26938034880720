import React, { Component } from "react";
import get from "lodash/get";
import { withRouter } from "react-router";
import { PageLoader } from "../../components/Loader/PageLoader";
import { maintenanceGql } from "../../store/person/maintenance";
import { qpDatatables } from "../../utils/misc";
import { Table } from "../../components/Tables/MaintenanceTableData";
import SettingsModal from "../../components/Modals/SettingsModal";
import MaintenanceModal from "../../components/Modals/MaintenanceModal";
import MInviteModal from "../../components/Modals/MInviteModal";
import FlashMessage from "../../components/Messages/FlashMessage";
import "./Maintenance.scss";
import { Icon, Header, Button, Message, Input, Dropdown, Grid } from "semantic-ui-react";
import { getCookie } from "../../store/auth/authUtility";
import moment from "moment";
import CancelModal from "../../components/Modals/CancelModal";
import mixpanel from "mixpanel-browser";
import Tour from 'reactour'
import { maintenanceSteps } from "../../utils/tourSteps";

class Maintenance extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedPrimary: this.props.selectedPrimary
        ? this.props.selectedPrimary.node
        : "",
      requests: [],
      tableData: [],
      isTourOpen: false,
      message: "",
      value: 1,
      email: "",
      showModal: false,
      loading: false,
      isError: false,
      isServiceProfessional: false,
    };
  }

  componentDidMount = async () => {
    await this.getMaintenanceRequests();
    qpDatatables();
    mixpanel.track("Manager Page Load", {
      sub: "Maintenance",
    });
  };

  componentWillReceiveProps(prevProps) {
    const nodeData = prevProps.selectedPrimary
      ? prevProps.selectedPrimary.node
      : "";
    if (nodeData !== this.state.selectedPrimary) {
      this.setState({ selectedPrimary: nodeData }, () => {
        this.getMaintenanceRequests();
      });
    }
  }

  setMessage = (message, isError = false) => {
    this.setState({ message, isError });
  };

  setPriority = (priority) => {
    return priority === "routine"
      ? "low"
      : priority === "essential"
        ? "medium"
        : priority;
  };

  Status = (values) => {
    if (values === "Urgent" || values === "urgent") {
      return (
        <Message
          compact
          size="mini"
          color="red"
          style={{
            color: "white",
            textTransform: "capitalize",
            minWidth: "70px",
            maxWidth: "70px",
          }}
        >
          {values}
        </Message>
      );
    }
    if (values === "Medium" || values === "medium" || values === "Standard") {
      return (
        <Message
          compact
          size="mini"
          color="orange"
          style={{
            color: "white",
            textTransform: "capitalize",
            minWidth: "70px",
            maxWidth: "70px",
          }}
        >
          {values}
        </Message>
      );
    }
    if (values === "Low" || values === "low") {
      return (
        <Message
          compact
          size="mini"
          color="blue"
          style={{
            color: "white",
            textTransform: "capitalize",
            minWidth: "70px",
            maxWidth: "70px",
          }}
        >
          {values}
        </Message>
      );
    }
  };

  // Cancel submit

  // Gathers the Maintenance Requests
  getMaintenanceRequests(Route) {
    const roleType = "manager";
    const statusType = [
      "Submitted",
      "Processing",
      "Scheduled",
      "Entered",
      "Completed",
      "Assigned",
      "Approval Needed",
      "Approved",
    ];
    Route !== "workOrder" && this.setState({ loading: true });
    try {
      this.props.maintenanceClient
        .query({
          query: maintenanceGql,
          variables: {
            role: roleType,
            status: statusType,
            location: this.props.selectedPrimary.node.id,
          },
        })
        .then((response) => {
          Route !== "workOrder" && this.setState({ loading: false });
          if (response.data.maintenanceRequest) {
            const dataToPass = get(response, "data.maintenanceRequest.edges", null).map((obj) => {
              const { created, id, priority, unit, status } = obj.node;
              const { firstName, lastName } = obj.node.requestedBy;
              const assigned = obj.node.assigned ? obj.node.assigned.lastName : <p style={{ fontStyle: "italic" }}>Unassigned</p>;
              const uiPriority = this.Status(priority);
              const submitted = moment(created).format("MM/DD/YYYY");
              const uiView = <Button icon="eye" size="mini" onClick={() => this.redirectToView(obj)} />
              const uiCancel = <CancelModal
                icon="cancel"
                size="mini"
                refreshTable={this.getRequest}
                user={this.user}
                getRequest={this.getRequest}
                props={obj}
              />
              return { id, firstName, lastName, uiPriority, unit, assigned, submitted, status, uiView, uiCancel }
            })
            this.setState(
              {
                requests: get(response, "data.maintenanceRequest.edges", null),
                requestStable: get(response, "data.maintenanceRequest.edges", null),
                tableData: dataToPass,
              },
              () => { }
            );
          }
        })
        .catch((error) => {
          Route !== "workOrder" && this.setState({ loading: false });
        });
    } catch (e) {
      Route !== "workOrder" && this.setState({ loading: false });
      console.log(e);
    }
  }

  refreshTable = (e) => {
    this.setState({ loading: true });
    const newArray = this.state.requests;
    newArray.forEach((value) => {
      if (value.node.id === e) {
        value.node.status = "Canceled";
      }
    });
    this.setState({ requests: newArray });
    this.setState({ loading: false });
  };

  modalOpen = (node) => this.setState({ node });

  // Redirect to view individual request
  redirectToView = (item) => {
    const { id, location } = item.node;
    this.props.history.push({
      pathname: "/maintenanceDetail/location=" + location + "/id=" + id,
    });
  };
  openCancelModal = (cancelItem) => {
    this.modalOpen(cancelItem.node);
    document.getElementById("cancel-modal-btn").click();
  };

  cancelIconFormatter = () => {
    return `<i class="fa fa-trash action-icon" title="View" />`;
  };

  closeMessage = () => {
    this.setState({ message: "" });
  };

  setFilter = (value) => {
    const { requestStable } = this.state
    if (this.state.value == 1) {
      const newArr = []
      requestStable.map(item => {
        if (item.node.id.includes(value)) {
          newArr.push(item)
        }
      })
      this.setState({ requests: newArr })
    }
    if (this.state.value == 2) {
      const newArr = []
      requestStable.map(item => {
        if (item.node.requestedBy.firstName.includes(value)) {
          newArr.push(item)
        }
      })
      this.setState({ requests: newArr })
    }
    if (this.state.value == 3) {
      const newArr = []
      requestStable.map(item => {
        if (item.node.requestedBy.lastName.includes(value)) {
          newArr.push(item)
        }
      })
      this.setState({ requests: newArr })
    }
    if (this.state.value == 4) {
      const newArr = []
      requestStable.map(item => {
        if (item.node.unit.includes(value)) {
          newArr.push(item)
        }
      })
      this.setState({ requests: newArr })
    }
    if (this.state.value == 5) {
      const newArr = []
      requestStable.map(item => {
        if (item.node.assigned && item.node.assigned.lastName.includes(value)) {
          newArr.push(item)
        }
      })
      this.setState({ requests: newArr })
    }
    if (this.state.value == 6) {
      const newArr = []
      requestStable.map(item => {
        if (item.node.created.includes(value)) {
          newArr.push(item)
        }
      })
      this.setState({ requests: newArr })
    }
    if (this.state.value == 7) {
      const newArr = []
      requestStable.map(item => {
        if (item.node.status.includes(value)) {
          newArr.push(item)
        }
      })
      this.setState({ requests: newArr })
    }

  }
  handleChange = (e, { value }) => this.setState({ value })
  render() {
    const { message, isError, loading, node, value } = this.state;
    const { primaryLocationClient } = this.props;
    const mainHeader = ["ID", "FIRST", "LAST", "PRIORITY", "UNIT", "ASSIGNED TO", "SUBMITTED", "STATUS", "VIEW", "CANCEL"];
    const options = [
      { key: 1, text: 'ID', value: 1 },
      { key: 2, text: 'First', value: 2 },
      { key: 3, text: 'Last', value: 3 },
      { key: 4, text: 'Unit', value: 4 },
      { key: 5, text: 'Assigned', value: 5 },
      { key: 6, text: 'Submitted', value: 6 },
      { key: 7, text: 'Status', value: 7 },
    ]
    const cookie = getCookie();

    // !: CancelModal open/close.
    // TODO: Make more beautiful.
    return loading ? (
      <PageLoader text />
    ) : (
      <main className="main-content" role="main">
        <div className="roll-container">
          <>
            <Header className="maintenance-first-step" >Maintenance <Icon size='small' circular name='lightbulb' onClick={() => this.setState({ isTourOpen: true })} /></Header>
            <Grid>
              <Grid.Column className="maintenance-second-step" floated='left' width={5}>
                <Input compact onChange={(e) => this.setFilter(e.target.value)} size='mini' placeholder='Search' label={<Dropdown
                  compact
                  onChange={this.handleChange}
                  style={{ zIndex: "101" }}
                  options={options}
                  placeholder='Choose an option'
                  value={value}
                />}
                  labelPosition='left' />

              </Grid.Column>
              <Grid.Column floated='right' width={7}>
                <Grid.Row>
                  <div className="maintenance-third-step">

                    <Button compact icon labelPosition="left" disabled>
                      <Icon name="cog" style={{ paddingTop: "15px" }} />
                      Settings
                    </Button>
                    {cookie.manager === "true" && (
                      <MInviteModal
                        user={this.props.user}
                        handleInviteSubmit={this.handleInviteSubmit}
                        primary={
                          this.props.selectedPrimary &&
                          this.props.selectedPrimary.node
                        }
                      />
                    )}
                  </div>
                  <div className="maintenance-fourth-step">

                    <MaintenanceModal
                      primary={
                        this.props.selectedPrimary && this.props.selectedPrimary.node
                      }
                      primaryLocations={this.props.primaryLocations}
                      primaryLocationClient={primaryLocationClient}
                      getMaintenanceRequests={() => this.getMaintenanceRequests()}
                    />
                  </div>
                </Grid.Row>
              </Grid.Column>
            </Grid>

            <br></br>
            <br></br>
            <Table
              propertyId={
                this.props.selectedPrimary.node &&
                this.props.selectedPrimary.node.id
              }
              data={this.state}
              view={this.redirectToView}
              getMaintenanceRequests={() => this.getMaintenanceRequests()}
              user={this.props.user}
              getRequest={this.refreshTable}
              cancel={this.openCancelModal}
            />
            <SettingsModal></SettingsModal>
            <button
              id="cancel-modal-btn"
              className="invisible"
              data-target="#CancelModal"
              data-toggle="modal"
              onClick={() => this.modalOpen(node)}
            ></button>
            {message && (
              <FlashMessage
                message={message}
                closeMessage={this.closeMessage}
                isError={isError}
              />
            )}
          </>
        </div>
        <Tour
          steps={maintenanceSteps}
          isOpen={this.state.isTourOpen}
          onRequestClose={() => this.setState({ isTourOpen: false })}
          startAt={0}
          className="helper"
          rounded={5}
        />
      </main>
    );
  }
}

export default withRouter(Maintenance);
