import React from "react";

const LogBody = ({ type, isSender, messageLabel, children, mediaType }) => {
  return (
    <div
      className={`log-body ${type}`}
      is-sender={isSender}
      title={messageLabel}
      media-type={mediaType}
    >
      {children}
    </div>
  );
};

export default LogBody;
