import React, { Component } from "react";
import { withApollo } from "react-apollo";
import { uploadBioPhotoGql } from "../../../store/person/person";
import "../../../assets/fomantic/dist/semantic.css";

import mixpanel from "mixpanel-browser";
// Account Info Table
// This table will allow users to view their current account information as reported in Leasera's system.
class Bio extends Component {
  constructor(props) {
    super(props);

    this.state = {
      applications: [],
      loading: false,
      bgChecks: [],
      apploading: false,
    };
  }

  setModalProperty = (modalProperty) => this.setState({ modalProperty });

  uploadBioPhoto = (e) => {
    const file = e.target.files[0];
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = async (e) => {
      const bioPhotoInput = {
        photo: e.target.result,
        name: file.name,
      };
      mixpanel.track("Renter Account Action", {
        sub: "Upload New Profile Picture Submit",
      });
      this.props.oldClient
        .mutate({
          mutation: uploadBioPhotoGql,
          variables: { input: bioPhotoInput },
        })
        .then((res) => { })
        .catch((error) => {
          alert(error);
          console.log(error);
        });
    };
  };



  render() {
    return (
      <div>

      </div>
    );
  }
}

export default withApollo(Bio);
