import gql from "graphql-tag";

export const createWepayLegalEntityGql = gql`
  mutation createWepayLegalEntity($merchant: CreateWePayLegalEntityInput) {
    createWepayLegalEntity(merchant: $merchant) {
      response
      merchant {
        id
        accountId
      }
    }
  }
`;

export const CreatePaymentMethodGql = gql`
  mutation createPaymentMethod($paymentMethod: CreatePaymentMethodInput) {
    createPaymentMethod(paymentMethod: $paymentMethod) {
      response
    }
  }
`;

export const GetPaymentMethodsGql = gql`
  query paymentMethods($locationId: String, $organizationId: String) {
    paymentMethods(locationId: $locationId, organizationId: $organizationId) {
      edges {
        node {
          id
          accountId
          type
          heartlandToken
          cardType
          cardReferenceNumber
          name
          lastFour
          expMonth
          expYear
          zip
          autopayAmount
          autopay
          bankName
          bankAccountNumber
          bankRoutingNumber
          payoutMethod
          achType
          metaData
          isDelete
          createdAt
          updatedAt
          locationId
          status
        }
      }
    }
  }
`;

export const DeletePaymentMethodGql = gql`
  mutation deletePaymentMethod($paymentMethod: DeletePaymentMethodInput) {
    deletePaymentMethod(paymentMethod: $paymentMethod) {
      response
    }
  }
`;

export const legalEntityGql = gql`
  query legalEntity($locationId: String!) {
    legalEntity(locationId: $locationId)
  }
`;

export const merchantGql = gql`
  query merchant($locationId: String!) {
    merchant(locationId: $locationId) {
      id
      personId
      locationId
      leaseraAccountId
      legalEntityId
      legalEntityOwnerId
      merchantCategoryCode
      merchantCategoryDetail
      paymentFrequency
      statementDescription
      accountId
      role
      isActive
      createdAt
      updatedAt
    }
  }
`;

export const legalEntityVerificationGql = gql`
  query legalEntityVerification($locationId: String!) {
    legalEntityVerification(locationId: $locationId)
  }
`;

export const accountCapabilitiesGql = gql`
  query accountCapabilities($locationId: String!) {
    accountCapabilities(locationId: $locationId)
  }
`;

export const createWepayAccountGql = gql`
  mutation createWepayAccount($merchant: CreateWePayAccountInput) {
    createWepayAccount(merchant: $merchant) {
      response
    }
  }
`;

export const wepayMerchantGql = gql`
  query merchant ($locationId: String!) {
    merchant (locationId: $locationId){
      id
      personId
      locationId
      leaseraAccountId
      legalEntityId
      legalEntityOwnerId
      merchantCategoryCode
      merchantCategoryDetail
      paymentFrequency
      statementDescription
      accountId
      role
      isActive
      createdAt
      updatedAt
    }
  }
`;