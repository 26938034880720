import React from "react";
import { useTable, useFilters, useSortBy, usePagination } from "react-table";

const defaultSortOption = {
  id: "node.created",
  desc: true,
};

export default function LeadsTable({
  columns,
  data,
  filteredValue,
  defaultColumn,
  onChange,
  fetchData,
  waitListPropsChange,
  isFilterApplied,
  filterTypes,
  calculateLastPage,
  pageCount: controlledPageCount,
  sortEnabled,
}) {
  // Use the state and functions returned from useTable to build your UI
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    gotoPage,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      autoResetPage: false,
      columns,
      filterTypes,
      data,
      defaultColumn,
      initialState: {
        sortBy: sortEnabled ? [defaultSortOption] : [],
      },
      manualPagination: true,
      pageCount: controlledPageCount,
    },
    useFilters,
    useSortBy,
    usePagination
  );

  /* eslint-disable */
  React.useEffect(() => {
    fetchData({ pageIndex, pageSize, filteredValue });
  }, [fetchData, pageIndex, pageSize]);
  /* eslint-enable */

  React.useEffect(() => {
    calculateLastPage({ pageSize, controlledPageCount });
  }, [calculateLastPage, pageSize, controlledPageCount]);

  React.useEffect(() => {
    if (waitListPropsChange || isFilterApplied) {
      gotoPage(0);
    }
  }, [waitListPropsChange, gotoPage, isFilterApplied]);

  // Render the UI for your table
  return (
    <>
      <div className="search-wrapper-row">
        <div className="search-wrapper">
          <input
            value={filteredValue}
            onChange={onChange}
            placeholder="Search name"
          />
          <i aria-hidden="true" className="search icon" />
        </div>
      </div>

      <table className="lead-table-list lead-table-layout leads-sixth-step" {...getTableProps()}>
      <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th
                  {...column.getHeaderProps(column.getSortByToggleProps())}
                  className={
                    column.isSorted
                      ? column.isSortedDesc
                        ? "sort-desc"
                        : "sort-asc"
                      : ""
                  }
                >
                  {column.render("Header")}
                  <br />
                  {column.canFilter ? column.render("Filter") : null}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {page.length === 0 ? (
            <td colSpan="10" className="text-center py-5">
              No Results Found
            </td>
          ) : (
            page.map((row, i) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()}>
                  {row.cells.map((cell) => (
                    <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                  ))}
                </tr>
              );
            })
          )}
        </tbody>
      </table>
    </>
  );
}

LeadsTable.defaultProps = {
  sortEnabled: true,
};