import React, { Component } from "react";
import {
  Button,
  Modal,
  Header,
  Grid,
  Segment,
  List,
  Table,
} from "semantic-ui-react";
import { withApollo } from "react-apollo";
import "react-toastify/dist/ReactToastify.css";
import { ACCOUNTING } from "../../utils/constants";
import { getClient } from "../../init-apollo-googleFn";
import moment from "moment";
import { LocationJournal, InvoiceInfoAccounting } from "../../store/person/accounting";

const ledgerClient = getClient(ACCOUNTING);

class ReconcileDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      AccountJournal: {},
      modalOpen: false,
      secondOpen: false,
      thirdOpen: false,
      invoice: "",
    };
  }

  handleClose = () => this.setState({ modalOpen: false });
  setSecondOpen = (data) => this.setState({ secondOpen: data });
  setThirdOpen = (data) => this.setState({ thirdOpen: data });

  handleInvoice = () => {
    this.setSecondOpen(true);
    this.getInvoice();
  };
  getJournal = () => {
    console.log(this.props)
    this.setState({ modalOpen: true })
    try {
      ledgerClient
        .query({
          query: LocationJournal,
          variables: {
            location: this.props.location && this.props.location.propertyId,
            journal: this.props.bill.JournalId.toString(),
          },
        })
        .then((res) => {
          this.getInvoice()
          this.setState({ AccountJournal: res.data.slLocationAccountJournal })
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (e) { }
  };

  getInvoice = () => {
    try {
      ledgerClient
        .query({
          query: InvoiceInfoAccounting,
          variables: {
            location: this.props.location && this.props.location.propertyId,
            id: this.props.bill.InvoiceId.toString(),
          },
        })
        .then((res) => {
          if (res.data) {
            this.setState({
              invoice: res.data.slLocationInvoice && res.data.slLocationInvoice,
            });
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (e) { }
  };

  render() {
    const { invoice } = this.state;
    return (
      <>
        <Modal
          className="semanticModal "
          open={this.state.modalOpen}
          size="medium"
          closeOnEscape={true}
          closeOnRootNodeClick={true}
          trigger={
            <a className="paybillsmodal" onClick={() => this.getJournal()}>
              {this.props.bill.Journal.number}
            </a>
          }
        >
          <Modal.Content>
            <Grid columns={2}>
              <Grid.Column>
                <Table compact>
                  <Table.Body>
                    <Table.Row>
                      <Table.Cell>Source Ledger</Table.Cell>
                      <Table.Cell>
                        {this.props.bill.Journal.sourceLedger}
                      </Table.Cell>
                    </Table.Row>
                    <Table.Row>
                      <Table.Cell>Reference</Table.Cell>
                      <Table.Cell>
                        {this.props.bill.Journal.reference}
                      </Table.Cell>
                    </Table.Row>
                    <Table.Row>
                      <Table.Cell>Status</Table.Cell>
                      <Table.Cell style={{ textTransform: "Capitalize" }}>
                        {this.props.bill.Journal.status}
                      </Table.Cell>
                    </Table.Row>
                    <Table.Row>
                      <Table.Cell>Entry Type</Table.Cell>
                      <Table.Cell>{this.props.bill.Journal.entryType}</Table.Cell>
                    </Table.Row>
                    <Table.Row>
                      <Table.Cell>Transaction</Table.Cell>
                      <Table.Cell>{moment(this.props.bill.transactionDate).format("MM/DD/YYYY")}</Table.Cell>
                    </Table.Row>
                    <Table.Row>
                      <Table.Cell>Posted</Table.Cell>
                      <Table.Cell>{moment(this.props.bill.postedDate).format("MM/DD/YYYY")}</Table.Cell>
                    </Table.Row>
                    <Table.Row>
                      <Table.Cell>Reversing</Table.Cell>
                      <Table.Cell>{this.props.bill.reversing}</Table.Cell>
                    </Table.Row>
                  </Table.Body>
                </Table>
              </Grid.Column>
              <Grid.Column>
                <Table compact>
                  <Table.Body>
                    <Table.Row>
                      <Table.Cell>Location</Table.Cell>
                      <Table.Cell>{this.props.bill.Location_name}</Table.Cell>
                    </Table.Row>
                    <Table.Row>
                      <Table.Cell>Currency</Table.Cell>
                      <Table.Cell>{this.props.bill.Location_currency}</Table.Cell>
                    </Table.Row>
                    <Table.Row>
                      <Table.Cell>Customer</Table.Cell>
                      <Table.Cell>
                        {
                          this.state.AccountJournal &&
                          this.state.AccountJournal.Customer &&
                          <a style={{ color: "blue" }} onClick={() => this.setThirdOpen(true)}>
                            {this.state.AccountJournal.Customer.first_name + " " + this.state.AccountJournal.Customer.last_name}
                          </a>
                        }
                      </Table.Cell>
                    </Table.Row>
                    <Table.Row>
                      <Table.Cell>Invoice</Table.Cell>
                      <Table.Cell>
                        <a style={{ color: "blue" }} onClick={() => this.setSecondOpen(true)}>
                          {this.props.bill.InvoiceId}
                        </a>
                      </Table.Cell>
                    </Table.Row>
                    <Table.Row>
                      <Table.Cell>Cash</Table.Cell>
                      <Table.Cell>{this.props.bill.CashReceiptId}</Table.Cell>
                    </Table.Row>
                    <Table.Row>
                      <Table.Cell>Vendor</Table.Cell>
                      <Table.Cell>{this.props.bill.Vendor_name}</Table.Cell>
                    </Table.Row>
                    <Table.Row>
                      <Table.Cell>Bill</Table.Cell>
                      <Table.Cell>
                        {this.props.bill.BillId}
                      </Table.Cell>
                    </Table.Row>
                    <Table.Row>
                      <Table.Cell>Vendor Credit</Table.Cell>
                      <Table.Cell>{this.props.bill.VendorCreditId}</Table.Cell>
                    </Table.Row>
                  </Table.Body>
                </Table>
              </Grid.Column>
            </Grid>

            <Table padded="very" compact>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell width="3">Account Name</Table.HeaderCell>
                  <Table.HeaderCell width="7">Description</Table.HeaderCell>
                  <Table.HeaderCell width="3">Debit</Table.HeaderCell>
                  <Table.HeaderCell width="3">Credit</Table.HeaderCell>
                </Table.Row>
              </Table.Header>
            </Table>
            <div style={{ maxHeight: "300px", overflowY: "overlay" }}>
              <Table padded="very" compact>
                <Table.Body>
                  {this.state.AccountJournal && this.state.AccountJournal.Transactions &&
                    this.state.AccountJournal.Transactions.map((item) => {
                      return (
                        <Table.Row>
                          <Table.Cell width="3">{item.LedgerAccount.name}({item.LedgerAccount.number})</Table.Cell>
                          <Table.Cell width="7">{item.description}</Table.Cell>
                          <Table.Cell width="3">$ {item.debit}</Table.Cell>
                          <Table.Cell width="3">$ {item.credit}</Table.Cell>
                        </Table.Row>
                      );
                    })}
                </Table.Body>
              </Table>
            </div>
          </Modal.Content>
          <Modal.Actions>
            <Button
              positive
              type="button"
              onClick={() => this.handleClose(true)}
              content="Close"
            />
          </Modal.Actions>
          <Modal
            className="semanticModal"
            onClose={() => this.setSecondOpen(false)}
            open={this.state.secondOpen}
            size="small"
          >
            <Modal.Header>Invoice:</Modal.Header>
            {
              invoice &&
              <Modal.Content>
                <Header textAlign="center">Invoice</Header>
                <br />
                <Grid columns={2}>
                  <Grid.Column>
                    <Table compact>
                      <Table.Body>
                        <Table.Row>
                          <Table.Cell>
                            {invoice.location && invoice.location.name}
                          </Table.Cell>
                          <Table.Cell>
                            {
                              invoice.location &&
                              invoice.location.contact &&
                              invoice.location.contact.phone}
                          </Table.Cell>
                        </Table.Row>
                        <Table.Row>
                          <Table.Cell>
                            {
                              invoice.location &&
                              invoice.location.addresses &&
                              invoice.location.addresses.street}
                          </Table.Cell>
                          <Table.Cell>
                            {
                              invoice.location &&
                              invoice.location.contact &&
                              invoice.location.contact.email}
                          </Table.Cell>
                        </Table.Row>
                        <Table.Row>
                          <Table.Cell>
                            {
                              invoice.location &&
                              invoice.location.addresses &&
                              invoice.location.addresses.city}
                            ,
                            {
                              invoice.location &&
                              invoice.location.addresses &&
                              invoice.location.addresses.state}
                            ,
                            {
                              invoice.location &&
                              invoice.location.addresses &&
                              invoice.location.addresses.zip}
                          </Table.Cell>
                          <Table.Cell>{"Insert Website"}</Table.Cell>
                        </Table.Row>
                      </Table.Body>
                    </Table>
                  </Grid.Column>
                </Grid>
                <br />
                <Segment>
                  <Table compact>
                    <Table.Header>
                      <Table.Row>
                        <Table.HeaderCell>Invoice Details</Table.HeaderCell>
                        <Table.HeaderCell>Customer Details</Table.HeaderCell>
                      </Table.Row>
                    </Table.Header>

                    <Table.Body>
                      <Table.Row>
                        <Table.Cell>
                          Number:{" "}
                          {invoice.invoice && invoice.invoice.number}{" "}
                        </Table.Cell>
                        <Table.Cell>
                          Customer:{" "}
                          {
                            invoice.customer &&
                            invoice.customer.nameUpper}
                        </Table.Cell>
                      </Table.Row>
                      <Table.Row>
                        <Table.Cell>
                          Invoice Date:{" "}
                          {
                            invoice.invoice &&
                            invoice.invoice.invoiceDate}{" "}
                        </Table.Cell>
                        <Table.Cell>
                          Unit:{" "}
                          {
                            invoice.invoice &&
                            invoice.invoice.unit}
                        </Table.Cell>
                      </Table.Row>
                      <Table.Row>
                        <Table.Cell>
                          Due:{" "}
                          {invoice.invoice && invoice.invoice.dueDate}{" "}
                        </Table.Cell>
                        <Table.Cell>
                          Email:{" "}
                          {invoice.customer && invoice.customer.email}
                        </Table.Cell>
                      </Table.Row>
                    </Table.Body>
                  </Table>
                </Segment>
                <br />

                <Table padded="very" compact>
                  <Table.Header>
                    <Table.Row>
                      <Table.HeaderCell>Description</Table.HeaderCell>
                      <Table.HeaderCell>Qty</Table.HeaderCell>
                      <Table.HeaderCell>Tax</Table.HeaderCell>
                      <Table.HeaderCell>Amount</Table.HeaderCell>
                      <Table.HeaderCell>Total</Table.HeaderCell>
                    </Table.Row>
                  </Table.Header>

                  <Table.Body>
                    {
                      invoice.items &&
                      invoice.items.map((inv) => {
                        return (
                          <Table.Row>
                            <Table.Cell>{inv.description}</Table.Cell>
                            <Table.Cell>{inv.quantity}</Table.Cell>
                            <Table.Cell>${inv.taxAmount}</Table.Cell>
                            <Table.Cell>${inv.subtotal}</Table.Cell>
                            <Table.Cell>${inv.total}</Table.Cell>
                          </Table.Row>
                        );
                      })}
                  </Table.Body>
                </Table>
                <Grid columns={2}>
                  <Grid.Column>
                  </Grid.Column>
                  <Grid.Column>
                    <Table padded="very" compact>
                      <Table.Body>
                        <Table.Row>
                          <Table.Cell>Total</Table.Cell>
                          <Table.Cell textAlign="right">$ {invoice && invoice.items && invoice.items.reduce((partialSum, a) => partialSum + +a.total, 0)}</Table.Cell>
                        </Table.Row>
                      </Table.Body>
                    </Table>
                  </Grid.Column>
                </Grid>
              </Modal.Content>
            }
            <Modal.Actions>
              <Button
                icon="check"
                content="Close"
                onClick={() => this.setSecondOpen(false)}
              />
            </Modal.Actions>
          </Modal>

          <Modal
            onClose={() => this.setThirdOpen(false)}
            open={this.state.thirdOpen}
            size="mini"
            className="semanticModal"
          >
            <Modal.Header>
              {this.state.AccountJournal &&
                this.state.AccountJournal.Customer &&
                this.state.AccountJournal.Customer.first_name}{" "}
              {this.state.AccountJournal &&
                this.state.AccountJournal.Customer &&
                this.state.AccountJournal.Customer.last_name}
            </Modal.Header>
            <Modal.Content>
              <List horizontal>
                <List.Item>
                  <List.Icon name="mail" />
                  <List.Content>
                    <a
                      href={`mailto:${this.state.AccountJournal &&
                        this.state.AccountJournal.Customer &&
                        this.state.AccountJournal.Customer.email
                        }`}
                    >
                      {this.state.AccountJournal &&
                        this.state.AccountJournal.Customer &&
                        this.state.AccountJournal.Customer.email}
                    </a>
                  </List.Content>
                </List.Item>
                <List.Item>
                  <List.Icon name="phone" />
                  <List.Content>
                    <a
                      href={`mailto:${this.state.AccountJournal &&
                        this.state.AccountJournal.Customer &&
                        this.state.AccountJournal.Customer.phone_mobile
                        }`}
                    >
                      {this.state.AccountJournal &&
                        this.state.AccountJournal.Customer &&
                        this.state.AccountJournal.Customer.phone_mobile}
                    </a>
                  </List.Content>
                </List.Item>
              </List>
            </Modal.Content>
            <Modal.Actions>
              <Button
                icon="check"
                content="Close"
                onClick={() => this.setThirdOpen(false)}
              />
            </Modal.Actions>
          </Modal>
        </Modal>
      </>
    );
  }
}
export default withApollo(ReconcileDetails);
