import gql from "graphql-tag";

export const googleEventsGql = gql`
  query googleEvents(
    $startDate: String
    $endDate: String
    $timezone: String
    $location: String
  ) {
    googleEvents(
      fromDate: $startDate
      toDate: $endDate
      limit: 100
      timezone: $timezone
      location: $location
    ) {
      edges {
        node {
          id
          start
          end
          status
          summary
          organizer
          attendees
          etag
          description
        }
      }
    }
  }
`;

export const createEventGql = gql`
  mutation ($package: AddGeneralEventInput!) {
    addGeneralEvent(package: $package) {
      response
    }
  }
`;
